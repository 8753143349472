import { addDoc, collection, getDocs, query, updateDoc, where, orderBy, limit, Timestamp } from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";

export async function getCompletedRoundCaddie(uid, pastRoundDate) {
    // NOTE : use scheduleTime from "round" instead of field "created" if calculation not work correctly
    // NOTE2 : get only complete round score card must be "finished" status
    if (!uid) {
        console.error('incorrect uid', uid);
        return;
    }
    try {
        const scoreCollection = collection(firestore, 'scoreCards');
        const now = Timestamp.now();
        let q;

        if (pastRoundDate > 0) {
            // Calculate the timestamp for `pastRoundDate` days ago
            const pastTimestamp = new Timestamp(now.seconds - pastRoundDate * 24 * 60 * 60, now.nanoseconds);

            // Query to fetch rounds within the past `pastRoundDate` days
            q = query(
                scoreCollection,
                where('caddieId', '==', uid),
                where('status', '==', 'finished'),
                where('created', '>=', pastTimestamp),
                orderBy('created', 'desc')
            );
        } else {
            // If `pastRoundDate` is 0, fetch all completed rounds
            q = query(
                scoreCollection,
                where('caddieId', '==', uid),
                where('status', '==', 'finished'),
                orderBy('created', 'desc')
            );
        }

        const scoreSnapshot = await getDocs(q);
        const scoreList = []
        if (!scoreSnapshot.empty) {
            scoreSnapshot.forEach(doc => {
                const data = doc.data();
                if (data.status === 'finished') {
                    scoreList.push(data);
                }
            });
            return scoreList
        }
    } catch (error) {
        console.error('Fail to get completed round', error);
    }
}

export async function getServiceMindAndGuide(completedRound, uid) {
    if (!completedRound.length) {
        return { avgServiceMind: 0, avgGuide: 0, avgDoonine: 0, reviewList: [] };
    }
    
    try {
        const reviewList = []
        // Extract roundIds using map
        const roundIds = completedRound.map(round => round.roundId);

        // Create Firestore query
        const reviewCollection = collection(firestore, 'reviewCaddies');
        const q = query(
            reviewCollection,
            where('roundId', 'in', roundIds),
            where('caddieId', '==', uid)
        );

        // Fetch the documents
        const reviewSnapshot = await getDocs(q);
        if (reviewSnapshot.empty) return { avgServiceMind: 0, avgGuide: 0, avgDoonine: 0, reviewList: [] };

        // Calculate average ratings
        let sumServiceMind = 0, sumGuide = 0, sumDoonine = 0;

        for (const doc of reviewSnapshot.docs) {
            const { serviceRating, adviceRating } = doc.data();
            sumServiceMind += serviceRating || 0;
            sumGuide += adviceRating || 0;

            sumDoonine += serviceRating || 0;
            sumDoonine += adviceRating || 0;

            reviewList.push(doc.data())
        }

        const totalReviews = reviewSnapshot.size;

        // Check for zero to avoid division by zero
        const avgServiceMind = totalReviews === 0 ? 0 : (sumServiceMind / totalReviews).toFixed(2);
        const avgGuide = totalReviews === 0 ? 0 : (sumGuide / totalReviews).toFixed(2);
        const avgDoonine = totalReviews === 0 ? 0 : (sumDoonine / (totalReviews * 2)).toFixed(2);


        // console.log('service', avgServiceMind);
        // console.log('guide', avgGuide);

        return { avgServiceMind, avgGuide, avgDoonine, reviewList };
    } catch (error) {
        console.error('Error fetching reviews:', error);
        return { avgServiceMind: 0, avgGuide: 0, avgDoonine: 0, reviewList: [] };
    }
}

export async function getTotalRound(userId) {
    // get all round ignore score card "finished" status
    const roundCollection = collection(firestore, 'round');
    const q = query(roundCollection);

    try {
        const roundSnapshot = await getDocs(q);
        let count = 0;

        // Filter the results based on userId and caddie.isJoin
        if (!roundSnapshot.empty) {
            roundSnapshot.forEach(doc => {
                const data = doc.data();
                const userList = data.userList || [];

                // Check if the userId matches and caddie has joined
                const isCaddie = userList.some(user =>
                    user.caddie?.uid === userId && user.caddie?.isJoin
                );

                if (isCaddie) {
                    count++;
                }
            });
        }

        return count;
    } catch (error) {
        console.error("Error fetching rounds:", error);
        return 0;
    }
}