import React from 'react'
import ReactSwitch from 'react-switch'

const switchStyle = {
    width: 44,
    height: 20,
    onColor: "#EE3D7F",
    boxShadow: "0 0 2px 1px #fff",
    handleDiameter: 16,
};

export const ReactSwitchComp = ({ isChecked = false, handleChecked = () => console.warn('not found switch handle function') }) => {
    return (
        <ReactSwitch
            onChange={() => handleChecked(!isChecked)}
            checked={isChecked ? true : false}
            onColor={switchStyle.onColor}
            width={switchStyle.width}
            height={switchStyle.height}
            handleDiameter={switchStyle.handleDiameter}
            activeBoxShadow={switchStyle.boxShadow}
        />
    )
}

export const CheckboxComp = ({ isChecked = false, handleChecked = () => console.warn('not found check handle function') }) => {
    return (
        <input
            onChange={() => handleChecked(!isChecked)}
            checked={isChecked ? true : false}
            type="checkbox"
            className="react-checkbox"
            style={{
                width: "20px",
                height: "20px",
                accentColor: "#EE3D7F",
            }}
        />
    )
}