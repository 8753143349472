import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { firestore } from '../../../components/firebase/FirebaseConfig'
import { collection, query, where, getDocs } from 'firebase/firestore'
import LoadCircle from '../../../components/LoadCircle'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { defaultClubs } from '../../user/myperfprofile/module/clubDefaultData'

const PlayerBag = ({ user, isOpen, closeModal }) => {
    const userData = useSelector((state) => state.user.data);

    const [loadingClub, setLoadingClub] = useState(true);
    const [myClub, setMyclub] = useState([])

    useEffect(() => {

        // console.log(user);

        async function getMyClub() {
            const clubsCollection = collection(firestore, 'clubs');
            const q = query(clubsCollection, where("userId", "==", user.player.uid));
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const data = querySnapshot.docs[0].data();
                    setMyclub(data.clubs);
                } else {
                    setMyclub(defaultClubs);
                }
            } catch (e) {
                console.error("Error adding or updating document: ", e);
            } finally {
                setLoadingClub(false);
            }
        }
        if (user && user.player) {
            getMyClub()
        }

        return () => {
            setLoadingClub(true);
            setMyclub([]);
        }
    }, [user])

    if (!user || !user.player) return;

    return (
        <Modal show={isOpen} onHide={closeModal} centered>
            <Modal.Body>
                <Link to={user.player.uid === userData.uid ? '/userPage' : `/user?uid=${user.player.uid}`}>
                    <img src={user.player.profileImage} alt="player" width={50} height={50} className='rounded-circle me-2 player-color-outline' />
                    <h4 className='fw-600 d-inline m-0'>{`${user.player.firstName} ${user.player.lastName}`}</h4>
                </Link>
            </Modal.Body>

            <Modal.Body>
                {loadingClub ?
                    <div className=' d-flex justify-content-center p-5'>
                        <LoadCircle size='md' variant='dark' />
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-center align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#E9F6E9' }}>
                            <strong className="text-center py-1 px-3 fs-1 fw-bolder me-2 rounded-3 text-white" style={{ backgroundColor: '#81D77A' }}>{myClub.filter(club => club.inBag).length}</strong>
                            <strong className="fs-4">{`Clubs in ${user.player.firstName}'s bag`}</strong>
                        </div>
                        <div className="py-2 px-4 rounded-3" style={{ backgroundColor: '#EBF6FF' }}>
                            {myClub
                                .filter(club => club.inBag)
                                .sort((a, b) => b.distance - a.distance)
                                .slice(0, 14)
                                .map((club, index) => (
                                    <div key={index} className="d-flex justify-content-between align-items-center">
                                        <strong className="text-truncate me-4">{club.name}</strong>
                                        <div className="text-nowrap">{club.distance} {club.distance ? "yd" : null}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>

                <Link to={user.player.uid === userData.uid ? '/userPage' : `/user?uid=${user.player.uid}`}>
                    <button className='btn bg-current fw-600 text-white'>Profile</button>
                </Link>

                <button className='btn bg-current fw-600 text-white' onClick={closeModal}>OK</button>
            </Modal.Footer>
        </Modal>
    )
}

export default PlayerBag