import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CourseCaddie from "./CourseCaddie";
import { ReactSVG } from "react-svg";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { useSelector } from "react-redux";

function CourseCard({ value, favoriteCourses }) {
  const userData = useSelector((state) => state.user.data);
  //   const handleHoveredCourse = useCallback((newHoveredCourse) => {
  //     if (hoverTimeoutRef.current) {
  //       clearTimeout(hoverTimeoutRef.current);
  //     }

  //     if (hoveredCourseRef.current !== newHoveredCourse) {
  //       hoverTimeoutRef.current = setTimeout(() => {
  //         if (mapRef.current) {
  //           mapRef.current.setCenter({
  //             lat: newHoveredCourse.location.latLong._lat,
  //             lng: newHoveredCourse.location.latLong._long,
  //           });
  //           mapRef.current.setZoom(11);
  //           hoveredCourseRef.current = newHoveredCourse;
  //         }
  //       }, 300);
  //     }
  //   }, []);

  const handleFavoriteCourse = async (courseId, isCourseFavorited) => {
    const timestampInSecond = Math.round(new Date().getTime() / 1000);

    const favoriteCourseRef = collection(firestore, "favoriteCourses");
    const querySnapshot = await getDocs(
      query(
        favoriteCourseRef,
        where("userId", "==", userData.uid),
        where("courseId", "==", courseId)
      )
    );

    if (querySnapshot.empty) {
      // If no document exists, add a new one
      const docRef = await addDoc(favoriteCourseRef, {
        userId: userData.uid,
        status: "active",
        timestamp: timestampInSecond,
        courseId: courseId,
      });
    } else {
      // If document exists, update it
      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, {
          status: isCourseFavorited ? "inactive" : "active",
          timestamp: timestampInSecond,
        });
      });
    }
  };

  const isCourseFavorited = (courseId) => {
    return favoriteCourses.some(
      (course) => course.courseId === courseId && course.status === "active"
    );
  };

  return (
    <>
      <div
        className="card w-100 h-100 p-0 hover-card shadow-xss border-0 rounded-3 overflow-hidden me-1"
        // onMouseOver={() => handleHoveredCourse(value)}
        // onMouseLeave={() => clearTimeout(hoverTimeoutRef.current)}
      >
        <div className="card-image w-100 mb-3">
          <Link
            to={`/course-detail?courseId=${value?.courseId}`}
            state={value}
            className="position-relative d-block"
          >
            <img
              src={`${value?.coverImage}`}
              alt="hotel"
              className="w-100"
              style={{ height: "200px", objectFit: "cover" }}
            />
          </Link>
        </div>
        <div className="card-body pt-0">
          <div
            className="mb-2"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ marginRight: "10px" }}>
              <img
                src={value?.profileImage}
                alt="avatar"
                className="bg-image-cover rounded-circle"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>

            <div style={{ position: "relative", flexGrow: 1 }}>
              <h4
                style={{ marginBottom: "0px" }}
                className="fw-600 mt-0 mb-0"
              >
                <Link
                  to="/course-detail"
                  state={value}
                  className="text-dark text-grey-900"
                >
                  {value?.courseName}
                </Link>
              </h4>
              <h6
                style={{ marginTop: "0" }}
                className="fw-600 text-muted mt-0 mb-2"
              >
                {value?.location?.districtEng} , {value?.location?.provinceEng}
              </h6>
            </div>

            <div
              className="pointer"
              style={{ marginLeft: "auto" }}
              onClick={() =>
                handleFavoriteCourse(
                  value.courseId,
                  isCourseFavorited(value.courseId)
                )
              }
            >
              {isCourseFavorited(value.courseId) ? (
                <i
                  className="btn-round-sm font-md bi bi-bookmark-fill right-0"
                  style={{
                    color: "#E59E49",
                  }}
                ></i>
              ) : (
                <i
                  className="btn-round-sm font-md bi bi-bookmark right-0"
                  style={{
                    color: "#B0B5BD",
                  }}
                ></i>
              )}
            </div>
          </div>

          {/* <div className="">
            {value.features.nightGolf ? "Night Golf ," : null}
            {value.features.drivingRange ? "Driving Range ," : null}
            {value.additionalServices.restaurant ? "Restaurant ," : null}
            {value.additionalServices.banquetRooms ? "Banquet Rooms ," : null}
            {value.additionalServices.hotel ? "Hotel " : null}
          </div> */}

          <CourseCaddie courseData={value}></CourseCaddie>

          <div className="clearfix"></div>

          <span className="font-lg fw-700 mt-0 pe-3 ls-2 lh-32 d-inline-block text-success float-left">
            <span className="font-xs">฿</span>{" "}
            {value?.features?.startingPriceRange?.minPrice}{" "}
            <span className="font-xsssss text-grey-500">/ Rounds</span>{" "}
          </span>

          <Link
            to="/course-detail"
            state={value}
            className="position-relative d-block"
          >
            <i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i>
          </Link>
        </div>
      </div>
    </>
  );
}

export default CourseCard;
