import React, { useState, useEffect } from 'react'
import { useScore } from '../../ScoreContext/ScoreContext'
import styles from '../../scorestyle.module.scss'
import LoadCircle from '../../../../components/LoadCircle';
import { useSelector } from 'react-redux';
import { mapScores } from '../module/scoreCalculation';
import HoleNumber from '../../component/HoleNumber';
import ScoreInputPar from '../../component/scoreInput/stroke/ScoreInputPar';

const MatchPlayTopar = () => {
  const userData = useSelector((state) => state.user.data);
  const { roundData, courseHoles, userList, score } = useScore();

  const [isFinished, setIsFinished] = useState(true);

  useEffect(() => {
    if (!score || !userData) return;

    // console.log(score);

    const foundUser = score.find(item => (item.userId && item.userId === userData.uid) || (item.caddieId && item.caddieId === userData.uid));
    if (foundUser && (foundUser.status === 'finished' || foundUser.status === 'unfinished')) {
      setIsFinished(true);
    } else {

      setIsFinished(false);
    }
  }, [score, userData])

  if (!roundData || !courseHoles || !userList || !score)
    return (
      <div className='p-5 bg-light theme-light-bg text-center'>
        <LoadCircle size='lg' variant='dark' />
      </div>
    );

  userList.sort((a, b) => a.row - b.row);

  const courseDet = courseHoles.holeDetails;
  courseDet.sort((a, b) => a.holeNumber - b.holeNumber);

  // console.log(courseDet);
  const mappedScore = mapScores(courseDet, score, userList, userData.uid);

  // console.log(mappedScore);

  return (
    <>
      <div className={`${styles.scorebody}`}
        style={{
          gridTemplateRows: `repeat(${mappedScore.length},1fr)`,
          width: `calc(${userList.length + 1} * 200px - 70px)`,
          marginBottom: '5px'
        }}>
        {mappedScore.map((hole, holeIdx) =>
          <React.Fragment key={holeIdx}>
            <HoleNumber hole={hole} />
            {hole.scores.map((score, scoreIdx) =>
              <div key={scoreIdx}
                className='bg-light theme-dark-bg p-2 rounded-3'
                style={{ pointerEvents: isFinished ? 'none' : 'auto', marginBottom: '-5px' }}>
                <ScoreInputPar score={score} />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </>
  )
}

export default MatchPlayTopar