import React from 'react'
import styles from '../scorestyle.module.scss'
import useModalWithHistory from '../../../components/ModalPopState'
import HoleDetail from './HoleDetail';

const HoleNumber = ({ hole }) => {
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();


    return (
        <>
            <div className={`d-flex justify-content-end align-items-center bg-light theme-dark-bg rounded-3 ${styles.scorehole} pointer`}
                style={{ marginBottom: '-5px' }}
                onClick={() => openModal()}
            >
                <div className='mx-1'>
                    <span className='fw-600 fs-1 text-current'>{hole.holeDetail.holeNumber}</span>
                </div>
                <div className='d-flex flex-column mx-1'>
                    <h5 className='fw-600 m-1'>{hole.holeDetail.par}</h5>
                    <span className='fw-600 fs-5 m-1 text-muted'>{hole.holeDetail.index}</span>
                </div>
            </div>

            <HoleDetail
                hole={hole.holeDetail}
                isOpen={isModalOpen}
                closeModal={closeModal}
            />
        </>
    )
}

export default HoleNumber