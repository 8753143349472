import React from 'react'

import Header from '../../../components/Header'
import Leftnav from '../../../components/Leftnav'
import Rightchat from '../../../components/Rightchat'
import Popupchat from '../../../components/Popupchat'
import Appfooter from '../../../components/Appfooter'

import { WizardProvider } from './wizard/wizardContext'
import MainWizard from './wizard/MainWizard'
import MainWizardEdit from './wizard/MainWizardEdit'
import { useSearchParams } from 'react-router-dom'

import './wizard/wizardstyle.css'

const CreateRoundPage = () => {

    const [searchParams] = useSearchParams();

    const roundId = searchParams.get('edit');

    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />
            <WizardProvider>
                {roundId ?
                    <MainWizardEdit roundId={roundId} />
                    :
                    <MainWizard />
                }
            </WizardProvider>
            <Popupchat />
            <Appfooter />
        </>
    )
}

export default CreateRoundPage