import { collection, doc, getDoc, getDocs, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";

export async function denyRound(roundId, userId) {

    if (!roundId || !userId) {
        console.error('Round ID', roundId);
        console.error('UID', userId);
        return
    }

    const roundCollection = collection(firestore, 'round');
    const roundActivityCollection = collection(firestore, 'roundActivity');

    try {
        const roundDoc = doc(roundCollection, roundId);
        const roundSnapshot = await getDoc(roundDoc);

        if (roundSnapshot.exists()) {

            // update user in round
            const roundRef = roundSnapshot.ref;
            const roundData = roundSnapshot.data();
            const roundUserList = roundData.userList;

            const foundUser = roundUserList.find(item => (item.player && item.player.uid === userId) || (item.caddie && item.caddie.uid === userId));
            if (foundUser) {
                if (foundUser.player && foundUser.player.uid === userId) {
                    foundUser.player = null;
                } else {
                    foundUser.caddie = null;
                }

                await updateDoc(roundRef, {
                    userList: roundUserList,
                    lastUpdate: serverTimestamp()
                })

                // update user in round activity
                const roundActivity_q = query(roundActivityCollection, where('roundId', '==', roundId));
                const roundActivitySnap = await getDocs(roundActivity_q);

                if (!roundActivitySnap.empty) {
                    const activityRef = roundActivitySnap.docs[0].ref;
                    const activityData = roundActivitySnap.docs[0].data();
                    const activityUserList = activityData.userList;
                    const filteredUser = activityUserList.filter(item => item !== userId);

                    await updateDoc(activityRef, {
                        userList: filteredUser,
                        lastUpdate: serverTimestamp()
                    })

                } else {
                    console.warn('activity not existed');
                }
            }
        } else {
            console.warn('round not existed');
        }
    } catch (error) {
        console.error('Failed to deny', error);
    }
}