
export const handleAddRow = (cardRow) => {
    if (cardRow.length < 6) {
        const updatedCardRow = [
            ...cardRow,
            {
                player: null,
                caddie: null,
                row: cardRow.length
            }
        ];

        return updatedCardRow;
    }
};

export const handleRemoveRow = (cardRow, rowNum) => {
    const updatedCardRow = cardRow.filter((row) => row.row !== rowNum);

    const reIndexedRows = updatedCardRow.map((row, index) => ({
        ...row,
        row: index
    }));
    return reIndexedRows;
};

export const handleRemoveBoth = (cardRow, rowNum) => {
    const updatedCardRow = cardRow.map((row) => {
        if (row.row === rowNum) {
            return {
                ...row,
                player: null,
                caddie: null
            };
        }
        return row;
    });
    return updatedCardRow;
};

export const handleRemovePlayer = (cardRow, rowNum) => {
    const updatedCardRow = cardRow.map((row) => {
        if (row.row === rowNum) {

            return {
                ...row,
                player: null,
            };
        }
        return row;
    });
    return updatedCardRow;
};

export const handleRemoveCaddie = (cardRow, rowNum) => {
    const updatedCardRow = cardRow.map((row) => {
        if (row.row === rowNum) {
            return {
                ...row,
                caddie: null,
            };
        }
        return row;
    });
    return updatedCardRow;
};