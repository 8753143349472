import React, { useEffect, useState, useMemo } from 'react'

import { firestore } from '../../../components/firebase/FirebaseConfig';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ViewCaddieReqStatus = ({ userData }) => {

    const [statusLoading, setStatusLoading] = useState(true);
    const [caddieStatus, setCaddieStatus] = useState(null);
    const [course, setCourse] = useState(null)

    useEffect(() => {
        async function getCaddieStatus(getCourseData) {
            try {
                setStatusLoading(true);
                const q = query(collection(firestore, "caddieRequests"), where("caddieId", "==", userData.uid), where("status", "!=", "removed"));
                const docSnap = await getDocs(q);

                if (!docSnap.empty) {
                    if (docSnap.size > 1) {
                        console.warn('found document more than one');
                    }
                    const docData = docSnap.docs[0].data();
                    const courseData = await getCourseData(docData.courseId);

                    setCourse(courseData);
                    setCaddieStatus(docData);
                } else {
                    setCaddieStatus({});
                }
                setStatusLoading(false);
            } catch (error) {
                setStatusLoading(false);
                setCaddieStatus({});
                console.error('Fail to get course data', error);
            }
        }

        async function getCourseData(courseId) {
            try {
                const docRef = query(collection(firestore, "courses"), where("id", "==", courseId));
                const docSnap = await getDocs(docRef);
                const data = docSnap.docs[0].data();
                return data;
            } catch (error) {
                console.error('Fail to get course data', error);
            }
        }
        if (userData) {
            getCaddieStatus(getCourseData)
        }
    }, [userData]);

    const renderStatus = useMemo(() => {
        if (statusLoading) {
            return (
                <strong className='text-muted fs-6 px-4 py-2 rounded-pill bg-white theme-light-bg'>Caddie Status – Loading
                    <Spinner className='ms-1' variant="grey" animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </strong>
            )
        }

        if (!caddieStatus) return;

        const officialColor = '#81D77A';
        const unoficialColor = '#4A74E5';
        const waitingColor = '#E59E49';

        const statusData = caddieStatus;


        if (statusData.status === 'approved') {
            if (statusData.approvedRole === 'official') {
                return (
                    <strong className='fs-6 px-4 py-2 rounded-pill text-white' style={{ backgroundColor: officialColor }}>Caddie Status – Official</strong>
                )
            } else {
                return (
                    <strong className='fs-6 px-4 py-2 rounded-pill text-white' style={{ backgroundColor: unoficialColor }}>Caddie Status – Confirm</strong>
                )
            }

        } else if (statusData.status === 'pending') {
            return (
                <strong className='fs-6 px-4 py-2 rounded-pill text-white' style={{ backgroundColor: waitingColor }}>Caddie Status – Waiting</strong>
            )
        } else if (statusData.status === 'rejected') {
            return (
                <strong className='text-muted fs-6 px-4 py-2 rounded-pill bg-white theme-light-bg'>Caddie Status – No Status</strong>
            )
        } else {
            return (
                <strong className='text-muted fs-6 px-4 py-2 rounded-pill bg-white theme-light-bg'>Caddie Status – No Status</strong>
            )
        }

    }, [caddieStatus, statusLoading])

    return (
        <>
            <div className="text-center p-3 bg-light theme-dark-bg rounded-3 mb-1" style={{ borderRadius: 10 }}>
                {!statusLoading ?
                    <>
                        {course ?
                            <Link to={`/course-detail?courseId=${course?.id}`} onClick={() => window.scrollTo(0, 0)}>
                                <figure>
                                    <img src={course?.profileImage} className='mb-2' alt="course" width={60} height={60} style={{ objectFit: 'contain' }} /><br />
                                    <h5 className='text-truncate'><strong>{course?.courseName}</strong></h5>
                                    <h6 className='fw-600 text-truncate'>{course?.location?.districtEng}, {course?.location?.provinceEng}</h6>
                                </figure>
                            </Link>
                            : null}
                        {renderStatus}
                    </>
                    :
                    <Spinner className='ms-1' variant="grey" animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
            </div>
        </>
    )
}

export default ViewCaddieReqStatus