export function holesDef(holes) {

    switch (holes) {
        case '1':
        case '9holes':
            return '9 holes'

        case '2':
        case '18holes':
            return '18 holes'

        case "9 holes":
            return '9holes'

        case "18 holes":
            return '18holes'

        default:
            console.warn('holes type not found', holes);
            return ''
    }
}

export function gameModeDef(scoring) {
    switch (scoring) {
        case 'str':
            return 'Stroke Play';
        case 'stb':
            return 'Stableford';
        case 'mp':
            return 'Match Play';

        case 'Stroke Play':
            return 'str';
        case 'Stableford':
            return 'stb';
        case 'Match Play':
            return 'mp';

        default:
            console.warn('scoring type not found', scoring);
            return '';
    }
}

export function handicapDef(handicap) {
    switch (handicap) {
        case "scratch":
            return 'scratch'
        case 'whs':
            return 'world_handicap_system'
        case 's36':
            return 'system36'

        case 'world_handicap_system':
            return 'whs'
        case 'system36':
            return 's36'


        default:
            console.warn('scoring type not found', handicap);
            return '';
    }
}

export function scoringDef(mode, handicap) {

    switch (mode) {
        case 'str':
        case 'mp':
            switch (handicap) {
                case 'scratch':
                    return ['Gross', 'To Par'];

                case 'whs':
                    return ['Gross-WHS', 'To Par-WHS'];

                case 's36':
                    return ['Gross-S36', 'To Par-S36'];

                default:
                    console.error('Undefine handicap', handicap);
                    break;
            }
            break;

        case 'stb':
            switch (handicap) {
                case 'scratch':
                    return ['Gross'];

                case 'whs':
                    return ['Gross-WHS'];

                case 's36':
                    return ['Gross-S36'];

                default:
                    console.error('Undefine handicap', handicap);
                    break;
            }
            break;

        default:
            console.error('Undefine mode', mode);
            break;
    }

}