import { collection, query, where, getDocs, addDoc, updateDoc, doc } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import { or } from "firebase/firestore";
import { addFriendnotifications } from "../notification/sentNotification";

export async function addFriend(friendUid, userUid) {
    // console.log('friendUid', friendUid);
    // console.log('userUid', userUid);


    let listId = [];
    const q = query(
        collection(firestore, "friends"),
        or(
            where("friendId", "==", userUid),
            where("userId", "==", userUid)
        )
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {

        if (doc.data().status === 'active') {
            listId.push(doc.data().friendId)
            listId.push(doc.data().userId)
        }

    });
    listId = [...new Set(listId)];
    listId = listId.filter(item => item !== userUid);

    if (listId.includes(friendUid)) {
        console.log('already friend')
        return
    } else {


        let listIdFriendRequest_pending = [];
        let listIdFriendRequest_accept = [];
        const qFriendRequest = query(
            collection(firestore, "friendRequests"),
            or(
                where("fromUserId", "==", userUid),
                where("toUserId", "==", userUid)
            )
        );
        const querySnapshotFriendRequest = await getDocs(qFriendRequest);
        querySnapshotFriendRequest.forEach(async (docFR) => {

            if (docFR.data().status === 'pending') {
                listIdFriendRequest_pending.push(docFR.data().fromUserId)
                listIdFriendRequest_pending.push(docFR.data().toUserId)
            }

            if (docFR.data().status === 'accept') {
                listIdFriendRequest_accept.push(docFR.data().fromUserId)
                listIdFriendRequest_accept.push(docFR.data().toUserId)
            }

        });
        listIdFriendRequest_pending = [...new Set(listIdFriendRequest_pending)];
        listIdFriendRequest_pending = listIdFriendRequest_pending.filter(item => item !== userUid);
        // console.log('listIdFriendRequest_pending', listIdFriendRequest_pending)

        listIdFriendRequest_accept = [...new Set(listIdFriendRequest_accept)];
        listIdFriendRequest_accept = listIdFriendRequest_accept.filter(item => item !== userUid);
        // console.log('listIdFriendRequest_accept', listIdFriendRequest_accept)

        if (listIdFriendRequest_pending.includes(friendUid)) {
            console.log('already friend request pending')
            return
        }

        if (listIdFriendRequest_accept.includes(friendUid)) {
            console.log('already friend request accept')
            return
        }

        if (!listIdFriendRequest_pending.includes(friendUid) && !listIdFriendRequest_accept.includes(friendUid)) {
            console.log('ยังไม่มีการเพิ่มเพื่อน');
            const timeStampInSeconds = Math.floor(Date.now() / 1000);

            try {
                const docRef = await addDoc(collection(firestore, "friendRequests"), {
                    requestId: '',
                    fromUserId: userUid,
                    toUserId: friendUid,
                    status: "pending",
                    timestamp: timeStampInSeconds
                });
                addFriendnotifications(userUid, friendUid, timeStampInSeconds);
                return 'addFriend_complete';
            } catch (error) {
                return error;
            }
        }


    }

}