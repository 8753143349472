import React, { Fragment, useEffect, useState } from "react";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import { useSelector } from "react-redux";
import { Scanner } from "@yudiel/react-qr-scanner";
import { use } from "i18next";

function ScanQRCodeModal({
  scanQRCodeModal,
  setScanQRCodeModal,
  setFindDooNineId,
  findDooNineId,
  handleFindFriendByDooNineId,
}) {
  const userData = useSelector((state) => state.user.data);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setResult(null);
  }, [scanQRCodeModal]);

  const handleScan = async (result) => {

    // console.log(result);
    // console.log(result[0].rawValue);

    const urlObj = new URL(result[0].rawValue);
    const ref = urlObj.searchParams.get("ref");

    const docRef = doc(firestore, "userShareLinks", ref);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      //   console.log("Document data:", docSnap.data().userId);

      const q = query(
        collection(firestore, "users"),
        where("uid", "==", docSnap.data().userId)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data().dooNineId);
        setFindDooNineId(doc.data().dooNineId);
        setResult(doc.data().dooNineId);
        setScanQRCodeModal(false);
        // handleFindFriendByDooNineId();
      });
    } else {
      console.log("No such document!");
      Swal.fire({
        title: "Link Invalid or Expired",
        html: `
        <h5 class="fw-600">This link is expired or unavailable.</h5>
       <h5 class="fw-600">Your friend may have disabled friend search.</h5>
        <h5 class="fw-600">Please contact them or try a new link.</h5>
      `,
        icon: "error",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    if (result) {
      handleFindFriendByDooNineId();
    }
  }, [findDooNineId]);

  return (
    <>
      <Modal
        show={scanQRCodeModal}
        onHide={() => setScanQRCodeModal(false)}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="fw-600 mb-0 mt-0">Scan QR Code</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Scanner onScan={(result) => handleScan(result)} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ScanQRCodeModal;
