import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  increment,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function AddFriend() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const courseId = params.get("ref");

  useEffect(() => {
    async function fetchData() {
      const docRef = doc(firestore, "userShareLinks", courseId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const q = query(
          collection(firestore, "users"),
          where("uid", "==", docSnap.data().userId)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc2) => {
          try {
            const ref = doc(firestore, "userShareLinks", courseId);
            await updateDoc(ref, {
              linkClickCount: increment(1),
            });
          } catch (error) {
            console.error("Error updating document: ", error);
          }

          navigate(`/myfriend`, {
            state: { dooNineId: doc2.data().dooNineId, type: "link" },
          });
        });
      } else {
        Swal.fire({
          title: "Link Invalid or Expired",
          html: `
          <h5 class="fw-600">This link is expired or unavailable.</h5>
         <h5 class="fw-600">Your friend may have disabled friend search.</h5>
          <h5 class="fw-600">Please contact them or try a new link.</h5>
        `,
          icon: "error",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        }).then(async (result) => {
          navigate("/home");
        });
      }
    }

    fetchData();
  }, [courseId]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <div className="d-flex w-100 flex-column flex-md-row ">
                    <h2 className="d-flex fw-700 mb-2 mb-md-0 mt-0 font-md text-grey-900 align-items-center">
                      Add Friends
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default AddFriend;
