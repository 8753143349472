import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import { or } from "firebase/firestore";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { ReactSVG } from "react-svg";
import { Dropdown, ModalFooter } from "react-bootstrap";
import "./MyFriedn.css";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import { addFriend } from "../../components/friend/AddFriend";
import { UnFriend } from "../../components/friend/UnFriend";
import { blockFriend } from "../../components/friend/BlockFriend";
import {
  favFriend,
  favFriendCaddie,
  unfavFriend,
  unfavFriendCaddie,
} from "../../components/friend/FavFriend";
import Label from "../createlabel/Label";
import Search from "./Search";
import ModalFilter from "./ModalFilter";
import StackFilter from "./StackFilter";

import MyQRCodeModal from "../addfriend/MyQRCodeModal";
import ScanQRCodeModal from "../addfriend/ScanQRCodeModal";
import { useLocation } from "react-router-dom";
import AddFriendModal from "./component/AddFriendModal";
import LoadCircle from "../../components/LoadCircle";
import { ChatTo } from "../../components/chat/Chat";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;
function MyFriend() {
  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();

  const [QRCodeModal, setQRCodeModal] = useState(false);
  const [scanQRCodeModal, setScanQRCodeModal] = useState(false);

  const { state } = useLocation();

  const [sortData, setSortData] = useState({
    isStar: { state: false },
    isPro: { state: false },
    isLabel: { state: false, selectedLabels: [] },
    isFavorites: { state: false },
    age: { state: false, min: 18, max: 40 },
    isBlocks: { state: false },
  });

  const [tempSortData, setTempSortData] = useState({
    isStar: { state: true },
    isPro: { state: true },
    isLabel: { state: true, selectedLabels: [] },
    isFavorites: { state: true },
    age: { state: true, min: 18, max: 40 },
    isBlocks: { state: true },
  });

  const [search, setSearch] = useState({
    text: {
      state: false,
      value: "",
    },
  });

  const allStatesFalse = Object.keys(sortData).every(
    (key) => sortData[key].state === false
  );

  const trueStates = Object.keys(sortData).filter(
    (key) => sortData[key].state === true
  );

  const [memberList, setMemberList] = useState([]);
  const [findDooNineId, setFindDooNineId] = useState("");
  const [show, setShow] = useState(false);
  const [resultDooNine, setResultDooNine] = useState({});
  const [resultDooNineState, setResultDooNineState] = useState(""); //'', 'myself', 'user-not-found', 'friend', 'not-friend'
  const [loadingCard, setLoadingCard] = useState(null);
  const [triggerFriendList, setTriggerFriendList] = useState(true);
  const [isFriendRequest, setIsFriendRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unfriendedList, setUnfriendedList] = useState([]);
  const [favList, setFavList] = useState([]);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [selectedUserLabel, setSelectedUserLabel] = useState(null);
  const [userLabels, setUserLabels] = useState([]);
  const [triggerGetLabel, setTriggerGetLabel] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState(memberList);
  const [favoriteFriendsList, setFavoriteFriendsList] = useState({});
  const [listUserBlocked, setListUserBolcked] = useState([]);
  const [isLoadingCard, setIsLoadingCard] = useState(false);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleClose = () => {
    setShow(false);
    setIsFriendRequest(false);
    setResultDooNineState("");
    setResultDooNine({});
  };
  const handleShow = () => {
    setShow(true);
    setResultDooNineState("");
    setResultDooNine({});
  };

  useEffect(() => {
    async function fetchData() {
      const q = query(
        collection(firestore, "labels"),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      let dataArray = [];
      querySnapshot.forEach((doc2) => {
        const data = doc2.data();
        dataArray.push({ docId: doc2.id, ...data });
      });
      setUserLabels(dataArray);
    }
    if (userData) {
      fetchData();
    }
  }, [userData, triggerGetLabel]);

  useEffect(() => {
    async function fetchData() {
      const q = query(
        collection(firestore, "blockedUsers"),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      let dataArray = [];
      querySnapshot.forEach((doc2) => {
        const data = doc2.data();
        dataArray.push(data.blockedUserId);
      });
      setListUserBolcked(dataArray);
    }
    if (userData) {
      fetchData();
    }
  }, [userData, tempSortData]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingCard(true);
      let listId = [];
      let memberListArray = [];

      if (sortData.isBlocks.state === false) {
        const q = query(
          collection(firestore, "friends"),
          or(
            where("friendId", "==", userData?.uid),
            where("userId", "==", userData?.uid)
          )
        );

        const querySnapshot = await getDocs(q);
        const friendPromises = querySnapshot.docs.map(async (doc) => {
          if (doc.data().status === "active") {
            listId.push(doc.data().friendId);
            listId.push(doc.data().userId);
          }
        });

        await Promise.all(friendPromises);
        listId = [...new Set(listId)].filter((item) => item !== userData?.uid);

        const userPromises = listId.map(async (id) => {
          const q2 = query(
            collection(firestore, "users"),
            where("uid", "==", id)
          );
          const querySnapshot2 = await getDocs(q2);
          querySnapshot2.forEach((doc2) => {
            memberListArray.push({
              imageUrl: doc2.data().profileImage || "user.png",
              name: `${doc2.data().firstName} ${doc2.data().lastName}`,
              uid: doc2.data().uid,
              dooNineId: doc2.data().dooNineId,
              role: doc2.data().role,
              isStar: doc2.data().isStar || false,
              isPro: doc2.data().isPro || false,
              coverImage: doc2.data().coverImage || "",
              birthDate: doc2.data().birthDate,
            });
          });
        });

        await Promise.all(userPromises);
      } else {
        const q = query(collection(firestore, "users"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          memberListArray.push({
            imageUrl: doc.data().profileImage || "user.png",
            name: `${doc.data().firstName} ${doc.data().lastName}`,
            uid: doc.data().uid,
            dooNineId: doc.data().dooNineId,
            role: doc.data().role,
            isStar: doc.data().isStar || false,
            isPro: doc.data().isPro || false,
            coverImage: doc.data().coverImage || "",
            birthDate: doc.data().birthDate,
          });
        });
      }

      const labelPromises = memberListArray.map(async (member, index) => {
        const q3 = query(
          collection(firestore, "labeledUsers"),
          where("friendId", "==", member.uid),
          where("userId", "==", userData.uid)
        );
        const querySnapshot3 = await getDocs(q3);

        let labels = [];
        querySnapshot3.forEach((doc2) => {
          const data = doc2.data();
          if (data.labels) {
            labels = data.labels.map((labelId) => {
              const item = userLabels.find((item) => item.docId === labelId);
              return item?.label;
            });
          }
        });
        memberListArray[index].labels = labels;
      });

      await Promise.all(labelPromises);

      setMemberList(memberListArray);
      setFilteredMembers(memberListArray);
      setIsLoadingCard(false);
    };

    if (userData) {
      fetchData();
    }
  }, [
    userData,
    triggerFriendList,
    userLabels,
    triggerGetLabel,
    sortData.isBlocks.state,
  ]);

  // Debug mode for check Data base User and SendBird
  useEffect(() => {
    async function fetchData() {
      // console.log(userData.uid)

      const q = query(
        collection(firestore, "users"),
        where("uid", "==", userData.uid)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc2) => {
        const data = doc2.data();

        if ("sendBirdAccessToken" in data) {
          // console.log('The key sendBirdAccessToken exists:', data.sendBirdAccessToken);
        } else {
          // console.log('The key sendBirdAccessToken does not exist.');

          try {
            const response = await axios.put(
              "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/users/" +
              data.dooNineId,
              {
                issue_access_token: true,
                profile_url: data.profileImage,
                nickname: data.firstName + " " + data.lastName,
              },
              {
                headers: {
                  "Content-Type": "application/json; charset=utf8",
                  "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
                },
              }
            );

            const washingtonRef = doc(firestore, "users", doc2.id);
            await updateDoc(washingtonRef, {
              sendBirdAccessToken: response.data.access_token,
            });
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    const fav = [];

    async function getFavUserCaddie() {
      const caddieQuery = query(
        collection(firestore, "favoriteFriends"),
        where("userId", "==", userData.uid),
        where("status", "==", "active")
      );

      const querySnapshot = await getDocs(caddieQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          fav.push(doc.data().caddieId);
        });
      }
    }

    async function getFavUser() {
      const playerQuery = query(
        collection(firestore, "favoritePlayers"),
        where("userId", "==", userData.uid),
        where("status", "==", "active")
      );

      const querySnapshot = await getDocs(playerQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          fav.push(doc.data().playerId);
        });
      }
    }

    if (userData) {
      getFavUserCaddie();
      getFavUser();

      setFavoriteFriendsList(fav);
    }
  }, [userData, sortData, tempSortData]);

  useEffect(() => {
    const filteredList = memberList.filter((member) => {
      const isStarMatch =
        !sortData.isStar.state || (sortData.isStar.state && member.isStar);

      const isProMatch =
        !sortData.isPro.state || (sortData.isPro.state && member.isPro);

      const hasValidBirthDate = member.birthDate.year;

      const isAgeMatch =
        !sortData.age.state ||
        (hasValidBirthDate &&
          2024 - member.birthDate.year >= sortData.age.min &&
          2024 - member.birthDate.year <= sortData.age.max);

      const isFavoriteMatch =
        !sortData.isFavorites.state ||
        Object.values(favoriteFriendsList).includes(member.uid);

      const selectedLabels =
        sortData.isLabel?.selectedLabels.map((option) => option.label) || [];
      const isLabelMatch =
        !sortData.isLabel?.state ||
        selectedLabels.length === 0 ||
        (member.labels &&
          selectedLabels.every((label) => member.labels.includes(label)));

      const isBlockMatch =
        !sortData.isBlocks.state ||
        Object.values(listUserBlocked).includes(member.uid);

      const isSearchMatch = !search.text.state || (
        member.name.toLowerCase().includes(search.text.value) ||
        member.dooNineId.toLowerCase().includes(search.text.value) ||
        member.role.toLowerCase().includes(search.text.value) ||
        (member.labels &&
          member.labels.some((label) =>
            label.toLowerCase().includes(search.text.value)
          ))
      );
      // ต้อง match ทุก filter ที่ถูกเปิดใช้งานพร้อมกัน
      return (
        isStarMatch &&
        isProMatch &&
        isFavoriteMatch &&
        isLabelMatch &&
        isAgeMatch &&
        isBlockMatch &&
        isSearchMatch
      );
    });

    setFilteredMembers(filteredList);
  }, [sortData, memberList, search]);

  const handleChat = async (dooNineId) => {
    const result = await ChatTo(userData.dooNineId, dooNineId);
    if (result) {
      navigate("/chat-room?channelId=" + result[0].channel_url);
    }
  };

  const handleUnfriend = async (friendId) => {
    const isUnFriend = await UnFriend(userData.uid, friendId);
    if (isUnFriend) {
      setUnfriendedList((prev) => [...prev, friendId]);
    }
  };

  const handleBlock = async (role, friendId) => {
    const isBlocked = await blockFriend(userData.uid, friendId);
    if (isBlocked) {
      const isUnFriend = await UnFriend(userData.uid, friendId);
      if (isUnFriend) {
        handleFav(role, friendId, "unfav");
        setUnfriendedList((prev) => [...prev, friendId]);
      }
    }
  };

  useEffect(() => {
    const fav = [];

    async function getFavUserCaddie() {
      const caddieQuery = query(
        collection(firestore, "favoriteFriends"),
        where("userId", "==", userData.uid),
        where("status", "==", "active")
      );

      const querySnapshot = await getDocs(caddieQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          fav.push(doc.data().caddieId);
        });
      }
    }

    async function getFavUser() {
      const playerQuery = query(
        collection(firestore, "favoritePlayers"),
        where("userId", "==", userData.uid),
        where("status", "==", "active")
      );

      const querySnapshot = await getDocs(playerQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          fav.push(doc.data().playerId);
        });
      }
    }

    if (userData) {
      getFavUserCaddie();
      getFavUser();

      setFavList(fav);
    }
  }, [userData]);

  const handleFav = async (isCaddie, friendId, favType) => {
    if (userData && friendId) {
      if (favType === "fav") {
        if (isCaddie === "caddie") {
          setFavList((prev) => [...prev, friendId]);
          const isFav = await favFriendCaddie(userData?.uid, friendId);
        } else {
          setFavList((prev) => [...prev, friendId]);
          const isFav = await favFriend(userData?.uid, friendId);
        }
      } else {
        if (isCaddie === "caddie") {
          setFavList((prev) => prev.filter((id) => id !== friendId));
          const isUnFav = await favFriendCaddie(userData?.uid, friendId);
        } else {
          setFavList((prev) => prev.filter((id) => id !== friendId));
          const isUnFav = await favFriend(userData?.uid, friendId);
        }
      }
    }
  };

  const handleFindFriendByDooNineId = async () => {

    // check is findDooNineId is email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(findDooNineId)) {
      console.log("email");
    }

    const qDooNineId = query(
      collection(firestore, "users"),
      where("dooNineId", "==", findDooNineId)
    );

    const qEmail = query(
      collection(firestore, "users"),
      where("email", "==", findDooNineId)
    );

    const [querySnapshotDooNineId, querySnapshotEmail] = await Promise.all([
      getDocs(qDooNineId),
      getDocs(qEmail),
    ]);

    let userDoc = null;
    let canSearchByDooNineId = false;
    let canSearchByEmail = false;

    let isMySelfBlock = false;
    let isBlocked = false;

    if (querySnapshotDooNineId.size > 0) {
      userDoc = querySnapshotDooNineId.docs[0];
      canSearchByDooNineId = userDoc.data().privacy?.dooNineIdSearch ?? false;
    }

    if (querySnapshotEmail.size > 0) {
      userDoc = querySnapshotEmail.docs[0];
      canSearchByEmail = userDoc.data().privacy?.emailSearch ?? false;
    }

    //Check is Block
    const q_Block = query(collection(firestore, "blockedUsers"), where("userId", "==", userData.uid), where("blockedUserId", "==", userDoc.data().uid));
    const querySnapshot_Block = await getDocs(q_Block);
    querySnapshot_Block.forEach((docBlock) => {
      isMySelfBlock = true
    });


    //Check is Block
    const q_Blocked = query(collection(firestore, "blockedUsers"), where("userId", "==", userDoc.data().uid), where("blockedUserId", "==", userData.uid));
    const querySnapshot_Blocked = await getDocs(q_Blocked);
    querySnapshot_Blocked.forEach((docBlocked) => {
      isBlocked = true
    });




    if (
      findDooNineId == userData.dooNineId ||
      findDooNineId == userData.email
    ) {
      setResultDooNineState("myself");
    } else if (isMySelfBlock) {
      // console.log('เราบล็อคเขา');
      setResultDooNine(userDoc.data());
      setResultDooNineState("my-self-block");
    } else if (isBlocked) {
      // console.log('เขาบล็อคเรา');
      setResultDooNine(userDoc.data());
      setResultDooNineState("me-blocked-by-user");
    }
    else if (userDoc && (canSearchByDooNineId || canSearchByEmail)) {

      let isFriend = false;
      setIsFriendRequest(false);

      const q3 = query(
        collection(firestore, "friends"),
        where("friendId", "==", userDoc.data().uid),
        where("userId", "==", userData?.uid)
      );

      const q2 = query(
        collection(firestore, "friends"),
        where("friendId", "==", userData?.uid),
        where("userId", "==", userDoc.data().uid)
      );

      const [querySnapshot2, querySnapshot3] = await Promise.all([
        getDocs(q2),
        getDocs(q3),
      ]);

      querySnapshot2.forEach((doc2) => {
        if (doc2.data().status == "active") {
          isFriend = true;
        }
      });

      setResultDooNine(userDoc.data());

      querySnapshot3.forEach((doc3) => {
        if (doc3.data().status == "active") {
          isFriend = true;
        }
      });

      const queryFriendRequest = query(
        collection(firestore, "friendRequests"),
        where("fromUserId", "==", userData?.uid),
        where("toUserId", "==", userDoc.data().uid)
      );

      const querySnapshotFriendRequest = await getDocs(queryFriendRequest);
      querySnapshotFriendRequest.forEach((doc4) => {
        if (doc4.data().status == "pending") {
          setIsFriendRequest(true);
        }
      });

      setResultDooNineState(isFriend ? "friend" : "not-friend");
    } else {
      setResultDooNineState("user-not-found");
    }
  };


  const handleUnblock = (user) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to unblock.",
      icon: "warning",
      cancelButtonText: "Cancel",
      confirmButtonText: "Ok",
      showCancelButton: true,
      customClass: {
        cancelButton: "post-warning-cancel-btn",
        confirmButton: "post-warning-confirm-btn",
        actions: "post-warning-actions",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {

        const blockedUsersRef = collection(firestore, "blockedUsers");

        const q = query(blockedUsersRef, where("blockedUserId", "==", user.uid), where("userId", "==", userData.uid));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (docSnapshot) => {
          await deleteDoc(docSnapshot.ref);
        });


        handleClose();
        //Show success message
        Swal.fire({
          title: "Unblock Success",
          icon: "success",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "post-warning-confirm-btn",
          },
        })

      }
    });

  };


  const handleAddFriend = (friendId, userId) => {
    if (!isFriendRequest) {
      setIsLoading(true);

      setTimeout(() => {
        addFriend(friendId, userId);
        setIsFriendRequest(true);
        setIsLoading(false);
      }, 500);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to cancel the friend request.",
        icon: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Ok",
        showCancelButton: true,
        customClass: {
          cancelButton: "post-warning-cancel-btn",
          confirmButton: "post-warning-confirm-btn",
          actions: "post-warning-actions",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const q2 = query(
            collection(firestore, "friendRequests"),
            where("fromUserId", "==", userId),
            where("toUserId", "==", friendId)
          );

          const querySnapshot2 = await getDocs(q2);

          querySnapshot2.forEach(async (doc3) => {
            try {
              // ลบเอกสาร
              await deleteDoc(doc(firestore, "friendRequests", doc3.id));
              // console.log(`Document with ID ${doc3.id} deleted successfully.`);
            } catch (error) {
              console.error("Error deleting document:", error);
            }
          });
          setIsFriendRequest(false);
        }
      });
    }
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleSwitchChange = (checked, switchId) => {
    setTempSortData((prevState) => ({
      ...prevState,
      [switchId]: { ...prevState[switchId], state: checked },
    }));
  };

  const handleSubmit = () => {
    setSortData(tempSortData);
    handleCloseSortAndFilter();
  };

  const [showSortAndFilter, setShowSortAndFilter] = useState(false);
  const handleCloseSortAndFilter = () => {
    setTempSortData(sortData);
    setShowSortAndFilter(false);
  };
  const handleShowSortAndFilter = () => {
    setTempSortData(sortData);

    setShowSortAndFilter(true);
  };

  const handleInputChange = (e, field, type) => {
    const value = e && e.target ? e.target.value : e;

    setTempSortData((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], [type]: value },
    }));
  };
  const iconSrc = isFriendRequest
    ? "assets/images/myFriend/CaddieFriendFav-CancelRequest.svg" // SVG for "Cancel Request"
    : "assets/images/myFriend/CaddieFriendFav-AddFriend.svg";


  useEffect(() => {
    if (state) {
      setShow(true);
      setFindDooNineId(state.dooNineId);
    }
  }, [state]);

  useEffect(() => {
    if (state) {
      if (findDooNineId) {
        handleFindFriendByDooNineId();
      }
    }
  }, [findDooNineId]);




  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12 pageMyFriend">
                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <div className="d-flex w-100 flex-column flex-md-row ">
                    <h2 className="d-flex fw-700 mb-2 mb-md-0 mt-0 font-md text-grey-900 align-items-center">
                      My Friends
                    </h2>

                    <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 w-md-auto">
                      <Search
                        filteredMembers={filteredMembers}
                        setFilteredMembers={setFilteredMembers}
                        search={search}
                        setSearch={setSearch}
                      />

                      <div
                        onClick={() => handleShowSortAndFilter()}
                        style={{ cursor: "pointer" }}
                        className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
                      >
                        <i className="feather-filter font-xss text-grey-500"></i>
                      </div>

                      <a
                        onClick={handleShow}
                        id="add-friend-btn"
                        className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
                        style={{ cursor: "pointer" }}
                      >
                        <ReactSVG
                          src="assets/images/myFriend/CaddieFriendFav-Add FriendID.svg"
                          style={{ width: "25px" }}
                        />
                      </a>
                    </div>
                  </div>
                  <StackFilter
                    trueStates={trueStates}
                    sortData={sortData}
                    setSortData={setSortData}
                    setTempSortData={setTempSortData}
                    search={search}
                    setSearch={setSearch}
                  ></StackFilter>
                </div>

                {isLoadingCard ? (
                  <div className="d-flex justify-content-center">
                    <LoadCircle variant="dark" size="lg" />
                  </div>
                ) : (
                  <div className="row ps-2 pe-2">
                    {filteredMembers.map((value, index) => {
                      if (unfriendedList.includes(value?.uid)) return null;
                      return (
                        <div
                          key={index}
                          className="col-md-6 col-sm-6 pe-2 ps-2"
                        >
                          <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                            <div className="card-body d-flex p-3">
                              <figure
                                className="avatar mb-0 position-relative w100 z-index-1 me-3"
                                style={{
                                  flexShrink: 0,
                                  justifyContent: "center",
                                  display: "flex",
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  onClick={() => handleProfileClick(value?.uid)}
                                  src={value.imageUrl}
                                  alt="avatar"
                                  className="bg-image-cover float-left p-1 rounded-circle w-100"
                                  style={{
                                    cursor: "pointer",
                                    objectFit: "cover",
                                    backgroundColor:
                                      value.role === "caddie"
                                        ? "#EE3D7F"
                                        : "#4A74E5",
                                  }}
                                />
                              </figure>
                              <div className="flex-grow-1">
                                <h4
                                  className="fw-700 font-xsss mt-2 mb-1"
                                  onClick={() => handleProfileClick(value?.uid)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {value.name}
                                </h4>
                                <div className="icon-container d-flex flex-wrap">
                                  {value.role === "caddie" && value.isStar && (
                                    <span className="fw-600 d-inline-block z-index-1 ls-3 font-xssss text-white">
                                      <ReactSVG
                                        src="assets/icon/CaddieFriendFav-CaddieStar.svg"
                                        style={{ width: "25px" }}
                                      />
                                    </span>
                                  )}
                                  {value.role === "caddie" && value.isPro && (
                                    <span className="fw-600 d-inline-block z-index-1 ls-3 font-xssss text-white">
                                      <ReactSVG
                                        src="assets/icon/CaddieFriendFav-ProCaddie.svg"
                                        style={{ width: "25px" }}
                                      />
                                    </span>
                                  )}
                                </div>
                                <div className="icon-container d-flex flex-wrap mt-1">
                                  {value.labels?.map((label, index2) => (
                                    <span
                                      key={index2}
                                      className="badge text-center me-1 mb-1 fw-600"
                                      style={{
                                        // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        backgroundColor: "#F5F5F5",
                                        color: "#888888",
                                        // color:"#888888",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {label}
                                    </span>
                                  ))}
                                </div>
                              </div>
                              <Dropdown
                                className="drodownFriend"
                                drop="down-centered"
                              >
                                {loadingCard === value.uid ? (
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                ) : (
                                  <Dropdown.Toggle
                                    as="i"
                                    className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"
                                    id="dropdown-basic"
                                    style={{ cursor: "pointer", zIndex: 1000 }}
                                  ></Dropdown.Toggle>
                                )}
                                <Dropdown.Menu
                                  as={CustomDropdown}
                                  popperConfig={{
                                    modifiers: [
                                      {
                                        name: "flip",
                                        options: {
                                          altBoundary: true,
                                          rootBoundary: "viewport",
                                          padding: 8,
                                        },
                                      },
                                      {
                                        name: "preventOverflow",
                                        options: {
                                          altAxis: true,
                                          altBoundary: true,
                                          tether: false,
                                        },
                                      },
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, 10],
                                        },
                                      },
                                    ],
                                  }}
                                  style={{ zIndex: 1050 }}
                                >
                                  <Dropdown.Item
                                    onClick={() => handleChat(value.dooNineId)}
                                    style={{ display: "flex" }}
                                  >
                                    <ReactSVG
                                      src="assets/images/CaddieFriendFav-ChatDropDown.svg"
                                      style={{
                                        width: "25px",
                                        marginRight: "8px",
                                        marginTop: "-1px",
                                      }}
                                    />
                                    Chat
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    style={{ display: "flex" }}
                                    onClick={() => {
                                      setSelectedUserLabel(value);
                                      setShowLabelModal(true);
                                    }}
                                  >
                                    <ReactSVG
                                      src="assets/images/dooNineIconV3/my-performance/profile-label.svg"
                                      style={{
                                        width: "25px",
                                        marginRight: "8px",
                                        marginTop: "-1px",
                                      }}
                                    />
                                    Label
                                  </Dropdown.Item>
                                  {favList.includes(value.uid) ? (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleFav(
                                          value.role,
                                          value.uid,
                                          "unfav"
                                        )
                                      }
                                      style={{ display: "flex" }}
                                    >
                                      <ReactSVG
                                        src="assets/images/dooNineIconV3/my-performance/profile-fav.svg"
                                        style={{
                                          width: "25px",
                                          marginRight: "8px",
                                          marginTop: "-1px",
                                        }}
                                      />
                                      Favorites
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleFav(value.role, value.uid, "fav")
                                      }
                                      style={{ display: "flex" }}
                                    >
                                      <ReactSVG
                                        src="assets/images/dooNineIconV3/my-performance/profile-unfav.svg"
                                        style={{
                                          width: "25px",
                                          marginRight: "8px",
                                          marginTop: "-1px",
                                        }}
                                      />
                                      Favorites
                                    </Dropdown.Item>
                                  )}
                                  <hr />
                                  <Dropdown.Item
                                    onClick={() => handleUnfriend(value.uid)}
                                    style={{ display: "flex" }}
                                  >
                                    <ReactSVG
                                      src="assets/images/dooNineIconV3/my-performance/profile-unfriend.svg"
                                      style={{
                                        width: "25px",
                                        marginRight: "8px",
                                        marginTop: "-1px",
                                      }}
                                    />
                                    Unfriend
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleBlock(value.role, value.uid)
                                    }
                                    style={{ display: "flex" }}
                                  >
                                    <ReactSVG
                                      src="assets/images/dooNineIconV3/my-performance/profile-block.svg"
                                      style={{
                                        width: "25px",
                                        marginRight: "8px",
                                        marginTop: "-1px",
                                      }}
                                    />
                                    Block
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>


      <AddFriendModal
        show={show}
        handleClose={handleClose}
        setFindDooNineId={setFindDooNineId}
        findDooNineId={findDooNineId}
        handleFindFriendByDooNineId={handleFindFriendByDooNineId}
        resultDooNineState={resultDooNineState}
        resultDooNine={resultDooNine}
        handleProfileClick={handleProfileClick}
        handleChat={handleChat}
        handleAddFriend={handleAddFriend}
        isLoading={isLoading}
        iconSrc={iconSrc}
        isFriendRequest={isFriendRequest}
        setQRCodeModal={setQRCodeModal}
        setScanQRCodeModal={setScanQRCodeModal}
        handleUnblock={handleUnblock}
      />


      <MyQRCodeModal
        QRCodeModal={QRCodeModal}
        setQRCodeModal={setQRCodeModal}
      />

      <ScanQRCodeModal
        scanQRCodeModal={scanQRCodeModal}
        setScanQRCodeModal={setScanQRCodeModal}
        findDooNineId={findDooNineId}
        setFindDooNineId={setFindDooNineId}
        handleFindFriendByDooNineId={handleFindFriendByDooNineId}
      />

      <Label
        triggerFriendList={triggerFriendList}
        setTriggerFriendList={setTriggerFriendList}
        showLabelModal={showLabelModal}
        setShowLabelModal={setShowLabelModal}
        setSelectedUserLabel={setSelectedUserLabel}
        selectedUserLabel={selectedUserLabel}
        userLabels={userLabels}
        triggerGetLabel={triggerGetLabel}
        setTriggerGetLabel={setTriggerGetLabel}
      />

      <ModalFilter
        showSortAndFilter={showSortAndFilter}
        setShowSortAndFilter={setShowSortAndFilter}
        handleCloseSortAndFilter={handleCloseSortAndFilter}
        tempSortData={tempSortData}
        handleSubmit={handleSubmit}
        handleSwitchChange={handleSwitchChange}
        handleInputChange={handleInputChange}
        userLabels={userLabels}
      />

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default MyFriend;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
