import React, { useState, useEffect, useRef, useCallback } from 'react';
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { collection, query, orderBy, limit, startAfter, getDocs, where, or, and } from 'firebase/firestore';

import PostviewDesktop from './PostviewDesktop';
// import PostviewMobile from './PostviewMobile';

import InfiniteScroll from 'react-infinite-scroll-component';

import Load from '../../../components/Load';
import { useSelector } from 'react-redux';

function MainPostview() {
    const [items, setItems] = useState(null);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const userData = useSelector((state) => state.user.data);
    const maxInit = 10;
    const maxNext = 5;
    const friendsListRef = useRef([]);
    const blockListRef = useRef([]);

    useEffect(() => {
        if (userData) {
            getFriendList(getPost, getBlockedUserList)
        }
    }, [userData]);

    const getFriendList = async (getPost, getBlockedUserList) => {
        const friendsList = []
        const friendRef = collection(firestore, "friends");
        const q = query(friendRef, or(where("friendId", "==", userData.uid), where("userId", "==", userData.uid)));
        const friendsDoc = await getDocs(q);

        friendsList.push(userData.uid);

        friendsDoc.forEach(doc => {
            friendsList.push(doc.data().friendId === userData.uid ? doc.data().userId : doc.data().friendId);
        });
        friendsListRef.current = friendsList;
        await getBlockedUserList()
        const initPost = await getPost(friendsList);
        if (initPost) {
            setItems(initPost);
        }

    }

    async function getBlockedUserList() {
        const userIds = [];

        const q1 = query(collection(firestore, "blockedUsers"), where("userId", "==", userData.uid))
        const querySnapshot1 = await getDocs(q1);

        const q2 = query(collection(firestore, "blockedUsers"), where("blockedUserId", "==", userData.uid))
        const querySnapshot2 = await getDocs(q2);

        querySnapshot1.forEach((doc) => {
            const data = doc.data();
            if (data.blockedUserId) {
                userIds.push(data.blockedUserId)
            }
        });

        querySnapshot2.forEach((doc) => {
            const data = doc.data();
            if (data.userId) {
                userIds.push(data.userId)
            }
        });

        blockListRef.current = userIds;
    }

    const getPost = async (friendsList) => {
        const privacy = userData.privacy;

        if (friendsList.length === 0 && !privacy.caddiePost && !privacy.playerPost) {
            setHasMore(false);
            return [];
        }

        const postsQuery = postsQueryDef(privacy, friendsList, maxInit);

        if (!postsQuery) return [];

        const postSnapshot = await getDocs(postsQuery);

        if (postSnapshot.size < maxInit) {
            setHasMore(false);
        }

        setLastVisible(postSnapshot.docs[postSnapshot.docs.length - 1]);

        const posts = postSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        const filteredPosts = posts.filter(post => !blockListRef.current.includes(post.userId));

        const userIds = [...new Set(filteredPosts.map(post => post.userId))];
        if (userIds.length === 0) {
            return filteredPosts;
        }

        const userRefs = query(collection(firestore, "users"), where("uid", "in", userIds));
        const userSnapshot = await getDocs(userRefs);

        const userMap = {};
        userSnapshot.docs.forEach(userDoc => {
            userMap[userDoc.data().uid] = userDoc.data();
        });

        const mergedPosts = filteredPosts.map(post => ({
            ...post,
            userData: userMap[post.userId] || {}
        }));

        return mergedPosts;
    };


    const getNextPost = async () => {

        const privacy = userData.privacy;

        if (friendsListRef.current.length === 0 && !privacy.caddiePost && !privacy.playerPost) {
            setHasMore(false);
            return;
        }

        const postsQuery = postsQueryDef(privacy, friendsListRef.current, maxNext, true, lastVisible)

        if (!postsQuery) return;

        const postSnapshot = await getDocs(postsQuery);

        if (postSnapshot.size < maxNext) {
            setHasMore(false);
        }

        setLastVisible(postSnapshot.docs[postSnapshot.docs.length - 1]);

        const posts = postSnapshot.docs.map(doc => doc.data());

        const filteredPosts = posts.filter(post => !blockListRef.current.includes(post.userId));

        const userIds = [...new Set(filteredPosts.map(doc => doc.userId))];
        const userRefs = query(collection(firestore, "users"), where("uid", "in", userIds));
        const userSnapshot = await getDocs(userRefs);


        const userMap = {};
        userSnapshot.docs.forEach(userDoc => {
            userMap[userDoc.data().uid] = userDoc.data();
        });

        const mergedPosts = filteredPosts.map(post => {
            return {
                ...post,
                userData: userMap[post.userId]
            };
        });
        setItems((prev) => [...prev, ...mergedPosts])
    }


    return (
        <>
            {items ?
                <>
                    {items.length ? (
                        <InfiniteScroll
                            dataLength={items.length}
                            next={getNextPost}
                            hasMore={hasMore}
                            loader={<Load />}
                            endMessage={<div className='text-center'><h5 className='fw-600'>You've seen all post</h5></div>}
                        // className='overflow-hidden'
                        >
                            <PostviewDesktop posts={items} />

                        </InfiniteScroll>

                    ) : (
                        <div className='text-center'><h5 className='fw-600'>No post to show</h5></div>
                    )}
                </>
                :
                <Load />
            }
        </>
    );
}
export default MainPostview;


function postsQueryDef(privacy, friendsList, maxInit, isNext, lastVisible) {

    let postsQuery = null;
    const postsRef = collection(firestore, "posts");

    //////////////////// Have friend //////////////////////////
    const queryAllPost = [
        and(
            or(
                where("userId", "in", friendsList),
                where("audience", "==", "public")
            ),
            where("audience", "!=", "only_me")
        ),
        orderBy("timestamp", "desc"),
        limit(maxInit)
    ];
    const queryPlayerPost = [
        or(
            and(
                where("userId", "in", friendsList),
                where("audience", "!=", "only_me")
            ),
            and(
                where("role", "==", "player"),
                where("audience", "==", "public")
            )
        ),
        orderBy("timestamp", "desc"),
        limit(maxInit)
    ];
    const queryCaddiePost = [
        or(
            and(
                where("userId", "in", friendsList),
                where("audience", "!=", "only_me")
            ),
            and(
                where("role", "==", "caddie"),
                where("audience", "==", "public")
            )
        ),
        orderBy("timestamp", "desc"),
        limit(maxInit)
    ];


    ////////////////////// No friend //////////////////////
    const queryAllPostNoFriend = [
        where("audience", "==", "public"),
        orderBy("timestamp", "desc"),
        limit(maxInit)
    ];
    const queryPlayerNoFriend = [
        where("role", "==", "player"),
        where("audience", "==", "public"),
        orderBy("timestamp", "desc"),
        limit(maxInit)
    ];
    const queryCaddieNoFriend = [
        where("role", "==", "caddie"),
        where("audience", "==", "public"),
        orderBy("timestamp", "desc"),
        limit(maxInit)
    ];

    if (friendsList.length) {
        if (privacy) {
            if (isNext) {
                if (privacy.caddiePost && privacy.playerPost) {
                    // all posts
                    queryAllPost.push(startAfter(lastVisible))
                    postsQuery = query(
                        postsRef,
                        ...queryAllPost,
                        startAfter(lastVisible),
                    );

                } else if (!privacy.caddiePost && privacy.playerPost) {
                    // player posts
                    queryPlayerPost.push(startAfter(lastVisible))
                    postsQuery = query(
                        postsRef,
                        ...queryPlayerPost
                    );

                } else if (privacy.caddiePost && !privacy.playerPost) {
                    // caddie posts
                    queryCaddiePost.push(startAfter(lastVisible))
                    postsQuery = query(
                        postsRef,
                        ...queryCaddiePost
                    );
                } else {
                    // friend posts
                    postsQuery = query(
                        postsRef,
                        where("audience", "!=", "only_me"),
                        where("userId", "in", friendsList),
                        orderBy("timestamp", "desc"),
                        startAfter(lastVisible),
                        limit(maxInit)
                    );
                }
            } else {
                if (privacy.caddiePost && privacy.playerPost) {
                    // all posts
                    postsQuery = query(
                        postsRef,
                        ...queryAllPost
                    );

                } else if (!privacy.caddiePost && privacy.playerPost) {
                    // player posts
                    postsQuery = query(
                        postsRef,
                        ...queryPlayerPost
                    );

                } else if (privacy.caddiePost && !privacy.playerPost) {
                    // caddie posts
                    postsQuery = query(
                        postsRef,
                        ...queryCaddiePost
                    );
                } else {
                    // friend posts
                    postsQuery = query(
                        postsRef,
                        where("audience", "!=", "only_me"),
                        where("userId", "in", friendsList),
                        orderBy("timestamp", "desc"),
                        limit(maxInit)
                    );
                }
            }
        } else {
            postsQuery = query(
                postsRef,
                or(
                    where("userId", "in", friendsList),
                    where("audience", "==", "public"),
                ),
                orderBy("timestamp", "desc"),
                limit(maxInit)
            );
        }
        return postsQuery;
    } else {
        if (privacy) {
            if (isNext) {
                if (privacy.caddiePost && privacy.playerPost) {
                    // all posts
                    queryAllPostNoFriend.push(startAfter(lastVisible))
                    postsQuery = query(
                        postsRef,
                        ...queryAllPostNoFriend
                    );

                } else if (!privacy.caddiePost && privacy.playerPost) {
                    // player posts
                    queryPlayerNoFriend.push(startAfter(lastVisible))
                    postsQuery = query(
                        postsRef,
                        ...queryPlayerNoFriend
                    );

                } else if (privacy.caddiePost && !privacy.playerPost) {
                    // caddie posts
                    queryCaddieNoFriend.push(startAfter(lastVisible))
                    postsQuery = query(
                        postsRef,
                        ...queryCaddieNoFriend
                    );
                } else {
                    // friend posts
                    return null;
                }
            } else {
                if (privacy.caddiePost && privacy.playerPost) {
                    // all posts
                    postsQuery = query(
                        postsRef,
                        ...queryAllPostNoFriend
                    );

                } else if (!privacy.caddiePost && privacy.playerPost) {
                    // player posts
                    postsQuery = query(
                        postsRef,
                        ...queryPlayerNoFriend
                    );

                } else if (privacy.caddiePost && !privacy.playerPost) {
                    // caddie posts
                    postsQuery = query(
                        postsRef,
                        ...queryCaddieNoFriend
                    );
                } else {
                    // friend posts
                    return null;
                }
            }
        } else {
            postsQuery = query(
                postsRef,
                where("audience", "==", "public"),
                orderBy("timestamp", "desc"),
                limit(maxInit)
            );
        }
        return postsQuery;
    }
}

