import React, { useState, useEffect } from 'react'
import PlayerPerf from './player/PlayerPerf';
import CaddiePerf from './caddie/CaddiePerf';
import { useSearchParams } from 'react-router-dom';
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { getDocs, addDoc, updateDoc, query, where, collection } from 'firebase/firestore';

const PerfProfile = () => {

    let [searchParams] = useSearchParams();
    const uid = searchParams.get('uid');
    const [userData, setUserData] = useState();

    useEffect(() => {
        async function getUser() {
            const clubsCollection = collection(firestore, 'users');
            const q = query(clubsCollection, where("uid", "==", uid));
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const data = querySnapshot.docs[0].data();
                    setUserData(data);
                } else {
                    console.error('User id not found', uid);
                }
            } catch (e) {
                console.error("Error adding or updating document: ", e);
            }
        }
        if (uid) {
            getUser()
        }
    }, [uid])


    switch (userData?.role) {
        case 'player':

            return <PlayerPerf data={userData} />

        case 'caddie':

            return <CaddiePerf data={userData} />

        default:
            break;
    }
}

export default PerfProfile