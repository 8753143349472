import React, { useState, useEffect } from 'react'
import styles from '../../../scorestyle.module.scss'
import { useSelector } from 'react-redux'
import useModalWithHistory from '../../../../../components/ModalPopState'
import Scorebox from '../../Scorebox'
import RenderRow3 from '../RenderRow3Comp'
import { isGreenInRegulation, getScorecardClassName, backgroundCalculation, stablefordCalculation } from '../scoreCalculation'

const ScoreInputGross = ({ score }) => {
    const userData = useSelector((state) => state.user.data);
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();

    const [bg, setBg] = useState("");
    const [gir, setGir] = useState(false);
    const [stabScore, setStabScore] = useState("-");

    const scorecard = getScorecardClassName(bg);

    // console.log(score);

    useEffect(() => {
        const holescore = score.holeScore?.score;
        const holeputts = score.holeScore?.putts;
        const holepar = score.holeDetail?.par;

        if (holescore && holepar && holeputts) {
            const numHolescore = Number(holescore);
            const numHolesputts = Number(holeputts);
            const numHolepar = Number(holepar);

            if (!isNaN(numHolescore) && !isNaN(numHolepar)) {


                // par bg calculation
                const scoreDifference = numHolescore - numHolepar;
                setBg(backgroundCalculation(scoreDifference));

                // stableford calculation
                const stabScore = stablefordCalculation(scoreDifference);
                setStabScore(stabScore);

                // GIR calculation
                if (!isNaN(numHolesputts)) {
                    setGir(isGreenInRegulation(numHolepar, numHolescore, numHolesputts))
                }

            } else {
                setBg(""); // Reset background if scores are invalid numbers
                setGir(false);
                setStabScore("-");
            }
        } else {
            setBg(""); // Reset background if holescore or holepar is null
            setGir(false);
            setStabScore("-");
        }

    }, [score]);



    if ((score.userId === userData.uid) || (score.userId && score.caddieId === userData.uid)) {
        return (
            <>
                <div className={`scorecard ${scorecard} pointer`} onClick={() => openModal()}>
                    <div className={`${styles.scorecardrow1} d-flex justify-content-end`}>
                        <span className='fw-600 fs-5'>{score.holeScore.putts}</span>
                    </div>
                    <div className={`${styles.scorecardrow2} d-flex align-items-baseline`}>
                        <span className='fw-600 fs-1 col-6 text-end'>{stabScore}</span>
                        <span className='fw-600 fs-6 col-6 text-end'>{score.holeScore.drivingRange}</span>
                    </div>
                    <RenderRow3
                        score={score}
                        gir={gir}
                    />
                </div>
                <Scorebox
                    isModalOpen={isModalOpen}
                    openModal={openModal}
                    handleClose={closeModal}
                    selectedHole={score.holeScore}
                    holeData={score.holeDetail}
                />
            </>
        )
    } else {
        return (
            <>
                <div className={`${styles.scorecard} ${styles['scorecard--otherbg']}`}>
                    <div className={`${styles.scorecardrow1} d-flex justify-content-end`}>
                        <span className='fw-600 fs-5'>{score?.holeScore?.putts || "-"}</span>
                    </div>
                    <div className={`${styles.scorecardrow2} d-flex align-items-baseline`}>
                        <span className='fw-600 fs-1 col-6 text-end'>{stabScore || "-"}</span>
                        <span className='fw-600 fs-6 col-6 text-end'>{score?.holeScore?.drivingRange || "-"}</span>
                    </div>
                    <RenderRow3
                        score={score}
                        gir={gir}
                    />
                </div>
            </>
        )
    }
}

export default ScoreInputGross
