import React from 'react'
import { Modal } from 'react-bootstrap';
import { StepTwoCard, StepTwoCardCaddie } from './module/StepTwoCard';
import { useSelector } from 'react-redux';

const SwapCaddie = ({ isOpen, handleClose, rowNum, data, updateStepData }) => {
    const userData = useSelector((state) => state.user.data);

    const filteredCaddie = data.row.filter((items) => items.row !== rowNum); // just show
    const swapCaddie = data.row.filter((items) => items.row === rowNum); // just show


    const handleSwap = (selectedRow) => {
        const fromRow = rowNum;
        const toRow = selectedRow;

        const updatedCaddie = [...data.row];

        const fromRowIndex = updatedCaddie.findIndex(item => item.row === fromRow);
        const toRowIndex = updatedCaddie.findIndex(item => item.row === toRow);

        if (fromRowIndex === -1 || toRowIndex === -1) {
            console.error('Invalid rows selected.');
            return;
        }

        const temp = updatedCaddie[fromRowIndex].caddie;
        updatedCaddie[fromRowIndex].caddie = updatedCaddie[toRowIndex].caddie;
        updatedCaddie[toRowIndex].caddie = temp;

        // console.log('Updated caddie after swap:', updatedCaddie);

        updateStepData('stepTwo', { row: updatedCaddie })

        handleClose();
    };


    return (
        <>
            <Modal show={isOpen} onHide={handleClose} centered animation={false} size='lg'>

                <Modal.Header>
                    <Modal.Title className='d-flex justify-content-between align-items-center gap-4 w-100'>
                        <h2 className='fw-600 text-nowrap'>Swap Caddie</h2>
                    </Modal.Title>
                </Modal.Header>

                <div className='right-scroll-bar' style={{ maxHeight: '80vh', overflow: 'auto' }}>
                    {userData.role === 'caddie' ? (
                        <>
                            <Modal.Body className='pb-0'>
                                <StepTwoCardCaddie rowData={swapCaddie} isJustShow={true} />
                                <h2 className='fw-600'>To
                                    <i className='ms-2 bi bi-arrow-down'></i>
                                </h2>
                            </Modal.Body>

                            <Modal.Body>
                                <StepTwoCardCaddie rowData={filteredCaddie} isJustShow={false} handleSwap={handleSwap} />
                            </Modal.Body>
                        </>
                    ) : (
                        <>
                            <Modal.Body className='pb-0'>
                                <StepTwoCard rowData={swapCaddie} isJustShow={true} />
                                <h2 className='fw-600'>To
                                    <i className='ms-2 bi bi-arrow-down'></i>
                                </h2>
                            </Modal.Body>

                            <Modal.Body>
                                <StepTwoCard rowData={filteredCaddie} isJustShow={false} handleSwap={handleSwap} />
                            </Modal.Body>
                        </>
                    )}
                </div>

            </Modal>
        </>
    )
}

export default SwapCaddie