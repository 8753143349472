import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./../CaddieModal.css";
import { ReactSVG } from "react-svg";
import { addFriend } from "../../../../../components/friend/AddFriend";
import { ChatTo } from "../../../../../components/chat/Chat";

function ModalCaddieDetail(props) {
  const userData = useSelector((state) => state.user.data);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const navigate = useNavigate();

  const [friendState, setFriendState] = useState("");
  const [sendBirdData, setSentBirdData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const caddieUid = props.caddieData.uid || "";
      let isFriend = false;
      const q3 = query(
        collection(firestore, "friends"),
        where("friendId", "==", caddieUid),
        where("userId", "==", userData?.uid)
      );

      const q2 = query(
        collection(firestore, "friends"),
        where("friendId", "==", userData?.uid),
        where("userId", "==", caddieUid)
      );

      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach(async (doc2) => {
        if (doc2.data().status == "active") {
          isFriend = true;
        }
      });

      const querySnapshot3 = await getDocs(q3);
      querySnapshot3.forEach(async (doc3) => {
        if (doc3.data().status == "active") {
          isFriend = true;
        }
      });

      if (isFriend) {
        setFriendState("friend");
      } else {
        // User add to caddie
        const q4 = query(
          collection(firestore, "friendRequests"),
          where("fromUserId", "==", userData?.uid),
          where("toUserId", "==", caddieUid)
        );

        // Caddie add to user (me)
        const q5 = query(
          collection(firestore, "friendRequests"),
          where("fromUserId", "==", caddieUid),
          where("toUserId", "==", userData?.uid)
        );

        let statePending = false;
        let stateRequesting = false;
        // User add to caddie
        const querySnapshot4 = await getDocs(q4);
        querySnapshot4.forEach(async (doc4) => {
          if (doc4.data().status == "pending") {
            setFriendState("not-friend-pending");
            statePending = true;
          }
        });

        // Caddie add to user (me)
        const querySnapshot5 = await getDocs(q5);
        querySnapshot5.forEach(async (doc5) => {
          if (doc5.data().status == "pending") {
            setFriendState("not-friend-requesting");
            stateRequesting = true;
          }
        });

        if (statePending == false && stateRequesting == false) {
          setFriendState("not-friend");
        }
      }
    }
    if (userData) {
      fetchData();
    }
  }, [userData, props.caddieData]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/users/${props.caddieData.dooNineId}`,
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
          },
        }
      );
      setSentBirdData(response.data);
    }
    if (props.caddieData) {
      fetchData();
    }
  }, [userData, props.caddieData]);

  const handleClose = () => {
    props.setCaddieData({});
    props.setCaddieProfile({});
    props.setShow(false);
  };

  const handleFavorite = async (uid) => {
    const q = query(
      collection(firestore, "favoriteFriends"),
      where("caddieId", "==", uid),
      where("userId", "==", userData.uid)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.size == 0) {
      const timestampInSecond = Math.round(Date.now() / 1000);
      const docRef = await addDoc(collection(firestore, "favoriteFriends"), {
        caddieId: uid,
        userId: userData.uid,
        status: "active",
        timestamp: timestampInSecond,
      });
      props.setFavoriteFriends(true);
    } else {
      querySnapshot.forEach(async (doc1) => {
        const washingtonRef = doc(firestore, "favoriteFriends", doc1.id);
        if (doc1.data().status == "active") {
          await updateDoc(washingtonRef, {
            status: "inactive",
          });
          props.setFavoriteFriends(false);
        } else {
          await updateDoc(washingtonRef, {
            status: "active",
          });
          props.setFavoriteFriends(true);
        }
      });
    }
  };

  const handleChat = async (dooNineId) => {
    const result = await ChatTo(userData.dooNineId, dooNineId);
    if (result) {
      navigate("/chat-room?channelId=" + result[0].channel_url);
    }
    // const response = await axios.get(
    //   `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userData.dooNineId}&show_member=false&members_exactly_in=${dooNineId}`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json; charset=utf8",
    //       "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
    //     },
    //   }
    // );

    // if (response.data.channels.length > 0) {
    //   isMobile
    //     ? navigate("/chat-room", {
    //         state: {
    //           channelUrl: response.data.channels[0].channel_url,
    //           previousPage: "caddie",
    //         },
    //       })
    //     : navigate("/chat", {
    //         state: {
    //           channelUrl: response.data.channels[0].channel_url,
    //           previousPage: "caddie",
    //         },
    //       });
    // } else {
    //   const response2 = await axios.post(
    //     "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels",
    //     {
    //       user_ids: [userData.dooNineId, dooNineId],
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json; charset=utf8",
    //         "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
    //       },
    //     }
    //   );
    //   isMobile
    //     ? navigate("/chat-room", {
    //         state: {
    //           channelUrl: response.data.channels[0].channel_url,
    //           previousPage: "caddie",
    //         },
    //       })
    //     : navigate("/chat", {
    //         state: {
    //           channelUrl: response.data.channels[0].channel_url,
    //           previousPage: "caddie",
    //         },
    //       });
    // }
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;
    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleAddFriend = async () => {
    setFriendState("not-friend-pending");

    try {
      await addFriend(props.caddieData.uid, userData.uid);
    } catch (error) {
      console.error("Failed to add friend:", error);
      setFriendState("error");
    }
  };

  return (
    <>
      {/* Modal Caddie detail */}
      <Modal show={props.show} onHide={handleClose} scrollable>
        <Modal.Body>
          <div className="card-image w-100 mb-3 position-relative">
            {/* Icon Star */}
            {props.caddieData.isStar ? (
              <h4
                className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
                style={{
                  top: "10px",
                  right: "10px",
                }}
              >
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-CaddieStar.svg"
                  style={{ width: "40px", height: "40px" }}
                />
              </h4>
            ) : null}

            {/* Icon Pro */}
            {props.caddieData.isPro ? (
              <h4
                className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
                style={{
                  top: props.caddieData.isStar ? "55px" : "10px",
                  right: "10px",
                }}
              >
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-ProCaddie.svg"
                  style={{ width: "40px", height: "40px" }}
                />
              </h4>
            ) : null}

            {/* Statue online & Name */}
            <h4
              className="fw-700 position-absolute z-index-1 ls-3 font-xss text-white"
              style={{
                bottom: "30px",
                left: "15px",
              }}
              onClick={() => handleProfileClick(props.caddieData.uid)}
            >
              <span
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor:
                    sendBirdData?.last_seen_at == 0 ? "#10d876" : "#B0B5BD",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "9px",
                  boxShadow: "0 0 0 3px white",
                }}
              ></span>
              {props.caddieData.firstName} {props.caddieData.lastName}
            </h4>

            {/* Chat Icon */}
            <h4
              onClick={() => handleChat(props.caddieData.dooNineId)}
              className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
              style={{
                bottom: "20px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              <ReactSVG
                src="assets/icon/CaddieFriendFav-Chat.svg"
                style={{ width: "55px", height: "55px" }}
              />
            </h4>

            {/* Chat Favorite */}
            <h4
              onClick={() => handleFavorite(props.caddieData.uid)}
              className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
              style={{
                bottom: "20px",
                right: "70px",
                cursor: "pointer",
              }}
            >
              {props.favoriteFriends ? (
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-Fav.svg"
                  style={{ width: "55px", height: "55px" }}
                />
              ) : (
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-UnFav.svg"
                  style={{ width: "55px", height: "55px" }}
                />
              )}
            </h4>

            <Carousel>
              {props.caddieProfile?.image?.map((img, index) => (
                <Carousel.Item key={index}>
                  <img
                    src={img.imageUrl}
                    alt="hotel"
                    className="w-100"
                    style={{
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "15px",
                    }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className="card-body pt-0">
            <h4 className="fw-700 font-xs mt-0 lh-28 mb-1">Intro</h4>

            <h6 className="font-xss text-grey-700 fw-400 mt-0 mb-3">
              {props.caddieProfile.description}
            </h6>

            <div className="clearfix"></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {friendState == "friend" ? (
            <button
              className="btn text-white font-xsss"
              style={{ backgroundColor: "#EE3D7F" }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                }}
              >
                {" "}
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-Friends.svg"
                  style={{ width: "25px", height: "25px" }}
                />
              </span>
              Friend
            </button>
          ) : friendState == "not-friend-pending" ||
            friendState == "not-friend-requesting" ? (
            <button
              className="btn text-white font-xsss"
              style={{ backgroundColor: "#EE3D7F" }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                }}
              >
                {" "}
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-CancelRequest.svg"
                  style={{ width: "25px", height: "25px" }}
                />
              </span>
              Pending
            </button>
          ) : (
            <button
              className="btn text-white font-xsss"
              style={{ backgroundColor: "#EE3D7F" }}
              onClick={handleAddFriend}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                }}
              >
                {" "}
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-AddFriend.svg"
                  style={{ width: "25px", height: "25px" }}
                />
              </span>
              Add Friend
            </button>
          )}

          <button
            className="btn font-xsss"
            style={{
              backgroundColor: "#F5F5F5",
              color: "#212529",
            }}
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCaddieDetail;
