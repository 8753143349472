import imageCompression from 'browser-image-compression'
import { uploadBytesResumable, getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../../../../../components/firebase/FirebaseConfig';

export const handleUploadImage = async (round_id, file) => {
    try {
        // Compress the image
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        });

        const storageRef = ref(storage, `round_cover/${round_id}/${file.name}.jpg`);
        const uploadTask = uploadBytesResumable(storageRef, compressedFile);

        const downloadURL = await new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                null, // Skip progress tracking
                (error) => {
                    console.error('Upload error:', error);
                    reject(error);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(downloadURL);
                    } catch (error) {
                        console.error('Error getting download URL:', error);
                        reject(error);
                    }
                }
            );
        });

        return downloadURL;
    } catch (error) {
        console.error('Error during image upload process:', error);
        throw error; // Re-throw error to be handled by the caller if necessary
    }
};