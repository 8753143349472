import React, { useMemo, useState, useEffect } from 'react'
import Chart from "react-apexcharts";
import { firestore } from '../../../../../components/firebase/FirebaseConfig';
import { doc, getDoc, collection, query, getDocs, where } from 'firebase/firestore';

const MonthlyScore = ({ userId = null, reviewData }) => {
    const [series, setSeries] = useState([
        {
            name: '',
            data: [0, 0, 0, 0, 0, 0]
        }
    ])

    const [monthList, setMonthList] = useState([]);

    useEffect(() => {
        function getLastSixMonths() {
            const months = [];
            const currentDate = new Date();

            for (let i = 0; i < 6; i++) {
                // Create a new date adjusted for the month offset
                const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);

                // Format the month name (e.g., "November 2024")
                const monthName = date.toLocaleString('default', { month: 'long', year: 'numeric' });

                // Get the timestamp of the first day of the month
                const timestamp = date.getTime();

                // Push the month and timestamp into the array
                months.push({ month: monthName, timestamp });
            }
            // console.log(months);

            setMonthList(months);
        }

        if (userId) {
            getLastSixMonths();
        }

    }, [userId])

    useEffect(() => {
        async function fetchRoundTimestamps() {
            const roundTimestamps = {};
            const uniqueRoundIds = [...new Set(reviewData.map(review => review.roundId).filter(id => !!id))];

            if (uniqueRoundIds.length === 0) return roundTimestamps;

            try {
                // Fetch the rounds' schedule times from Firestore
                const roundsRef = collection(firestore, 'round');
                const batchQuery = query(roundsRef, where('__name__', 'in', uniqueRoundIds));
                const querySnapshot = await getDocs(batchQuery);

                querySnapshot.forEach((doc) => {
                    const roundData = doc.data();

                    // Ensure scheduleTime is converted from seconds to milliseconds
                    let scheduleTime = roundData?.scheduleTime;
                    if (scheduleTime) {
                        scheduleTime = scheduleTime * 1000; // Convert from seconds to milliseconds
                    } else {
                        console.warn(`Missing or invalid scheduleTime for roundId: ${doc.id}. Falling back to current timestamp.`);
                        scheduleTime = Date.now(); // Fallback to the current timestamp if missing or invalid
                    }

                    roundTimestamps[doc.id] = scheduleTime;
                });
            } catch (error) {
                console.error('Error fetching round data:', error);
            }

            return roundTimestamps;
        }

        async function processReviews() {
            if (monthList.length > 0 && reviewData.length > 0) {
                const roundTimestamps = await fetchRoundTimestamps();

                const scoresByMonth = new Array(6).fill(0);
                const reviewCountsByMonth = new Array(6).fill(0);

                const currentMonthIndex = 5;

                // Debugging: Check the generated monthList
                // console.log('Month List:', monthList);

                reviewData.forEach((review) => {
                    // Get the scheduleTime from Firestore data or use current timestamp if missing
                    const reviewTimestamp = roundTimestamps[review.roundId] || Date.now();

                    // Debugging: Log the review's assigned timestamp
                    // console.log(`Review ID: ${review.roundId}, Timestamp: ${new Date(reviewTimestamp).toISOString()}`);

                    // Extract ratings
                    const serviceScore = review.serviceRating || 0;
                    const adviceScore = review.adviceRating || 0;
                    const totalReviewScore = serviceScore + adviceScore;

                    // Assign review to the correct month
                    let isAssigned = false;
                    for (let i = 0; i < monthList.length; i++) {
                        const { timestamp } = monthList[i];
                        const nextMonthTimestamp = i > 0 ? monthList[i - 1].timestamp : Date.now();

                        // console.log(
                        //     `Month: ${monthList[i].month}, Start: ${new Date(timestamp).toISOString()}, End: ${new Date(
                        //         nextMonthTimestamp
                        //     ).toISOString()}`
                        // );

                        // Check if the review falls within the current month's range
                        if (reviewTimestamp >= timestamp && reviewTimestamp < nextMonthTimestamp) {
                            // console.log(`Assigned to month: ${monthList[i].month}`);
                            scoresByMonth[5 - i] += totalReviewScore;
                            reviewCountsByMonth[5 - i] += 1;
                            isAssigned = true;
                            break;
                        }
                    }

                    // Assign to the current month if no other match
                    if (!isAssigned) {
                        // console.log('Assigned to current month (fallback)');
                        scoresByMonth[currentMonthIndex] += totalReviewScore;
                        reviewCountsByMonth[currentMonthIndex] += 1;
                    }
                });

                // Calculate averages for each month
                const finalScoresByMonth = scoresByMonth.map((sum, index) => {
                    const count = reviewCountsByMonth[index];
                    return count > 0 ? (sum / (count * 2)).toFixed(2) : 0;
                });

                // Update the series state with the final scores
                setSeries([
                    {
                        name: 'Monthly Average Scores',
                        data: finalScoresByMonth
                    }
                ]);

                // console.log('Final Monthly Scores:', finalScoresByMonth);
            }
        }

        if (reviewData.length > 0) {
            processReviews();
        }
    }, [monthList, reviewData]);


    const renderChart = useMemo(() => {
        if (!userId || !monthList.length) return

        const options = {
            chart: {
                id: 'responsive-bar',
            },
            xaxis: {
                categories: [monthList[5].month, monthList[4].month, monthList[3].month, monthList[2].month, monthList[1].month, monthList[0].month,],
            },
            yaxis: {
                max: 5,
            },
            title: {
                text: 'Monthly average doonine score ',
                align: 'center',
                style: {
                    fontSize: '16px',
                    color: '#333'
                }
            },
            colors: ['#008FFB80'],
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    distributed: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
        }

        return (
            <div className="chart-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="300"
                />
            </div>
        )
    }, [monthList, series, userId])

    return (
        <>
            {renderChart}
        </>
    );
}

export default MonthlyScore