import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { bestScoreCalculation } from "../../user/myperfprofile/module/statCalculation";

export async function finishRound(playerScore, courseHoles, navFinishRound = () => console.warn('not found nav function')) {
    Swal.fire({
        icon: 'warning',
        text: 'Do you want to finish round ?',
        confirmButtonColor: '#EE3C7F',
        showCancelButton: true
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const scoreRef = doc(firestore, 'scoreCards', playerScore.docId);
                await updateDoc(scoreRef, {
                    status: 'finished'
                })
                await bestScoreCalculation(playerScore, courseHoles);

                navFinishRound();
            } catch (error) {
                console.error('Fail to finish round', error);
            }
        }
    })

}