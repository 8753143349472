import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';

// const engFlag = process.env.PUBLIC_URL + '/assets/images/flag/us_flag.png';
const usFlag = process.env.PUBLIC_URL + '/assets/images/flag/us_flag.png';
const thFlag = process.env.PUBLIC_URL + '/assets/images/flag/th_flag.png';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();


  useEffect(() => {
    const currentLang = localStorage.getItem('i18nextLng');
    i18n.changeLanguage(currentLang);
  }, [])

  function handleChangeLang(lang) {
    localStorage.setItem('i18nextLng', lang);
    i18n.changeLanguage(lang);
  }

  return (
    <Dropdown className='ms-3 mb-1'>
      <Dropdown.Toggle className='bg-transparent border border-0' variant='light' id="language-select" size="sm">
        <img src={i18n.language === 'en' ? usFlag : thFlag} alt='sel_flag' width={28} />
      </Dropdown.Toggle>

      <Dropdown.Menu className='p-2 w-25'>
        <Dropdown.Item
          className='text-black'
          onClick={() => handleChangeLang('en')}
          style={{ width: '100%', lineHeight: 'inherit', backgroundColor: i18n.language === 'en' ? '#e9ecef' : 'transparent' }}
        >
          <img className='me-1' src={usFlag} alt='us_flag' width={20} />
          English
        </Dropdown.Item>
        <Dropdown.Item
          className='text-black'
          onClick={() => handleChangeLang('th')}
          style={{ width: '100%', lineHeight: 'inherit', backgroundColor: i18n.language === 'th' ? '#e9ecef' : 'transparent' }}
        >
          <img className='me-1' src={thFlag} alt='th_flag' width={20} />
          ไทย
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
