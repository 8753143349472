import { createSlice } from '@reduxjs/toolkit'

export const createRoundSlice = createSlice({
    name: 'createRound',
    initialState: {
        course: null,
        value: 0,
        roundName:"",
    },
    reducers: {
        selectCourse: (state, action) => {
            state.course = action.payload;
        },
        roundNameInput: (state, action) => {
            state.roundName = action.payload;
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { selectCourse, roundNameInput, incrementByAmount } = createRoundSlice.actions

export default createRoundSlice.reducer