import { collection, addDoc, updateDoc, doc, where, query, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/FirebaseConfig";

export async function addFriendnotifications(senderID, receiverID, timeStampInSeconds) {
    try {

        if (!senderID || !receiverID) {
            console.error("Invalid userID or friendID!!");
            console.error("userID :", senderID);
            console.error("friendID :", receiverID);
            return;
        }

        if (!timeStampInSeconds) {
            timeStampInSeconds = Math.floor(Date.now() / 1000);
        }
        const docRef = await addDoc(collection(firestore, "notifications"), {
            notificationId: '',
            fromUserId: senderID,
            toUserId: receiverID,
            isRead: false,
            timestamp: timeStampInSeconds,
            type: 'add'
        });
        await updateDoc(doc(firestore, "notifications", docRef.id), {
            notificationId: docRef.id
        });
    } catch (error) {
        console.error('Fail to send notification', error);
    }
}

export async function acceptFriendnotifications(senderID, receiverID, timeStampInSeconds) {
    try {

        if (!senderID || !receiverID) {
            console.error("Invalid userID or friendID!!");
            console.error("userID :", senderID);
            console.error("friendID :", receiverID);
            return;
        }

        if (!timeStampInSeconds) {
            timeStampInSeconds = Math.floor(Date.now() / 1000);
        }
        const docRef = await addDoc(collection(firestore, "notifications"), {
            notificationId: '',
            fromUserId: senderID,
            toUserId: receiverID,
            isRead: false,
            timestamp: timeStampInSeconds,
            type: 'accept'
        });
        await updateDoc(doc(firestore, "notifications", docRef.id), {
            notificationId: docRef.id
        });
    } catch (error) {
        console.error('Fail to send notification', error);
    }
}

export async function commentedPostNotification(senderID, receiverID, postID, content, timeStampInSeconds) {
    try {
        const truncated = truncateString(content, 20);

        if (!senderID || !receiverID) {
            console.error("Invalid userID or friendID!!");
            console.error("userID :", senderID);
            console.error("friendID :", receiverID);
            return;
        }

        if (!timeStampInSeconds) {
            timeStampInSeconds = Math.floor(Date.now() / 1000);
        }
        const docRef = await addDoc(collection(firestore, "notifications"), {
            notificationId: '',
            fromUserId: senderID,
            toUserId: receiverID,
            postId: postID,
            content: truncated,
            isRead: false,
            timestamp: timeStampInSeconds,
            type: 'comment'
        });
        await updateDoc(doc(firestore, "notifications", docRef.id), {
            notificationId: docRef.id
        });
    } catch (error) {
        console.error('Fail to send notification', error);
    }
}

export async function createRoundNotification(senderID, receiverIDs = [], content = "", roundId, timeStampInSeconds) {
    try {
        if (!senderID || !receiverIDs.length) {
            console.error("Invalid senderID or receiverIDs!!");
            return;
        }

        // Use current timestamp if not provided
        if (!timeStampInSeconds) {
            timeStampInSeconds = Math.floor(Date.now() / 1000);
        }

        // Step 1: Perform a single batched query for all receivers
        const notificationQuery = query(
            collection(firestore, "notifications"),
            where("fromUserId", "==", senderID),
            where("toUserId", "in", receiverIDs),
            where("type", "==", "create_round"),
            where("isRead", "==", false),
            where("roundId", "==", roundId)
        );

        const querySnapshot = await getDocs(notificationQuery);

        // Step 2: Collect existing notifications to avoid duplicates
        const existingNotifications = new Set();
        querySnapshot.forEach((doc) => {
            existingNotifications.add(doc.data().toUserId);
        });

        // Step 3: Send notifications only to users who haven't received one
        for (const receiver of receiverIDs) {
            if (existingNotifications.has(receiver)) {
                continue;
            }

            // Create a new notification if not found
            const docRef = await addDoc(collection(firestore, "notifications"), {
                notificationId: '',
                fromUserId: senderID,
                toUserId: receiver,
                content: content,
                isRead: false,
                timestamp: timeStampInSeconds,
                type: 'create_round',
                roundId: roundId
            });

            // Update the notificationId field with the document ID
            await updateDoc(doc(firestore, "notifications", docRef.id), {
                notificationId: docRef.id
            });
        }
    } catch (error) {
        console.error('Failed to send notifications', error);
    }
}

function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.slice(0, maxLength - 3) + "...";
    } else {
        return str;
    }
}