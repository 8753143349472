export function defDefaultClubData(clubType, clubNumber, clubLoft, distance, isIronNumChange) {

    let clubData = null;

    const { loftFull, loftDec } = clubLoft;

    switch (clubType) {
        case 'Driver':
            clubData = {
                name: 'Driver',
                type: 'Driver',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: distance,
                note: "",
                inBag: true
            };
            return clubData

        case 'Wood':
            clubData = {
                name: 'Wood',
                type: 'Wood',
                number: clubNumber,
                loft: null,
                distance: distance,
                note: "",
                inBag: true
            };
            return clubData
        case 'Hybrid':
            clubData = {
                name: 'Hybrid',
                type: 'Hybrid',
                number: clubNumber,
                loft: null,
                distance: distance,
                note: "",
                inBag: true
            };
            return clubData
        case 'Iron':
            clubData = {
                name: 'Iron',
                type: 'Iron',
                number: clubNumber,
                loft: null,
                distance: isIronNumChange ? clubDistance('iron', clubNumber) : distance,
                note: "",
                inBag: true
            };
            return clubData
        case 'Pitching Wedge':
            clubData = {
                name: 'Pitching Wedge',
                type: 'Pitching Wedge',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: distance,
                note: "",
                inBag: true
            };
            return clubData
        case 'Gap Wedge':
            clubData = {
                name: 'Gap Wedge',
                type: 'Gap Wedge',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: distance,
                note: "",
                inBag: true
            };
            return clubData
        case 'Sand Wedge':
            clubData = {
                name: 'Sand Wedge',
                type: 'Sand Wedge',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: distance,
                note: "",
                inBag: true
            };
            return clubData
        case 'Lob Wedge':
            clubData = {
                name: 'Lob Wedge',
                type: 'Lob Wedge',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: distance,
                note: "",
                inBag: true
            };
            return clubData
        case 'Chipper':
            clubData = {
                name: 'Chipper',
                type: 'Chipper',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: distance,
                note: "",
                inBag: true
            };
            return clubData
        case 'Putter':
            clubData = {
                name: 'Putter',
                type: 'Putter',
                number: null,
                loft: null,
                distance: null,
                note: "",
                inBag: true
            };
            return clubData

        default:
            break;
    }
}

export function clubDistance(clubType, clubNumber) {

    switch (clubType) {
        case 'wood':
            break;
        case 'hybrid':
            break;
        case 'iron':
            switch (clubNumber) {
                case 1:
                    return 208;
                case 2:

                    return 202;
                case 3:

                    return 191;
                case 4:

                    return 180;
                case 5:

                    return 170;
                case 6:

                    return 159;
                case 7:

                    return 148;
                case 8:

                    return 137;
                case 9:

                    return 120;
                case 10:

                    return 98;
                case 11:

                    return 87;

                default:
                    return 208;
            }
        default:
            break;
    }
}

export function clubTypeDef(clubData) {
    const { loftFull, loftDec } = clubData.loft;
    
    switch (clubData.type) {
        case 'Driver':
            clubData = {
                name: clubData.name,
                type: 'Driver',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: clubData.distance,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData

        case 'Wood':
            clubData = {
                name: clubData.name,
                type: 'Wood',
                number: clubData.number,
                loft: null,
                distance: clubData.distance,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData
        case 'Hybrid':
            clubData = {
                name: clubData.name,
                type: 'Hybrid',
                number: clubData.number,
                loft: null,
                distance: clubData.distance,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData
        case 'Iron':
            clubData = {
                name: clubData.name,
                type: 'Iron',
                number: clubData.number,
                loft: null,
                distance: clubData.distance,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData
        case 'Pitching Wedge':
            clubData = {
                name: clubData.name,
                type: 'Pitching Wedge',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: clubData.distance,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData
        case 'Gap Wedge':
            clubData = {
                name: clubData.name,
                type: 'Gap Wedge',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: clubData.distance,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData
        case 'Sand Wedge':
            clubData = {
                name: clubData.name,
                type: 'Sand Wedge',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: clubData.distance,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData
        case 'Lob Wedge':
            clubData = {
                name: clubData.name,
                type: 'Lob Wedge',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: clubData.distance,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData
        case 'Chipper':
            clubData = {
                name: clubData.name,
                type: 'Chipper',
                number: null,
                loft: loftFull + (loftDec / 10),
                distance: clubData.distance,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData
        case 'Putter':
            clubData = {
                name: clubData.name,
                type: 'Putter',
                number: null,
                loft: null,
                distance: null,
                note: clubData.note,
                inBag: clubData.inBag
            };
            return clubData

        default:
            return null;
    }
}