import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { firestore } from '../../../components/firebase/FirebaseConfig'
import { sumPenalties, sumPutts, sumScoreStableford } from '../../scoreV2/scoring/module/scoreCalculation';

const Stableford = ({ roundData, courseHoleData }) => {

    const [scoreList, setScoreList] = useState([]);
    const [userList, setUsetList] = useState([]);

    // console.log(courseHoleData);

    const getUids = () => {
        const userUids = []
        roundData.userList.forEach(user => {
            if (user.player && user.player.uid) {
                userUids.push(user.player.uid)
            }
            if (user.caddie && user.caddie.uid) {
                userUids.push(user.caddie.uid)
            }
        });
        return userUids;
    }

    useEffect(() => {
        const getUserData = async () => {
            const userCollection = collection(firestore, 'users');
            const q = query(userCollection, where('uid', 'in', getUids()));
            const userSnapshot = await getDocs(q);
            if (!userSnapshot.empty) {
                const userArr = [];
                userSnapshot.forEach(doc => {
                    const user = doc.data();
                    userArr.push(user);
                });
                setUsetList(userArr);
            }
        }

        if (roundData) {
            getUserData()
        }

    }, [roundData])


    useEffect(() => {
        if (!roundData) return;



        const scoreCollection = collection(firestore, 'scoreCards');
        const q = query(scoreCollection, where('userId', 'in', getUids()), where('roundId', '==', roundData.roundId));
        const unsubscribe = onSnapshot(q, async (scoreSnapshot) => {
            if (!scoreSnapshot.empty) {
                const scoreArr = []
                scoreSnapshot.forEach(doc => {
                    const data = doc.data();
                    scoreArr.push(data);
                });
                setScoreList(scoreArr);
            }
        }, (error) => {
            console.error('Fail to get round data', error);
        });

        return () => unsubscribe();

    }, [roundData])



    switch (roundData.handicap) {
        case 'scratch':
            return <StablefordScratch score={scoreList} courseHoleData={courseHoleData} userList={userList} />

        case 'whs':
        case 'world_handicap_system':
            return <StablefordWhs />

        case 's36':
            return <StablefordS36 />


        default:
            console.error('Not found handicap for stableford');
            break;
    }
}

export default Stableford


const StablefordScratch = ({ score, courseHoleData, userList }) => {

    // console.log(score);
    // console.log(userList);


    const renderScore = useMemo(() => {

        score.forEach(playerScore => {
            const sum = sumScoreStableford(playerScore, courseHoleData);
            const sumPenal = sumPenalties(playerScore);
            const sumPutt = sumPutts(playerScore);

            playerScore.sum = sum;
            playerScore.sumPutts = sumPutt;
            playerScore.sumPenalties = sumPenal;
        });
        score.sort((a, b) => {
            const aSum = a.sum === "-" ? 0 : a.sum;
            const bSum = b.sum === "-" ? 0 : b.sum;
            return bSum - aSum;
        });
        

        return (
            <div className='overflow-auto' style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                {score.map((scoreItem, idx) => {
                    const player = userList.find(item => item.uid === scoreItem.userId);
                    const caddie = userList.find(item => item.uid === scoreItem.caddieId);
                    return (
                        <React.Fragment key={scoreItem.userId}>
                            {/* Render something based on the user and score */}
                            {player || caddie ? (
                                <div className='d-flex gap-1 mb-2 w-100'>
                                    <div className='col-3 border p-3 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#F5F5F5' }}>
                                        <h1 className=' fw-700' style={{
                                            color: "#f5427d",
                                            fontSize: '2rem'
                                        }}>
                                            {idx + 1}
                                        </h1>
                                    </div>

                                    <div className='col-5 border p-3' style={{ backgroundColor: '#A6A6A6' }}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            {scoreItem.userId ?
                                                <img src={player.profileImage} alt="player" width={65} height={65} className='rounded-circle player-color-outline' />
                                                :
                                                null
                                            }
                                            {scoreItem.caddieId ?
                                                <img src={caddie.profileImage} alt="player" width={65} height={65} className='rounded-circle caddie-color-outline' />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                    <div className='w-100 border p-3 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#F5F5F5' }}>
                                        <div className='d-flex justify-content-center align-items-baseline gap-4'>
                                            <h3 className='fw-600 text-danger'>{scoreItem.sumPenalties}</h3>
                                            <h1 className=' fw-700 text-center' style={{
                                                color: "#f5427d",
                                                fontSize: '2rem'
                                            }}>
                                                {scoreItem.sum}
                                            </h1>
                                            <h3 className='fw-600'>{scoreItem.sumPutts}</h3>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                null
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        );


    }, [courseHoleData, score, userList])


    return (
        <>{renderScore}</>
    )
}
const StablefordWhs = () => {
    return (
        <div>Stableford</div>
    )
}
const StablefordS36 = () => {
    return (
        <div>Stableford</div>
    )
}
