import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { collection, query, where, getDocs, limit } from "firebase/firestore";
import { firestore } from './firebase/FirebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { FriendReqAccept, FriendReqDeny } from './friend/FriendConfirmation';
import clsx from 'clsx';

const Friends = () => {

    const userData = useSelector((state) => state.user.data);
    const [friendsData, setFriendsData] = useState([]);
    const [acceptList, setAcceptList] = useState([]);
    const [deletedList, setDeletedList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function getFriendsReq() {

            const friendRequestsQuery = query(
                collection(firestore, "friendRequests"),
                where("toUserId", "==", userData.uid),
                where('status', '==', 'pending'),
                limit(5)
            );

            try {
                const querySnapshotFriendRequest = await getDocs(friendRequestsQuery);

                const sortedFriendRequests = querySnapshotFriendRequest.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .sort((a, b) => b.timestamp - a.timestamp);

                const friendUids = sortedFriendRequests.map(request => request.fromUserId);

                const userPromises = friendUids.map(async (req_uid, index) => {
                    const userQuery = query(
                        collection(firestore, "users"),
                        where("uid", "==", req_uid)
                    );
                    const querySnapshotUser = await getDocs(userQuery);

                    return querySnapshotUser.docs.map(doc => ({
                        ...doc.data(),
                        timestamp: moment(sortedFriendRequests[index].timestamp * 1000).fromNow()
                    }));
                });

                const usersData = await Promise.all(userPromises);

                const flattenedUsersData = usersData.flat();

                setFriendsData(flattenedUsersData);
            } catch (error) {
                console.error("Error fetching friend requests or users:", error);
            }
        }

        if (userData) {
            getFriendsReq();
        }
    }, [userData]);

    const handleFriendReqAccept = useCallback(async (req_uid) => {
        setAcceptList(prev => [...prev, req_uid])
        const acceptList = await FriendReqAccept(userData, req_uid);
    }, [userData])

    const handleFriendReqDeny = useCallback(async (req_uid) => {
        setDeletedList(prev => [...prev, req_uid])
        const deniedList = await FriendReqDeny(userData, req_uid);
    }, [userData])

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-2">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Friend Request</h4>
                <Link to={'/myfriend'} className="fw-600 ms-auto font-xssss text-primary">See all</Link>
            </div>
            {friendsData.length > 0 ? (<>
                {friendsData.map((data, index) => (
                    <div className="wrap" key={index}>
                        <Link to={`/user?uid=${data?.uid}`}>
                            <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-0 bor-0 mb-2 pointer">
                                <figure className="avatar m-0 me-3">
                                    <img src={`${data?.profileImage}`} alt="avater" className={clsx("shadow-sm rounded-circle p-1", {
                                        "player-color": data?.role === 'player',
                                        "caddie-color": data?.role === 'caddie'
                                    })} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                </figure>
                                <h4 className="fw-700 text-grey-900 font-xssss m-0">{data?.firstName + ' ' + data?.lastName}
                                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{data?.timestamp}</span> </h4>
                            </div>
                        </Link>
                        <div className="card-body d-flex align-items-center justify-content-center pt-0 ps-4 pe-4 pb-4">
                            {acceptList.includes(data.uid) || deletedList.includes(data.uid) ? (
                                <>
                                    {acceptList.includes(data.uid) &&
                                        <button className="p-2 lh-20 w-75 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border border-0">Accepted</button>
                                    }
                                    {deletedList.includes(data.uid) &&
                                        <button className="p-2 lh-20 w-75 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border border-0">Deleted</button>
                                    }
                                </>
                            ) : (
                                <>
                                    <button className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0" onClick={() => handleFriendReqAccept(data.uid)}>Confirm</button>
                                    <button className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border border-0" onClick={() => handleFriendReqDeny(data.uid)}>Delete</button>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </>) : (<>
                <div className='text-muted text-center mb-2 fs-6'>You have no friend request</div>
            </>)}
        </div>
    );
}

export default Friends;