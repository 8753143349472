import { firestore } from "../../../components/firebase/FirebaseConfig";
import { collection, or, where, query, getDocs } from "firebase/firestore";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function FriendCount({ user, numOnly }) {
    const [friendCount, setFriendCount] = useState(0);
    const userData = useSelector((state) => state.user.data);

    useEffect(() => {
        if (!user) return;

        const fetchFriendCount = async () => {
            try {
                const friendsList = [];
                const friendRef = collection(firestore, "friends");
                const q = query(friendRef, or(where("friendId", "==", user.uid), where("userId", "==", user.uid)));
                const friendsDoc = await getDocs(q);

                friendsDoc.forEach((doc) => {
                    friendsList.push(doc.data().friendId === user.uid ? doc.data().userId : doc.data().friendId);
                });

                setFriendCount(friendsList.length);
            } catch (error) {
                console.error("Error fetching friend count:", error);
            }
        };

        fetchFriendCount();
    }, [user]);

    return (
        <>
            {userData?.uid === user?.uid ?
                <>
                    {numOnly ?
                        <span to={'/myfriend'}>{friendCount}</span>
                        :
                        <Link to={'/myfriend'}>{friendCount > 1 ? (`${friendCount} Friends`) : (`${friendCount} Friend`)}</Link>}
                </>
                :
                <>
                    {numOnly ?
                        <span>{friendCount}</span>
                        :
                        <span>{friendCount > 1 ? (`${friendCount} Friends`) : (`${friendCount} Friend`)}</span>
                    }
                </>
            }
        </>
    )

}