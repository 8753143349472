import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx';
// import { timeAgo } from './testfunction/TimeConvert';
import TimeAgo from './utilsfunction/TimeConvert';
import Slider from 'react-slick';
import Audience from './utilsfunction/Audience';
import { ReactSVG } from 'react-svg';
import Comment from './Comment';
import './poststyle.css'
import ExpandableContent from './utilsfunction/ExpandableContent';
import Like from './Like';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import PostDropdown from '../../../components/PostDropdown';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';


function PostviewDesktop({ posts }) {

    const isMobile = useRef(window.matchMedia("(max-width: 768px)").matches);
    const [likeTrigger, setLikeTrigger] = useState(0);
    const userData = useSelector((state) => state.user.data);
    const navigate = useNavigate();
    const [deletedPostId, setDeletedPostId] = useState([]);

    const sliderStyle = {
        arrows: isMobile.current ? false : true,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        // centerMode: true,
    }

    const [showCommentModal, setShowCommentModal] = useState(false);
    const commentData = useRef({});

    const handleShowComment = () => {
        setShowCommentModal(true);
        window.history.pushState({ modal: true }, '');
    }
    const handleCloseComment = () => {
        setShowCommentModal(false);
        if (window.history.state?.modal) {
            window.history.back();
        }
    }

    useEffect(() => {
        const handlePopState = (event) => {
            if (event.state?.modal) {
                setShowCommentModal(false);
            } else {
                if (showCommentModal) {
                    setShowCommentModal(false);
                }
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [posts, showCommentModal]);

    const handleDataComment = (data) => commentData.current = data;

    // console.log(posts);
    const handleLikeTrigger = () => {
        setLikeTrigger((prev) => prev + 1)
    }

    const handleDeletedPost = async (postId) => {
        setDeletedPostId(prevDeletedPostIds => [...prevDeletedPostIds, postId]);
    }
    return (
        <>
            {posts.map((post, index) => (
                <div className={clsx("card w-100 shadow-xss border-0 p-2 mb-2", {
                    'rounded-xxl p-4': !isMobile.current,
                    'd-none': deletedPostId.includes(post.postId)
                })} key={index}
                >
                    <div className="card-body p-0 d-flex justify-content-between">
                        <div className='d-flex'>
                            <Link to={post.userData?.uid !== userData?.uid ? `/user?uid=${post.userData?.uid}` : `/userPage`}>
                                <figure className="avatar me-3">
                                    <img
                                        src={post?.userData?.profileImage || defaultProfile}
                                        alt="avatar"
                                        className="shadow-sm rounded-circle w45 pointer"
                                        style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                                        onError={(e) => e.target.src = defaultProfile}
                                    /></figure>
                            </Link>
                            <Link to={post.userData?.uid !== userData?.uid ? `/user?uid=${post.userData?.uid}` : `/userPage`}>
                                <h4 className="d-block fw-700 text-grey-900 font-xssss pointer">
                                    {post?.userData?.firstName || post?.userData?.firstname || post?.userData?.lastName || post?.userData?.lastname
                                        ? `${post?.userData?.firstName || post?.userData?.firstname || ''} ${post?.userData?.lastName || post?.userData?.lastname || ''}`.trim()
                                        : 'Invalid User'}
                                    <div className='d-flex align-content-center mt-1'>
                                        <TimeAgo timestamp={post.timestamp.seconds} />
                                        <Audience privacy={post.audience} />
                                        {post?.lastUpdate ? <i className=' bi bi-pencil-fill ms-1 text-grey-500'></i> : null}
                                    </div>
                                </h4>
                            </Link>
                        </div>
                        {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}
                        <PostDropdown
                            post={post}
                            handleDeletedPost={handleDeletedPost}
                        />
                    </div>
                    <div className="card-body p-0">
                        <div className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2 post-text-para">
                            <span className="post-text-content" id={post.postId}>
                                <h5><ExpandableContent content={post.content} /></h5>
                            </span>
                        </div>
                    </div>
                    {post.mediaType === "image" && (
                        <>
                            {post.media.length > 0 && (
                                <PhotoProvider>
                                    <div className="card-body d-block p-0 mb-0 post-image-container">
                                        <Slider {...sliderStyle} className='post-image-slider'>
                                            {post.media.map((image, index) => (
                                                <div key={index} className='each-post-image'>
                                                    <PhotoView key={index} src={image}>
                                                        <img src={image} alt={index} style={{ cursor: 'pointer' }} />
                                                    </PhotoView>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </PhotoProvider>
                            )}
                        </>
                    )}
                    <div className="card-body d-flex p-0">
                        <div className="d-flex align-items-center gap-3">
                            <span className='d-flex align-items-center'>
                                <Like
                                    isPost={true}
                                    key={likeTrigger}
                                    postData={post}
                                />
                            </span>
                            <span className='d-flex align-items-center pointer' onClick={() => {
                                handleShowComment();
                                handleDataComment(post);
                            }}>
                                <ReactSVG src='assets/images/dooNineIconV2/others/post-Comment.svg' style={{ zIndex: 1 }} className='w35' />
                                <span><h5 className='m-0 ms-1'>{post?.commentsCount || 0}</h5></span>
                            </span>
                        </div>
                    </div>
                </div>
            ))}
            <Comment
                likeTrigger={handleLikeTrigger}
                isShow={showCommentModal}
                handleClose={handleCloseComment}
                commentData={commentData.current}
            />
        </>
    )
}

export default PostviewDesktop
