import React, { useState, useReducer } from 'react'
import { Modal, Form } from 'react-bootstrap'
import { ReactSwitchComp, CheckboxComp } from '../../../../../components/ReactSwitch'


function filterReducer(state, action) {
    switch (action.type) {
        case 'BOOKMARK':
            return {
                ...state,
                bookmark: action.payload
            };
        case 'OFFICIAL':
            return {
                ...state,
                official: action.payload
            };
        case 'PRICERANGE':
            return {
                ...state,
                priceRange: action.payload
            };
        case 'NIGHTGOLF':
            return {
                ...state,
                nightGolf: action.payload
            };
        case 'DRIVINGRANGE':
            return {
                ...state,
                drivingRange: action.payload
            };
        case 'RESTAURANT':
            return {
                ...state,
                restaurant: action.payload
            };
        case 'BANQUET':
            return {
                ...state,
                banquetRooms: action.payload
            };
        case 'HOTEL':
            return {
                ...state,
                hotel: action.payload
            };

        default:
            throw new Error('Unknown action type');
    }
}


const FilterCourse = ({ isOpen, handleClose }) => {

    const initialState = {
        bookmark: false,
        official: false,
        priceRange: false,
        nightGolf: false,
        drivingRange: false,
        restaurant: false,
        banquetRooms: false,
        hotel: false
    }

    const [state, dispatch] = useReducer(filterReducer, initialState);

    return (
        <>
            <Modal show={isOpen} onHide={handleClose} animation={false} centered>
                <Modal.Header>
                    <Modal.Title>
                        <div className='d-flex align-items-center'>
                            <i className='feather-filter me-2 text-muted' style={{ fontSize: 22 }}></i>
                            <h2 className='fw-600'>Filter</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div className='d-flex justify-content-between mb-4'>
                        <h5 className='fw-600'>Bookmark Only</h5>
                        <ReactSwitchComp
                            isChecked={state.bookmark}
                            handleChecked={(checkValue) => dispatch({ type: 'BOOKMARK', payload: checkValue })} />
                    </div>

                    <div className='d-flex justify-content-between mb-4'>
                        <span>
                            <h5 className='fw-600'>Official Course</h5>
                            <h6 className='fw-600 text-muted me-2'>Only officially registered golf courses with doonine.</h6>
                        </span>
                        <ReactSwitchComp
                            isChecked={state.official}
                            handleChecked={(checkValue) => dispatch({ type: 'OFFICIAL', payload: checkValue })} />
                    </div>

                    <div className='d-flex justify-content-between mb-4'>
                        <h5 className='fw-600'>Starting Price Range</h5>
                        <ReactSwitchComp
                            isChecked={state.priceRange}
                            handleChecked={(checkValue) => dispatch({ type: 'PRICERANGE', payload: checkValue })} />
                    </div>

                    <div className='d-flex justify-content-between mb-4'>
                        <h5 className='fw-600'>Night Golf</h5>
                        <CheckboxComp
                            isChecked={state.nightGolf}
                            handleChecked={(checkValue) => dispatch({ type: 'NIGHTGOLF', payload: checkValue })} />
                    </div>
                    <div className='d-flex justify-content-between mb-4'>
                        <h5 className='fw-600'>Driving Range</h5>
                        <CheckboxComp
                            isChecked={state.drivingRange}
                            handleChecked={(checkValue) => dispatch({ type: 'DRIVINGRANGE', payload: checkValue })} />
                    </div>
                    <div className='d-flex justify-content-between mb-4'>
                        <h5 className='fw-600'>Restaurant</h5>
                        <CheckboxComp
                            isChecked={state.restaurant}
                            handleChecked={(checkValue) => dispatch({ type: 'RESTAURANT', payload: checkValue })} />
                    </div>
                    <div className='d-flex justify-content-between mb-4'>
                        <h5 className='fw-600'>Banquet Rooms</h5>
                        <CheckboxComp
                            isChecked={state.banquetRooms}
                            handleChecked={(checkValue) => dispatch({ type: 'BANQUET', payload: checkValue })} />
                    </div>
                    <div className='d-flex justify-content-between mb-4'>
                        <h5 className='fw-600'>Hotel</h5>
                        <CheckboxComp
                            isChecked={state.hotel}
                            handleChecked={(checkValue) => dispatch({ type: 'HOTEL', payload: checkValue })} />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-light theme-dark-bg'><h4 className='fw-600'>Cancel</h4></button>
                    <button className='btn bg-current'><h4 className='fw-600 text-white'>Apply</h4></button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default FilterCourse

// Handle change in the search input
export const handleSearch = (event, setSearchTerm, setFilteredCourse, courseListRef) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    // Filter data based on the input value
    const filtered = courseListRef.current.filter(item =>
        item.courseName.toLowerCase().includes(value) ||
        item.location.provinceEng.toLowerCase().includes(value) ||
        item.location.subDistrictEng.toLowerCase().includes(value)
    );
    setFilteredCourse(filtered);
};



