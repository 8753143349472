import React from 'react'
import { useScore } from './ScoreContext/ScoreContext'
import StablefordGross from './scoring/stableford/StablefordGross';

const ScoreStableford = () => {

    const { roundData, playerGameMode } = useScore();

    if (playerGameMode) {
        switch (playerGameMode.scoring) {
            case "Gross":
                return <StablefordGross />

            default:
                console.warn('Incorrect scoring', playerGameMode.scoring);
                break;
        }
    } else {
        switch (roundData.scoring) {
            case "Gross":
                return <StablefordGross />

            default:
                console.warn('Incorrect scoring', roundData.scoring);
                break;
        }
    }
}

export default ScoreStableford