import React, { useEffect, useState, useRef } from 'react'

import Header from '../../components/Header'
import Leftnav from '../../components/Leftnav'
import Rightchat from '../../components/Rightchat'
import Popupchat from '../../components/Popupchat'
import Appfooter from '../../components/Appfooter'

import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { firestore } from '../../components/firebase/FirebaseConfig'
import LoadCircle from '../../components/LoadCircle'
import CaddieReq from '../activity/component/joinRound/module/CaddieReq'
import SelectCaddieModal from '../activity/component/joinRound/module/SelectCaddieModal'
import useModalWithHistory from '../../components/ModalPopState'

import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import SwapCaddie from './component/SwapCaddie'
import Swal from 'sweetalert2'

const PlayerAndCaddieRound = () => {
    const userData = useSelector((state) => state.user.data);
    const [searchParams] = useSearchParams();
    const roundId = searchParams.get('round');
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();

    const [roundData, setRoundData] = useState(null);
    const [userList, setUserList] = useState([]);
    const [courseHoleName, setCourseHolesName] = useState("");
    const [uids, setUids] = useState([]);
    const [isCreator, setIsCreator] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [swapModal, setSwapModal] = useState(false);
    const selectedSwapRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getRoundData = async (roundId) => {
            const roundDoc = doc(firestore, 'round', roundId);
            const roundSnapshot = await getDoc(roundDoc);
            if (roundSnapshot.exists()) {
                const data = roundSnapshot.data();
                await getcourseHole(data.holesCourse)
                await getUserData(data.userList);
                setRoundData(data);
                setUserList(data.userList);

                // find creator
                const foundCreator = data.userList.find(
                    item => [item.player, item.caddie].some(role => role && role.uid === userData.uid && role.isCreator)
                );
                setIsCreator(!!foundCreator);

            } else {
                console.error('Not found round id', roundId);
            }

        }

        async function getcourseHole(courseId) {
            try {
                const courseHolesCollection = collection(firestore, 'courseHoles');
                const q = doc(courseHolesCollection, courseId);
                const courseHolesSnapshot = await getDoc(q);
                if (courseHolesSnapshot.exists) {
                    setCourseHolesName(courseHolesSnapshot.data().courseHoleName);
                }
            } catch (error) {
                console.error('Fail to get Course Holes data', error);

            }
        }

        async function getUserData(userList) {
            const userListCopy = [...userList];
            const userUidList = [];

            userList.forEach(user => {
                if (user.player) {
                    userUidList.push(user.player.uid);
                }
                if (user.caddie) {
                    userUidList.push(user.caddie.uid);
                }
            });
            setUids(userUidList)
            try {
                const userCollection = collection(firestore, 'users');
                const q = query(userCollection, where('uid', 'in', userUidList));
                const userSnapshot = await getDocs(q);

                if (!userSnapshot.empty) {
                    userSnapshot.forEach(doc => {
                        const firestoreUserData = doc.data();
                        const firestoreUid = firestoreUserData.uid;

                        userListCopy.forEach(user => {
                            if (user.player && user.player.uid === firestoreUid) {
                                user.player = {
                                    ...user.player,
                                    ...firestoreUserData
                                };
                            }
                            if (user.caddie && user.caddie.uid === firestoreUid) {
                                user.caddie = {
                                    ...user.caddie,
                                    ...firestoreUserData
                                };
                            }
                        });
                    });
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }

        if (roundId && userData) {
            getRoundData(roundId);
        }

    }, [roundId, userData])

    // console.log(userList);
    // console.log(roundData);

    function handleOpenAddModal() {
        setAddModal(true);
        openModal();
    }
    function handleCloseAddModal() {
        setAddModal(false);
        closeModal();
    }

    function handleOpenSwapModal(user) {
        selectedSwapRef.current = user;
        setSwapModal(true);
        openModal();
    }
    function handleCloseSwapModal() {
        setSwapModal(false);
        closeModal();
    }

    const handleCancelCaddie = (user) => {

        const updatedUserList = userList.map(item => {
            if (item.row === user.row) {
                return { ...item, caddie: null };
            }
            return item;
        });

        const filteredCaddie = uids.filter(item => item !== user.caddie.uid);

        setUids(filteredCaddie);
        setUserList(updatedUserList);
    };

    const handleAddCaddie = (caddie) => {
        // Map over userList and create a new array with the updated caddie
        const updatedUserList = userList.map(item => {
            if (item.player && item.player.uid === userData.uid) {
                // Return a new object with the updated caddie
                return { ...item, caddie: caddie };
            }
            return item;
        });

        setUids(prev => [...prev, caddie.uid])

        // Update the state with the modified array
        setUserList(updatedUserList);
    };

    const handleSubmit = async () => {
        console.log(userList);

        setLoading(true);
        const updatedUserList = JSON.parse(JSON.stringify(userList)).map(item => {
            if (item.player) {
                item.player = {
                    uid: item.player.uid,
                    isCreator: item.player.isCreator,
                    isJoin: item.player.isJoin,
                }
            }
            if (item.caddie) {
                item.caddie = {
                    uid: item?.caddie?.uid,
                    isCreator: item?.caddie?.isCreator || false,
                    isJoin: item?.caddie?.isJoin || false,
                    playerConfirm: item.player?.isJoin === true ? true : (item?.caddie?.playerConfirm || false),
                }
            }
            return item;
        });
        try {

            const roundDoc = doc(firestore, 'round', roundId);

            await updateDoc(roundDoc, {
                userList: updatedUserList
            })

            const scoreCollection = collection(firestore, 'scoreCards');
            const q = query(scoreCollection, where('roundId', '==', roundId));
            const scoreSnapshot = await getDocs(q);
            if (!scoreSnapshot.empty) {
                for (const doc of scoreSnapshot.docs) {
                    const scoreData = doc.data();

                    // Find the corresponding user based on 'row'
                    const matchingUser = userList.find(user => user.row === scoreData.row);

                    if (matchingUser) {
                        await updateDoc(doc.ref, {
                            caddieId: matchingUser.caddie.uid
                        });
                    } else {
                        console.log(`No matching user found for document with ID: ${doc.id}`);
                    }
                }
                Swal.fire({
                    icon: 'success',
                    title: 'Update Complete',
                    confirmButtonColor:'#ee3c7f'
                }).then(() => {
                    navigate(`/score?round=${roundId}`)
                })
            }

        } catch (error) {
            console.error('Fail to update caddie', error);
        } finally {
            setLoading(false);
        }
    }


    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to={`/score?round=${roundId}`} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <div className='d-flex align-items-center w-100'>
                                        <div className='mx-2'>
                                            {roundData ?
                                                <>
                                                    <h3 className='fw-700 text-white'>{roundData?.roundName}</h3>
                                                    <h6 className='fw-600 text-white text-capitalize'>{`${roundData?.holes}(${courseHoleName || ""}), ${roundData?.gameMode}(${roundData?.scoring})`}</h6>
                                                </>
                                                :
                                                <LoadCircle />
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {roundData ? (
                                                <>
                                                    {userData?.role === 'caddie' ? (
                                                        <section className='mb-4'>
                                                            <h3 className='fw-600 mb-1'>Your player</h3>
                                                            <div className='bg-light theme-light-bg p-3 rounded-3'>
                                                                {(() => {
                                                                    const foundPlayer = userList.find(item =>
                                                                        item.caddie && item.caddie.uid === userData.uid && item.player
                                                                    );

                                                                    if (foundPlayer) {
                                                                        return (
                                                                            <>
                                                                                <div className='d-flex justify-content-start align-items-center mb-2'>
                                                                                    <img
                                                                                        src={foundPlayer.player.profileImage}
                                                                                        alt="player"
                                                                                        width={50}
                                                                                        height={50}
                                                                                        className='rounded-circle me-2 player-color-outline'
                                                                                    />
                                                                                    <h4 className='fw-600 d-inline'>{`${foundPlayer.player.firstName} ${foundPlayer.player.lastName}`}</h4>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <div style={{ height: 50 }}></div>
                                                                        )
                                                                    }
                                                                })()}
                                                            </div>
                                                        </section>
                                                    ) : (
                                                        <section className='mb-4'>
                                                            <h3 className='fw-600 mb-1'>Select your caddie</h3>
                                                            <div className='bg-light theme-light-bg p-3 rounded-3'>
                                                                {(() => {
                                                                    const foundCaddie = userList.find(item =>
                                                                        item.player && item.player.uid === userData.uid && item.caddie
                                                                    );

                                                                    if (foundCaddie) {
                                                                        return (
                                                                            <>
                                                                                <div className='d-flex justify-content-start align-items-center mb-2'>
                                                                                    <img
                                                                                        src={foundCaddie.caddie.profileImage}
                                                                                        alt="caddie"
                                                                                        width={50}
                                                                                        height={50}
                                                                                        className='rounded-circle me-2 caddie-color-outline'
                                                                                    />
                                                                                    <h4 className='fw-600 d-inline'>{`${foundCaddie.caddie.firstName} ${foundCaddie.caddie.lastName}`}</h4>
                                                                                </div>
                                                                                {!foundCaddie.caddie.isCreator &&
                                                                                    <button className='btn bg-white theme-dark-bg d-block'
                                                                                        onClick={() => handleCancelCaddie(foundCaddie)}
                                                                                    >
                                                                                        <h4 className='fw-600'>Cancel</h4>
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <div>
                                                                                <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_p.svg'
                                                                                    wrapper='svg'
                                                                                    width={50}
                                                                                    height={50}
                                                                                    className='pointer'
                                                                                    onClick={handleOpenAddModal}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                })()}
                                                            </div>
                                                        </section>
                                                    )}
                                                    {userData.role === 'player' ?
                                                        <section>
                                                            <CaddieReq roundData={roundData} />
                                                        </section>
                                                        : null}

                                                    <section className='mb-4'>
                                                        <h4 className='fw-600 mb-1'>{`Players and caddies in this round (${uids.length}/${userList.length * 2})`}</h4>
                                                        <div className='card bg-light py-1 px-2 border-0'>
                                                            {userList.map((user, idx) =>
                                                                <div key={idx} className='card-body bg-white theme-dark-bg my-1 d-flex flex-wrap'>
                                                                    <div className='col-sm-6 col-12 p-2'>
                                                                        {user.player ?
                                                                            <div className='d-flex align-items-center gap-2'>
                                                                                <div className='position-relative'>
                                                                                    <img src={user.player.profileImage} alt="player" width={50} height={50} className='rounded-circle player-color-outline' />
                                                                                    {user.player.isJoin ?
                                                                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-confirmJoin_g.svg'
                                                                                            wrapper='svg'
                                                                                            width={20}
                                                                                            height={20}
                                                                                            className='position-absolute'
                                                                                            style={{ top: 0, left: 0 }}
                                                                                        />
                                                                                        : null}
                                                                                </div>
                                                                                <div>
                                                                                    <h4 className='fw-600'>{`${user.player.firstName} ${user.player.lastName}`}</h4>
                                                                                    {user.player.isCreator ? <h6 className='fw-600 text-muted'>Creator</h6> : null}
                                                                                    {userData.uid === user.player.uid ? <h6 className='fw-600 text-muted'>You</h6> : null}
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className='bg-light theme-light-bg rounded-3'>
                                                                                <div className='w-100' style={{ height: 50 }}></div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className='col-sm-6 col-12 p-2'>
                                                                        {user.caddie ?
                                                                            <div className='d-flex align-items-center gap-2 position-relative'>
                                                                                <div className='position-relative'>
                                                                                    <img src={user.caddie.profileImage} alt="caddie" width={50} height={50} className='rounded-circle caddie-color-outline' />
                                                                                    {user.caddie.isJoin ?
                                                                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-confirmJoin_g.svg'
                                                                                            wrapper='svg'
                                                                                            width={20}
                                                                                            height={20}
                                                                                            className='position-absolute'
                                                                                            style={{ top: 0, left: 0 }}
                                                                                        />
                                                                                        : null}
                                                                                </div>
                                                                                <div>
                                                                                    <h4 className='fw-600'>{`${user.caddie.firstName} ${user.caddie.lastName}`}</h4>
                                                                                    {user.caddie.isCreator ? <h6 className='fw-600 text-muted'>Creator</h6> : null}
                                                                                    {userData.uid === user.caddie.uid ? <h6 className='fw-600 text-muted'>You</h6> : null}
                                                                                </div>
                                                                                {user.caddie && user.caddie.uid !== userData.uid ?
                                                                                    <>
                                                                                        {isCreator &&
                                                                                            <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-change.svg'
                                                                                                wrapper='svg'
                                                                                                width={30}
                                                                                                height={30}
                                                                                                className='position-absolute pointer'
                                                                                                style={{ bottom: 0, right: 0 }}
                                                                                                onClick={() => handleOpenSwapModal(user)}
                                                                                            />
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className='bg-light theme-light-bg rounded-3'>
                                                                                <div className='w-100' style={{ height: 50 }}></div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </section>
                                                </>
                                            ) :
                                                <div className='p-5 d-flex justify-content-center align-items-center'>
                                                    <LoadCircle size='lg' variant='dark' />
                                                </div>
                                            }
                                            <section className='d-flex justify-content-end align-items-center gap-2'>
                                                <button className='btn bg-current' onClick={() => handleSubmit()}>
                                                    {loading ?
                                                        <LoadCircle />
                                                        :
                                                        <h4 className='fw-600 text-white'>Ok</h4>
                                                    }
                                                </button>
                                                <Link to={`/score?round=${roundId}`}>
                                                    <button className='btn bg-light theme-dark-bg'>
                                                        <h4 className='fw-600'>Cancel</h4>
                                                    </button>
                                                </Link>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />

            {addModal &&
                <SelectCaddieModal
                    isOpen={isModalOpen}
                    handleClose={handleCloseAddModal}
                    selectedCaddie={(caddie) => handleAddCaddie(caddie)}
                    inServiceCaddie={uids}
                    course={roundData?.course}
                />
            }

            {swapModal &&
                <SwapCaddie
                    isOpen={isModalOpen}
                    handleClose={handleCloseSwapModal}
                    selectedCaddie={selectedSwapRef.current}
                    userList={userList}
                    setUserList={setUserList}
                />
            }
        </>
    )
}

export default PlayerAndCaddieRound