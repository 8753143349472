import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import CreatableSelect from "react-select/creatable";
import { useSelector } from "react-redux";
import ReactSwitch from "react-switch";
import { Form } from "react-bootstrap";

const switchStyle = {
  width: 44,
  height: 20,
  onColor: "#EE3D7F",
  boxShadow: "0 0 2px 1px #fff",
  handleDiameter: 16,
};

function ModalFilter({
  showSortAndFilter,
  handleCloseSortAndFilter,
  tempSortData,
  handleSubmit,
  handleSwitchChange,
  handleInputChange,
  userLabels,
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const formattedOptions = userLabels.map((label) => ({
      value: label.value,
      label: label.label,
    }));
    setOptions(formattedOptions);
  }, [userLabels]);

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "20px", // Rounded borders
      display: "flex",
      alignItems: "center",
      padding: "6px 6px", // Increase padding for larger size
      fontSize: "16px", // Increase font size
      height: "30px", // Increase the height to make the separator taller
      background: "#F5F5F5",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "0 16px", // Adjust padding for more space between text and icon
      fontSize: "16px", // Match font size
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: "0 20px 20px 0",
      borderLeft: "1px solid #ccc", // Separator between text and close icon
      height: "100%", // Ensure the separator spans the full height of the container
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontSize: "18px", // Increase icon size
      "&:hover": {
        backgroundColor: "#FF6B6B", // Change background color on hover
        color: "white",
      },
    }),
  };

  return (
    <>
      <Modal show={showSortAndFilter} onHide={handleCloseSortAndFilter}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="feather-filter font-md text-grey-500"></i>
            <h2
              style={{ display: "inline-block", marginLeft: "15px" }}
              className="fw-700 mb-0 mt-0 font-sm text-grey-700"
            >
              Filter
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="block-switch" className="mb-3 switch-label">
              <h4 className="fw-600 mb-0 mt-0 switch-text">Favorites</h4>
              <ReactSwitch
                checked={tempSortData.isFavorites.state}
                onChange={(checked) =>
                  handleSwitchChange(checked, "isFavorites")
                }
                onColor={switchStyle.onColor}
                width={switchStyle.width}
                height={switchStyle.height}
                handleDiameter={switchStyle.handleDiameter}
                activeBoxShadow={switchStyle.boxShadow}
              />
            </label>

            <label htmlFor="isStar-switch" className="mb-3 switch-label">
              <h4 className="fw-600 mb-0 mt-0 switch-text">Caddie Star</h4>
              <ReactSwitch
                checked={tempSortData.isStar.state}
                onChange={(checked) => handleSwitchChange(checked, "isStar")}
                onColor={switchStyle.onColor}
                width={switchStyle.width}
                height={switchStyle.height}
                handleDiameter={switchStyle.handleDiameter}
                activeBoxShadow={switchStyle.boxShadow}
              />
            </label>

            <label htmlFor="isPro-switch" className="mb-3 switch-label">
              <h4 className="fw-600 mb-0 mt-0 switch-text">Pro Caddie</h4>

              <ReactSwitch
                checked={tempSortData.isPro.state}
                onChange={(checked) => handleSwitchChange(checked, "isPro")}
                onColor={switchStyle.onColor}
                width={switchStyle.width}
                height={switchStyle.height}
                handleDiameter={switchStyle.handleDiameter}
                activeBoxShadow={switchStyle.boxShadow}
              />
            </label>

            <label htmlFor="age-switch" className="mb-3 switch-label">
              <h4 className="fw-600 mb-0 mt-0 switch-text">Age</h4>
              <ReactSwitch
                checked={tempSortData.age.state}
                onChange={(checked) => handleSwitchChange(checked, "age")}
                onColor={switchStyle.onColor}
                width={switchStyle.width}
                height={switchStyle.height}
                handleDiameter={switchStyle.handleDiameter}
                activeBoxShadow={switchStyle.boxShadow}
              />
            </label>

            {/* Age input */}
            {tempSortData.age.state ? (
              <>
                <div className="row">
                  <Form>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-2">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Age min</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder=""
                            value={tempSortData.age.min}
                            onChange={(e) => handleInputChange(e, "age", "min")}
                          />
                        </Form.Group>
                      </div>
                      <div className="flex-shrink-0 mx-2 fw-300 font-xs text-grey-700">
                        to
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label>Age max</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder=""
                            value={tempSortData.age.max}
                            onChange={(e) => handleInputChange(e, "age", "max")}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            ) : (
              ""
            )}

            <label htmlFor="label-switch" className="mb-3 switch-label">
              <h4 className="fw-600 mb-0 mt-0 switch-text">Label</h4>
              <ReactSwitch
                checked={tempSortData.isLabel.state}
                onChange={(checked) => handleSwitchChange(checked, "isLabel")}
                onColor={switchStyle.onColor}
                width={switchStyle.width}
                height={switchStyle.height}
                handleDiameter={switchStyle.handleDiameter}
                activeBoxShadow={switchStyle.boxShadow}
              />
            </label>

            {tempSortData.isLabel.state && (
              <div className="mb-3">
                <CreatableSelect
                  isMulti
                  options={options}
                  value={tempSortData.isLabel.selectedLabels}
                  onChange={(selectedOptions) =>
                    handleInputChange(
                      selectedOptions,
                      "isLabel",
                      "selectedLabels"
                    )
                  }
                  isDisabled={false}
                  styles={customStyles}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select an option"
                  onCreateOption={null} // Disable creation of new options
                  formatCreateLabel={() => null} // ไม่แสดงข้อความ "Create"
                />
              </div>
            )}

            <label htmlFor="block-switch" className="switch-label">
              <h4 className="fw-600 mb-0 mt-0 switch-text">Block</h4>

              <ReactSwitch
                checked={tempSortData.isBlocks.state}
                onChange={(checked) => handleSwitchChange(checked, "isBlocks")}
                onColor={switchStyle.onColor}
                width={switchStyle.width}
                height={switchStyle.height}
                handleDiameter={switchStyle.handleDiameter}
                activeBoxShadow={switchStyle.boxShadow}
              />
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn bg-greylight"
            onClick={handleCloseSortAndFilter}
          >
            <h4 className="fw-600 m-0 text-grey-700 p-1">Close</h4>
          </button>
          <button className="btn bg-current post-btn" onClick={handleSubmit}>
            <h4 className="fw-600 m-0 text-white p-1">Apply</h4>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalFilter;
