import React from "react";

import Stack from "react-bootstrap/Stack";
import { ReactSVG } from "react-svg";

function StackFilter({
  trueStates,
  sortData,
  setSortData,
  setTempSortData,
  search,
  setSearch,
  originalCaddieList,
}) {
  const handleRemoveBadge = (key, state) => {
    if (key === "text") {
      setSearch({
        ...search,
        text: {
          state: false,
          value: "",
        },
      });
    
    } else {
      setSortData((prevState) => ({
        ...prevState,
        [key]: { ...prevState[key], state: false },
      }));
      setTempSortData(sortData);
    }
  };

  const handleRemoveBadgeLabel = (badgeToRemove) => {
    const updatedLabels = sortData.isLabel.selectedLabels.filter(
      (label) => label.value !== badgeToRemove.value
    );

    setSortData((prevState) => ({
      ...prevState,
      isLabel: {
        ...prevState.isLabel,
        selectedLabels: updatedLabels,
        state: updatedLabels.length > 0 ? prevState.isLabel.state : false,
      },
    }));
  };
  // console.log(trueStates);

  const badgeStyle = {
    backgroundColor: "#F5F5F5",
    display: "inline-flex",
    alignItems: "center",
    padding: "0 0.75em",
    margin: "0.25em",
    borderRadius: "50px",
    whiteSpace: "nowrap",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Light shadow for depth
    height: "32px", // Set a fixed height
  };

  const buttonStyle = {
    background: "transparent",
    border: "none",
    color: "#555",
    cursor: "pointer",
    padding: "0",
    marginLeft: "0.5em",
    fontSize: "1em",
    lineHeight: "1",
  };

  const filterStyle = {
    marginRight: "0.5em",
    display: "inline-flex",
    marginBottom: "0",
    lineHeight: "32px", // Match the fixed height to center content
  };

  return (
    <>
      {trueStates.length !== 0 || search.text.value !== "" ? <hr style={{marginBottom:"0rem"}}/> : null}

      <Stack direction="horizontal" gap={2} className="mb-3 mt-1">
        {trueStates
          .filter((key) => key !== "isLabel") // Filter out "isLabel"
          .map((key) => (
            <div className="font-xssss fw-600" key={key} style={badgeStyle}>
              &nbsp;&nbsp;
              {key === "isStar" ? (
                <h5 style={filterStyle}>Star</h5>
              ) : key === "isPro" ? (
                <h5 style={filterStyle}>Pro</h5>
              ) : key === "age" ? (
                <h5 style={filterStyle}>
                  Age : {sortData[key].min} to {sortData[key].max}
                </h5>
              ) : key === "isFavorites" ? (
                <h5 style={filterStyle}>Favorites</h5>
              ) : key === "height" ? (
                <h5 style={filterStyle}>
                  Height ({sortData[key].min}-{sortData[key].max})
                </h5>
              ) : key === "weight" ? (
                <h5 style={filterStyle}>
                  Weight ({sortData[key].min}-{sortData[key].max})
                </h5>
              ) : key === "isBlocks" ? (
                <h5 style={filterStyle}>Block</h5>
              ) : (
                ""
              )}
              <button
                className="btn"
                onClick={() => handleRemoveBadge(key)}
                style={buttonStyle}
              >
                ✕
              </button>
            </div>
          ))}

        {sortData.isLabel.state &&
          sortData.isLabel.selectedLabels.length > 0 && (
            <>
              {sortData.isLabel.selectedLabels.map((label) => (
                <div
                  className="font-xssss fw-600 badge-container"
                  key={label.value}
                  style={badgeStyle}
                >
                  <h5 style={filterStyle}>
                    <ReactSVG
                      src="assets/images/dooNineIconV3/my-performance/profile-label.svg"
                      style={{ width: "18px", marginRight: "0.25em" }}
                    />
                    {label.label}
                  </h5>
                  <button
                    className="btn badge-close-button"
                    onClick={() => handleRemoveBadgeLabel(label)}
                    style={buttonStyle}
                  >
                    ✕
                  </button>
                </div>
              ))}
            </>
          )}

        {search.text?.state === true ? (
          <div className="font-xssss fw-600" style={badgeStyle}>
            <h5 style={filterStyle}>
              <i className="bi bi-search fs-6 me-2"></i>
              {search.text?.value}
            </h5>
            <button
              className="btn"
              onClick={() => handleRemoveBadge("text", search.text)}
              style={buttonStyle}
            >
              ✕
            </button>
          </div>
        ) : null}

        
      </Stack>
    </>
  );
}

export default StackFilter;
