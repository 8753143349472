import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Popupchat from "../../components/Popupchat";
import Appfooter from "../../components/Appfooter";
import { firestore } from "../../components/firebase/FirebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import CourseDetailHeader from "./component/CourseDetailHeader";
import CourseDetailAboutBody from "./component/CourseDetailAboutBody";
import CourseDetailAboutGallery from "./component/CourseDetailAboutGallery";

import CourseDetailCourseBody from "./component/CourseDetailCourseBody";

import CourseDetailCaddieBody from "./component/bodycaddie/CourseDetailCaddieBody";

function CourseDetail() {
  const { state } = useLocation();
  const userData = useSelector((state) => state.user.data);
  const [courseData, setCourseData] = useState(state);
  const [section, setSection] = useState("about");
  const [favoriteCourse, setFavoriteCourse] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [urlCourseId, setUrlCourseId] = useState(null);

  // useEffect(() => {
  //   async function fetchData() {
  //     const params = new URLSearchParams(window.location.search);
  //     setUrlCourseId(params.get("courseId"));
  //     const courseId = params.get("courseId");

  //     const docRef = doc(firestore, "courses", courseId);
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       setCourseData({ courseId: docSnap.id, ...docSnap.data() });

  //       if (userData?.uid !== undefined) {
  //         const favoriteCourseRef = collection(firestore, "favoriteCourses");
  //         const querySnapshot = await getDocs(
  //           query(
  //             favoriteCourseRef,
  //             where("userId", "==", userData?.uid),
  //             where("courseId", "==", docSnap.id)
  //           )
  //         );

  //         if (querySnapshot.empty) {
  //           setFavoriteCourse(false);
  //         } else {
  //           querySnapshot.forEach(async (doc) => {
  //             if (doc.data().status === "active") {
  //               setFavoriteCourse(true);
  //             } else {
  //               setFavoriteCourse(false);
  //             }
  //           });
  //         }
  //       }
  //     } else {
  //       // docSnap.data() will be undefined in this case
  //       console.log("No such document!");
  //     }
  //   }
  //   fetchData();
  // }, [userData?.uid, trigger]);

  useEffect(() => {
    async function fetchData() {
      try {
        const params = new URLSearchParams(window.location.search);
        
        const courseId = params.get("courseId");
  
        if (!courseId) {
          console.error("Course ID is not present in the URL.");
          return;
        }
  
        setUrlCourseId(params.get("courseId"));
  
        const docRef = doc(firestore, "courses", courseId);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const courseData = docSnap.data();
          setCourseData({ courseId: docSnap.id, ...courseData });
  
          if (userData?.uid) {
            const favoriteCourseRef = collection(firestore, "favoriteCourses");
            const querySnapshot = await getDocs(
              query(
                favoriteCourseRef,
                where("userId", "==", userData.uid),
                where("courseId", "==", docSnap.id)
              )
            );
  
            if (querySnapshot.empty) {
              setFavoriteCourse(false);
            } else {
              let isFavoriteActive = false;
              querySnapshot.forEach((doc) => {
                if (doc.data()?.status === "active") {
                  isFavoriteActive = true;
                }
              });
              setFavoriteCourse(isFavoriteActive);
            }
          }
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  
    fetchData();
  }, [userData?.uid, trigger]);
  

  // useEffect(() => {
  //   async function fetchData() {
  //     const favoriteCourseRef = collection(firestore, "favoriteCourses");
  //     const querySnapshot = await getDocs(
  //       query(
  //         favoriteCourseRef,
  //         where("userId", "==", userData.uid),
  //         where("courseId", "==", doc.data().courseId)
  //       )
  //     );

  //     if (querySnapshot.empty) {
  //       setFavoriteCourse(false);
  //     } else {
  //       querySnapshot.forEach(async (doc) => {
  //         if (doc.data().status === "active") {
  //           setFavoriteCourse(true);
  //         } else {
  //           setFavoriteCourse(false);
  //         }
  //       });
  //     }
  //   }

  //   if (userData) {
  //     fetchData();
  //   }
  // }, []);

  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <CourseDetailHeader
                  courseData={courseData}
                  section={section}
                  setSection={setSection}
                  favoriteCourse={favoriteCourse}
                  trigger={trigger}
                  setTrigger={setTrigger}
                />
              </div>

              {section === "about" ? (
                <>
                  <div className="col-xl-12 mb-3">
                    <CourseDetailAboutBody courseData={courseData} />
                  </div>

                  <div className="col-xl-12 mb-3">
                    <CourseDetailAboutGallery courseData={courseData} />
                  </div>
                </>
              ) : section === "course" ? (
                <>
                  <div className="col-xl-12 mb-3">
                    <CourseDetailCourseBody courseData={courseData} />
                  </div>
                </>
              ) : section === "caddie" ? (
                <>
                  <div className="col-xl-12 mb-3">
                    <CourseDetailCaddieBody courseData={courseData} setCourseData={setCourseData} />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </>
  );
}

export default CourseDetail;
