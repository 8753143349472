import { collection, query, where, getDocs, addDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { firestore } from "../firebase/FirebaseConfig";
import { acceptFriendnotifications } from "../notification/sentNotification";

export async function FriendReqAccept(userData, req_uid) {
    const friendRequestsQuery = query(
        collection(firestore, "friendRequests"),
        where("fromUserId", "==", req_uid),
        where("toUserId", "==", userData.uid),
    );
    try {
        const querySnapshotFriendRequest = await getDocs(friendRequestsQuery);
        if (!querySnapshotFriendRequest.empty) {

            const timeStampInSeconds = Math.floor(Date.now() / 1000);
            const req_doc = querySnapshotFriendRequest.docs[0];

            if (req_doc.data().status === 'pending') {
                const friendsQuery = query(
                    collection(firestore, "friends"),
                    where(
                        'userId',
                        'in',
                        [userData.uid, req_uid]
                    ),
                    where(
                        'friendId',
                        'in',
                        [userData.uid, req_uid]
                    )
                );
                const friendsSnapshot = await getDocs(friendsQuery);
                if (friendsSnapshot.empty) {
                    const friendeqRef = doc(firestore, "friendRequests", req_doc.id);

                    await updateDoc(friendeqRef, {
                        status: 'accept',
                        statusUpdateTime: timeStampInSeconds
                    });

                    await addDoc(collection(firestore, "friends"), {
                        friendDocId: "",
                        friendId: userData.uid,
                        status: "active",
                        timestamp: timeStampInSeconds,
                        userId: req_uid,
                    });
                    
                    acceptFriendnotifications(userData.uid, req_uid, timeStampInSeconds);

                    return req_uid;
                }
            }
        }
    } catch (error) {
        console.error('Friend accept failed', error);
    }
}

export async function FriendReqDeny(userData, req_uid) {
    const friendRequestsQuery = query(
        collection(firestore, "friendRequests"),
        where("fromUserId", "==", req_uid),
        where("toUserId", "==", userData.uid),
    );
    try {
        const querySnapshotFriendRequest = await getDocs(friendRequestsQuery);

        if (!querySnapshotFriendRequest.empty) {
            const req_doc = querySnapshotFriendRequest.docs[0];

            if (req_doc.data().status === 'pending') {

                const friendeqRef = doc(firestore, "friendRequests", req_doc.id);
                await deleteDoc(friendeqRef);
                return req_uid
            }
        }
    } catch (error) {
        console.error('Friend deleted failed', error);
    }
}

export async function FriendReqCancel(userData, req_uid) {

    const friendRequestsQuery = query(
        collection(firestore, "friendRequests"),
        where("fromUserId", "==", userData.uid),
        where("toUserId", "==", req_uid),
    );
    try {
        const querySnapshotFriendRequest = await getDocs(friendRequestsQuery);

        if (!querySnapshotFriendRequest.empty) {
            const req_doc = querySnapshotFriendRequest.docs[0];

            if (req_doc.data().status === 'pending') {

                const friendeqRef = doc(firestore, "friendRequests", req_doc.id);
                await deleteDoc(friendeqRef);
                return req_uid
            }
        }
    } catch (error) {
        console.error('Friend deleted failed', error);
    }
}