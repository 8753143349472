import { collection, documentId, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../../../../components/firebase/FirebaseConfig';

const MAX_BATCH_SIZE = 30;

// Helper function to split an array into chunks
function chunkArray(array, size) {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
}

async function fetchDocumentsInBatches(collectionRef, idList) {
    const idChunks = chunkArray(idList, MAX_BATCH_SIZE);
    const promises = idChunks.map(async (chunk) => {
        const q = query(collectionRef, where(documentId(), 'in', chunk));
        const snapshot = await getDocs(q);
        return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    });

    const results = await Promise.all(promises);
    return results.flat();
}

export async function getCourseData(completedRound, numberOfRound) {
    
    let sortedRounds = completedRound;
    if (numberOfRound) {
        sortedRounds = completedRound
            .sort((a, b) => new Date(a.created) - new Date(b.created))
            .slice(0, numberOfRound);
    }

    const roundIdList = sortedRounds.map((item) => item.roundId);

    try {
        const roundCollection = collection(firestore, 'round');
        const courseHoleCollection = collection(firestore, 'courseHoles');

        // Fetch round data in batches
        const roundDataList = await fetchDocumentsInBatches(roundCollection, roundIdList);

        if (roundDataList.length > 0) {
            const holesCourseIdList = [];
            const courseHoleMap = {};

            // Prepare holesCourseIdList and courseHoleMap
            roundDataList.forEach((round) => {
                const roundId = round.id;
                const roundData = round;

                if (roundData.holesCourse) {
                    if (!holesCourseIdList.includes(roundData.holesCourse)) {
                        holesCourseIdList.push(roundData.holesCourse);
                    }

                    if (!courseHoleMap[roundData.holesCourse]) {
                        courseHoleMap[roundData.holesCourse] = [];
                    }
                    courseHoleMap[roundData.holesCourse].push(roundId);
                }
            });

            // Fetch course hole data in batches
            const courseHoleDataList = await fetchDocumentsInBatches(courseHoleCollection, holesCourseIdList);

            if (courseHoleDataList.length > 0) {
                const courseHoleList = courseHoleDataList.map((courseHole) => {
                    const courseHoleData = { ...courseHole, docId: courseHole.id };
                    const associatedRoundIds = courseHoleMap[courseHoleData.docId];

                    if (associatedRoundIds && associatedRoundIds.length > 0) {
                        courseHoleData.roundIds = associatedRoundIds;
                    }
                    return courseHoleData;
                });

                return courseHoleList;
            }
        }
    } catch (error) {
        console.error('Failed to get data', error);
    }
}