import React, { useRef, useEffect, useState, useMemo } from "react";
import Header from "../../components/Header";
import Rightchat from "../../components/Rightchat";
import Leftnav from "../../components/Leftnav";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";

import GoogleMapReact from "google-map-react";

import { useSelector } from "react-redux";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import "./coursestyle.css";

import CourseSearch from "./component/CourseSearch";

import axios from "axios";
import CourseCard from "./component/CourseCard";
import FilterStack from "./component/FilterStack";
import FilterCourse from "./component/FilterCourse";

// Helper function to calculate distance between two coordinates
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
};

function MyCourse() {
  const userData = useSelector((state) => state.user.data);

  const [showSortAndFilter, setShowSortAndFilter] = useState(false);

  const [courseList, setCourseList] = useState([]);
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [selectedCourse, setSeletedCourse] = useState(null);
  const [originalCourseList, setOriginalCourseList] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [favoriteCourses, setFavoriteCourses] = useState([]);

  const hoverTimeoutRef = useRef(null);
  const mapRef = useRef(null);

  const [search, setSearch] = useState({
    province: {
      state: false,
      value: "",
    },
    text: {
      state: false,
      value: "",
    },
    nearMe: {
      state: false,
    },
  });

  const [filter, setFilter] = useState({
    officialCourse: {
      state: false,
    },
    startingPriceRange: {
      state: false,
      min: 500,
      max: 4500,
    },
    nightGolf: {
      state: false,
    },
    drivingRange: {
      state: false,
    },
    restaurant: {
      state: false,
    },
    banquetRooms: {
      state: false,
    },
    hotel: {
      state: false,
    },
  });

  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    let filteredCourses = originalCourseList;

    if (search.nearMe.state) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });

          filteredCourses.sort((a, b) => {
            const distanceA = calculateDistance(
              position.coords.latitude,
              position.coords.longitude,
              a.location.latLong.latitude,
              a.location.latLong.longitude
            );
            const distanceB = calculateDistance(
              position.coords.latitude,
              position.coords.longitude,
              b.location.latLong.latitude,
              b.location.latLong.longitude
            );
            return distanceA - distanceB;
          });
          setCourseList(filteredCourses);
        },
        (error) => console.error("Error getting user location:", error),
        { enableHighAccuracy: true }
      );
    } else {
      setUserLocation(null);
      filteredCourses.sort((a, b) => {
        const nameA = a.courseName || "";
        const nameB = b.courseName || "";
        return nameA.localeCompare(nameB);
      });
      setCourseList(filteredCourses);
    }
  }, [search.nearMe.state]);

  useEffect(() => {
    async function fetchData() {
      const querySnapshot = await getDocs(collection(firestore, "courses"));
      let arrayData = [];
      querySnapshot.forEach((doc) => {
        arrayData.push({
          courseId: doc.id,
          ...doc.data(),
        });
      });

      arrayData.sort((a, b) => {
        const nameA = a.courseName || "";
        const nameB = b.courseName || "";
        return nameA.localeCompare(nameB);
      });

      setOriginalCourseList(arrayData);
      setCourseList(arrayData);
      setCourseLoaded(true);
    }

    if (userData) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    // Define a function to fetch and listen to favorite courses in real-time
    const fetchData = () => {
      // Query to get favorite courses for the specific user
      const q = query(
        collection(firestore, "favoriteCourses"),
        where("userId", "==", userData.uid)
      );

      // Real-time listener for the query
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let arrayData = [];
        querySnapshot.forEach((doc) => {
          arrayData.push(doc.data());
        });
        setFavoriteCourses(arrayData);
      });

      // Clean up the listener when the component unmounts or userData changes
      return () => unsubscribe();
    };

    if (userData) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    const filterCourses = () => {
      let filteredCourses = originalCourseList;
      // Filter by province if the state is true
      if (search.province.state && search.province.value) {
        filteredCourses = filteredCourses.filter(
          (course) =>
            course.location.provinceEng.toLowerCase() ===
            search.province.value.toLowerCase()
        );
      }

      // Filter by text search if the state is true
      if (search.text.state && search.text.value) {
        // Assuming Algolia search API is used for text search
        let courseIdList = [];

        const url = "https://RJJNPL7MRL.algolia.net/1/indexes/courses/query";
        const payload = {
          query: search.text.value,
        };
        const headers = {
          "x-algolia-application-id": "RJJNPL7MRL",
          "x-algolia-api-key": "bb21db75be816cbedc8dd1b5838e4bba",
          "Content-Type": "application/json",
        };

        axios
          .post(url, payload, { headers })
          .then((response) => {
            courseIdList = response.data.hits.map((hit) => hit.id);
            filteredCourses = filteredCourses.filter((course) =>
              courseIdList.includes(course.courseId)
            );
            applyAdditionalFilters(filteredCourses);
          })
          .catch((error) => {
            console.error("Error fetching courses:", error);
          });
      } else {
        applyAdditionalFilters(filteredCourses);
      }
    };

    const applyAdditionalFilters = (filteredCourses) => {
      // Apply additional filters from the filter state

      // Filter by officialCourse
      if (filter.officialCourse.state) {
        filteredCourses = filteredCourses.filter(
          (course) => course.features.officialCourse
        );
      }

      // Filter by startingPriceRange
      if (filter.startingPriceRange.state) {
        filteredCourses = filteredCourses.filter((course) => {
          const minPrice = course.features.startingPriceRange.minPrice;
          const maxPrice = course.features.startingPriceRange.maxPrice;
          return (
            minPrice >= filter.startingPriceRange.min &&
            maxPrice <= filter.startingPriceRange.max
          );
        });
      }

      // Filter by nightGolf
      if (filter.nightGolf.state) {
        filteredCourses = filteredCourses.filter(
          (course) => course.features.nightGolf
        );
      }

      // Filter by drivingRange
      if (filter.drivingRange.state) {
        filteredCourses = filteredCourses.filter(
          (course) => course.features.drivingRange
        );
      }

      // Filter by restaurant
      if (filter.restaurant.state) {
        filteredCourses = filteredCourses.filter(
          (course) => course.additionalServices.restaurant
        );
      }

      // Filter by banquetRooms
      if (filter.banquetRooms.state) {
        filteredCourses = filteredCourses.filter(
          (course) => course.additionalServices.banquetRooms
        );
      }

      // Filter by hotel
      if (filter.hotel.state) {
        filteredCourses = filteredCourses.filter(
          (course) => course.additionalServices.hotel
        );
      }

      // Sort by "Near Me" if enabled
      if (search.nearMe.state && userLocation) {
        filteredCourses.sort((a, b) => {
          const distanceA = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            a.location.latLong.latitude,
            a.location.latLong.longitude
          );
          const distanceB = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            b.location.latLong.latitude,
            b.location.latLong.longitude
          );
          return distanceA - distanceB;
        });
      } else {
        // Sort by course name A-Z when "Near Me" is disabled
        filteredCourses.sort((a, b) => {
          const nameA = a.courseName || "";
          const nameB = b.courseName || "";
          return nameA.localeCompare(nameB);
        });
      }

      // Set the final filtered course list
      setCourseList(filteredCourses);
      // console.log(filteredCourses);
    };

    filterCourses();
  }, [
    search.province.state,
    search.province.value,
    search.text.state,
    search.text.value,
    originalCourseList,
    filter, // Include the filter state dependencies
    userLocation,
  ]);

  const renderSelectedCourse = useMemo(() => {
    if (!selectedCourse) return;

    return (
      <div
        className="card w-100"
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
          opacity: "1",
        }}
      >
        <div
          className="card-body p-0 overflow-hidden rounded-3"
          style={{ height: "300px" }}
        >
          <div className="position-relative h-100 w-100">
            <img
              src={selectedCourse.coverImage}
              alt="cover"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />

            <div
              className="position-absolute bg-black"
              style={{
                bottom: 0,
                background: "rgba(0, 0, 0, 0.5)",
                height: "100%",
                width: "100%",
              }}
            >
              <div
                className="d-flex justify-content-between align-items-center text-white p-2"
                style={{ background: "rgba(0, 0, 0, 0.3)" }}
              >
                <span className="fs-3 fw-600">{selectedCourse.courseName}</span>
                <i
                  className="bi bi-x pointer fs-2"
                  onClick={() => setSeletedCourse(null)}
                ></i>
              </div>

              <div className="p-2">
                <span className="fs-4 fw-600 text-white">Contact</span>
                <br />
                <span className="fw-500 text-white">
                  Website : {selectedCourse.contact.website}
                </span>
                <br />
                <span className="fw-500 text-white">
                  Phone : {selectedCourse.contact.phone}
                </span>
                <br />
                <span className="fw-500 text-white">
                  Email : {selectedCourse.contact.email}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [selectedCourse]);

  useEffect(() => {
    if (mapRef.current) {
      const maps = window.google.maps;

      mapRef.current.markers?.forEach((marker) => marker.setMap(null));
      mapRef.current.markers = [];

      if (courseList.length > 0) {
        const bounds = new maps.LatLngBounds();

        courseList.forEach((courseData) => {
          const marker = new maps.Marker({
            position: {
              lat: courseData.location.latLong.latitude,
              lng: courseData.location.latLong.longitude,
            },
            map: mapRef.current,
            title: courseData.courseName,
          });

          bounds.extend(marker.getPosition());

          mapRef.current.markers.push(marker);

          const infoWindow = new maps.InfoWindow({
            content: courseData.courseName,
            position: {
              lat: courseData.location.latLong.latitude,
              lng: courseData.location.latLong.longitude,
            },
          });

          infoWindow.open(mapRef.current, marker);

          marker.addListener("click", () => {
            // console.log("Clicked on course: ", courseData);
            setSeletedCourse(courseData);
          });
        });

        mapRef.current.fitBounds(bounds);
      }
    }
  }, [courseList]);

  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div
            className="middle-sidebar-left pe-0"
            style={{ maxWidth: "100%" }}
          >
            <div className="row">
              <div className="col-xl-6 chat-left scroll-bar">
                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  {/* <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
                    Golf Course
                    <div className="pt-0 pb-0 ms-auto">
                      <CourseSearch
                        search={search}
                        setSearch={setSearch}
                        openSearch={openSearch}
                        setOpenSearch={setOpenSearch}
                      ></CourseSearch>
                    </div>
                    <div
                      onClick={() => setShowSortAndFilter(true)}
                      className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 pointer"
                    >
                      <i className="feather-filter font-xss text-grey-500"></i>
                    </div>
                  </h2>

                  <FilterStack
                    courseList={courseList}
                    originalCourseList={originalCourseList}
                    search={search}
                    setSearch={setSearch}
                    filter={filter}
                    setFilter={setFilter}
                  ></FilterStack> */}

                  <div className="d-flex w-100 flex-column flex-md-row ">
                    <h2 className="d-flex fw-600 mb-2 mb-md-0 mt-0 align-items-center">
                      Golf Course
                    </h2>

                    <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 w-md-auto">
                      <div className="search-form-2 flex-grow-1">
                        <CourseSearch
                          search={search}
                          setSearch={setSearch}
                          openSearch={openSearch}
                          setOpenSearch={setOpenSearch}
                        ></CourseSearch>
                      </div>

                      <div
                        onClick={() => setShowSortAndFilter(true)}
                        style={{ cursor: "pointer" }}
                        className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
                      >
                        <i className="feather-filter font-xss text-grey-500"></i>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <FilterStack
                      courseList={courseList}
                      originalCourseList={originalCourseList}
                      search={search}
                      setSearch={setSearch}
                      filter={filter}
                      setFilter={setFilter}
                    ></FilterStack>
                  </div>
                </div>

                <div className="row ps-2 pe-2">
                  {courseList.map((value, index) => (
                    <div
                      key={index}
                      className="col-lg-6 col-md-6 col-sm-6 mb-3 pe-2 ps-2"
                    >
                      <CourseCard
                        value={value}
                        favoriteCourses={favoriteCourses}
                      ></CourseCard>
                    </div>
                  ))}
                </div>
              </div>

              {/* Section Google maps */}
              <div className="col-xl-6 ps-0 d-none d-xl-block">
                <div className="card w-100 border-0 shadow-none rounded-3 border-0 mb-4 overflow-hidden ">
                  <div
                    style={{
                      height: "86vh",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    {/* {courseLoaded && (
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyAD6ycBR-QVUjiHoTk-qphLx8Ij9ZiEdUY",
                        }}
                        defaultCenter={{
                          lat: 13.831645904382652,
                          lng: 100.55372919513437,
                        }}
                        defaultZoom={9}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) => {
                          mapRef.current = map;
                          courseList.forEach((courseData) => {
                            console.log(courseData)
                            // Create a new marker
                            const marker = new maps.Marker({
                              position: {
                                lat: courseData.location.latLong._lat,
                                lng: courseData.location.latLong._long,
                              },
                              map: map,
                              title: courseData.courseName,
                              courseData: courseData,
                            });
                            const infoWindow = new maps.InfoWindow({
                              content: courseData.courseName,
                              position: {
                                lat: courseData.location.latLong._lat,
                                lng: courseData.location.latLong._long,
                              },
                            });

                            infoWindow.open(map, marker);
                            marker.addListener("click", () => {
                              setSeletedCourse(marker.courseData);
                            });
                          });
                        }}
                      ></GoogleMapReact>
                    )} */}

                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyAD6ycBR-QVUjiHoTk-qphLx8Ij9ZiEdUY",
                      }}
                      defaultCenter={{
                        lat: 13.831645904382652,
                        lng: 100.55372919513437,
                      }}
                      defaultZoom={9}
                      yesIWantToUseGoogleMapApiInternals={true}
                      onGoogleApiLoaded={({ map, maps }) => {
                        mapRef.current = map;
                        mapRef.current.markers = [];
                      }}
                    />

                    {renderSelectedCourse}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterCourse
        showSortAndFilter={showSortAndFilter}
        setShowSortAndFilter={setShowSortAndFilter}
        filter={filter}
        setFilter={setFilter}
      ></FilterCourse>
      <Popupchat />
      <Appfooter />
    </>
  );
}

export default MyCourse;
