import React from "react";
import { ReactSVG } from "react-svg";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
function CaddieCard({
  value,
  index,
  handleShow,
  handleChat,
  favoriteFriendsList,
  handleFav,
  setSelectedUserLabel,
  setShowLabelModal,
}) {
  return (
    <>
      <div key={index} className="col-lg-2 col-md-4 col-4">
        <div
          className="square-card shadow-xss rounded-xxxl bg-gradiant-bottom-2 overflow-hidden mb-1 bg-image-cover"
          style={{
            backgroundImage: `url(${value.profileImage})`,
            borderColor: "white",
            cursor: "pointer",
          }}
        >
          <div className="card-body d-block w-100 position-absolute bottom-0">
            <div
              className="clearfix"
              style={{ height: "85%" }}
              onClick={() => handleShow(value.id)}
            ></div>

            {value.isStar ? (
              <h4
                className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
                style={{ top: "5px", right: "5px" }}
                onClick={() => handleShow(value.id)}
              >
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-CaddieStar.svg"
                  style={{ width: "25px" }}
                />
              </h4>
            ) : (
              ""
            )}

            {value.isPro ? (
              <h4
                className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
                onClick={() => handleShow(value.id)}
                style={{
                  top: value.isStar ? "35px" : "5px",
                  right: "5px",
                }}
              >
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-ProCaddie.svg"
                  style={{ width: "25px" }}
                />
              </h4>
            ) : (
              ""
            )}

            <div
              className="d-flex justify-content-between align-items-center position-absolute z-index-1 text-white w-100"
              style={{
                bottom: "-5px",
                left: "5px",
              }}
            >
              <h6
                className="fw-600 ls-3 text-white mb-0"
                style={{
                  overflow: "hidden",
                  width: "60%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                onClick={() => handleShow(value.id)}
              >
                {value.firstName} {value.lastName}
              </h6>
              <Dropdown
                className="ms-auto drodownFriend"
                drop="down-centered"
                align="end"
              >
                <Dropdown.Toggle
                  as="i"
                  className="ti-more-alt text-white-900 btn-round-md font-xss"
                  id="dropdown-basic"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-bars text-white"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  as={CustomDropdown}
                  popperConfig={{
                    modifiers: [
                      {
                        name: "flip",
                        options: {
                          altBoundary: true,
                          rootBoundary: "viewport",
                          padding: 8,
                        },
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          altAxis: true,
                          altBoundary: true,
                          tether: false,
                        },
                      },
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                    ],
                  }}
                >
                  <Dropdown.Item
                    onClick={() => handleChat(value.dooNineId)}
                    style={{ display: "flex" }}
                  >
                    <ReactSVG
                      src="assets/images/CaddieFriendFav-ChatDropDown.svg"
                      style={{
                        width: "25px",
                        marginRight: "8px",
                        marginTop: "-1px",
                      }}
                    />
                    Chat
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ display: "flex" }}
                    onClick={() => {
                      setSelectedUserLabel(value);
                      setShowLabelModal(true);
                    }}
                  >
                    <ReactSVG
                      src="assets/images/dooNineIconV3/my-performance/profile-label.svg"
                      style={{
                        width: "25px",
                        marginRight: "8px",
                        marginTop: "-1px",
                      }}
                    />
                    Label
                  </Dropdown.Item>
                  {favoriteFriendsList.includes(value.uid) ? (
                    <Dropdown.Item
                      onClick={() => handleFav(value.role, value.uid, "unfav")}
                      style={{ display: "flex" }}
                    >
                      <ReactSVG
                        src="assets/images/dooNineIconV3/my-performance/profile-fav.svg"
                        style={{
                          width: "25px",
                          marginRight: "8px",
                          marginTop: "-1px",
                        }}
                      />
                      Favorites
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={() => handleFav(value.role, value.uid, "fav")}
                      style={{ display: "flex" }}
                    >
                      <ReactSVG
                        src="assets/images/dooNineIconV3/my-performance/profile-unfav.svg"
                        style={{
                          width: "25px",
                          marginRight: "8px",
                          marginTop: "-1px",
                        }}
                      />
                      Favorites
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CaddieCard;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
