import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import Picker from 'react-mobile-picker';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { firestore } from '../../../../../components/firebase/FirebaseConfig';
import { updateDoc, addDoc, where, query, collection, getDocs } from 'firebase/firestore';
const selections = {
    handicap: ['+10', '+9', '+8', '+7', '+6', '+5', '+4', '+3', '+2', '+1'],
    handicapDec: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
}
const handicapInt = Array.from({ length: 55 }, (_, i) => i)
const handicapStr = handicapInt.map(String);
selections.handicap.push(...handicapStr);

const Handicap = ({ data }) => {

    const [handicapModal, setHandicapModal] = useState(false);
    const userData = useSelector((state) => state.user.data);
    const [selectedHandicap, setSelectedHandicap] = useState({
        handicap: '0',
        handicapDec: '0'
    })
    const [handicapResult, setHandicapResult] = useState("0");
    const [loadingHandicap, setLoadingHandicap] = useState(true);

    const handleOpenHandicapModal = () => {
        setHandicapModal(true)
    }


    useEffect(() => {

        async function getHandidcap() {
            const statCollection = collection(firestore, 'playerStatistic');
            const q = query(statCollection, where("userId", "==", data.uid));
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0].data();
                    if (doc.handicap) {
                        const [int, dec] = doc.handicap.split(".");
                        setHandicapResult(doc.handicap);
                        setSelectedHandicap({
                            handicap: int,
                            handicapDec: dec
                        })
                    }
                }
                setLoadingHandicap(false)
            } catch (e) {
                setHandicapResult("Err");
                setLoadingHandicap(false)
                console.error("fail to get handicap ", e);
            }
        }

        if (userData && data) {
            getHandidcap()
        }
    }, [data, userData])

    const handleSubmit = () => {
        const handicapResult = selectedHandicap.handicap + "." + (selectedHandicap.handicap === "+10" || selectedHandicap.handicap === "54" ? "0" : selectedHandicap.handicapDec);

        setHandicapResult(handicapResult)
        setHandicapModal(false);

        updateHandicap(handicapResult)
    }

    const updateHandicap = async (handicap) => {
        const statCollection = collection(firestore, 'playerStatistic');
        const q = query(statCollection, where("userId", "==", userData.uid));
        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const ref = querySnapshot.docs[0].ref
                updateDoc(ref, {
                    handicap: handicap
                })
            } else {
                addDoc(statCollection, {
                    userId: userData.uid,
                    handicap: handicap
                })

            }
        } catch (e) {
            console.error("Error adding or updating document: ", e);
        }
    }

    const renderPicker = useMemo(() => {
        let updateSelectedHandicap = selectedHandicap;

        if (selectedHandicap.handicap === "+10" || selectedHandicap.handicap === "54") {
            selections.handicapDec = ['0'];
            updateSelectedHandicap = {
                handicap: selectedHandicap.handicap,
                handicapDec: '0'
            }
        } else {
            selections.handicapDec = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        }

        const columns = Object.keys(selections);
        return (
            <Picker value={updateSelectedHandicap} onChange={setSelectedHandicap} wheelMode='normal' height={180}>
                {Object.keys(selections).map((name, index) => (
                    <React.Fragment key={name}>
                        <Picker.Column key={name} name={name}>
                            {selections[name].map(option => (
                                <Picker.Item key={option} value={option}>
                                    {option}
                                </Picker.Item>
                            ))}
                        </Picker.Column>
                        {index < columns.length - 1 && (
                            <div style={{ width: 10, fontSize: 30 }} className='d-flex align-items-center mb-2'>.</div>
                        )}
                    </React.Fragment>
                ))}
            </Picker>
        );
    }, [selectedHandicap]);


    return (
        <>
            <div className="w-100 p-3 rounded-3 position-relative" style={{ backgroundColor: '#FAF0E9' }}>
                <Link to={'#'} className={clsx('d-block', { 'd-none': data?.uid !== userData.uid })}><div className="position-absolute" style={{ top: 5, right: 10 }} onClick={handleOpenHandicapModal}>Update</div></Link>
                <div className="d-flex justify-content-start align-items-center" >
                    <strong className="px-2 rounded-3 col-5 text-center" style={{ backgroundColor: '#E59E49' }}>
                        {!loadingHandicap ?
                            handicapResult
                            :
                            <div className="spinner-border mb-1" role="status">
                                <span className="sr-only"></span>
                            </div>
                        }
                    </strong>
                    <span className="mb-3 ms-2">Handicap</span>
                </div>
            </div>
            <Modal show={handicapModal} onHide={() => setHandicapModal(false)} centered animation={false}>
                <Modal.Body>
                    <h4><strong>Handicap</strong></h4>
                    {renderPicker}
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn bg-current text-white' onClick={handleSubmit}>OK</button>
                    <button className='btn btn-light' onClick={() => setHandicapModal(false)}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Handicap

