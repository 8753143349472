import React, { useRef, useState, useEffect } from 'react'
import { useSearchParams, Link, useNavigate } from 'react-router-dom'

import Header from '../../../components/Header';
import Leftnav from '../../../components/Leftnav';
import Rightchat from '../../../components/Rightchat';
import Popupchat from '../../../components/Popupchat';
import Appfooter from '../../../components/Appfooter';

import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import TimeAgo from './utilsfunction/TimeConvert';
import Audience from './utilsfunction/Audience';
import ExpandableContent from './utilsfunction/ExpandableContent';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Slider from 'react-slick';
import useModalWithHistory from '../../../components/ModalPopState';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../../components/firebase/FirebaseConfig';
import Like from './Like';
import Comment from './Comment';
import PostDropdown from '../../../components/PostDropdown';
import LoadCircle from '../../../components/LoadCircle';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';


const IndividualPost = () => {
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();
    const [searchParams] = useSearchParams();
    const postId = searchParams.get('id');
    const isMobile = useRef(window.matchMedia("(max-width: 768px)").matches);
    const userData = useSelector((state) => state.user.data);
    const [likeTrigger, setLikeTrigger] = useState(0);
    const commentData = useRef({});
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const sliderStyle = {
        arrows: isMobile.current ? false : true,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        // centerMode: true,
    }


    const handleShowComment = () => {
        handleDataComment(post);
        openModal()
    }

    const handleDataComment = (data) => commentData.current = data;

    useEffect(() => {
        async function getPostData() {
            try {
                // Get post data
                const postDoc = doc(firestore, 'posts', postId);
                const postSnapshot = await getDoc(postDoc);

                if (postSnapshot.exists()) {
                    const data = postSnapshot.data();

                    // Get user data
                    const userCollection = collection(firestore, 'users');
                    const q = query(userCollection, where('uid', '==', data.userId));
                    const userSnapshot = await getDocs(q);

                    // Combine post and user data
                    const userData = userSnapshot.empty ? null : userSnapshot.docs[0].data();

                    setPost({
                        ...data,
                        userData,
                    });
                }
            } catch (error) {
                console.error('Failed to get post or user data', error);
            } finally {
                setLoading(false);
            }
        }

        if (postId) {
            setLoading(true);
            getPostData();
        }
    }, [postId]);


    const handleLikeTrigger = () => {
        setLikeTrigger((prev) => prev + 1)
    }

    const handleDeletedPost = async () => {
        navigate('/home');
    }
    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom" >
                    <div className="middle-sidebar-left">
                        <div className="row feed-body">
                            <div className="col-xl-8 col-xxl-9 col-lg-8 px-md-3 feed-home">

                                {!loading && post ?
                                    <div className={clsx("card w-100 shadow-xss border-0 p-2 mb-2 mt-1", {
                                        'rounded-xxl p-4 mt-0': !isMobile.current,
                                    })}
                                    >
                                        <div className="card-body p-0 d-flex justify-content-between">
                                            <div className='d-flex'>
                                                <Link to={post.userData?.uid !== userData?.uid ? `/user?uid=${post.userData?.uid}` : `/userPage`}>
                                                    <figure className="avatar me-3">
                                                        <img
                                                            src={post?.userData?.profileImage || defaultProfile}
                                                            alt="avatar"
                                                            className="shadow-sm rounded-circle w45 pointer"
                                                            style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                                                            onError={(e) => e.target.src = defaultProfile}
                                                        /></figure>
                                                </Link>
                                                <Link to={post.userData?.uid !== userData?.uid ? `/user?uid=${post.userData?.uid}` : `/userPage`}>
                                                    <h4 className="d-block fw-700 text-grey-900 font-xssss pointer">
                                                        {post?.userData?.firstName || post?.userData?.firstname || post?.userData?.lastName || post?.userData?.lastname
                                                            ? `${post?.userData?.firstName || post?.userData?.firstname || ''} ${post?.userData?.lastName || post?.userData?.lastname || ''}`.trim()
                                                            : 'Invalid User'}
                                                        <div className='d-flex align-content-center mt-1'>
                                                            <TimeAgo timestamp={post.timestamp.seconds} />
                                                            <Audience privacy={post.audience} />
                                                            {post?.lastUpdate ? <i className=' bi bi-pencil-fill ms-1 text-grey-500'></i> : null}
                                                        </div>
                                                    </h4>
                                                </Link>
                                            </div>
                                            {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}
                                            <PostDropdown
                                                post={post}
                                                handleDeletedPost={handleDeletedPost}
                                            />
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2 post-text-para">
                                                <span className="post-text-content" id={post.postId}>
                                                    <h5><ExpandableContent content={post.content} /></h5>
                                                </span>
                                            </div>
                                        </div>
                                        {post.mediaType === "image" && (
                                            <>
                                                {post.media.length > 0 && (
                                                    <PhotoProvider>
                                                        <div className="card-body d-block p-0 mb-0 post-image-container">
                                                            <Slider {...sliderStyle} className='post-image-slider'>
                                                                {post.media.map((image, index) => (
                                                                    <div key={index} className='each-post-image'>
                                                                        <PhotoView key={index} src={image}>
                                                                            <img src={image} alt={index} style={{ cursor: 'pointer' }} />
                                                                        </PhotoView>
                                                                    </div>
                                                                ))}
                                                            </Slider>
                                                        </div>
                                                    </PhotoProvider>
                                                )}
                                            </>
                                        )}
                                        <div className="card-body d-flex p-0">
                                            <div className="d-flex align-items-center gap-3">
                                                <span className='d-flex align-items-center'>
                                                    <Like
                                                        isPost={true}
                                                        key={likeTrigger}
                                                        postData={post}
                                                    />
                                                </span>
                                                <span className='d-flex align-items-center pointer' onClick={() => {
                                                    handleShowComment();
                                                }}>
                                                    <ReactSVG src='assets/images/dooNineIconV2/others/post-Comment.svg' style={{ zIndex: 1 }} className='w35' />
                                                    <span><h5 className='m-0 ms-1'>{post?.commentsCount || 0}</h5></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {loading ?
                                            <div className={clsx("card w-100 shadow-xss border-0 p-2 mb-2", {
                                                'rounded-xxl p-4': !isMobile.current,
                                            })}
                                            >
                                                <div className="card-body p-0 d-flex justify-content-between">
                                                    <div className='d-flex'>
                                                        <figure className="avatar me-3">
                                                            <img
                                                                src={defaultProfile}
                                                                alt="avatar"
                                                                className="shadow-sm rounded-circle w45 pointer"
                                                                style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                                                                onError={(e) => e.target.src = defaultProfile}
                                                            /></figure>
                                                        <h4 className="d-block fw-700 text-grey-900 font-xssss pointer">
                                                            <div className='bg-light theme-dark-bg' style={{ height: 20, width: 200 }} />
                                                            <div className='bg-light theme-dark-bg' style={{ height: 20, width: 200 }} />
                                                        </h4>
                                                    </div>
                                                </div>

                                                <div className="card-body p-0">
                                                    <div className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2 post-text-para">
                                                        <span className="post-text-content">
                                                            <div className='bg-light theme-dark-bg' style={{ height: 40, width: '100%' }} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="card-body d-block p-0 mb-0 post-image-container">
                                                    <div className='bg-light theme-dark-bg d-flex justify-content-center align-items-center' style={{ height: 400, width: '100%' }}>
                                                        <LoadCircle size='md' variant='dark' />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className=' p-3'>
                                                <div className='d-flex flex-column align-items-center justify-content-center p-5 bg-light theme-light-bg rounded-3'>
                                                    <h3 className='fw-600 text-muted'>
                                                        This post does not exist or may have been deleted.
                                                    </h3>
                                                    <Link to={'/home'} className='mt-2'>
                                                        <span className='fw-600 fs-4'>Return</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popupchat />
            <Appfooter />
            {isModalOpen ?
                <Comment
                    likeTrigger={handleLikeTrigger}
                    isShow={isModalOpen}
                    handleClose={closeModal}
                    commentData={commentData.current}
                />
                : null}
        </>
    )
}

export default IndividualPost