import React from 'react'
import { Modal } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';

const SwapCaddie = ({ isOpen, handleClose, userList, setUserList, selectedCaddie }) => {

    const handleSwapCaddie = (toRow) => {
        // console.log(toRow);
        // console.log(selectedCaddie.row);

        // Find the two players based on the given row values
        const player1 = userList.find(item => item.row === selectedCaddie.row);
        const player2 = userList.find(item => item.row === toRow);

        if (player1 && player2) {
            // Swap their caddies
            const updatedUserList = userList.map(item => {
                if (item.row === selectedCaddie.row) {
                    return { ...item, caddie: player2.caddie };
                }
                if (item.row === toRow) {
                    return { ...item, caddie: player1.caddie };
                }
                return item; // Return unchanged item for all others
            });

            // Update the state with the modified array
            setUserList(updatedUserList);
            handleClose()
        }
    };

    const filteredCaddie = userList.filter(item => item.row !== selectedCaddie.row);

    return (
        <>
            <Modal show={isOpen} onHide={handleClose} centered animation={false} size='lg'>

                <Modal.Header>
                    <Modal.Title className='d-flex justify-content-between align-items-center gap-4 w-100'>
                        <h2 className='fw-600 text-nowrap'>Swap Caddie</h2>
                    </Modal.Title>
                </Modal.Header>

                <div className='right-scroll-bar' style={{ maxHeight: '80vh', overflow: 'auto' }}>
                    <Modal.Body className='pb-0'>
                        <div className='row'>
                            <div className='col-sm-6 p-2'>
                                {selectedCaddie.player ?
                                    <div className='d-flex justify-content-start align-items-center mb-2'>
                                        <img
                                            src={selectedCaddie.player.profileImage}
                                            alt="player"
                                            width={50}
                                            height={50}
                                            className='rounded-circle me-2 player-color-outline'
                                        />
                                        <h4 className='fw-600 d-inline'>{`${selectedCaddie.player.firstName} ${selectedCaddie.player.lastName}`}</h4>
                                    </div>
                                    :
                                    <div className='w-100 bg-light theme-light-bg rounded-3 h-100 p-2' style={{ minHeight: 50 }}></div>
                                }
                            </div>
                            <div className='col-sm-6 p-2'>
                                {selectedCaddie.caddie ?
                                    <div className='d-flex justify-content-start align-items-center mb-2'>
                                        <img
                                            src={selectedCaddie.caddie.profileImage}
                                            alt="caddie"
                                            width={50}
                                            height={50}
                                            className='rounded-circle me-2 caddie-color-outline'
                                        />
                                        <h4 className='fw-600 d-inline'>{`${selectedCaddie.caddie.firstName} ${selectedCaddie.caddie.lastName}`}</h4>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>

                    </Modal.Body>
                    <div className='w-100 text-end p-3'>
                        <h2 className='fw-600'>To
                            <i className='ms-2 bi bi-arrow-down'></i>
                        </h2>
                    </div>
                    <Modal.Body>
                        {filteredCaddie.map(user =>
                            <div className='row'>
                                <div className='col-sm-6 p-2'>
                                    {user.player ?
                                        <div className='d-flex justify-content-start align-items-center mb-2'>
                                            <img
                                                src={user.player.profileImage}
                                                alt="player"
                                                width={50}
                                                height={50}
                                                className='rounded-circle me-2 player-color-outline'
                                            />
                                            <h4 className='fw-600 d-inline'>{`${user.player.firstName} ${user.player.lastName}`}</h4>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className='col-sm-6 p-2'>
                                    {user.caddie ?
                                        <div className='d-flex justify-content-start align-items-center mb-2 pointer'
                                            onClick={() => handleSwapCaddie(user.row)}
                                        >
                                            <img
                                                src={user.caddie.profileImage}
                                                alt="caddie"
                                                width={50}
                                                height={50}
                                                className='rounded-circle me-2 caddie-color-outline'
                                            />
                                            <h4 className='fw-600 d-inline'>{`${user.caddie.firstName} ${user.caddie.lastName}`}</h4>
                                        </div>
                                        :
                                        <div className='w-100 bg-light theme-light-bg rounded-3 pointer text-center p-2'
                                            onClick={() => handleSwapCaddie(user.row)}
                                        >
                                            <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_w.svg'
                                                wrapper='svg'
                                                width={50}
                                                height={50}
                                            />
                                        </div>

                                    }
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </div>

            </Modal>
        </>
    )
}

export default SwapCaddie