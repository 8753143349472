import React, { useState, useMemo, useEffect } from 'react'
import Chart from "react-apexcharts";
import { getCourseData } from '../module/getData';

export const DistributionChart = ({ userId = null, completedRound, courseHoleData, numberOfRound }) => {

    const [series, setSeries] = useState(null)

    useEffect(() => {
        async function getData() {
            const courseHoleList = await getCourseData(completedRound, numberOfRound) || [];
            courseHoleData(courseHoleList)
            setSeries([{
                name: "",
                data: alignData(completedRound, courseHoleList)
            }])
        }

        if (userId && completedRound.length) {
            getData()
        };

    }, [userId, completedRound])

    function alignData(completedRound, courseHoleList) {
        let totalStroke = 0;

        let totalEagle = 0;
        let totalBirdie = 0;
        let totalPar = 0;
        let totalBogey = 0;
        let totalDouble = 0;
        let totalTriple = 0;
        let totalSkipping = 0;

        completedRound.forEach(round => {
            courseHoleList.forEach(course => {
                if (course.roundIds.includes(round.roundId)) {
                    // Found round id in that course
                    course.holeDetails.forEach(hole => {
                        const holeData = hole;
                        const scoreData = round[`hole${hole.holeNumber}`];

                        // Convert hole par to number
                        const holePar = Number(holeData.par);
                        // Convert score to number and check for NaN
                        const score = Number(scoreData.score);

                        // Check if score is a valid number (not NaN) skip calculate that score
                        if (!isNaN(score) && !isNaN(holePar)) {
                            // Update total strokes
                            totalStroke += 1;
                            // console.log(score);

                            // Update scores based on comparison to par
                            if (score === holePar) {
                                totalPar++;
                            } else if (score === holePar - 1) {
                                totalBirdie++;
                            } else if (score === holePar - 2) {
                                totalEagle++;
                            } else if (score < holePar - 2) {
                                totalEagle++;  // Less than eagle
                            } else if (score === holePar + 1) {
                                totalBogey++;
                            } else if (score === holePar + 2) {
                                totalDouble++;
                            } else if (score === holePar + 3) {
                                totalTriple++;
                            } else if (score > holePar + 3) {
                                totalTriple++;  // More than triple bogey
                            }
                        } else {
                            totalSkipping += 1

                            // No or invalid score considered to tiple bogey
                            totalTriple += 1
                        }
                    });
                }
            });
        });

        // NOTE //
        // totalStroke is total valid score
        // totalSkipping is total invalid score (NaN score | "-", etc);
        // (totalStroke + totalSkipping) is total hole

        const parPercentage = ((totalPar / (totalStroke + totalSkipping)) * 100).toFixed(2);
        const birdiePercentage = ((totalBirdie / (totalStroke + totalSkipping)) * 100).toFixed(2);
        const eaglePercentage = ((totalEagle / (totalStroke + totalSkipping)) * 100).toFixed(2);
        const bogeyPercentage = ((totalBogey / (totalStroke + totalSkipping)) * 100).toFixed(2);
        const doublePercentage = ((totalDouble / (totalStroke + totalSkipping)) * 100).toFixed(2);
        const triplePercentage = ((totalTriple / (totalStroke + totalSkipping)) * 100).toFixed(2);

        // console.log(parPercentage);
        // console.log(birdiePercentage);
        // console.log(eaglePercentage);
        // console.log(bogeyPercentage);
        // console.log(doublePercentage);
        // console.log(triplePercentage);

        return [eaglePercentage, birdiePercentage, parPercentage, bogeyPercentage, doublePercentage, triplePercentage]

    }

    // =============================================================================================================
    const renderChart = useMemo(() => {
        if (!userId || !series) return;

        const options = {
            chart: {
                id: 'responsive-bar',
            },
            xaxis: {
                categories: ['Eagle', 'Birdie', 'Par', 'Bogey', 'Bouble', 'Triple'],
            },
            yaxis: {
                max: 100,
                show: false,
                labels: {
                    formatter: (value) => `$${value}`, // Add unit to y-axis labels
                },
            },
            title: {
                text: 'Score Distribution',
                align: 'center',
                style: {
                    fontSize: '16px',
                    color: '#333'
                }
            },
            colors: ['#93e8ff80', '#a3b8e380', '#88db8580', '#ffeb9980', '#ffceb280', '#e4b56d80'],
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    distributed: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                y: {
                    formatter: (value) => `${value}%`
                }
            },
            legend: {
                show: false
            }
        }

        return (
            <div className="chart-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="300"
                />
            </div>
        )
    }, [userId, series])

    return (
        <>
            {renderChart}
        </>
    );
}

export default DistributionChart