export const create9Holes = (count = 9) => {
    const newHoles = {};
    for (let i = 1; i <= count; i++) {
        newHoles[`hole${i}`] = {
            bunkerHit: false,
            drivingRange: "-",
            fairwayHit: false,
            holeNumber: `${i}`,
            penalties: "-",
            putts: "-",
            score: "-",
        };
    }
    return newHoles
};
export const create18Holes = (count = 18) => {
    const newHoles = {};
    for (let i = 1; i <= count; i++) {
        newHoles[`hole${i}`] = {
            bunkerHit: false,
            drivingRange: "-",
            fairwayHit: false,
            holeNumber: `${i}`,
            penalties: "-",
            putts: "-",
            score: "-",
        };
    }
    return newHoles
};