import React, { useState, useEffect, useRef } from 'react'
import Cropper from 'react-easy-crop'
import { Modal } from 'react-bootstrap'

function ImageCropper({ show, hide, profileImage, croppedImage, isCover }) {
    // const editor = useRef(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1.2);
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const handleSubmit = async () => {

        await getCroppedImg(
            profileImage,
            croppedAreaPixels,
            rotation
        ).then(({ base64String, blobURL }) => {
            const file = base64ToFile(base64String, 'image/jpeg');
            croppedImage(file, blobURL);
            hide();
        }).catch((error) => {
            console.error(error);
        })
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    return (
        <>
            <Modal show={show} onHide={hide} centered size='lg'>
                <Modal.Header>
                    <Modal.Title><h3 className='fw-600'>Crop your image</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className='crop-container w-100' style={{ height: '70vh', minHeight: '400px' }}>
                        <Cropper
                            image={profileImage}
                            aspect={isCover ? 4 / 3 : 1}
                            crop={crop}
                            zoom={zoom}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                        // objectFit='cover'
                        // cropSize={{ width: 250, height: 250 }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className='btn btn-light theme-dark-bg p-2'><h5 className='m-0 fw-600'>Cancel</h5></button>
                    <button onClick={handleSubmit} className='btn bg-current p-2'><h5 className='m-0 fw-600 text-white'>Submit</h5></button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ImageCropper

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}

async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    const croppedCanvas = document.createElement('canvas')

    const croppedCtx = croppedCanvas.getContext('2d')

    if (!croppedCtx) {
        return null
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width
    croppedCanvas.height = pixelCrop.height

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    )

    return new Promise((resolve, reject) => {
        // Get Base64 string
        const base64String = croppedCanvas.toDataURL('image/jpeg');

        // Get Blob URL
        croppedCanvas.toBlob((file) => {
            const blobURL = URL.createObjectURL(file);
            resolve({ base64String, blobURL });
        }, 'image/jpeg');
    });
}

function base64ToFile(base64String, mimeType) {
    const byteString = atob(base64String.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
}