import React from 'react'
import HeaderDropdown from '../../score/component/HeaderDropdown'
import { Link } from 'react-router-dom'
import { useScore } from '../ScoreContext/ScoreContext'

function ScoreTopNav() {
    const { roundData, courseHoles, playerGameMode } = useScore();
    if (!roundData) return;

    return (
        <div className="card-body p-4 w-100 bg-current border-0 d-flex align-items-center rounded-3">
            <Link to="/round-activity" className="d-inline-block mt-2">
                <i className="ti-arrow-left font-sm text-white"></i>
            </Link>
            <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='mx-2'>
                    <h3 className='fw-700 text-white'>{roundData.roundName}</h3>
                    {playerGameMode && !playerGameMode.defaultSw ?
                        <h6 className='fw-600 text-white text-capitalize'>{`${roundData.holes}(${courseHoles?.courseHoleName || ""}), ${playerGameMode.gameMode}(${playerGameMode.scoring})`}</h6>
                        :
                        <h6 className='fw-600 text-white text-capitalize'>{`${roundData.holes}(${courseHoles?.courseHoleName || ""}), ${roundData.gameMode}(${roundData.scoring})`}</h6>
                    }
                </div>
                <div>
                    <HeaderDropdown />
                </div>
            </div>
        </div>
    )
}

export default ScoreTopNav