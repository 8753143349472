import moment from "moment/moment";

export const timeConverter = (date, time) => {

    if (date && time) {
        const dateTimeString = `${date}T${time}`;
        const epochTimestamp = moment(dateTimeString).valueOf();
        const mills = epochTimestamp;
        const sec = Math.floor(epochTimestamp / 1000);
        return sec
    } else {
        // console.warn("Date or time is missing.");
        return null
    }
}

export const epochToDateTime = (epochTimestamp) => {
    if (epochTimestamp) {
        const dateTimeString = moment.unix(epochTimestamp).format('YYYY-MM-DDTHH:mm');
        const date = moment.unix(epochTimestamp).format('YYYY-MM-DD'); // Extract the date part
        const day = moment.unix(epochTimestamp).format('DD'); // Extract the day part
        const month = moment.unix(epochTimestamp).format('MMM').toUpperCase(); // Extract the month part and convert to uppercase shorthand
        const time = moment.unix(epochTimestamp).format('HH:mm'); // Convert to 24-hour format

        return { date, time, day, month };
    } else {
        // console.warn("Epoch timestamp is missing or null.");
        return { date: null, time: null }; // Return null values if timestamp is missing
    }
}