export const th = {
  translation: {
    Login: 'ล็อกอิน',
    language: 'ภาษา',
    Thai: 'ไทย',
    English: 'อังกฤษ',
    Phone: 'โทรศัพท์',
    Forgot_your_Password: 'ลืมรหัสผ่าน ?',
    Remember_me: 'จดจำฉัน',
    email: 'อีเมล',
    password: 'รหัสผ่าน',
    do_not_have_account:'ยังไม่มีบัญชี ?',
    register:'สมัคร'
  }
}
