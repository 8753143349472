import React from 'react'
import { useSelector } from 'react-redux';
import PlayerMyPerf from './PlayerMyPerf';
import CaddieMyPerf from './CaddieMyPerf';

const MyPerfProfile = () => {
    const userData = useSelector((state) => state.user.data);

    switch (userData?.role) {
        case 'player':

            return <PlayerMyPerf />

        case 'caddie':

            return <CaddieMyPerf />

        default:
            break;
    }
}

export default MyPerfProfile