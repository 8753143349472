import React, { useRef, useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { Modal } from "react-bootstrap";

function CourseDetailCourseHoleImageModal({
  showModal,
  setShowModal,
  selectedHole,
  selectCourseHole,
}) {
  console.log(selectedHole);
  console.log(selectCourseHole);

  const [currentHole, setCurrentHole] = useState(selectedHole);

  console.log(currentHole);

  // ฟังก์ชันค้นหาข้อมูลหลุม
  const getHoleImageUrl = (number) => {
    const hole = selectCourseHole.holeDetails.find(
      (hole) => hole.holeNumber === number
    );
    return hole ? hole.holeImage : ""; // คืนค่า URL ของภาพ หรือคืนค่าเป็นว่างถ้าไม่เจอ
  };

  const handleBack = () => {
    const currentIndex = selectCourseHole.holeDetails.findIndex(
      (hole) => hole.holeNumber === currentHole.no
    );

    if (currentIndex > 0) {
      const previousHole = selectCourseHole.holeDetails[currentIndex - 1];
      setCurrentHole({
        no: previousHole.holeNumber,
        par: previousHole.par,
        index: previousHole.index,
        yellow: previousHole.yellowTee,
        red: previousHole.redTee,
        blue: previousHole.blueTee,
        white: previousHole.whiteTee,
        black: previousHole.blackTee,
      });
    }
  };

  const handleNext = () => {
    const currentIndex = selectCourseHole.holeDetails.findIndex(
      (hole) => hole.holeNumber === currentHole.no
    );

    if (currentIndex < selectCourseHole.holeDetails.length - 1) {
      const nextHole = selectCourseHole.holeDetails[currentIndex + 1];
      setCurrentHole({
        no: nextHole.holeNumber,
        par: nextHole.par,
        index: nextHole.index,
        yellow: nextHole.yellowTee,
        red: nextHole.redTee,
        blue: nextHole.blueTee,
        white: nextHole.whiteTee,
        black: nextHole.blackTee,
      });
    }
  };

  useEffect(() => {
    setCurrentHole(selectedHole);
  }, [selectedHole]);

  // ฟังเหตุการณ์การกดปุ่มลูกศรซ้ายขวา
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handleBack();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentHole]);

  const imageUrl = getHoleImageUrl(currentHole?.no);

  const rightDiv = ["Par", "Index", "Y", "R", "BL", "W", "B"];
  const rightDivColor = {
    Par: "#E9F6E9",
    Index: "#E9F6E9",
    Y: "#FFFED5",
    R: "#FFD5D5",
    BL: "#CAD0FE",
    W: "#ffffff",
    B: "#000000",
  };

  const currentIndex = selectCourseHole.holeDetails.findIndex(
    (hole) => hole.holeNumber === currentHole?.no
  );

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="md"
      >
        <Modal.Body className="p-0">
          <div className="row">
            <div className="col-xl-12 mb-3">
              <div
                className="card shadow-xss w-100 d-block d-flex border-0 p-3"
                style={{ backgroundColor: "#4D4D4D" }}
              >
                <div className="d-flex w-100 flex-column flex-md-row ">
                  <div className="d-flex align-items-center">
                    <h4 className="fw-600 mt-0 m-0 text-white">
                      {selectCourseHole?.courseHoleName}
                    </h4>
                  </div>

                  <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 w-md-auto">
                    <h4 className="fw-600 m-0 ms-3 mt-0 text-white">No.</h4>
                    <h2 className="fw-700 m-0 ms-3 mt-0 text-white">
                      {currentHole?.no}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "relative", // ใช้ position relative เพื่อให้ container นี้เป็นฐานของการจัดตำแหน่ง absolute
              height: "70vh",
              minHeight: "200px",
            }}
          >
            <img
              src={imageUrl}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              className="rounded-3"
            />

            <div
              style={{
                position: "absolute", // ใช้ position absolute เพื่อให้สามารถจัดวางในตำแหน่งที่ต้องการ
                top: "5%", // ระยะจากขอบบน (ปรับตามที่ต้องการ)
                right: "5%", // ระยะจากขอบขวา (ปรับตามที่ต้องการ)
                backgroundColor: "#4D4D4D",
                padding: "6px",
                borderRadius: "8px",
                boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                maxWidth: "200px", // จำกัดความกว้างสูงสุดของ Box
              }}
            >
              {rightDiv.map((item) => (
                <div
                  style={{
                    backgroundColor: rightDivColor[item],
                    padding: "5px",
                    borderRadius: "8px",
                    boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                    maxWidth: "200px",
                    display: "flex", // ทำให้เป็น flex container
                    flexDirection: "column", // จัดการเนื้อหาในแนวตั้ง
                    alignItems: "center", // จัดกึ่งกลางในแนวนอน
                  }}
                  className="mb-2"
                >
                  <h4
                    className={`fw-600 mb-0  ${
                      item === "B" ? "text-white" : ""
                    }`}
                  >
                    {item}
                  </h4>
                  <h5
                    className={`fw-600 mb-0  ${
                      item === "B" ? "text-white" : ""
                    }`}
                  >
                    {item == "Par"
                      ? currentHole?.par
                      : item == "Index"
                      ? currentHole?.index
                      : item == "Y"
                      ? currentHole?.yellow
                      : item == "R"
                      ? currentHole?.red
                      : item == "BL"
                      ? currentHole?.blue
                      : item == "W"
                      ? currentHole?.white
                      : item == "B"
                      ? currentHole?.black
                      : ""}
                  </h5>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn bg-current post-btn"
            onClick={handleBack}
            style={{ width: "80px" }}
            disabled={currentIndex === 0}
          >
            <h4 className="fw-600 m-0 text-white p-1">Back</h4>
          </button>

          <button
            className="btn bg-current post-btn"
            onClick={handleNext}
            disabled={currentIndex === selectCourseHole.holeDetails.length - 1}
            style={{ width: "80px" }}
          >
            <h4 className="fw-600 m-0 text-white p-1">Next</h4>
          </button>

          <button
            className="btn bg-greylight"
            onClick={() => setShowModal(false)}
            style={{ width: "80px" }}
          >
            <h4 className="fw-600 m-0 p-1">OK</h4>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CourseDetailCourseHoleImageModal;
