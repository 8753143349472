import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";

import { Dropdown } from "react-bootstrap";

function CourseSearch({ search, setSearch, openSearch, setOpenSearch }) {
  const inputRef = useRef(null);

  const handleProviceSelect = (province) => {
    setOpenSearch(false);
    setSearch({
      ...search,
      province: {
        state: true,
        value: province,
      },
    });
    setOpenSearch(false);
  };

  const handleOnTypeSearch = (e) => {
    const value = e.target.value;

    if (value.trim() === "") {
      setSearch({
        ...search,
        text: {
          state: false,
          value: "",
        },
      });
    } else {
      setSearch({
        ...search,
        text: {
          state: true,
          value: value,
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    const value = e.target.value;
    if (e.key === "Enter") {
      setOpenSearch(false);
      triggerSearch(value);
    }
  };

  const triggerSearch = (value) => {
    console.log(value);
    setSearch({
      ...search,
      text: {
        state: true,
        value: value,
      },
    });

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleNearMe = (nearMe) => {
    setOpenSearch(false);
    setSearch({
      ...search,
      nearMe: {
        state: true,
      },
    });
    setOpenSearch(false);
  };

  return (
    <>
      <Dropdown show={openSearch} onToggle={() => setOpenSearch(!openSearch)}>
        <Dropdown.Toggle as="div">
          <div className="search-form-2 flex-grow-1">
            <i className="ti-search font-xss"></i>
            <input
              type="text"
              className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
              placeholder="Search here."
              onChange={handleOnTypeSearch} // ค้นหาทันทีที่พิมพ์
              onKeyDown={handleKeyDown} // ค้นหาเมื่อกด Enter
              ref={inputRef}
            />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={CustomDropdown}
          style={{
            maxWidth: "calc(100% - 16px)", // ปรับความกว้างให้เหมาะสม
            width: "auto", // ใช้ความกว้างอัตโนมัติตามเนื้อหา
            minWidth: "280px", // ตั้งค่าความกว้างขั้นต่ำ
          }}
        >
          <Dropdown.Item
            onClick={handleNearMe}
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="fw-600"
          >
            <ReactSVG
              src="assets/icon/golf-course/Course-Near Me.svg"
              style={{ width: "25px", marginRight: "8px" }}
            />
            Near Me
          </Dropdown.Item>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "default",
              userSelect: "none",
              color: "#212529",
              padding: "0.25rem 1rem",
            }}
            className="fw-600"
          >
            <ReactSVG
              src="assets/icon/golf-course/Course-Location Recommend.svg"
              style={{ width: "25px", marginRight: "8px" }}
            />
            Popular Location
          </div>
          <Dropdown.Item
            onClick={() => handleProviceSelect("Pathum Thani")}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ReactSVG
              src="assets/icon/golf-course/Course-Location.svg"
              style={{ width: "25px", marginRight: "8px", marginLeft: "22px" }}
            />
            Pathum Thani
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => handleProviceSelect("Chon Buri")}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ReactSVG
              src="assets/icon/golf-course/Course-Location.svg"
              style={{ width: "25px", marginRight: "8px", marginLeft: "22px" }}
            />
            Chon Buri
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => handleProviceSelect("Phuket")}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ReactSVG
              src="assets/icon/golf-course/Course-Location.svg"
              style={{ width: "25px", marginRight: "8px", marginLeft: "22px" }}
            />
            Phuket
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => handleProviceSelect("Chiang Mai")}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ReactSVG
              src="assets/icon/golf-course/Course-Location.svg"
              style={{ width: "25px", marginRight: "8px", marginLeft: "22px" }}
            />
            Chiang Mai
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default CourseSearch;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
