import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom';
import { firestore } from '../../../../components/firebase/FirebaseConfig';

import JoinRoundPlayer from './JoinRoundPlayer';
import JoinRoundCaddie from './JoinRoundCaddie';
import JoinRoundUnPlayer from './uninvited/JoinRoundUnPlayer';
import JoinRoundUnCaddie from './uninvited/JoinRoundUnCaddie';


const JoinRound = () => {
    const userData = useSelector((state) => state.user.data);
    const [searchParams] = useSearchParams();
    const roundId = searchParams.get('id');
    const [isPlayer, setIsPlayer] = useState(null); // null initially
    const [isInvited, setIsInvited] = useState(null); // null initially
    const [roundData, setRoundData] = useState(null);

    useEffect(() => {
        async function getRoundData(roundId) {
            try {
                const q = doc(firestore, 'round', roundId);
                const roundSnapshot = await getDoc(q);
                if (roundSnapshot.exists()) {
                    setRoundData(roundSnapshot.data());
                    const userList = roundSnapshot.data().userList;
                    if (userData.role === 'player') {
                        setIsPlayer(true);
                        const foundPlayer = userList.find(item => item.player && item.player.uid === userData.uid);
                        setIsInvited(!!foundPlayer);
                    } else {
                        setIsPlayer(false);
                        const foundCaddie = userList.find(item => item.caddie && item.caddie.uid === userData.uid);
                        setIsInvited(!!foundCaddie);
                    }
                }
            } catch (error) {
                console.error('Failed to get round data', error);
            }
        }

        if (userData && roundId) {
            getRoundData(roundId);
        }
    }, [roundId, userData]);

    // Only render when isPlayer and isInvited have been set
    if (isInvited === null || isPlayer === null) {
        return null;
    }

    if (isInvited) {
        return isPlayer ? <JoinRoundPlayer roundData={roundData} /> : <JoinRoundCaddie roundData={roundData} />;
    } else {
        return isPlayer ? <JoinRoundUnPlayer roundData={roundData} /> : <JoinRoundUnCaddie roundData={roundData} />;
    }

}

export default JoinRound