import React, { useEffect, useState } from 'react'

import { firestore } from '../../../../components/firebase/FirebaseConfig';
import { collection, where, query, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Load from '../../../../components/Load';
import { useSelector } from 'react-redux';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';

function FetchLike({ scrollParent, postData }) {

    const [items, setItems] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.data);

    useEffect(() => {
        const fetchTime = setTimeout(() => {
            fetchData();
        }, 100);
        return () => {
            clearTimeout(fetchTime)
        }
    }, []);

    const fetchData = async () => {
        const collectionRef = collection(firestore, 'likes');
        let q = query(collectionRef, orderBy('timestamp', 'desc'), limit(10), where('postId', '==', postData.postId));

        if (lastVisible) {
            q = query(collectionRef, orderBy('timestamp', 'desc'), startAfter(lastVisible), limit(10), where('postId', '==', postData.postId));
        }

        const documentSnapshots = await getDocs(q);
        const itemsArray = documentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        // Collect all userIds from the fetched posts
        const userIds = itemsArray.map(item => item.userId);

        // Fetch userData for all userIds in parallel using where clause
        const userDataArray = await Promise.all(userIds.map(async userId => {
            const usersRef = collection(firestore, 'users');
            const querySnapshot = await getDocs(query(usersRef, where('uid', '==', userId)));

            if (!querySnapshot.empty) {
                return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];
            } else {
                return null; // Handle case where user data does not exist
            }
        }));

        // Merge userData with itemsArray
        const mergedData = itemsArray.map(item => {
            const userData = userDataArray.find(user => user && user.uid === item.userId);
            return {
                ...item,
                userData: userData || {}
            };
        });

        setItems(prevItems => [...prevItems, ...mergedData]);

        const lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        setLastVisible(lastDoc);

        if (documentSnapshots.docs.length < 10) {
            setHasMore(false);
        }
    };

    const handleProfileClick = (uid) => {

        if (!uid) return;

        if (uid === userData.uid) {
            navigate('/userPage')
        } else {
            navigate("/user", { state: { uid: uid } });
        }
    }

    return (
        <>
            {scrollParent?.current?.id ? (
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<Load />}
                    endMessage={<> </>}
                    scrollableTarget={scrollParent.current.id}
                >
                    {items.map((likes, index) => (
                        <div key={likes.userData.uid} className=' d-flex align-items-center w-100 mb-3 pointer' onClick={() => handleProfileClick(likes.userData.uid)}>
                            <figure className="avatar me-3 mb-0"><img
                                src={likes?.userData?.profileImage || defaultProfile}
                                alt="avatar"
                                className="shadow-sm rounded-circle w45"
                                style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                                onError={(e) => e.target.src = defaultProfile}
                            /></figure>
                            <span className='d-block'><h4 className='fw-600 m-0'>{`${likes?.userData?.firstName || likes?.userData?.firstname || 'Invalid'} ${likes?.userData?.lastName || likes?.userData?.lastname || 'User'}`.trim()}</h4></span>
                        </div>
                    ))}
                </InfiniteScroll>
            ) : (
                <Load />
            )}
        </>
    )
}

export default FetchLike