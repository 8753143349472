import React from 'react'

import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { ScoreProvider } from './ScoreContext/ScoreContext';

import { Link, useSearchParams } from 'react-router-dom';
import ScoreMain from './ScoreMain';

const ScoreIndex = () => {

    const [searchParams] = useSearchParams();
    const roundId = searchParams.get('round');

    if (roundId) {
        return (
            <>
                <Header />
                <Leftnav />
                <Rightchat />
                <ScoreProvider>
                    <div className="main-content right-chat-active">
                        <div className="middle-sidebar-bottom">
                            <div className="middle-sidebar-left">
                                <div className="middle-wrap">
                                    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                        <ScoreMain roundId={roundId} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScoreProvider>
                <Popupchat />
                <Appfooter />
            </>
        )
    } else {
        return (
            <>
                <Header />
                <Leftnav />
                <Rightchat />
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                                    <h1 className='fw-700 text-muted mb-2'>
                                        Round not found
                                    </h1>
                                    <Link to={'/home'}>
                                        <h4 className='fw-600 text-muted'>Return</h4>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Popupchat />
                <Appfooter />
            </>
        )
    }
}
export default ScoreIndex
