
import { where, collection, query, getDocs, deleteDoc } from "firebase/firestore";
import { firestore } from "../firebase/FirebaseConfig";
import Swal from "sweetalert2";

export const UnFriend = async (userId, friendId) => {

    let friendData = {};

    try {
        const userCollection = collection(firestore, 'users')
        const q = query(userCollection, where('uid', '==', friendId));
        const friendSnapshot = await getDocs(q);
        if (friendSnapshot.empty) {
            console.error('not found this friend', friendId);
            return;
        } else {
            friendData = friendSnapshot.docs[0].data();
        }
    } catch (error) {
        console.error('fail to get friend data', error);
    }

    Swal.fire({
        icon: 'warning',
        title: 'Warning',
        html: `<div>Do you want to <span class="text-danger">Unfriend</span> with <strong>${friendData?.firstName || ""} ${friendData?.lastName || ""}</strong> ?</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#ee3c7f',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then(async (result) => {

        if (!result.isConfirmed) return;

        // Create the first query where the friend request is from friendId to userId
        const queryFromFriendToUser = query(
            collection(firestore, "friendRequests"),
            where("fromUserId", "==", friendId),
            where("toUserId", "==", userId)
        );

        // Create the second query where the friend request is from userId to friendId
        const queryFromUserToFriend = query(
            collection(firestore, "friendRequests"),
            where("fromUserId", "==", userId),
            where("toUserId", "==", friendId)
        );

        // Create queries for friendship relations
        const queryRelation1 = query(
            collection(firestore, "friends"),
            where("friendId", "==", friendId),
            where("userId", "==", userId)
        );
        const queryRelation2 = query(
            collection(firestore, "friends"),
            where("friendId", "==", userId),
            where("userId", "==", friendId)
        );

        try {
            // Execute friend request queries and combine the results
            const [snapshotFromFriendToUser, snapshotFromUserToFriend] = await Promise.all([
                getDocs(queryFromFriendToUser),
                getDocs(queryFromUserToFriend)
            ]);

            const friendRequestResults = [
                ...snapshotFromFriendToUser.docs,
                ...snapshotFromUserToFriend.docs
            ];

            // Delete friend requests
            const friendRequestDeletions = friendRequestResults.map(doc => deleteDoc(doc.ref));
            await Promise.all(friendRequestDeletions);

            // Execute friendship relation queries and combine the results
            const [snapshotRelation1, snapshotRelation2] = await Promise.all([
                getDocs(queryRelation1),
                getDocs(queryRelation2)
            ]);

            const friendshipResults = [
                ...snapshotRelation1.docs,
                ...snapshotRelation2.docs
            ];

            // Delete friendship relations
            const friendshipDeletions = friendshipResults.map(doc => deleteDoc(doc.ref));
            await Promise.all(friendshipDeletions);

            return true;

        } catch (error) {
            console.error("Error unfriending:", error);
            return false;
        }
    })
};