import { collection, doc, getDoc, getDocs, query, updateDoc, where, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";

export async function deleteRoundForCreator(roundId) {

    if (!roundId) {
        console.error('Round ID', roundId);
        return
    }

    const roundCollection = collection(firestore, 'round');
    const roundActivityCollection = collection(firestore, 'roundActivity');

    try {
        const roundDoc = doc(roundCollection, roundId);
        const roundSnapshot = await getDoc(roundDoc);

        if (roundSnapshot.exists()) {
            // update status in round
            const roundRef = roundSnapshot.ref;

            await updateDoc(roundRef, {
                lastUpdate: serverTimestamp(),
                status: 'deleted'
            })

            // update status in round activity
            const roundActivity_q = query(roundActivityCollection, where('roundId', '==', roundId));
            const roundActivitySnap = await getDocs(roundActivity_q);

            if (!roundActivitySnap.empty) {
                const activityRef = roundActivitySnap.docs[0].ref;

                await updateDoc(activityRef, {
                    lastUpdate: serverTimestamp(),
                    status: 'deleted'
                })
            } else {
                console.warn('activity not existed');
            }
        } else {
            console.warn('round not existed');
        }

    } catch (error) {
        console.error('Failed to delete', error);
    }
}