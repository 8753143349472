import React, { useState, useEffect, useRef, useReducer } from 'react'
import { firestore } from '../../../../../components/firebase/FirebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';

import { Accordion, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useWizard } from '../wizardContext';
import clsx from 'clsx';
import moment from 'moment';
import { holesDef, scoringDef } from '../module/holeDef';
import { stepOneReducer } from '../module/reducer';
import { handleModeChange, handleHoleChange, handleHandicapChange, handleScoringChange, handleRoundNameChange } from '../module/stepOneUtils';

const StepOneByCaddie = () => {
    const userData = useSelector((state) => state.user.data);
    const { stepData, updateStepData } = useWizard();
    const data = stepData.stepOne;

    const [courseLoading, setCourseLoading] = useState(true);
    const [courseHole, setCourseHole] = useState([]);
    const gameModeRef = useRef([
        {
            name: 'Stroke Play',
            value: 'str',
        },
        {
            name: 'Stableford',
            value: 'stb'
        },
        {
            name: 'Match Play',
            value: 'mp'
        }
    ])

    const initialState = {
        course: data.course,
        roundName: data.roundName,
        holes: data.holes,
        holesCourse: data.holesCourse,
        gameMode: data.gameMode,
        handicap: data.handicap,
        scoring: data.scoring,
        scoringType: data.scoringType,
    };

    const [state, dispatch] = useReducer(stepOneReducer, initialState);

    useEffect(() => {
        async function getYourCourse() {
            try {
                const courseReqCollection = collection(firestore, 'caddieRequests');
                const q = query(courseReqCollection, where('caddieId', '==', userData.uid), where('status', '==', 'approved'))
                const courseReqSnapshot = await getDocs(q);

                if (!courseReqSnapshot.empty) {
                    const data = courseReqSnapshot.docs[0].data();
                    return data.courseId;
                } else {
                    console.error('No course found for your uid', userData.uid);
                    return null;
                }
            } catch (error) {
                console.error('Fail to get your course', error);
                return null;
            }
        }
        async function getHoles(courseId) {
            const numberOfHoleList = [];
            try {
                const holesCollection = collection(firestore, "courseHoles");
                const q = query(holesCollection, where('courseId', '==', courseId));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    querySnapshot.forEach(doc => {
                        const data = doc.data();
                        const existingHole = numberOfHoleList.find(hole => hole.numberOfHole === data.numberOfHole);

                        if (existingHole) {
                            if (!existingHole.courseHoleNames.includes(data.courseHoleName)) {
                                existingHole.courseHoleNames.push(data.courseHoleName);
                            }
                            // Add doc.id if not already in the list
                            if (!existingHole.id.includes(doc.id)) {
                                existingHole.id.push(doc.id);
                            }
                        } else {
                            numberOfHoleList.push({
                                numberOfHole: data.numberOfHole,
                                courseHoleNames: [data.courseHoleName],
                                id: [doc.id]
                            });
                        }
                    });
                }
                setCourseHole(numberOfHoleList);

            } catch (error) {
                console.error('fail to load course', error);
            }
        }


        async function getCourseData() {
            try {
                const yourCourseId = await getYourCourse();
                if (!yourCourseId) return;

                await getHoles(yourCourseId);

                const courseCollection = collection(firestore, 'courses');
                const q = query(courseCollection, where('id', '==', yourCourseId));
                const courseSnapshot = await getDocs(q);
                if (!courseSnapshot.empty) {
                    const data = courseSnapshot.docs[0].data();
                    dispatch({ type: 'RENAME', payload: `${userData.firstName}'s Round-${moment().format('YYMMDD')}`, updateStepData: updateStepData, });
                    dispatch({ type: 'COURSE', payload: data, updateStepData: updateStepData, });

                } else {
                    console.error('Course id not found in course collection', yourCourseId);
                }
                setCourseLoading(false);
            } catch (error) {
                setCourseLoading(false);
                console.error('Fail to get course data', error);
            }
        }
        if (userData && !state.course) {
            getCourseData()
        } else {
            getHoles(state?.course?.id)
            setCourseLoading(false)
        }
    }, [state.course, userData]);


    return (
        <>
            <Accordion flush>
                <div className='p-3 w-100 text-center rounded-3'
                    style={{ backgroundColor: '#F5F5F5' }}>
                    {!courseLoading ?
                        <>
                            {state.course ?
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={state.course.profileImage} alt="img" width={60} height={60} className='me-2 rounded-circle' />
                                    <div>
                                        <h4 className='fw-600'>{state.course.courseName}</h4>
                                        <small>
                                            {state.course.location.subDistrictEng}, {state.course.location.provinceEng}
                                        </small>
                                    </div>
                                </div>
                                : 'Select Course'}
                        </>
                        :
                        <Spinner variant="dark" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    }
                </div>
                <Accordion.Item className='border-0'>
                    <h5 className='mt-4 mb-1'><strong>Round Name</strong></h5>
                    <input type="text" name="round-name" id="round-name"
                        className='form-control'
                        value={state.roundName}
                        onChange={(e) => handleRoundNameChange(e, updateStepData, dispatch)}
                    />

                    <h5 className='mt-4 mb-1'><strong>Playing Holes</strong></h5>
                    <div className='d-flex align-items-center gap-2 mb-3'>
                        {courseHole.map((hole, idx) =>
                            <div key={idx} className={clsx('col-sm-3 step-three-radio-card pointer p-3 p-sm-4', { 'active': state.holes === hole.numberOfHole })}
                                onClick={() => handleHoleChange(hole, updateStepData, dispatch)}
                            >
                                <h6 className='fw-600'>{holesDef(hole.numberOfHole)}</h6>
                                <i className="bi bi-check-circle-fill text-current step-one-checked-icon"></i>
                            </div>
                        )}
                    </div>
                    <div className={clsx('mb-2', { 'd-none': !courseHole.length })}>
                        <select name="holeCourseSelect" id="holeCourseSelect" className='form-select'
                            value={state.holesCourse}
                            onChange={(e) => dispatch({ type: 'HOLESCOURSE', payload: e.target.value, updateStepData: updateStepData, })}
                        >
                            <option value=''>No Select</option>
                            {courseHole.find((item) => item.numberOfHole === state.holes)?.courseHoleNames?.map((courseHoleName, index) => {
                                const holeId = courseHole.find(item => item.numberOfHole === state.holes)?.id[index];
                                return (
                                    <option key={holeId} value={holeId}>
                                        {courseHoleName}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <h5 className='mt-4 mb-1'><strong>Game Mode</strong></h5>
                    <div className='d-flex align-items-center gap-2 mb-2 flex-wrap' >
                        {gameModeRef.current.map((mode, idx) =>
                            <div key={idx} className={clsx('col-sm-3 step-three-radio-card pointer p-3 p-sm-4', { 'active': state.gameMode === mode.value })}
                                onClick={() => handleModeChange(mode, updateStepData, dispatch, scoringDef)}
                            >
                                <h6 className='fw-600'>{mode.name}</h6>
                                <i className="bi bi-check-circle-fill text-current step-one-checked-icon"></i>
                            </div>
                        )}
                    </div>

                    <div className='d-sm-flex align-items-center gap-2 mb-2'>
                        <label htmlFor="handicap" className='w-50 fs-5 fw-bold'>Handicap</label>
                        <select name="handicap" id="handicap" className='form-select form-select-sm' value={state.handicap} onChange={(e) => handleHandicapChange(e, updateStepData, state, dispatch)}>
                            <option value="scratch">Scratch {`(No Handicap Applied)`}</option>
                            <option value="whs">WHS {`(World Handicap System)`}</option>
                            <option value="s36">S36 {`(System 36)`}</option>
                        </select>
                    </div>

                    <div className='d-sm-flex align-items-center gap-2 mb-2'>
                        <label htmlFor="scoring" className='w-50 fs-5 fw-bold'>Scoring</label>
                        <select name="scoring" id="scoring" className='form-select form-select-sm' value={state.scoring} onChange={(e) => handleScoringChange(e, updateStepData, dispatch)}>
                            {state.scoringType.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>

                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default StepOneByCaddie