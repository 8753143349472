import React, { useEffect, useState } from 'react'

import Header from '../../../components/Header'
import Leftnav from '../../../components/Leftnav'
import Rightchat from '../../../components/Rightchat'
import Popupchat from '../../../components/Popupchat'
import Appfooter from '../../../components/Appfooter'

import { firestore } from '../../../components/firebase/FirebaseConfig'
import { where, query, collection, getDocs, deleteDoc, doc } from 'firebase/firestore';

import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import Load from '../../../components/Load'

import { Link } from 'react-router-dom'

function BlockList() {

    const userData = useSelector((state) => state.user.data);
    const [blockedUser, setBlockedUser] = useState([]);
    const [unblockList, setUnblockList] = useState([]);
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingList, setLoadingList] = useState(true);

    useEffect(() => {

        async function getBlockedUserList(getBlockedUserData) {
            setLoadingList(true);
            const userIds = [];
            const q = query(collection(firestore, "blockedUsers"), where("userId", "==", userData.uid))
            const querySnapshot1 = await getDocs(q);

            querySnapshot1.forEach((doc) => {
                const data = doc.data();
                if (data.blockedUserId) {
                    userIds.push(data.blockedUserId)
                }
            });
            if (userIds.length) {
                await getBlockedUserData(userIds);
            } else {
                setBlockedUser([]);
            }
            setLoadingList(false);
        }

        async function getBlockedUserData(userIds) {
            const userList = [];

            const userquery = query(collection(firestore, "users"), where("uid", "in", userIds));

            const userSnapshot = await getDocs(userquery);
            userSnapshot.forEach((doc) => {
                const data = doc.data();
                userList.push(data)
            });
            setBlockedUser(userList);
        }

        if (userData) {
            getBlockedUserList(getBlockedUserData);
        }
    }, [userData, unblockList])

    async function handleUnblock(user) {

        setLoadingUser(prevState => ({ ...prevState, [user.uid]: true }));

        const blockedUsersRef = collection(firestore, "blockedUsers");

        const q = query(blockedUsersRef, where("blockedUserId", "==", user.uid));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (docSnapshot) => {
            await deleteDoc(docSnapshot.ref);
        });
        setUnblockList((prev) => [...prev, user.uid]);
        setLoadingUser(prevState => ({ ...prevState, [user.uid]: false }));
    }

    return (
        <>
            <style>
                {`.blocklist-card-body {
    min-height: calc(100vh - 220px);
}`}
            </style>

            <Header />
            <Leftnav />
            <Rightchat />
            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4 blocklist-card">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/defaultsettings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Block List</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0 blocklist-card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {!loadingList ?
                                                <>
                                                    {blockedUser.length ?
                                                        <ul className="list-group list-group-flush">
                                                            {blockedUser.map((user) =>
                                                                <li key={user.uid} className="list-group-item row">
                                                                    <div className='col-12 col-sm-6 d-flex align-items-center gap-2'>
                                                                        <img src={user.profileImage} alt="user" width={40} height={40} className='rounded-circle' />
                                                                        <h5 className='m-0 fw-500 text-truncate'>{user.firstName} {user.lastName}</h5>
                                                                    </div>
                                                                    <h5 className='m-0 fw-500 text-capitalize me-5 col-sm-2 col-12 d-flex align-items-center'>{user.role}</h5>
                                                                    <div className='text-end d-flex align-items-center justify-content-end col-sm-2'>
                                                                        <button className='btn bg-current text-white w100' onClick={() => handleUnblock(user)}>
                                                                            {loadingUser[user.uid] ? (
                                                                                <Spinner variant="light" animation="border" role="status" size="sm">
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                </Spinner>
                                                                            ) : (
                                                                                'Unblock'
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                        :
                                                        <h3 className='fw-600 text-muted text-center'>
                                                            Your block list is empty
                                                        </h3>
                                                    }
                                                </> : <Load />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popupchat />
            <Appfooter />
        </>
    )
}

export default BlockList