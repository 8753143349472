export const defaultClubs = [
    {
        name: "Driver 10.5°",
        type: "Driver",
        loft: 10.5,
        number: null,
        distance: 250,
        note: "",
        inBag: true
    },
    {
        name: "3 Wood",
        type: "Wood",
        loft: null,
        number: 3,
        distance: 230,
        note: "",
        inBag: true
    },
    {
        name: "2 Hybrid",
        type: "Hybrid",
        loft: null,
        number: 2,
        distance: 220,
        note: "",
        inBag: true
    },
    {
        name: "3 Hybrid",
        type: "Hybrid",
        loft: null,
        number: 3,
        distance: 195,
        note: "",
        inBag: true
    },
    {
        name: "4 Iron",
        type: "Iron",
        loft: 24,
        number: 4,
        distance: 180,
        note: "",
        inBag: true
    },
    {
        name: "5 Iron",
        type: "Iron",
        loft: 27,
        number: 5,
        distance: 170,
        note: "",
        inBag: true
    },
    {
        name: "6 Iron",
        type: "Iron",
        loft: 30,
        number: 6,
        distance: 160,
        note: "",
        inBag: true
    },
    {
        name: "7 Iron",
        type: "Iron",
        loft: 34,
        number: 7,
        distance: 150,
        note: "",
        inBag: true
    },
    {
        name: "8 Iron",
        type: "Iron",
        loft: 38,
        number: 8,
        distance: 135,
        note: "",
        inBag: true
    },
    {
        name: "9 Iron",
        type: "Iron",
        loft: 42,
        number: 9,
        distance: 125,
        note: "",
        inBag: true
    },
    {
        name: "Pitching Wedge 47°",
        type: "Pitching Wedge",
        loft: 47,
        number: null,
        distance: 100,
        note: "",
        inBag: true
    },
    {
        name: "Sand Wedge 52°",
        type: "Sand Wedge",
        loft: 52,
        number: null,
        distance: 70,
        note: "",
        inBag: true
    },
    {
        name: "Lob Wedge 56°",
        type: "Lob Wedge",
        loft: 56,
        number: null,
        distance: 55,
        note: "",
        inBag: true
    },
    {
        name: "Putter",
        type: "Putter",
        loft: null,
        number: null,
        distance: null,
        note: "",
        inBag: true
    }
];