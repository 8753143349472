import React, { Fragment, useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import {
  getDoc,
  query,
  where,
  getDocs,
  collection,
  doc,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { firestore, storage } from "../../components/firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import ReactSwitch from "react-switch";
import clsx from "clsx";
import CardRoundFinish from "./component/CardRoundFinish";
import { Form } from "react-bootstrap";
import SelectedPrivBtn from "../home/postview/utilsfunction/SelectedPriv";
import imageCompression from "browser-image-compression";
import { checkScore } from "../scoreV2/scoring/module/scoreCalculation";
import { create9Holes, create18Holes } from '../scoreV2/scoring/module/createHoleObj';

const switchStyle = {
  onColor: "#FF4081",
  offColor: "#ccc",
  onHandleColor: "#fff",
  offHandleColor: "#fff",
  width: 48,
  height: 24,
  handleDiameter: 20,
  boxShadow: "0 0 2px 3px #FF6B6B",
};

const defaultProfile =
  "https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742";

function FinishRound() {
  const navigate = useNavigate();
  const [roundId, setRoundId] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const userData = useSelector((state) => state.user.data);
  const [imageList, setImageList] = useState([]);
  const [showImageList, setShowImageList] = useState([]);
  const isMobile = useRef(window.matchMedia("(max-width: 768px)").matches);
  const [selectedPrivacy, setSelectedPrivacy] = useState(null);
  const [isToggled, setIsToggled] = useState(false);
  const postTextRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData && !selectedPrivacy) {
      setSelectedPrivacy(userData?.privacy?.defaultAudience);
    }
  }, [userData, selectedPrivacy]);

  const handleSelectPriv = (selectedPriv) => {
    setSelectedPrivacy(selectedPriv);
  };

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams(window.location.search);
      const roundId = params.get("round");
      setRoundId(roundId);

      if (roundId == null) {
        Swal.fire({
          title: "Invalid Round",
          html: `
            <h5 class="fw-600">The round you selected is invalid.</h5>
            <h5 class="fw-600">Please check and try again.</h5>
          `,
          icon: "error",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        }).then(async (result) => {
          navigate("/round-activity");
        });
      } else {
        const docRef = doc(firestore, "round", roundId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setRoundData(docSnap.data());
        } else {
          Swal.fire({
            title: "Invalid Round",
            html: `
              <h5 class="fw-600">The round you selected is invalid.</h5>
              <h5 class="fw-600">Please check and try again.</h5>
            `,
            icon: "error",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/round-activity");
          });
        }
      }
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  const handleFinishRound = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonText: "Yes, Finish Round!",
      confirmButtonColor: "#EE3C7F",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          let imageUrlList = [];

          if (isToggled) {
            const postText = postTextRef.current.value;
            if (postText === "" && !showImageList.length) return;

            const docRef = await addDoc(collection(firestore, "posts"), {
              postId: "",
              timestamp: serverTimestamp(),
              userId: userData.uid,
              content: postText,
              media: [],
              mediaType: imageList.length > 0 ? "image" : "text",
              likesCount: 0,
              commentsCount: 0,
              tag: [],
              audience: selectedPrivacy || "public",
              role: userData.role,
              notification: true,
            });

            imageUrlList = await handleUploadImage();
            await updateDoc(docRef, {
              postId: docRef.id,
              media: imageUrlList,
              timestamp: serverTimestamp(),
            });
          } else {
            imageUrlList = await handleUploadImage();
          }

          const ref2 = doc(firestore, "round", roundId);
          await updateDoc(ref2, {
            // teeTime: "finished",
            roundImage: imageUrlList,
          });

          const q = query(
            collection(firestore, "scoreCards"),
            where("roundId", "==", roundId),
            where("userId", "==", userData.uid)
          );
          const querySnapshot = await getDocs(q);
          
          if (querySnapshot.empty) {
            console.log("Not Found");
            const qCondition = [
              where('roundId', '==', roundId)
            ];

            if (userData.role === 'caddie') {
                qCondition.push(
                    where('caddieId', '==', userData.uid)
                )
            } else {
                qCondition.push(
                    where('userId', '==', userData.uid)
                )
            }

            const scoreCollection = collection(firestore, 'scoreCards');
            const q = query(scoreCollection, ...qCondition);
            const scoreSnapshot = await getDocs(q);

            if (scoreSnapshot.empty) {
                // Create holes object based on round type
                const holeObj = roundData.holes === '9 holes' ? create9Holes() : create18Holes();

                // Find the user or caddie
                const foundUser = roundData.userList.find(({ player, caddie }) =>
                    (player?.uid === userData.uid) || (caddie?.uid === userData.uid)
                );

                // Destructure properties for cleaner access
                const { player, caddie, row } = foundUser || {};

                // Create score object
                const scoreObj = {
                    ...holeObj,
                    userId: player?.isJoin ? player.uid : null,
                    caddieId: caddie?.isJoin ? caddie.uid : null,
                    roundId,
                    status: 'unfinished',
                    created: serverTimestamp(),
                    row
                };

                // Add document to collection and update with its document ID
                const docRef = await addDoc(scoreCollection, scoreObj);
                await updateDoc(docRef, { docId: docRef.id });
            } else {
                if (scoreSnapshot.size > 1) {
                    console.warn('found similar doc more than one');
                }
            }
          } else {
            for (const doc2 of querySnapshot.docs) {
              const docData = doc2.data();
              const checkedScore = checkScore(docData);
          
              const ref = doc(firestore, "scoreCards", doc2.id);
              await updateDoc(ref, {
                status: checkedScore ? "finished" : "unfinished",
              });
            }
          }


          Swal.fire({
            title: "Round Finished!",
            text: "Your round has been finished.",
            icon: "success",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(() => {
            // navigate(`/review-round?round=${roundId}`);
            const hasCaddie = roundData.userList.some((user) => user.caddie);
            if (hasCaddie && userData.role === "player") {
              navigate(`/review-round?round=${roundId}`);
            } else {
              navigate(`/round-activity`);
            }
          });

          setLoading(false);
          setImageList([]);
          setShowImageList([]);
        } catch (e) {
          setLoading(false);
          console.error("Error adding document: ", e);
        }
      }
    });
  };

  function handleImageSelect(e) {
    const selectedImage = e.target.files;
    const imageListUrl = [];
    const imageList = [];
    for (const image of selectedImage) {
      const imageUrl = URL.createObjectURL(image);
      imageList.push(image);
      imageListUrl.push(imageUrl);
    }
    setShowImageList((prevShowImageList) => [
      ...prevShowImageList,
      ...imageListUrl,
    ]);
    setImageList((prevImageList) => [...prevImageList, ...imageList]);
  }

  const handleImageDelete = (image) => {
    setShowImageList((prevList) => {
      const index = prevList.indexOf(image);
      if (index > -1) {
        setImageList((prevImageList) => {
          const updatedImageList = [...prevImageList];
          updatedImageList.splice(index, 1);
          return updatedImageList;
        });
        return prevList.filter((_, i) => i !== index);
      }
      return prevList;
    });
  };

  const handleUploadImage = async () => {
    if (!imageList.length) {
      return [];
    }

    const promises = [];

    for (let file of imageList) {
      try {
        // Compress the image
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });

        const storageRef = ref(
          storage,
          `round_image/${roundId}/${file.name}.jpg`
        );
        const uploadTask = uploadBytesResumable(storageRef, compressedFile);

        promises.push(
          new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${progress}% done`);
              },
              (error) => {
                console.error(error);
                reject(error);
              },
              async () => {
                try {
                  const downloadURL = await getDownloadURL(
                    uploadTask.snapshot.ref
                  );
                  resolve(downloadURL);
                } catch (error) {
                  console.error(error);
                  reject(error);
                }
              }
            );
          })
        );
      } catch (error) {
        console.error("Error compressing file:", error);
      }
    }

    try {
      const downloadURLs = await Promise.all(promises);
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files: ", error);
      throw error; // Re-throw error to be handled by the caller if necessary
    }
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3">
                  <div className="d-flex align-items-center">
                    <Link
                      to={`/score?round=${roundId}`}
                      className="d-inline-block mt-2"
                    >
                      <i className="ti-arrow-left font-sm text-white"></i>
                    </Link>
                    <div>
                      <h2 className="text-white fw-600 ms-4 mb-0 mt-0">
                        Finish Round
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="card-body p-lg-3 p-4 w-100 border-0">
                  <CardRoundFinish id={roundId}></CardRoundFinish>

                  {/* <section className="round-cover-sect align-items-center mb-4">
                    <h4 className="fw-600 mb-1">Round Image</h4>
                    <div className="d-flex flex-wrap">
                      {showImageList.map((image, index) => (
                        <div
                          key={index}
                          className="each-image-container"
                          style={{
                            width: "150px",
                            height: "150px",
                          }}
                        >
                          <img
                            src={image}
                            aria-hidden
                            alt={`image-${index}`}
                            className="shadow-sm"
                          />
                          <i
                            className="bi bi-x-circle"
                            onClick={() => handleImageDelete(image)}
                          ></i>
                        </div>
                      ))}

                      {showImageList.length < 9 && (
                        <div
                          className="add-image-input-container d-flex justify-content-center align-items-center m-1"
                          onClick={() =>
                            document.getElementById("add-image-input").click()
                          }
                        >
                          <ReactSVG
                            src="assets/images/dooNineIconV2/others/create_Post_Add_Photo.svg"
                            className="create-post-add-photo-btn "
                          />
                          <input
                            type="file"
                            name="add-image-input"
                            id="add-image-input"
                            multiple
                            accept="image/*"
                            className="d-none"
                            onChange={handleImageSelect}
                          />
                        </div>
                      )}
                    </div>
                  </section>

                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h4
                      className="form-check-label fw-600 me-2"
                      htmlFor="feedToggle"
                    >
                      Share to your feeds
                    </h4>
                    <ReactSwitch
                      onChange={handleToggle}
                      checked={isToggled}
                      onColor={switchStyle.onColor}
                      offColor={switchStyle.offColor}
                      onHandleColor={switchStyle.onHandleColor}
                      offHandleColor={switchStyle.offHandleColor}
                      width={switchStyle.width}
                      height={switchStyle.height}
                      handleDiameter={switchStyle.handleDiameter}
                      activeBoxShadow={switchStyle.boxShadow}
                    />
                  </div>

                  {isToggled && (
                    <>
                      <div className="d-flex align-items-start">
                        <figure className="avatar mt-2 me-2">
                          <img
                            src={userData?.profileImage}
                            alt="icon"
                            className="shadow-sm"
                            style={{
                              width: "60px",
                              height: "60px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultProfile;
                            }}
                          />
                        </figure>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="post-text-input"
                          placeholder="What's on your mind ?"
                          ref={postTextRef}
                        />
                      </div>
                      <div className="d-flex justify-content-end mb-3">
                        <SelectedPrivBtn
                          handleSelectPriv={handleSelectPriv}
                          isPreSelected={userData?.privacy?.defaultAudience}
                        />
                      </div>
                    </>
                  )} */}

                  <div className="row">
                    <div className="col-lg-12 d-flex align-items-center justify-content-end">
                      <button
                        className="btn bg-current post-btn me-2"
                        onClick={() => handleFinishRound()}
                      >
                        <h4 className="fw-600 m-0 text-white p-1">OK</h4>
                      </button>
                      <button className="btn bg-light theme-dark-bg">
                        <h4 className="fw-600 m-0 p-1">Cancel</h4>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default FinishRound;
