import React from "react";
import { ReactSVG } from "react-svg";
import Dropdown from "react-bootstrap/Dropdown";
import clsx from "clsx";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { deleteRoundForCreator } from "../module/deleteRound";
import { updateStartRound } from "../module/startRound";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const ConfirmedRound = ({
  listRound,
  userData,
  maxUserList,
  listRoundStart,
  loading,
}) => {
  const navigate = useNavigate();

  const handleDeleteRound = async (roundId) => {
    try {
      await deleteRoundForCreator(roundId);
    } catch (error) {
      console.error("Error deleted round:", error);
    }
  };

  const handleUpdateStartRound = async (roundId) => {
    try {
      if (listRoundStart.length > 0) {
        Swal.fire({
          title: "Round In Progress",
          text: "You still have a round in progress. Please finish that round first.",
          icon: "warning",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      } else {
        await updateStartRound(roundId);
      }
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  const handleLinkToCourse = (courseId) => {
    navigate(`/course-detail?courseId=${courseId}`);
  };

  const isSameDay = (timestamp) => {
    const scheduledDate = new Date(timestamp * 1000); // Convert to milliseconds
    const currentDate = new Date();

    return (
      scheduledDate.getFullYear() < currentDate.getFullYear() ||
      (scheduledDate.getFullYear() === currentDate.getFullYear() &&
        (scheduledDate.getMonth() < currentDate.getMonth() ||
          (scheduledDate.getMonth() === currentDate.getMonth() &&
            scheduledDate.getDate() <= currentDate.getDate())))
    );
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        listRound
          .filter((round) =>
            round.userData.some(
              (user) => user.uid === userData.uid && user.isJoin === true
            )
          ) // Check if userData.uid matches and isJoin is false
          .sort((a, b) => {
            return new Date(b.scheduleTime) - new Date(a.scheduleTime);
          })
          .map((round, index) => (
            <div key={index} className="create-round-req-main">
              <section className="create-round-req-head">
                <div className="pointer">
                  {round.userData.some(
                    (user) =>
                      user.uid === userData.uid && user.isCreator === true
                  ) ? (
                    <a href={`/create-round?edit=${round.roundId}`}>
                      <h4 className="fw-600">{round.roundName}</h4>
                      <br />
                      <h6 className="fw-600 text-muted">
                        {`${round.holes} (${round.holesCourse}), ${round.gameMode} (${round.scoring})`}
                      </h6>
                    </a>
                  ) : (
                    <div>
                      <h4 className="fw-600">{round.roundName}</h4>
                      <br />
                      <h6 className="fw-600 text-muted">
                        {`${round.holes} (${round.holesCourse}), ${round.gameMode} (${round.scoring})`}
                      </h6>
                    </div>
                  )}
                </div>

                <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                  <ReactSVG
                    src="assets/images/dooNineIconV2/chat-SL.svg"
                    wrapper="svg"
                    style={{ height: 40, width: 40 }}
                    className={clsx("pointer", {
                      "d-none": round.groupChat !== "create",
                    })}
                  />
                  <Dropdown className="drodownFriend" drop="down">
                    <Dropdown.Toggle
                      as="span" // Use a span element for the wrapper
                      id="dropdown-basic"
                      style={{ cursor: "pointer", zIndex: 1000 }}
                    >
                      <ReactSVG
                        src="assets/images/dooNineIconV3/create-round/createRound-menu.svg"
                        wrapper="svg"
                        className="wizrd-more-btn"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomDropdown}>
                      <Dropdown.Item
                        disabled={
                          !isSameDay(round.scheduleTime) || // Check if it's the same day
                          !round.userData.some(
                            (user) =>
                              user.uid === userData.uid &&
                              user.isCreator === true // Check if the user can start the round
                          )
                        }
                        onClick={() => handleUpdateStartRound(round.roundId)}
                      >
                        Start Round
                      </Dropdown.Item>
                      <Dropdown.Item href="#/leader-board">
                        Leader Board
                      </Dropdown.Item>
                      <Dropdown.Item href="#/game-mode">
                        Game mode
                      </Dropdown.Item>
                      <Dropdown.Item href="#/competitor">
                        Competitor
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/player-caddie">
                        Player & Caddie
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleLinkToCourse(round.course)}
                      >
                        Course
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        disabled={
                          !round.userData.some(
                            (user) =>
                              user.uid === userData.uid &&
                              user.isCreator === true
                          )
                        }
                        href={`/create-round?edit=${round.roundId}`}
                      >
                        Edit Round
                      </Dropdown.Item>

                      {/* Invitation Link */}
                      <Dropdown.Item
                        disabled={
                          !round.userData.some(
                            (user) =>
                              user.uid === userData.uid &&
                              user.isCreator === true
                          )
                        }
                      >
                        Invitation Link
                      </Dropdown.Item>

                      {round.userData.some(
                        (user) =>
                          user.uid === userData.uid && user.isCreator === true
                      ) ? (
                        <Dropdown.Item
                          onClick={() => handleDeleteRound(round.roundId)}
                        >
                          Delete Round
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item href="#/leave-round">
                          Leave Round
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </section>
              <section
                className="create-round-req-body p-2 mb-2 pointer"
                //   onClick={() => handleChatandDateClick()}
                style={{
                  backgroundImage: round.coverImage
                    ? typeof round.coverImage === "string"
                      ? `url(${round.coverImage})` // ถ้าเป็น URL ให้ใช้โดยตรง
                      : `url(${URL.createObjectURL(round.coverImage)})` // ถ้าเป็นไฟล์ ให้ใช้ createObjectURL
                    : "none",
                  height: round.coverImage ? "" : "inherit",
                }}
              >
                <div
                  className="req-course-data px-0 row"
                  style={{
                    backgroundColor: round.coverImage ? "" : "#fff",
                  }}
                >
                  <div
                    className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center"
                    onClick={() => handleLinkToCourse(round.course)}
                  >
                    <img
                      src={round.courseInfo.profileImage}
                      alt="course-profile"
                      width={70}
                      height={70}
                      className="rounded-circle my-2 d-sm-block d-none"
                    />
                    {/* mobile */}
                    <img
                      src={round.courseInfo.profileImage}
                      alt="course-profile"
                      width={50}
                      height={50}
                      className="rounded-circle my-2 d-sm-none d-block"
                    />
                  </div>
                  <div
                    className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center"
                    onClick={() => handleLinkToCourse(round.course)}
                  >
                    <div>
                      {/* desktop */}
                      <span className="fw-600 fs-4 d-sm-block d-none">
                        {round.courseInfo.courseName}
                      </span>
                      {/* mobile */}
                      <span className="fw-600 fs-5 d-sm-none d-block">
                        {round.courseInfo.courseName}
                      </span>
                      {/* mobile */}
                      <span className="fw-600 fs-5 d-sm-none d-block">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>

                      <span className="fw-600 fs-6 d-sm-block d-none">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                    </div>
                  </div>
                  <div className="col-sm-2 col-3 px-0 px-sm-20">
                    <div
                      className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                      style={{ backgroundColor: "#E59E49" }}
                      // onClick={() => handleChatandDateClick()}
                    >
                      {round.teeTime === "schedule" ? (
                        <>
                          <h4 className="fw-600 text-white text-center">
                            {round.month}
                          </h4>
                          <h1 className="fw-700 text-white text-center">
                            {round.day}
                          </h1>
                          <div className="bg-white text-center w-100">
                            <span
                              className="fw-600 fs-4"
                              style={{ color: "#E59E49" }}
                            >
                              {round.time}
                            </span>
                          </div>
                        </>
                      ) : (
                        <div className="bg-white text-center w-100">
                          <span
                            className="fw-600 fs-4"
                            style={{ color: "#E59E49" }}
                          >
                            Start Now
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <section>
                {/* desktop display 7 limit */}
                <div className="d-none d-sm-flex align-items-center justify-content-between p-3 pointer">
                  {/* Left side: User profile images */}
                  <div className="d-flex align-items-center">
                    {round.userData
                      .slice(0, maxUserList.desktop)
                      .map((user, idx) =>
                        user.isCreator ? (
                          <React.Fragment key={idx}>
                            {user.role === "player" ? (
                              <img
                                src={user.profileImage}
                                alt="player"
                                width={50}
                                height={50}
                                className="rounded-circle player-color-outline me-4"
                              />
                            ) : (
                              <img
                                src={user.profileImage}
                                alt="caddie"
                                width={50}
                                height={50}
                                className="rounded-circle caddie-color-outline me-4"
                              />
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment key={idx}>
                            {user.role === "player" ? (
                              <img
                                src={user.profileImage}
                                alt="player"
                                width={50}
                                height={50}
                                className="rounded-circle player-color-outline"
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.desktop - idx,
                                }}
                              />
                            ) : (
                              <img
                                src={user.profileImage}
                                alt="caddie"
                                width={50}
                                height={50}
                                className="rounded-circle caddie-color-outline"
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.desktop - idx,
                                }}
                              />
                            )}
                          </React.Fragment>
                        )
                      )}
                    {round.userData.length > maxUserList.desktop ? (
                      <div
                        className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                        style={{
                          width: 50,
                          height: 50,
                          outline: "1px dashed grey",
                          opacity: 0.6,
                        }}
                      >
                        <h6 className="text-center">
                          +{round.userData.length - maxUserList.desktop} more
                        </h6>
                      </div>
                    ) : null}
                  </div>

                  {/* Right side: Button */}
                  <div>
                    {isSameDay(round.scheduleTime) && (
                      <button
                        className={clsx(
                          "p-2 lh-20 w100 me-2 text-center font-xssss fw-600 ls-1 rounded-xl border border-0",
                          {
                            "bg-current text-white":
                              round.creator === userData.uid, // Active button style
                            "bg-light text-muted":
                              round.creator !== userData.uid, // Disabled button style
                          }
                        )}
                        onClick={() => handleUpdateStartRound(round.roundId)}
                        disabled={round.creator !== userData.uid} // Disable button if user is not the creator
                      >
                        Start
                      </button>
                    )}
                  </div>
                </div>

                {/* mobile display 5 limit */}
                <div className="d-sm-none d-flex align-items-center justify-content-between p-3 pointer">
                  {/* Left side: User profile images */}
                  <div className="d-flex align-items-center">
                    {round.userData
                      .slice(0, maxUserList.mobile)
                      .map((user, idx) =>
                        user.isCreator ? (
                          <React.Fragment key={idx}>
                            {user.role === "player" ? (
                              <img
                                src={user.profileImage}
                                alt="player"
                                width={40}
                                height={40}
                                className="rounded-circle player-color-outline me-4"
                              />
                            ) : (
                              <img
                                src={user.profileImage}
                                alt="caddie"
                                width={40}
                                height={40}
                                className="rounded-circle caddie-color-outline me-4"
                              />
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment key={idx}>
                            {user.role === "player" ? (
                              <img
                                src={user.profileImage}
                                alt="player"
                                width={40}
                                height={40}
                                className="rounded-circle player-color-outline"
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.mobile - idx,
                                }}
                              />
                            ) : (
                              <img
                                src={user.profileImage}
                                alt="caddie"
                                width={40}
                                height={40}
                                className="rounded-circle caddie-color-outline"
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.mobile - idx,
                                }}
                              />
                            )}
                          </React.Fragment>
                        )
                      )}
                    {round.userData.length > maxUserList.mobile ? (
                      <div
                        className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                        style={{
                          width: 40,
                          height: 40,
                          outline: "1px dashed grey",
                        }}
                      >
                        <h6 className="text-center">
                          +{round.userData.length - maxUserList.mobile} more
                        </h6>
                      </div>
                    ) : null}
                  </div>

                  {/* Right side: Button */}
                  <div>
                    {isSameDay(round.scheduleTime) && (
                      <button
                        className={clsx(
                          "p-2 lh-20 w100 me-2 text-center font-xssss fw-600 ls-1 rounded-xl border border-0",
                          {
                            "bg-current text-white":
                              round.creator === userData.uid, // Active button style
                            "bg-light text-muted":
                              round.creator !== userData.uid, // Disabled button style
                          }
                        )}
                        onClick={() => handleUpdateStartRound(round.roundId)}
                        disabled={round.creator !== userData.uid} // Disable button if user is not the creator
                      >
                        Start
                      </button>
                    )}
                  </div>
                </div>
              </section>
            </div>
          ))
      )}
    </>
  );
};

export default ConfirmedRound;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
