import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742'

function Appfooter() {

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    const userData = useSelector((state) => state.user.data);
    const [unreadMessageCount, setUnreadMEssageCount] = useState(0);

    useEffect(() => {
        async function fetchData() {

            try {
                const response = await axios.get(`https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/users/${userData.dooNineId}/unread_message_count`, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf8',
                        'Api-Token': '1a3a0f2103a01d56c01cde731a802118945aeeb4'
                    }
                });

                setUnreadMEssageCount(response.data.unread_count)


            } catch (error) {
                console.log(error)
            }

        }
        if (userData && isMobile) {
            fetchData();
        }
    }, [isMobile, userData]);

    return (
        <div className="app-footer border-0 shadow-lg bg-primary-gradiant">

            <NavLink
                to="/home"
                className={({ isActive }) => isActive ? "nav-content-bttn nav-center bg-current-shade rounded-3 p-1 mx-1" : "nav-content-bttn nav-center"}
                id='home-btn-mb'
                onClick={() => window.scrollTo(0, 0)}
            >
                <ReactSVG src='assets/images/dooNineIconV2/mobile/mobile-feed-ns-light.svg' className='dooNine-icon-mobile' />
            </NavLink>

            <NavLink
                to="/caddie"
                className={({ isActive }) => isActive ? "nav-content-bttn bg-current-shade rounded-3 p-1 mx-1" : "nav-content-bttn"}
            >
                <ReactSVG src='assets/images/dooNineIconV2/mobile/mobile-caddie-short-cut-nl-light.svg' className='dooNine-icon-mobile' />
            </NavLink>

            <NavLink
                to="/round-activity"
                className={({ isActive }) => isActive ? "nav-content-bttn bg-current-shade rounded-3 p-1 mx-1" : "nav-content-bttn"}
            >
                <ReactSVG src='assets/images/dooNineIconV2/mobile/mobile-golf-activity-ns-light.svg' className='dooNine-icon-mobile' />
            </NavLink>

            <NavLink
                to="/chat"
                className={({ isActive }) => isActive ? "nav-content-bttn bg-current-shade rounded-3 p-1 mx-1" : "nav-content-bttn"}
            >
                <div className='position-relative'>
                    <ReactSVG src='assets/images/dooNineIconV2/mobile/mobile-chat-ns-light.svg' className='dooNine-icon-mobile' />
                    {unreadMessageCount ? <>
                        <span className='position-absolute bg-current text-white d-flex justify-content-center align-items-center rounded-circle'
                            style={{ top: '-5px', right: '-5px', width: 20, height: 20 }}>{unreadMessageCount}</span>
                    </> : null}
                </div>
            </NavLink>

            <NavLink
                to="/userPage"
                className={({ isActive }) => isActive ? "nav-content-bttn bg-current-shade rounded-3 p-1 mx-1" : "nav-content-bttn"}
            >
                <img src={userData?.profileImage || defaultProfile} alt="user" className="w30 shadow-xss rounded-2 m-1" />
            </NavLink>
        </div>
    );
}

export default Appfooter;