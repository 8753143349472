import React from 'react'
import styles from '../../scorestyle.module.scss'
import { ReactSVG } from 'react-svg'

const RenderRow3Comp = ({ score, gir }) => {
    return (
        <div className={`${styles.scorecardrow3} d-flex justify-content-between align-items-center`}>
            {/* GIR */}
            <>
                {gir ?
                    <ReactSVG src='assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_g.svg' wrapper='svg' width={25} height={25} />
                    :
                    <EmptyScore />
                }
            </>

            {/* Fairway */}
            <>
                {score?.holeScore?.fairwayHit !== "-" ? (
                    score?.holeScore?.fairwayHit === "center" ? (
                        <ReactSVG
                            src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_up.svg"
                            wrapper="svg"
                            width={25}
                            height={25}
                        />
                    ) : score?.holeScore?.fairwayHit === "left" ? (
                        <ReactSVG
                            src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_left.svg"
                            wrapper="svg"
                            width={25}
                            height={25}
                        />
                    ) : score?.holeScore?.fairwayHit === "right" ? (
                        <ReactSVG
                            src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_right.svg"
                            wrapper="svg"
                            width={25}
                            height={25}
                        />
                    ) : (
                        <EmptyScore />
                    )
                ) : (
                    <EmptyScore />
                )}
            </>

            {/* Bunker */}
            <>
                {score?.holeScore?.bunkerHit && score?.holeScore?.bunkerHit !== "-" ?
                    <ReactSVG src='assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_b.svg' wrapper='svg' width={25} height={25} />
                    :
                    <EmptyScore />
                }
            </>

            {/* Penalties */}
            <>
                <span className='fw-600 fs-2' style={{ color: '#C00000', lineHeight: 'normal' }}>{score?.holeScore?.penalties || "-"}</span>
            </>

            {/* Match Play trop (not use in stroke) */}
            <>
                {score?.holeScore?.winner ?
                    <ReactSVG src='assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_cup.svg' wrapper='svg' width={25} height={25} />
                    :
                    <EmptyScore />
                }
            </>

        </div>
    )
}

export default RenderRow3Comp

const EmptyScore = () => {
    return (
        <div style={{ width: 25, height: 25 }} className='text-center' >-</div>
    )
}