import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { getDocs, where, query, collection } from 'firebase/firestore';
import PlayerProfile from './player/PlayerProfile';
import CaddieProfile from './caddie/CaddieProfile';


import Rightchat from '../../../components/Rightchat';
import Leftnav from '../../../components/Leftnav';
import Header from '../../../components/Header';
import Appfooter from '../../../components/Appfooter';
import Popupchat from '../../../components/Popupchat';

function UserIndex() {
    const location = useLocation();
    let [searchParams] = useSearchParams();
    const uid = location?.state?.uid || searchParams.get('uid');
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        async function fetchUserData() {
            const q = query(collection(firestore, "users"), where("uid", "==", uid));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setUserData(doc.data());
            });
        }
        if (uid) {
            fetchUserData()
        }
    }, [uid])

    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />
            {userData?.role === 'caddie' ? (
                <CaddieProfile data={userData} />
            ) : (
                <PlayerProfile data={userData} />
            )}
            <Popupchat />
            <Appfooter />
        </>
    )
}

export default UserIndex