import React, { useEffect, useState } from "react";
import Stack from "react-bootstrap/Stack";

function FilterStack({
  courseList,
  originalCourseList,
  search,
  setSearch,
  filter,
  setFilter,
}) {
  const handleRemoveBadge = (state) => {
    if (state.key === "province") {
      setSearch({
        ...search,
        province: {
          state: false,
          value: "",
        },
      });
    } else if (state.key === "text") {
      setSearch({
        ...search,
        text: {
          state: false,
          value: "",
        },
      });
    } else if (state.key == "officialCourse") {
      setFilter({
        ...filter,
        officialCourse: {
          state: false,
        },
      });
    } else if (state.key == "startingPriceRange") {
      setFilter({
        ...filter,
        startingPriceRange: {
          state: false,
        },
      });
    } else if (state.key == "nightGolf") {
      setFilter({
        ...filter,
        nightGolf: {
          state: false,
        },
      });
    } else if (state.key == "drivingRange") {
      setFilter({
        ...filter,
        drivingRange: {
          state: false,
        },
      });
    } else if (state.key == "restaurant") {
      setFilter({
        ...filter,
        restaurant: {
          state: false,
        },
      });
    } else if (state.key == "banquetRooms") {
      setFilter({
        ...filter,
        banquetRooms: {
          state: false,
        },
      });
    } else if (state.key == "hotel") {
      setFilter({
        ...filter,
        hotel: {
          state: false,
        },
      });
    } else if (state.key == "nearMe") {
      setSearch({
        ...search,
        nearMe: {
          state: false,
        },
      });
    }
  };

  const trueStates = Object.keys(filter).filter(
    (key) => filter[key].state === true
  );

  return (
    <>
      <Stack direction="horizontal" gap={2}>
        {courseList.length === originalCourseList.length ? null : (
          <div
            className="font-xssss fw-600"
            style={{
              backgroundColor: "#F5F5F5",
              display: "inline-block",
              padding: "0.25em 0.75em",
              margin: "0.25em",
              borderRadius: "50px",
              whiteSpace: "nowrap",
            }}
          >
            &nbsp;&nbsp;
            <i className="bi bi-check-circle-fill fs-4 me-2"></i>
            {courseList.length} Result
          </div>
        )}

        {/* Province */}
        {search.province?.state === true ? (
          <div
            className="font-xssss fw-600"
            style={{
              backgroundColor: "#F5F5F5",
              display: "inline-block",
              padding: "0.25em 0.75em",
              margin: "0.25em",
              borderRadius: "50px",
              whiteSpace: "nowrap",
            }}
          >
            &nbsp;&nbsp;
            <i className="bi bi-geo-alt fs-4 me-2"></i>
            {search.province?.value}
            <button
              className="btn"
              onClick={() => handleRemoveBadge({ key: "province" })}
              style={{ color: "black" }}
            >
              X
            </button>
          </div>
        ) : null}

        {/* Text */}
        {search.text?.state === true ? (
          <div
            className="font-xssss fw-600"
            style={{
              backgroundColor: "#F5F5F5",
              display: "inline-block",
              padding: "0.25em 0.75em",
              margin: "0.25em",
              borderRadius: "50px",
              whiteSpace: "nowrap",
            }}
          >
            &nbsp;&nbsp;
            <i className="bi bi-search fs-4 me-2"></i>
            {search.text?.value}
            <button
              className="btn"
              onClick={() => handleRemoveBadge({ key: "text" })}
              style={{ color: "black" }}
            >
              X
            </button>
          </div>
        ) : null}

        {search.nearMe?.state === true ? (
          <div
            className="font-xssss fw-600"
            style={{
              backgroundColor: "#F5F5F5",
              display: "inline-block",
              padding: "0.25em 0.75em",
              margin: "0.25em",
              borderRadius: "50px",
              whiteSpace: "nowrap",
            }}
          >
            &nbsp;&nbsp;
            <i className="bi bi-crosshair fs-4 me-2"></i>
            Near Me
            <button
              className="btn"
              onClick={() => handleRemoveBadge({ key: "nearMe" })}
              style={{ color: "black" }}
            >
              X
            </button>
          </div>
        ) : null}

        {trueStates.map((key) => (
          <div
            className="font-xssss fw-600"
            key={key}
            style={{
              backgroundColor: "#F5F5F5",
              display: "inline-block",
              padding: "0.25em 0.75em",
              margin: "0.25em",
              borderRadius: "50px",
              whiteSpace: "nowrap",
            }}
          >
            &nbsp;&nbsp; <i className="bi bi-funnel fs-5 me-2"></i>
            {key == "officialCourse"
              ? "Offcial Course"
              : key == "startingPriceRange"
              ? `Starting Price : ${filter[key].min} to ${filter[key].max}`
              : key == "nightGolf"
              ? "Night Golf"
              : key == "drivingRange"
              ? "Driving Range"
              : key == "restaurant"
              ? "Restaurant"
              : key == "banquetRooms"
              ? "Banquet Rooms"
              : key == "hotel"
              ? "Hotel"
              : null}
            <button
              className="btn"
              onClick={() => handleRemoveBadge({ key: key })}
              style={{ color: "black" }}
            >
              X
            </button>
          </div>
        ))}
      </Stack>
    </>
  );
}

export default FilterStack;
