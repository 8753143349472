import React, { createContext, useContext, useState } from 'react';

// Create a Context
const WizardContext = createContext();

// Custom Hook to use the WizardContext
export const useWizard = () => {
    return useContext(WizardContext);
};

// WizardContextProvider component to manage state
export const WizardProvider = ({ children }) => {

    const [currentStep, setCurrentStep] = useState(1);

    const [stepData, setStepData] = useState({
        stepOne: {
            course: null,
            roundName: "",
            holes: "",
            holesCourse: "",
            gameMode: "str",
            handicap: "scratch",
            scoring: "Gross",
            scoringType: ['Gross', 'To Par'],
        },
        stepTwo: {
            row: []
        },
        stepThree: {
            teeTimeBtn: 'now',
            groupChatBtn: 'create',
            groupChatName: "",
            scheduleTime: null,
            caddieReqBtn: 'all',
            imageFile: null,
            imageUrl: null
        },
    });

    // Function to update step data
    const updateStepData = (step, data) => {
        setStepData((prevData) => ({
            ...prevData,
            [step]: {
                ...prevData[step] || {},  // Fallback to an empty object if undefined
                ...data
            },
        }));
    };


    const nextStep = () => {
        setCurrentStep((prev) => {
            if (prev < 4) {
                return prev + 1;
            }
            return prev;
        });
    }

    const prevStep = () => {
        setCurrentStep((prev) => {
            if (prev > 1) {
                return prev - 1;
            }
            return prev;
        });
    }


    const currentStepIndex = (step) => {
        if (step > 0 && step < 4) {
            setCurrentStep(step);
        } else {
            console.warn(`Step ${step} is not existed`);
        }
    }

    return (
        <WizardContext.Provider
            value={{ stepData, updateStepData, nextStep, prevStep, currentStep, currentStepIndex }}
        >
            {children}
        </WizardContext.Provider>
    );
};
