import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import Gamemode from "../../scoreV2/component/Gamemode";
import useModalWithHistory from "../../../components/ModalPopState";

function HeaderDropdown() {
  const navigate = useNavigate();
  const [roundId, setRoundId] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const userData = useSelector((state) => state.user.data);
  const { isModalOpen, openModal, closeModal } = useModalWithHistory();

  useEffect(() => {
    async function getRound() {
      const params = new URLSearchParams(window.location.search);
      const roundId = params.get("round");
      setRoundId(roundId);

      const docRef = doc(firestore, "round", roundId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setRoundData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }
    getRound();
  }, []);

  const handleEndRound = async () => {
    Swal.fire({
      title: "End this round?",
      html: `
        <h5 class="fw-600">Are you sure you want to end this round?</h5>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(`/finish-round?round=${roundId}`);
      }
    });
  };

  // console.log(roundData);
  // console.log(userData);

  return (
    <>
      <Dropdown className="drodownFriend" drop="down-centered">
        <Dropdown.Toggle
          as="i"
          className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"
          id="dropdown-basic"
          style={{ cursor: "pointer", zIndex: 1000 }}
        ></Dropdown.Toggle>
        <Dropdown.Menu as={CustomDropdown} style={{ borderRadius: 15, overflow: 'hidden' }} className="border-0 shadow-md theme-dark-bg">
          <Dropdown.Item onClick={() => navigate(`/leader-board?round=${roundId}`)} style={dropdownItemStyle}>
            <h5 className="fw-600" >
              Leaderboard
            </h5>
          </Dropdown.Item>
          <Dropdown.Item style={dropdownItemStyle} onClick={openModal}>
            <h5 className="fw-600">
              Game mode
            </h5>
          </Dropdown.Item>
          <Dropdown.Item style={dropdownItemStyle} disabled>
            <h5 className="fw-600"
              style={{
                color: "#cdcdcd",
              }}
            >
              Competitor
            </h5>
          </Dropdown.Item>
          <hr />
          <Dropdown.Item as={Link} to={`/p-and-c-round?round=${roundData?.roundId}`} style={dropdownItemStyle}>
            <h5 className="fw-600">
              Player & Caddie
            </h5>
          </Dropdown.Item>
          <Dropdown.Item style={dropdownItemStyle} >
            <h5 className="fw-600"
              onClick={() => {
                navigate(`/course-detail?courseId=${roundData?.course}`);
              }}
            >
              Course
            </h5>
          </Dropdown.Item>
          <hr />
          <Dropdown.Item style={dropdownItemStyle} disabled>
            <h5 className="fw-600"
              style={{
                color: "#cdcdcd",
              }}
            >
              Edit Round
            </h5>
          </Dropdown.Item>
          <Dropdown.Item style={dropdownItemStyle} disabled>
            <h5 className="fw-600"
              style={{
                color: "#cdcdcd",
              }}
            >
              Invitation Link
            </h5>
          </Dropdown.Item>
          <Dropdown.Item
            // disabled={roundData?.creator !== userData?.uid}
            disabled={userData?.role === "caddie"}
            style={dropdownItemStyle}
            onClick={() => handleEndRound()}
          >
            <h5 className="fw-600"
              // style={{
              //   color:
              //     roundData?.creator == userData?.uid ? "black" : "#cdcdcd",
              // }}

              style={{
                color:
                  userData?.role === "player" ? "black" : "#cdcdcd",
              }}
              
            >
              End Round
            </h5>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Gamemode
        isOpen={isModalOpen}
        closeModal={closeModal}
      />

    </>
  );
}

export default HeaderDropdown;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}

const dropdownItemStyle = {
  paddingTop: 8,
  paddingBottom: 8
}
