import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const CreateRound = () => {
    return (
        <Card className='border-0 shadow-xss' style={{ borderRadius: '15px' }}>
            <Card.Body>
                <Card.Title className='mb-5'><strong className='fs-2'>Create Round</strong></Card.Title>

                <Link to={'/create-round'}>
                    <div className='w-100 p-sm-4 p-3 rounded-3 mb-3 text-center' style={{ backgroundColor: '#F5F5F5' }}>
                        <strong style={{ color: '#B0B5BD' }}>Select course</strong>
                    </div>
                </Link>

                <div className='d-flex align-items-center'>
                    <i className='feather-calendar text-current fs-1 me-2'></i>
                    <span className='fs-4'>Create a round schedule</span>
                </div>

            </Card.Body>
        </Card>
    )
}

export default CreateRound