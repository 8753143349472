import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import axios from "axios";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";

import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";

import GroupChannel from "@sendbird/uikit-react/GroupChannel";
import MessageContent from "@sendbird/uikit-react/ui/MessageContent";
import { MessageMenu, MenuItem } from "@sendbird/uikit-react/ui/MessageMenu";
import { Message } from "@sendbird/uikit-react/GroupChannel/components/Message";
import "@sendbird/uikit-react/dist/index.css";

import { ReactSVG } from "react-svg";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const backChat = "assets/images/message/Chat-Back.svg";
const friend = "assets/images/message/Chat-Friends.svg";
const addFriend = "assets/images/message/Char-Add-Friend.svg";
const menu = "assets/images/message/Chat-Menu.svg";

function ChatRoom() {
  const userData = useSelector((state) => state.user.data);
  const [channelId, setChannelId] = useState();
  const navigate = useNavigate();

  const [members, setMembers] = useState([]);
  const [isFriend, setIsFriend] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dataProfile, setDataProfile] = useState();

  const [channelData, setChannelData] = useState(null);

  const [groupMemberData, setGroupMemberData] = useState(null);
  const [show, setShow] = useState(false);
  const [isOperator, setIsOperator] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const channelId_ = params.get("channelId");
    setChannelId(channelId_);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}/members`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );

        let member = response.data.members.filter(
          (user) => user.user_id !== userData?.dooNineId
        );
        setMembers(member);

        const q = query(
          collection(firestore, "users"),
          where("dooNineId", "==", member[0].user_id)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          let isFriend = false;
          setDataProfile(doc.data());
          const q3 = query(
            collection(firestore, "friends"),
            where("friendId", "==", doc.data().uid),
            where("userId", "==", userData?.uid)
          );

          const q2 = query(
            collection(firestore, "friends"),
            where("friendId", "==", userData?.uid),
            where("userId", "==", doc.data().uid)
          );

          const querySnapshot2 = await getDocs(q2);
          querySnapshot2.forEach(async (doc2) => {
            if (doc2.data().status == "active") {
              isFriend = true;
            }
          });

          const querySnapshot3 = await getDocs(q3);
          querySnapshot3.forEach(async (doc3) => {
            if (doc3.data().status == "active") {
              isFriend = true;
            }
          });

          if (isFriend) {
            // console.log("isFriend");
            setIsFriend(true);
          } else {
            // console.log("notFriend");
            setIsFriend(false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    if (userData && channelId) {
      fetchData();
    }
  }, [channelId, userData]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );
        setChannelData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    if (channelId) {
      fetchData();
    }
  }, [channelId]);

  const handleShowMember = async () => {
    try {
      setShow(true);
      const response = await axios.get(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}/members`,
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
          },
        }
      );
      const members = response.data.members;
      const operators = members.filter((member) => member.role == "operator");

      let array = [];
      for (let index = 0; index < response.data.members.length; index++) {
        const element = response.data.members[index];

        const q = query(
          collection(firestore, "users"),
          where("dooNineId", "==", element.user_id)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          array.push({ roleSendbird: element.role, ...doc.data() });
        });
      }
      array.sort((a, b) => {
        const roleOrder = { player: 1, caddie: 2 };

        // Prioritize operator roles first
        if (a.roleSendbird === "operator" && b.roleSendbird !== "operator")
          return -1;
        if (a.roleSendbird !== "operator" && b.roleSendbird === "operator")
          return 1;

        // If both are or are not operators, sort by role
        return roleOrder[a.role] - roleOrder[b.role];
      });
      setGroupMemberData(array);

      const user = array.find((user) => user.uid === userData.uid);
      setIsOperator(user.roleSendbird == "operator");
    } catch (error) {
      console.log(error);
    }
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleCloseModal = () => {
    setGroupMemberData(null);
    setShow(false);
  };

  const handleRemoveMemberinGroup = async (value) => {
    Swal.fire({
      title:
        "Remove '" +
        value.firstName +
        " " +
        value.lastName +
        "' from the group?",
      html: `
        <h5 class="fw-600">You are about to remove this member from the group.</h5>
        <h5 class="fw-600">Once removed, they cannot be added back.</h5>
      `,
      icon: "warning",
      confirmButtonColor: "#ee3d7f",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await axios.put(
          "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/" +
            channelId +
            "/leave",
          {
            user_ids: [value.dooNineId],
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );

        if (response.status == 200) {
          handleShowMember();
          Swal.fire({
            icon: "success",
            title: "Remove member success",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          });
        } else {
          console.log(response);

          Swal.fire({
            icon: "error",
            title: "Remove member failed",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const colorSet = {
    "--sendbird-light-primary-500": "#ee3d7f",
    "--sendbird-light-primary-400": "#f16398",
    "--sendbird-light-primary-300": "#ee3d7f",
    "--sendbird-light-primary-200": "#f8b1cb",
    "--sendbird-light-primary-100": "#fbd8e5",
  };

  const handleDeleteChat = async (friendData) => {
    Swal.fire({
      title: "Do you want to delete this chat?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}`,

            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
              },
            }
          );

          Swal.fire({
            title: "Delete Channel Success ",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/chat");
          });
        } catch (error) {
          console.error("Error leaving channel:", error);
        }
      }
    });
  };

  return (
    <>
      <style>
        {`
      .sendbird-fileviewer{
      height: 70dvh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      }
      .chat-container{
      height:calc(100vh - 56px - 80px - 65px);
      }
      `}
      </style>
      <Fragment>
        <Header />
        <Leftnav />
        <Rightchat />
        <SendbirdProvider
          appId={"B72515CE-E0DA-417F-AF02-4BF3D07B3D50"}
          userId={userData?.dooNineId}
          accessToken={userData?.sendBirdAccessToken}
          // breakpoint={true}
          // theme="dark"
          colorSet={colorSet}
        >
          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="chat-container">
                  <GroupChannel
                    channelUrl={channelId}
                    renderChannelHeader={() => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "5px",
                            borderStartEndRadius: "16px",
                            borderStartStartRadius: "16px",
                            marginTop: "5px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ReactSVG
                              src={backChat}
                              style={{ width: "40px", height: '30px',  cursor: "pointer" }}
                              onClick={() => navigate("/chat")}
                            />
                            <p
                              style={{
                                fontWeight: "bold",
                                marginBottom: 0,
                                marginLeft: "10px",
                                fontSize: "18px",
                                fontWeight: "600",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "-0.2px",
                                fontFamily:
                                  "var(--sendbird-font-family-default)",
                              }}
                            >
                              {channelData?.joined_member_count > 2
                                ? channelData?.name +
                                  "  (" +
                                  channelData?.joined_member_count +
                                  ")"
                                : members[0]?.nickname}
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p style={{ marginBottom: 0, marginRight: "10px" }}>
                              {channelData?.joined_member_count >
                              2 ? null : isFriend ? (
                                <ReactSVG
                                  src={friend}
                                  style={{ width: "35px" }}
                                />
                              ) : (
                                <ReactSVG
                                  src={addFriend}
                                  style={{ width: "30px" }}
                                />
                              )}
                            </p>
                            <div>
                              <ReactSVG
                                src={menu}
                                style={{ width: "35px", cursor: "pointer" }}
                                onClick={toggleDropdown}
                              />

                              {dropdownOpen && (
                                <Dropdown
                                  className="drodownFriend"
                                  drop="down-centered"
                                  show={dropdownOpen}
                                  onToggle={toggleDropdown}
                                >
                                  <Dropdown.Toggle
                                    as="i"
                                    id="dropdown-basic"
                                    style={{
                                      cursor: "pointer",
                                      zIndex: 1000,
                                    }}
                                  ></Dropdown.Toggle>

                                  <Dropdown.Menu
                                    className="dropdownChat"
                                    as={CustomDropdown}
                                    popperConfig={{
                                      modifiers: [
                                        {
                                          name: "flip",
                                          options: {
                                            altBoundary: true,
                                            rootBoundary: "viewport",
                                            padding: 8,
                                          },
                                        },
                                        {
                                          name: "preventOverflow",
                                          options: {
                                            altAxis: true,
                                            altBoundary: true,
                                            tether: false,
                                          },
                                        },
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, 10],
                                          },
                                        },
                                      ],
                                    }}
                                    style={{ zIndex: 1050 }}
                                  >
                                    {channelData?.joined_member_count > 2 ? (
                                      <Dropdown.Item
                                        style={{ display: "flex" }}
                                        onClick={() => handleShowMember()}
                                      >
                                        Members
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        style={{ display: "flex" }}
                                        onClick={() =>
                                          handleProfileClick(dataProfile.uid)
                                        }
                                      >
                                        Profile
                                      </Dropdown.Item>
                                    )}

                                    <Dropdown.Item
                                      style={{ display: "flex" }}
                                      onClick={() =>
                                        handleDeleteChat(dataProfile)
                                      }
                                    >
                                      Delete Chat
                                    </Dropdown.Item>

                                    {/* {channelData?.joined_member_count >
                                    2 ? null : (
                                      <>
                                        <hr className="verticalHeader" />
                                        <Dropdown.Item
                                          style={{ display: "flex" }}
                                          disabled
                                        >
                                          Block
                                        </Dropdown.Item>
                                      </>
                                    )} */}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr className="verticalHeader" />
                      </>
                    )}
                    disableUserProfile={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal show={show} onHide={() => handleCloseModal()} scrollable>
            <Modal.Header closeButton>
              <Modal.Title>
                <h2
                  style={{ display: "inline-block", marginLeft: "15px" }}
                  className="fw-700 mb-0 mt-0 font-sm text-grey-700"
                >
                  Group members
                </h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row ps-2 pe-2">
                  {groupMemberData?.map((value, index) => (
                    <div key={index} className="col-md-12 col-sm-12 pe-2 ps-2">
                      <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                        <div className="card-body d-flex p-3">
                          <figure
                            className="avatar mb-0 position-relative z-index-1 me-3"
                            style={{
                              flexShrink: 0,
                              justifyContent: "center",
                              display: "flex",
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={value.profileImage}
                              alt={`${value.firstName} ${value.lastName}`}
                              className="bg-image-cover float-left p-1 rounded-circle w-100"
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                                backgroundColor:
                                  value.role === "caddie"
                                    ? "#EE3D7F"
                                    : "#4A74E5",
                              }}
                            />
                          </figure>
                          <div className="flex-grow-1">
                            <div
                              className="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <h4
                                className="fw-700 font-xsss mt-2 mb-1"
                                onClick={() => handleProfileClick(value.uid)}
                                style={{ cursor: "pointer" }}
                              >
                                {value.firstName} {value.lastName}
                                {value.roleSendbird === "operator" &&
                                  " (Operator)"}
                              </h4>

                              {isOperator && value.uid !== userData.uid ? (
                                <i
                                  onClick={() =>
                                    handleRemoveMemberinGroup(value)
                                  }
                                  className="bi bi-trash ms-3"
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              ) : (
                                value.uid !== userData.uid && (
                                  <i
                                    className="bi bi-trash ms-3"
                                    style={{
                                      fontSize: "18px",
                                      color: "#d3d3d3",
                                    }}
                                  ></i>
                                )
                              )}
                            </div>
                            <div className="icon-container d-flex flex-wrap">
                              {value.role === "caddie" && value.isStar && (
                                <span className="fw-600 d-inline-block z-index-1 ls-3 font-xssss text-white">
                                  <ReactSVG
                                    src="assets/icon/CaddieFriendFav-CaddieStar.svg"
                                    style={{ width: "25px" }}
                                  />
                                </span>
                              )}
                              {value.role === "caddie" && value.isPro && (
                                <span className="fw-600 d-inline-block z-index-1 ls-3 font-xssss text-white">
                                  <ReactSVG
                                    src="assets/icon/CaddieFriendFav-ProCaddie.svg"
                                    style={{ width: "25px" }}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn bg-greylight"
                onClick={() => handleCloseModal()}
              >
                <h4 className="fw-600 m-0 text-grey-700 p-1">Close</h4>
              </button>
            </Modal.Footer>
          </Modal>
        </SendbirdProvider>
        <Popupchat />
        <Appfooter />
      </Fragment>
    </>
  );
}

export default ChatRoom;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
