import React from "react";

function ScoreBox({ data }) {
  const cellStyle = {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
    borderBottom: "2px solid #ddd",
    borderTop: "2px solid #ddd",
    borderRight: "2px solid #ddd",
    borderLeft: "2px solid #ddd",
  };

  const colorStyles = {
    grey: { backgroundColor: "#A6A6A6", borderRadius: "6px" },
    white: { backgroundColor: "#F5F5F5", borderRadius: "6px" },
  };

  return (
    <>
      <div
        style={{
          ...cellStyle,
          ...colorStyles.white,
          position: "relative",
          fontSize: "2rem",
          lineHeight: "1",
          display: "flex",
          flexDirection: "column", // เปลี่ยนการจัดเรียงเป็นแนวตั้ง
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            position: "relative",
            zIndex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              color: "#C00000",
              margin: "0 10px",
            }}
            className="fw-600"
          >
            {data?.totalPenalties}
          </h1>
          <h1
            className="fw-700"
            style={{
              margin: "0 5px",
              fontSize: "2rem",
            }}
          >
            {data?.totalScore}
          </h1>
          <h1
            className="fw-600"
            style={{
              margin: "0 10px",
            }}
          >
            {data?.totalPutts}
          </h1>
        </span>
        <h5
          style={{
            margin: "2px 0 0 0",
          }}
        >
          {data?.status == "finished"
            ? "Finished"
            : data?.status == "playing"
            ? "Playing"
            : ""}
        </h5>
      </div>
    </>
  );
}

export default ScoreBox;
