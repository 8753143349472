import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { firestore } from '../../../firebase/FirebaseConfig';
import { collection, getDocs, where, query, updateDoc, doc } from "firebase/firestore";

// fetch
export const fetchUserData = createAsyncThunk('user/fetchUserData', async ({ userId, user }) => {

    const userQuery = await getDocs(query(collection(firestore, "users"), where("uid", "==", userId)))
    if (!userQuery.empty) {
        const userDoc = userQuery.docs[0];
        const userData = userDoc.data();
        const created = userData.created ? userData.created.toDate().toISOString() : null;
        return {
            ...userData,
            created,
            providerData: user.providerData
        };
    } else {
        throw new Error('User not found');
    }
});

// update
export const updateUserData = createAsyncThunk('user/updateUserData', async ({ userId, newData }) => {
    const userQuery = await getDocs(query(collection(firestore, "users"), where("uid", "==", userId)));
    if (!userQuery.empty) {
        const userDoc = userQuery.docs[0];
        await updateDoc(doc(firestore, "users", userDoc.id), newData);
        return { userId, newData };
    } else {
        throw new Error('User not found');
    }
});
export const userSlice = createSlice({
    name: 'user',
    initialState: { data: null, status: 'idle', error: null },
    reducers: {
        clearUserData: (state) => {
            state.data = null;
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(updateUserData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = { ...state.data, ...action.payload.newData };
            })
            .addCase(updateUserData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }

});

export const updateUser = (userId, newData) => async (dispatch) => {
    await dispatch(updateUserData({ userId, newData }));
};
export const { clearUserData } = userSlice.actions
export default userSlice.reducer;