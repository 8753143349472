import React, { useState, useEffect } from "react";
import Header from '../../../../components/Header';
import Leftnav from '../../../../components/Leftnav';
import Rightchat from '../../../../components/Rightchat';
import Appfooter from '../../../../components/Appfooter';
import Popupchat from '../../../../components/Popupchat';

import { Card } from "react-bootstrap";
import { PhotoProvider, PhotoView } from "react-photo-view";
import FriendCount from "../../module/FriendCount";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import numeral from "numeral";
import TotalPost from "./statcomponent/TotalPost";

import ServiceMindChartCaddie from "../../module/chart/caddie/ServiceMindChartCaddie";
import AdviceChartCaddie from "../../module/chart/caddie/AdviceChartCaddie";

import { getCompletedRoundCaddie, getServiceMindAndGuide, getTotalRound } from "../../myperfprofile/module/statCalculationCaddie";
import MonthlyScore from "../../module/chart/caddie/MonthlyScore";

function CaddiePerf({ data }) {

    const [selectedCompletedRound, setSelectedCompletedRound] = useState(0);
    const [totalRoundCount, setTotalRoundCount] = useState(0);
    const [completedRound, setCompletedRound] = useState([]);
    const [reviewData, setReviewData] = useState([]);
    const [monthlyReviewData, setMonthlyReviewData] = useState([]);
    const [completedRoundCount, setCompletedRoundCount] = useState(null);
    const [serviceMind, setServiceMind] = useState(0);
    const [guide, setGuide] = useState(0);
    const [doonine, setDoonine] = useState(0);

    const handleSelectCompltedRound = (selected) => {

        setSelectedCompletedRound(selected)
    }

    useEffect(() => {
        async function getTotalRoundCount() {
            const totalRound = await getCompletedRoundCaddie(data.uid, 0) || [];
            const { reviewList } = await getServiceMindAndGuide(totalRound, data.uid);
            setMonthlyReviewData(reviewList)

            const totalRoundCounts = await getTotalRound(data.uid) || 0;
            setTotalRoundCount(totalRoundCounts);
        }
        if (data) {
            getTotalRoundCount()
        }
    }, [data])


    useEffect(() => {
        const getStat = async () => {
            const completedScore = await getCompletedRoundCaddie(data.uid, selectedCompletedRound) || [];
            setCompletedRoundCount(completedScore.length);
            setCompletedRound(completedScore);

            const { avgServiceMind, avgGuide, avgDoonine, reviewList } = await getServiceMindAndGuide(completedScore, data.uid);
            setServiceMind(avgServiceMind);
            setGuide(avgGuide);
            setDoonine(avgDoonine);
            setReviewData(reviewList);
        }

        if (data) {
            getStat();
        }

    }, [selectedCompletedRound, data])


    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />
            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-3 p-0 px-sm-3">
                                <Card className="w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                    <Card.Body className="p-0 rounded-xxl overflow-hidden m-3 profile-cover-card" style={{ height: 250 }}>
                                        {data?.coverImage ? (
                                            <PhotoProvider bannerVisible={false}>
                                                <PhotoView src={data?.coverImage}>
                                                    <img src={data?.coverImage} alt="avater" style={{ objectFit: 'cover', width: '100%', height: '100%', cursor: 'pointer' }} />
                                                </PhotoView>
                                            </PhotoProvider>
                                        ) : (
                                            <div className='w-100 h-100 bg-light'></div>
                                        )}
                                    </Card.Body>
                                    <Card.Body className="p-0 position-relative profile-image-card">
                                        <PhotoProvider bannerVisible={false}>
                                            <figure className="avatar position-absolute w100 h100 z-index-1" style={{ top: '-40px', left: '30px' }}>
                                                <PhotoView src={data?.profileImage}>
                                                    <img src={data?.profileImage} alt="avater"
                                                        className="float-right p-1 rounded-circle w-100 h-100 caddie-color" style={{ objectFit: 'cover', cursor: 'pointer' }} />
                                                </PhotoView>
                                            </figure>
                                        </PhotoProvider>
                                        <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">{data?.firstName || data?.firstname} {data?.lastName || data?.lastname}
                                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block text-truncate"><FriendCount user={data} /></span>
                                        </h4>
                                        <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2 mb-3 mb-sm-0">
                                            <Link to={`/user?uid=${data?.uid}`}>
                                                <button className="btn text-white p-2 px-3 caddie-color">Profile</button>
                                            </Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-xl-4 col-xxl-3 col-lg-4 pe-sm-0 px-sm-3 px-0 mb-2">
                                <Card className="border border-0 shadow-xss rounded-xxl">
                                    {/* my stats */}
                                    <Card.Body>
                                        <div className="d-flex justify-content-between">
                                            <h4 className="fw-bold">Social stats</h4>
                                        </div>
                                        <TotalPost userData={data} />
                                        <div className="d-flex justify-content-start align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#FDE7EF' }}>
                                            <strong className="text-center py-1 px-3 fs-1 fw-bolder me-2 rounded-3 text-white" style={{ backgroundColor: '#EE3C7F' }}><FriendCount user={data} numOnly={true} /></strong>
                                            <strong className="fs-4">Friends</strong>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#E9F6E9' }}>
                                            <strong className="text-center py-1 px-3 fs-1 fw-bolder me-2 rounded-3 text-white" style={{ backgroundColor: '#81D77A' }}>{totalRoundCount}</strong>
                                            <strong className="fs-4">Total Rounds</strong>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            {/* my stat */}
                            <div className="col-xl-8 col-xxl-9 col-lg-8 px-sm-3 px-0" >
                                <Card className="border border-0 shadow-xss rounded-xxl">
                                    <Card.Body className="main-caddie-stat">
                                        <h4 className="fw-bold">My Statistics</h4>
                                        {/* completed round */}
                                        <section className="d-flex justify-content-between align-items-center py-2 px-3 rounded-3 mb-2" style={{ backgroundColor: '#E9F6E9' }}>
                                            <div className="d-none d-sm-flex justify-content-center align-items-center rounded-circle" style={{ width: 60, height: 60, backgroundColor: '#81D77A' }}>
                                                <i className="bi bi-activity text-white"
                                                    style={{ fontSize: 40 }}>
                                                </i>
                                            </div>
                                            <div className="me-auto ms-4">
                                                <strong className="" style={{ color: '#81D77A' }}>{completedRoundCount}</strong> <br />
                                                <small>Total number of rounds you  have completed</small>
                                            </div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" className="btn rounded-pill bg-white py-2 px-4 fs-5 fw-bold text-black w125">
                                                    {selectedCompletedRound === 0 ? 'All Time' : `Past ${selectedCompletedRound}`}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{ borderRadius: 15 }} className="border-0">
                                                    <Dropdown.Item onClick={() => handleSelectCompltedRound(0)} className="fw-600">All Time</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSelectCompltedRound(90)} className="fw-600">Past 90 Days</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSelectCompltedRound(30)} className="fw-600">Past 30 Days</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </section>
                                        {/* score & mind */}
                                        <section className="gap-2 d-flex flex-md-nowrap flex-wrap hand-putt-section mb-2">
                                            <div className="d-flex justify-content-start align-items-center w-100 p-3 rounded-3" style={{ backgroundColor: '#FAF0E9' }}>
                                                <strong className="px-2 rounded-3 col-5 text-center" style={{ backgroundColor: '#E59E49' }}>
                                                    {doonine}
                                                </strong>
                                                <span className="mb-3 ms-2">doonine score</span>
                                            </div>
                                            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#F5F3FF' }}>
                                                <strong className="px-2 rounded-3 col-5 text-center" style={{ backgroundColor: '#5D3DB5' }}>
                                                    {serviceMind}
                                                </strong>
                                                <span className="mb-3 ms-2">Service Mind</span>
                                            </div>
                                        </section>
                                        {/* advice & point */}
                                        <section className="gap-2 d-flex flex-md-nowrap flex-wrap reg-best-section mb-2">
                                            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#E9F6E9' }}>
                                                <strong className="px-2 rounded-3 col-5 text-center" style={{ backgroundColor: '#81D77A' }}>
                                                    {guide}
                                                </strong>
                                                <span className="mb-3 ms-2">Advice and Guidance</span>
                                            </div>
                                            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#FDE7EF' }}>
                                                <strong className="px-2 rounded-3 col-5 text-center" style={{ backgroundColor: '#EE3C7F' }}>
                                                    {numeral(0).format('0.0a')}
                                                </strong>
                                                <span className="mb-3 ms-2">Total points earned</span>
                                            </div>
                                        </section>
                                        {/* Score Distribution */}
                                        <section className="mb-2 text-center pt-2 rounded-3" style={{ height: 'auto', backgroundColor: '#F5F5F5' }}>
                                            <ServiceMindChartCaddie userId={data.uid} completedRound={completedRound} reviewData={reviewData} />
                                        </section>

                                        <section className="mb-2 text-center pt-2 rounded-3" style={{ height: 'auto', backgroundColor: '#F5F5F5' }}>
                                            <AdviceChartCaddie userId={data.uid} completedRound={completedRound} reviewData={reviewData} />
                                        </section>

                                        <section className="mb-2 text-center pt-2 rounded-3" style={{ height: 'auto', backgroundColor: '#F5F5F5' }}>
                                            <MonthlyScore userId={data.uid} reviewData={monthlyReviewData} />
                                        </section>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Popupchat />
            <Appfooter />

        </>
    );
}

export default CaddiePerf;