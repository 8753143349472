import React, { useState, useRef, useEffect } from 'react';

const ExpandableContent = ({ content, charLimit = 250, lineLimit = 5 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isContentLong, setIsContentLong] = useState(false);
    const contentRef = useRef(null);

    const toggleContent = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        // Check if the content exceeds character or line limit
        if (contentRef.current) {
            // Calculate number of lines in the container
            const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight, 10);
            const maxHeight = lineHeight * lineLimit;

            // Check if the content exceeds either the character limit or the line count limit
            const isLongByCharacter = content.length > charLimit;
            const isLongByLines = contentRef.current.scrollHeight > maxHeight;

            setIsContentLong(isLongByCharacter || isLongByLines);
        }
    }, [content, isExpanded]);

    const truncatedContent = content.length > charLimit ? content.substring(0, charLimit) + '...' : content;

    const formatContent = (text) => {
        return text
            .replace(/\n/g, '<br/>')
            .replace(/ {2,}/g, (match) => '&nbsp;'.repeat(match.length));
    };

    return (
        <div style={{ lineHeight: '20px' }}>
            <div
                ref={contentRef}
                style={{
                    maxHeight: isExpanded ? 'none' : `${lineLimit * 20}px`,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    transition: 'max-height 0.3s ease',
                }}
                dangerouslySetInnerHTML={{
                    __html: formatContent(isExpanded || !isContentLong ? content : truncatedContent)
                }}
            />
            {isContentLong && (
                <span
                    style={{ cursor: 'pointer', color: 'blue', whiteSpace: 'nowrap' }}
                    onClick={toggleContent}
                >
                    {isExpanded ? ' See less' : ' See more'}
                </span>
            )}
        </div>
    );
};


export default ExpandableContent;
