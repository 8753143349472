import React, { useState, useEffect } from 'react'

import { firestore, storage } from '../../../../components/firebase/FirebaseConfig';
import { ref, deleteObject } from 'firebase/storage';
import { getDocs, limit, startAfter, orderBy, or, deleteDoc, doc, collection, query, where, updateDoc, increment } from 'firebase/firestore';
import InfiniteScroll from 'react-infinite-scroll-component';
import Load from '../../../../components/Load';
import ExpandableContent from './ExpandableContent';
import Audience from './Audience';
import TimeAgo from './TimeConvert';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Dropdown, Modal } from 'react-bootstrap';
import clsx from 'clsx';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';

function FetchComment({ postId, scrollParent, postData, handleEditComment }) {
    const [items, setItems] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [viewImage, setViewImage] = useState(null);
    const [viewImageModal, setViewImageModal] = useState(false);
    const userData = useSelector((state) => state.user.data);
    const navigate = useNavigate();
    const [deletedList, setDeletedList] = useState([]);

    const seenKeys = new Set();

    useEffect(() => {
        const fetchTime = setTimeout(() => {
            fetchData();
        }, 100);
        return () => {
            clearTimeout(fetchTime)
        }
    }, []);

    async function getFriends(user) {
        if (!user.uid) {
            return false;
        }
        const listId = new Set();
        const q = query(
            collection(firestore, "friends"),
            or(
                where("friendId", "==", user.uid),
                where("userId", "==", user.uid)
            )
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            if (doc.data().status === 'active') {
                listId.add(doc.data().friendId);
                listId.add(doc.data().userId);
            }
        });

        const isFriend = listId.has(userData?.uid);
        return isFriend;
    }

    const fetchData = async () => {
        const collectionRef = collection(firestore, 'comments');
        let q = query(collectionRef, orderBy('timestamp', 'desc'), limit(5), where('postId', '==', postId));

        if (lastVisible) {
            q = query(collectionRef, orderBy('timestamp', 'desc'), startAfter(lastVisible), limit(5), where('postId', '==', postId));
        }

        const documentSnapshots = await getDocs(q);
        const itemsArray = documentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Collect all userIds from the fetched posts
        const userIds = itemsArray.map(item => item.userId);

        // Fetch userData for all userIds in parallel using where clause
        const userDataArray = await Promise.all(userIds.map(async userId => {
            const usersRef = collection(firestore, 'users');
            const querySnapshot = await getDocs(query(usersRef, where('uid', '==', userId)));

            if (!querySnapshot.empty) {
                return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];
            } else {
                return null; // Handle case where user data does not exist
            }
        }));

        // Merge userData with itemsArray
        const mergedData = itemsArray.map(item => {
            const userData = userDataArray.find(user => user && user.uid === item.userId);
            return {
                ...item,
                userData: userData || {}
            };
        });

        const filteredData = (await Promise.all(mergedData.map(async (data) => {
            const isFriends = await getFriends(data.userData);

            if (data.audience === 'public') {
                return data; // Include this item for public audience
            }

            if (data.audience === 'friends') {
                if (!isFriends && userData.uid !== data.userData.uid && postData.userId !== userData.uid) {
                    // Anonymize user data for non-friends
                    data.userData = {
                        ...data.userData,
                        firstName: 'Other',
                        lastName: 'User',
                        isPrivate: true,
                        profileImage: defaultProfile
                    };
                }
                return data; // Include this item for friends audience, anonymized if not friends
            }

            return null; // Exclude this item for other audiences
        }))).filter(item => item !== null);

        const updatedMergedData = mergedData.filter((data, index) => filteredData[index]);

        setItems(prevItems => [...prevItems, ...updatedMergedData]);

        const lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        setLastVisible(lastDoc);

        if (documentSnapshots.docs.length < 5) {
            setHasMore(false);
        }
    };

    const handleCommentImage = (image) => {
        setViewImage(image);
        setViewImageModal(true);
    }

    const handleProfileClick = (uid) => {

        if (!uid) return;

        if (uid === userData.uid) {
            navigate('/userPage')
        } else {
            navigate("/user", { state: { uid: uid } });
        }
    }

    const handleDeleteComment = async (commentData) => {

        const postDocRef = doc(firestore, "posts", postId);
        try {
            await deleteDoc(doc(firestore, "comments", commentData.commentId));
            if (commentData?.imageUrl) {
                const imageUrl = commentData.imageUrl;
                const filePath = decodeURIComponent(imageUrl.split("/o/")[1].split("?")[0]);
                const fileRef = ref(storage, filePath);
                deleteObject(fileRef);
            }
            await updateDoc(postDocRef, {
                commentsCount: increment(-1),
            });
            setDeletedList((prev) => [...prev, commentData.commentId])

        } catch (error) {
            console.error('Delete comment failed', error);
        }
    }


    return (
        <>
            {scrollParent?.current?.id ? (
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<Load />}
                    endMessage={<> </>}
                    scrollableTarget={scrollParent.current.id}
                    className='overflow-visible'
                >
                    {items.map((comment, index) => {
                        if (seenKeys.has(comment.commentId)) {
                            return null;
                        }
                        seenKeys.add(comment.commentId);
                        return (
                            <div key={comment.commentId} className={clsx('d-flex w-100 mb-2', {
                                'd-none': deletedList.includes(comment.commentId)
                            })}>
                                <Link to={comment?.userData?.uid !== userData.uid ? `/user?uid=${comment?.userData?.uid}` : `/userPage`} style={{ pointerEvents: comment?.userData?.isPrivate ? 'none' : 'auto' }}>
                                    <figure className="avatar me-3 commentor-img pointer" style={{ pointerEvents: comment?.userData?.isPrivate ? 'none' : 'auto' }}>
                                        <img
                                            src={comment?.userData?.profileImage || defaultProfile}
                                            alt="avatar"
                                            className="shadow-sm rounded-circle"
                                            onError={(e) => e.target.src = defaultProfile}
                                        />
                                    </figure>
                                </Link>
                                <div className='rounded-3 p-2 bg-light theme-light-bg w-100'>
                                    <div className='d-flex align-items-center justify-content-between position-relative'>
                                        <Link to={comment?.userData?.uid !== userData.uid ? `/user?uid=${comment?.userData?.uid}` : `/userPage`} style={{ pointerEvents: comment?.userData?.isPrivate ? 'none' : 'auto' }}>
                                            <span className='d-block pointer' style={{ pointerEvents: comment?.userData?.isPrivate ? 'none' : 'auto' }}>
                                                <h5 className='fw-600 m-0'>
                                                    {comment?.userData?.firstName || comment?.userData?.firstname || comment?.userData?.lastName || comment?.userData?.lastname
                                                        ? `${comment?.userData?.firstName || comment?.userData?.firstname || ''} ${comment?.userData?.lastName || comment?.userData?.lastname || ''}`.trim()
                                                        : 'Invalid User'}
                                                </h5>
                                            </span>
                                        </Link>
                                        <div className='position-absolute' style={{ top: 0, right: 0 }}>
                                            <Dropdown>
                                                <Dropdown.Toggle className='p-0 m-0 bg-transparent border-0'>
                                                    <i className=' bi bi-three-dots pointer text-black fs-4' ></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='p-0 rounded-3' style={{ maxWidth: 100, minWidth: 100 }}>
                                                    <Dropdown.Item className='w100 p-1'
                                                        disabled={comment.userId === userData.uid ? false : true}
                                                        onClick={() => handleEditComment(comment)}>
                                                        <i className='ms-1 me-2 bi bi-pencil-fill'></i>
                                                        Edit
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className='w100 p-1'
                                                        disabled={comment.userId === userData.uid || userData.uid === postData.userId ? false : true}
                                                        onClick={() => handleDeleteComment(comment)}>
                                                        <i className='ms-1 me-2 bi bi-trash-fill'></i>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>

                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <TimeAgo timestamp={comment?.timestamp?.seconds} />
                                        <Audience privacy={comment?.audience} />
                                        {comment.lastUpdate ? <i className='bi bi-pencil ms-1 fs-6 text-muted'></i> : null}
                                    </div>
                                    <span className='d-block'><h6><ExpandableContent content={comment.content} /></h6></span>
                                    {comment.imageUrl && (
                                        <img src={comment.imageUrl} alt={comment.commentId} className='w100 d-block' onClick={() => handleCommentImage(comment.imageUrl)} style={{ cursor: 'pointer' }} />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </InfiniteScroll>
            ) : (
                <Load />
            )}
            <Modal show={viewImageModal} onHide={() => setViewImageModal(false)} centered className="preview-comment-image-modal">
                <Modal.Body className='preview-comment-image-body'>
                    <img src={viewImage} alt="viewImage" className='comment-image-preview' />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FetchComment