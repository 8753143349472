import React, { useRef, useEffect, useState, useMemo } from 'react'
import { firestore } from '../../../components/firebase/FirebaseConfig'
import { getDocs, query, where, collection } from 'firebase/firestore'
import { Carousel } from 'react-bootstrap'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ViewCaddieAbout = ({ data }) => {
    const [caddieDesc, setCaddieDesc] = useState(null);
    const userData = useSelector((state) => state.user.data);

    useEffect(() => {

        const getCaddierofile = async (getCaddieDescPic) => {
            try {
                const q = query(collection(firestore, 'caddieProfiles'), where("caddieId", "==", data.uid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docRef = querySnapshot.docs[0].ref;

                    const caddieDesc = querySnapshot.docs[0].data();
                    const caddieDescPic = await getCaddieDescPic(docRef);

                    const caddieDescData = {
                        uid: caddieDesc.caddieId,
                        description: caddieDesc.description,
                        image: caddieDescPic
                    }
                    setCaddieDesc(caddieDescData);
                } else {
                    console.warn('No profile found');
                }
            } catch (error) {
                console.error('Error retrieving caddie profile:', error);
            }
        }

        const getCaddieDescPic = async (docRef) => {
            try {
                // Ensure docRef exists
                if (!docRef) {
                    console.warn('Document reference does not exist.');
                    return null;
                }

                const imageCollectionRef = collection(docRef, 'image');
                const imageSnapshot = await getDocs(imageCollectionRef);

                // Check if the collection exists and contains documents
                if (!imageSnapshot.empty) {
                    const imageList = [];
                    imageSnapshot.forEach((doc) => {
                        imageList.push(doc.data());
                    });
                    return imageList;
                } else {
                    console.warn('No images found in the image collection.');
                    return null;
                }
            } catch (error) {
                console.error('Error retrieving caddie profile pictures:', error);
                return null;
            }
        }

        if (data) {
            getCaddierofile(getCaddieDescPic)
        }
    }, [data])

    // console.log(caddieDesc);

    const handleRenderCaddiePic = useMemo(() => {
        if (caddieDesc && Array.isArray(caddieDesc.image) && caddieDesc.image.length > 0) {

            const orderedImages = caddieDesc.image.sort((a, b) => a.order - b.order);
            // console.log(orderedImages);

            return (
                <PhotoProvider>
                    <Carousel touch={true}>
                        {userData?.uid === data.uid ? (
                            <Carousel.Item key={0} interval={3000}>
                                <PhotoView src={userData?.profileImage}>
                                    <img src={userData?.profileImage} alt='0' height={190} style={{ objectFit: 'cover' }} className='w-100 rounded-3' />
                                </PhotoView>
                            </Carousel.Item>
                        ) : (
                            <Carousel.Item key={0} interval={3000}>
                                <PhotoView src={data.profileImage}>
                                    <img src={data.profileImage} alt='0' height={190} style={{ objectFit: 'cover' }} className='w-100 rounded-3' />
                                </PhotoView>
                            </Carousel.Item>
                        )}
                        {orderedImages.map((image, index) => {
                            if (!image.imageUrl) return null;
                            return (
                                <Carousel.Item key={index} interval={3000}>
                                    <PhotoView src={image.imageUrl}>
                                        <img src={image.imageUrl} alt={index} height={190} style={{ objectFit: 'cover' }} className='w-100 rounded-3' />
                                    </PhotoView>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </PhotoProvider>
            )
        }
    }, [caddieDesc])

    return (
        <>
            <div className="d-flex justify-content-between"><h4 className="fw-600">About</h4>
                {userData?.uid === data?.uid && <Link to="/edit-caddie-about" state={{ userData: data, descriptionData: caddieDesc }}>Edit</Link>}
            </div>

            {handleRenderCaddiePic}
            <h5 className='fw-bold mt-2'>Intro</h5>
            <div className='bg-light theme-dark-bg about-div right-scroll-bar' style={{ maxHeight: 150, minHeight: 70 }}>
                <h5 className="m-0"
                    dangerouslySetInnerHTML={{
                        __html: caddieDesc?.description
                            .replace(/\n/g, '<br/>')
                            .replace(/ {2,}/g, (match) => '&nbsp;'.repeat(match.length))
                    }}>
                </h5>
            </div>
        </>
    )
}

export default ViewCaddieAbout