import { ReactSVG } from "react-svg";

export default function Audience({ privacy }) {

    switch (privacy) {
        case 'public':
            return (
                <ReactSVG src='assets/images/dooNineIconV2/others/post-Public.svg' className="ms-1 privacy-icon" />
            );
        case 'friends':
            return (
                <ReactSVG src='assets/images/dooNineIconV2/others/post-Friend.svg' className="ms-1 privacy-icon" />
            );
        case 'only_me':
            return (
                <ReactSVG src='assets/images/dooNineIconV2/others/post-Private.svg' className="ms-1 privacy-icon" />
            );

        default:
            return (
                <ReactSVG src='assets/images/dooNineIconV2/others/post-Public.svg' className="ms-1 privacy-icon" />
            );
    }
}