import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg'

import { firestore } from '../../../components/firebase/FirebaseConfig';
import { collection, setDoc, serverTimestamp, runTransaction, doc, where, query, getDocs, deleteDoc } from 'firebase/firestore';

import { Modal } from 'react-bootstrap';

import FetchLike from './utilsfunction/FetchLike';

import styles from './likebtnstyle.module.scss';

function Like({ postData, handleLikeTrigger, isPost }) {

  const [isLike, setIsLike] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [likeListModal, setLikeListModal] = useState(false);
  const likeDataRef = useRef([]);
  const scrollParent = useRef(null);

  const userData = useSelector((state) => state.user.data);

  const isDark = localStorage.getItem('theme') === 'theme-dark' ? true : false;

  async function getLikeData() {
    try {
      const q = query(collection(firestore, "likes"), where('postId', '==', postData.postId));
      const querySnapshot = await getDocs(q);
      const likeData = querySnapshot.docs.map(doc => doc.data());
      likeDataRef.current = likeData;
      setLikesCount(likeData.length);

      const found = likeData.find((isLike) => isLike.userId === userData.uid);
      if (found) {
        setIsLike(true);
      } else {
        setIsLike(false);
      }

    } catch (error) {
      console.error("Error getting like data: ", error);
    }
  }

  async function updateLikesCount(isLike) {
    const postRef = doc(firestore, 'posts', postData.postId);

    await runTransaction(firestore, async transaction => {
      const postDoc = await transaction.get(postRef);

      if (!postDoc.exists()) {
        throw new Error('Post does not exist!');
      }
      const currentLikesCount = postDoc.data().likesCount || 0;
      transaction.update(postRef, {
        likesCount: isLike ? currentLikesCount + 1 : currentLikesCount - 1
      });
    });
  }

  async function addLike() {
    const likesRef = collection(firestore, "likes");

    // Query to check if the like already exists
    const q = query(likesRef,
      where("postId", "==", postData.postId),
      where("userId", "==", userData.uid));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      // If no such like exists, proceed to add a new one
      const docRef = doc(likesRef);

      await setDoc(docRef, {
        likeId: docRef.id,
        postId: postData.postId,
        userId: userData.uid,
        timestamp: serverTimestamp()
      });
    }
  }

  async function deleteLike() {
    const q = query(collection(firestore, "likes"), where("postId", "==", postData.postId), where("userId", "==", userData.uid));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });
  }

  useEffect(() => {
    if (postData) {
      getLikeData();
    }
  }, [postData]);


  const handleUnlike = async () => {
    setIsLike(false);
    setLikesCount((prevLikeCount) => prevLikeCount - 1);
    await deleteLike();
    await updateLikesCount(false);
    if (!isPost) {
      handleLikeTrigger();
    }
    // await getLikeData();
  }
  const handleLike = async () => {
    setIsLike(true);
    setLikesCount((prevLikeCount) => prevLikeCount + 1);
    await addLike();
    await updateLikesCount(true);
    if (!isPost) {
      handleLikeTrigger();
    }
    // await getLikeData();
  }

  return (
    <>
      {isLike ? (
        <>
          <ReactSVG src='assets/images/dooNineIconV2/others/post-Like.svg' style={{ zIndex: 1 }} className='w35 pointer like-btn' onClick={handleUnlike} />
        </>
      ) : (
        <>
          <ReactSVG src='assets/images/dooNineIconV2/others/post-Unlike.svg' style={{ zIndex: 1 }} className={`w35 pointer unlikebtn ${isDark ? styles.unlikebtn : ""}`} onClick={handleLike} />
        </>
      )}
      <span onClick={() => setLikeListModal(true)}><h5 className='m-0 ms-1 pointer'>{likesCount || 0}</h5></span>

      <Modal show={likeListModal} onHide={() => setLikeListModal(false)} centered>
        <Modal.Header className='justify-content-between'>
          <Modal.Title>{likesCount} {likesCount > 1 ? 'Likes' : 'Like'}</Modal.Title>
          <ReactSVG src='assets/images/dooNineIconV2/others/post-close.svg' className='dooNine-close-button' onClick={() => setLikeListModal(false)} />
        </Modal.Header>
        <Modal.Body ref={scrollParent} id='like-body' style={{ overflowY: 'auto', height: '60vh' }}>
          <FetchLike
            scrollParent={scrollParent}
            postData={postData} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Like