import React, { useRef, useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import CourseDetailCourseHoleDetail from "./CourseDetailCourseHoleDetail";

function CourseDetailCourseBody({ courseData }) {
  const [courseHoles, setCourseHoles] = useState([]);
  const [selectCourseHole, setSelectCourseHole] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let array = [];
      const q = query(
        collection(firestore, "courseHoles"),
        where("courseId", "==", courseData.courseId),
        where("status", "==", "inService")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        array.push({ id: doc.id, ...doc.data() });
      });
      setCourseHoles(array);
    }
    fetchData();
  }, [courseData]);

  console.log(courseHoles);
  console.log(selectCourseHole);

  return (
    <>
      {selectCourseHole ? (
        <>
          <CourseDetailCourseHoleDetail
            selectCourseHole={selectCourseHole}
            setSelectCourseHole={setSelectCourseHole}
          ></CourseDetailCourseHoleDetail>
        </>
      ) : (
        <>
          {courseHoles && courseHoles.length > 0 ? (
            <div className="row ps-2 pe-2">
              {courseHoles.map((hole) => (
                <div
                  className="col-md-6 col-sm-6 pe-2 ps-2"
                  key={hole.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectCourseHole(hole);
                  }}
                >
                  <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                    <div className="card-body d-flex p-4">
                      <div className="flex-grow-1">
                        <h4 className="fw-600 mb-1">{hole.courseHoleName}</h4>
                        <div className="d-flex flex-wrap">
                          <h6 className="text-muted d-inline-block z-index-1 ls-3">
                            {hole.numberOfHole == "9holes" ? "9" : "18"} Holes
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="card d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss">
              <p className="font-xsss fw-500 lh-28 text-grey-600 mb-0 ps-2 text-center">
                This section is currently not available.
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CourseDetailCourseBody;
