import styles from '../../scorestyle.module.scss'

export function backgroundCalculation(scoreDifference) {
    let bg = ""
    if (scoreDifference === 0) {
        bg = 'par'; // Equal to par
    } else if (scoreDifference === 1) {
        bg = 'bogey'; // 1 over par
    } else if (scoreDifference === 2) {
        bg = 'double'; // 2 over par (double bogey)
    } else if (scoreDifference === 3) {
        bg = 'triple'; // 3 over par (triple bogey)
    } else if (scoreDifference < 0) {
        // Eagle or Birdie depending on the score difference
        bg = scoreDifference === -1 ? 'birdie' : 'eagle'; // -1 for birdie, -2 for eagle
    } else if (scoreDifference > 3) {
        // More than a triple bogey
        bg = 'triple';
    } else {
        bg = ""
    }

    return bg;
}

export function getScorecardClassName(bg) {
    if (!bg) {
        return styles.scorecard + ' ' + styles['scorecard--nobg'];
    }

    const classMap = {
        eagle: styles['scorecard--eaglebg'],
        birdie: styles['scorecard--birdiebg'],
        par: styles['scorecard--parbg'],
        bogey: styles['scorecard--bogeybg'],
        double: styles['scorecard--doublebg'],
        triple: styles['scorecard--triplebg'],
    };

    const bgClass = classMap[bg] || ''; // Fallback to empty string if bg does not match any key.

    return `${styles.scorecard} ${bgClass}`;
}

export function isGreenInRegulation(par, strokes, putts) {
    // Calculate the strokes taken to reach the green (excluding putts)
    const nonPuttStrokes = strokes - putts;

    // Define a helper function to determine the regulation strokes based on the par of the hole
    const getRegulationStrokes = (par) => {
        switch (par) {
            case 3:
                return 1; // Par 3: green in 1 stroke
            case 4:
                return 2; // Par 4: green in 2 strokes
            case 5:
                return 3; // Par 5: green in 3 strokes
            default:
                return null; // Invalid par value
        }
    }

    // Get the regulation strokes for the given par
    const regulationStrokes = getRegulationStrokes(par);

    // Check if the par value is valid
    if (regulationStrokes === null) {
        return false; // Invalid par value, return false
    }

    // Check if the player reached the green in regulation (excluding putts)
    return nonPuttStrokes <= regulationStrokes;
}

export function stablefordCalculation(scoreDifference) {
    // console.log("Score Difference:", scoreDifference);
    let points;

    switch (scoreDifference) {
        case -3:
            points = 5;
            break;
        case -2:
            points = 4;
            break;
        case -1:
            points = 3;
            break;
        case 0:
            points = 2;
            break;
        case 1:
            points = 1;
            break;
        default:
            // For cases where scoreDifference is greater than 1 (double bogey or worse)
            if (scoreDifference > 1) {
                points = 0;
            } else {
                points = "-";
                console.error("Invalid score difference:", scoreDifference);
            }
            break;
    }

    // console.log("Stableford Points:", points);
    return points;
}

