import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { denyRound } from "../module/denyRound";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactSVG } from "react-svg";
import clsx from "clsx";
import ReactDOM from "react-dom";
import { deleteRoundForCreator } from "../module/deleteRound";
import { updateStartRound } from "../module/startRound";
import Spinner from "react-bootstrap/Spinner";

function AllRound() {
  const userData = useSelector((state) => state.user.data);
  const [listRound, setListRound] = useState([]);
  const [listRoundStart, setListRoundStart] = useState([]);
  const [loading, setLoading] = useState(true);
  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roundDataArray = [];

        const fetchScoreDetails = async (roundId, playerUids) => {
          if (playerUids.length === 0) return [];
          const scoreQuery = query(
            collection(firestore, "scoreCards"),
            where("roundId", "==", roundId),
            where("userId", "in", playerUids)
          );
          const scoreSnapshot = await getDocs(scoreQuery);
          return scoreSnapshot.docs.map((doc) => doc.data());
        };

        const userQuery = query(
          collection(firestore, "round"),
          where("teeTime", "==", "now")
        );
        const userSnapshot = await getDocs(userQuery);

        const roundPromises = userSnapshot.docs.map(async (roundDoc) => {
          const roundData = roundDoc.data();
          const isCreator = roundData.creator === userData?.uid;
          const isUserInList = roundData.userList.some(
            (user) =>
              (user.player?.uid === userData?.uid &&
                user.player?.isJoin === true) ||
              (user.caddie?.uid === userData?.uid &&
                user.caddie?.isJoin === true)
          );
          if (isCreator || isUserInList) {
            const playerUids = roundData.userList
              .filter(
                (user) =>
                  user.player?.uid === userData.uid ||
                  user.caddie?.uid === userData.uid // Check for matching uids in both player and caddie
              )
              .map((user) => user.player?.uid || user.caddie?.uid);
            if (playerUids.length > 0) {
              const scoreDetailsArray = await fetchScoreDetails(
                roundData.roundId,
                playerUids
              );

              roundData.userList = roundData.userList.map((user) => {
                const playerScore = scoreDetailsArray.find(
                  (details) => details.userId === user.player?.uid
                );
                const status = playerScore ? playerScore.status : undefined;
                return {
                  ...user,
                  player: { ...user.player, status },
                  caddie: { ...user.caddie, status },
                };
              });

              const isRoundStatus = roundData.userList.some(
                (user) =>
                  (user.player?.uid === userData.uid && (user.player?.status !== "finished" && user.player?.status !== "unfinished") && user.player?.status == undefined) ||
                  (user.caddie?.uid === userData.uid && (user.caddie?.status !== "finished" && user.caddie?.status !== "unfinished") && user.caddie?.status == undefined)
              );

              if (isRoundStatus) {
                roundDataArray.push({
                  ...roundData,
                  roundId: roundDoc.id,
                });
              }
            }
          }
        });

        await Promise.all(roundPromises); // Run all round processing in parallel

        setListRoundStart(roundDataArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userData]);

  useEffect(() => {
    if (!userData?.uid) return;
    setLoading(true);

    const fetchUserDetails = async (uid, userCache) => {
      if (userCache[uid]) return userCache[uid];

      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", uid)
      );
      const userSnapshot = await getDocs(userQuery);
      let userDetails = {};
      userSnapshot.forEach((userDoc) => {
        userDetails = userDoc.data();
      });
      userCache[uid] = userDetails;
      return userDetails;
    };

    const fetchScoreDetails = async (uid, roundId) => {
      const q = query(
        collection(firestore, "scoreCards"),
        where("userId", "==", uid),
        where("roundId", "==", roundId)
      );
      const userSnapshot = await getDocs(q); // Still using getDocs here for nested async data fetching
      let scoreDetails = {};
      userSnapshot.forEach((scoreDoc) => {
        scoreDetails = scoreDoc.data();
      });
      return scoreDetails;
    };

    const fetchRoundActivity = async () => {
      const roundActivityQuery = query(
        collection(firestore, "roundActivity"),
        where("userList", "array-contains", userData.uid),
        where("creator", "!=", userData.uid),
        orderBy("created", "desc")
      );

      const roundActivityQuery2 = query(
        collection(firestore, "roundActivity"),
        where("creator", "==", userData.uid),
        orderBy("created", "desc")
      );

      const roundActivitySnapshot = await getDocs(roundActivityQuery);
      const roundActivitySnapshot2 = await getDocs(roundActivityQuery2);

      if (roundActivitySnapshot.empty) return setListRound([]);

      const rounds = roundActivitySnapshot.docs.map((doc) => ({
        ...doc.data(),
        sortType: "request",
      }));

      const rounds2 = roundActivitySnapshot2.docs.map((doc) => ({
        ...doc.data(),
        sortType: "create",
      }));

      const combinedRounds = [...rounds, ...rounds2];

      const dataArray = [];
      const userCache = {};

      for (const roundActivity of combinedRounds) {
        const { roundId } = roundActivity;
        const { sortType } = roundActivity;

        const roundQuery = query(
          collection(firestore, "round"),
          where("roundId", "==", roundId),
          orderBy("created", "desc"),
          where("teeTime", "!=", "finished")
        );

        const roundSnapshot = await getDocs(roundQuery);
        if (roundSnapshot.empty) continue;

        const roundDoc = roundSnapshot.docs[0];
        const round = roundDoc.data();

        if (round.scheduleTime) {
          const courseHolesRef = doc(
            firestore,
            "courseHoles",
            round.holesCourse
          );
          const [courseHolesSnapshot, courseSnapshot] = await Promise.all([
            getDoc(courseHolesRef),
            getDocs(
              query(
                collection(firestore, "courses"),
                where("id", "==", round.course)
              )
            ),
          ]);

          const holesCourse = courseHolesSnapshot.exists()
            ? courseHolesSnapshot.data().courseHoleName
            : "";

          const { time, day, month } = epochToDateTime(round.scheduleTime);

          const userFetches = round.userList.map(async ({ player, caddie }) => {
            const userDataArray = [];
            if (player?.uid) {
              const playerData = await fetchUserDetails(player.uid, userCache);
              userDataArray.push({
                ...playerData,
                isCreator: player.isCreator,
                isJoin: player.isJoin,
              });
            }
            if (caddie?.uid) {
              const caddieData = await fetchUserDetails(caddie.uid, userCache);
              userDataArray.push({
                ...caddieData,
                isCreator: caddie.isCreator,
                isJoin: caddie.isJoin,
              });
            }
            return userDataArray;
          });

          const userDataArray = await Promise.all(userFetches);

          const playerUids = round.userList
            .map((user) => user.player?.uid)
            .filter((uid) => uid !== undefined);

          if (playerUids.length > 0) {
            const scoreDetailsArray = await Promise.all(
              playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
            );

            round.userList = round.userList.map((user) => {
              if (playerUids.includes(user.player?.uid)) {
                const playerScore = scoreDetailsArray.find(
                  (details) => details.userId === user.player?.uid || details.userId === user.caddie?.uid
                );
                const status = playerScore ? playerScore.status : undefined;
                return {
                  ...user,
                  player: { ...user.player, status },
                  caddie: { ...user.caddie, status },
                };
              }
              return user;
            });

            const isRoundStatus = round.userList.some(
              (user) =>
                ((user.player?.uid === userData.uid) && (user.player?.status === "playing" || user.player?.status === undefined)) ||
                (user.caddie?.uid === userData.uid &&
                  user.caddie?.status === "playing" || user.caddie?.status === undefined)
            );
            courseSnapshot.forEach((courseDoc) => {
              const courseData = courseDoc.data();
              if (
                isRoundStatus
                
              ) {
                dataArray.push({
                  ...round,
                  courseInfo: courseData,
                  time,
                  day,
                  month,
                  userData: userDataArray.flat(),
                  holesCourse,
                  sortType: sortType,
                });
              }
            });
          }
        }
      }

      setListRound(dataArray);
      setLoading(false);
    };

    fetchRoundActivity();
  }, [userData]);
console.log(listRound)
  const handleJoinClick = (roundId) => {
    if (listRoundStart.length > 0) {
      Swal.fire({
        title: "Round In Progress",
        text: "You still have a round in progress. Please finish that round first.",
        icon: "warning",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    } else {
      navigate(`/join-round?id=${roundId}`);
    }
  };

  const handleDeny = async (roundId, userId) => {
    Swal.fire({
      title: "Deny this round?",
      html: `
        <h5 class="fw-600">Are you sure you want to deny this round?</h5>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await denyRound(roundId, userId);
          console.log("Round denied successfully");
          Swal.fire("Denied!", "The round has been denied.", "success");
        } catch (error) {
          console.error("Error denying round:", error);
          Swal.fire("Error!", "There was an issue denying the round.", "error");
        }
      } else {
        console.log("Round denial cancelled");
      }
    });
  };

  const handleDeleteRound = async (roundId) => {
    try {
      await deleteRoundForCreator(roundId);
    } catch (error) {
      console.error("Error deleted round:", error);
    }
  };

  const handleUpdateStartRound = async (roundId) => {
    try {
      if (listRoundStart.length > 0) {
        Swal.fire({
          title: "Round In Progress",
          text: "You still have a round in progress. Please finish that round first.",
          icon: "warning",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      } else {
        await updateStartRound(roundId);
      }
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  const handleLinkToCourse = (courseId) => {
    navigate(`/course-detail?courseId=${courseId}`);
  };

  const isSameDay = (timestamp) => {
    const scheduledDate = new Date(timestamp * 1000); // Convert to milliseconds
    const currentDate = new Date();

    return (
      scheduledDate.getFullYear() < currentDate.getFullYear() ||
      (scheduledDate.getFullYear() === currentDate.getFullYear() &&
        (scheduledDate.getMonth() < currentDate.getMonth() ||
          (scheduledDate.getMonth() === currentDate.getMonth() &&
            scheduledDate.getDate() <= currentDate.getDate())))
    );
  };

  const handleEndRound = async (roundId) => {
    Swal.fire({
      title: "End this round?",
      html: `
        <h5 class="fw-600">Are you sure you want to end this round?</h5>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(`/finish-round?round=${roundId}`);
      }
    });
  };

  const isCreator = (round, userData) =>
    round.userData.some((user) => user.uid === userData.uid && user.isCreator);

  const renderDropdownItems = (round, userData, isRoundStarted) => {
    const commonItems = (
      <>
        <Dropdown.Item href={`/leader-board?round=${round.roundId}`}>
          Leader Board
        </Dropdown.Item>
        <Dropdown.Item href="#/game-mode">Game mode</Dropdown.Item>
        <Dropdown.Item href="#/competitor">Competitor</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#/player-caddie">Player & Caddie</Dropdown.Item>
        <Dropdown.Item onClick={() => handleLinkToCourse(round.course)}>
          Course
        </Dropdown.Item>
      </>
    );

    if (isRoundStarted) {
      return (
        <>
          <Dropdown.Item href={`/score?round=${round.roundId}`}>
            Resume Round
          </Dropdown.Item>
          {commonItems}
          <Dropdown.Divider />
          <Dropdown.Item disabled>Edit Round</Dropdown.Item>
          <Dropdown.Item disabled>Invitation Link</Dropdown.Item>
          <Dropdown.Item
            disabled={!isCreator(round, userData)}
            onClick={() => handleEndRound(round.roundId)}
          >
            End Round
          </Dropdown.Item>
        </>
      );
    }

    return (
      <>
        <Dropdown.Item
          disabled={
            !isSameDay(round.scheduleTime) || !isCreator(round, userData)
          }
          onClick={() => handleUpdateStartRound(round.roundId)}
        >
          Start Round
        </Dropdown.Item>
        {commonItems}
        <Dropdown.Divider />
        <Dropdown.Item
          disabled={!isCreator(round, userData)}
          href={`/create-round?edit=${round.roundId}`}
        >
          Edit Round
        </Dropdown.Item>
        <Dropdown.Item disabled={!isCreator(round, userData)}>
          Invitation Link
        </Dropdown.Item>
        {isCreator(round, userData) ? (
          <Dropdown.Item onClick={() => handleDeleteRound(round.roundId)}>
            Delete Round
          </Dropdown.Item>
        ) : (
          <Dropdown.Item href="#/leave-round">Leave Round</Dropdown.Item>
        )}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        listRound
          .sort((a, b) => {
            return new Date(b.scheduleTime) - new Date(a.scheduleTime);
          })
          .map((round, index) => (
            <div key={index} className="create-round-req-main">
              <section className="create-round-req-head">
                <div className="pointer">
                  {round.userData.some(
                    (user) =>
                      user.uid === userData.uid && user.isCreator === true
                  ) ? (
                    <a href={`/create-round?edit=${round.roundId}`}>
                      <h4 className="fw-600">{round.roundName}</h4>
                      <br />
                      <h6 className="fw-600 text-muted">
                        {`${round.holes} (${round.holesCourse}), ${round.gameMode} (${round.scoring})`}
                      </h6>
                    </a>
                  ) : (
                    <div>
                      <h4 className="fw-600">{round.roundName}</h4>
                      <br />
                      <h6 className="fw-600 text-muted">
                        {`${round.holes} (${round.holesCourse}), ${round.gameMode} (${round.scoring})`}
                      </h6>
                    </div>
                  )}
                </div>

                <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                  <ReactSVG
                    src="assets/images/dooNineIconV2/chat-SL.svg"
                    wrapper="svg"
                    style={{ height: 40, width: 40 }}
                    className={clsx("pointer", {
                      "d-none": round.groupChat !== "create",
                    })}
                  />
                  {round.userData &&
                    round.userData.some(
                      (user) =>
                        user.uid === userData.uid && user.isJoin === true
                    ) && (
                      <Dropdown className="drodownFriend" drop="down">
                        <Dropdown.Toggle
                          as="span" // Use a span element for the wrapper
                          id="dropdown-basic"
                          style={{ cursor: "pointer", zIndex: 1000 }}
                        >
                          <ReactSVG
                            src="assets/images/dooNineIconV3/create-round/createRound-menu.svg"
                            wrapper="svg"
                            className="wizrd-more-btn"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomDropdown}>
                          {round.teeTime === "now" &&
                            renderDropdownItems(round, userData, true)}
                          {round.teeTime === "schedule" &&
                            renderDropdownItems(round, userData, false)}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </div>
              </section>
              <section
                className="create-round-req-body p-2 mb-2 pointer"
                //   onClick={() => handleChatandDateClick()}
                style={{
                  backgroundImage: round.coverImage
                    ? typeof round.coverImage === "string"
                      ? `url(${round.coverImage})`
                      : `url(${URL.createObjectURL(round.coverImage)})`
                    : "none",
                  height: round.coverImage ? "" : "inherit",
                }}
              >
                <div
                  className="req-course-data px-0 row"
                  style={{
                    backgroundColor: round.coverImage ? "" : "#fff",
                  }}
                >
                  <div
                    className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center"
                    onClick={() => handleLinkToCourse(round.course)}
                  >
                    <img
                      src={round.courseInfo.profileImage}
                      alt="course-profile"
                      width={70}
                      height={70}
                      className="rounded-circle my-2 d-sm-block d-none"
                    />
                    {/* mobile */}
                    <img
                      src={round.courseInfo.profileImage}
                      alt="course-profile"
                      width={50}
                      height={50}
                      className="rounded-circle my-2 d-sm-none d-block"
                    />
                  </div>
                  <div
                    className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center"
                    onClick={() => handleLinkToCourse(round.course)}
                  >
                    <div>
                      {/* desktop */}
                      <span className="fw-600 fs-4 d-sm-block d-none">
                        {round.courseInfo.courseName}
                      </span>
                      {/* mobile */}
                      <span className="fw-600 fs-5 d-sm-none d-block">
                        {round.courseInfo.courseName}
                      </span>
                      {/* mobile */}
                      <span className="fw-600 fs-5 d-sm-none d-block">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>

                      <span className="fw-600 fs-6 d-sm-block d-none">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                    </div>
                  </div>
                  <div className="col-sm-2 col-3 px-0 px-sm-20">
                    <div
                      className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                      style={{
                        backgroundColor:
                          round.teeTime === "schedule" ? "#E59E49" : "#81D77A",
                      }}
                      // onClick={() => handleChatandDateClick()}
                    >
                      {round.teeTime === "schedule" ? (
                        <>
                          <h4 className="fw-600 text-white text-center">
                            {round.month}
                          </h4>
                          <h1 className="fw-700 text-white text-center">
                            {round.day}
                          </h1>
                          <div className="bg-white text-center w-100">
                            <span
                              className="fw-600 fs-4"
                              style={{ color: "#E59E49" }}
                            >
                              {round.time}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <h4 className="fw-600 text-white text-center">
                            {round.month}
                          </h4>
                          <h1 className="fw-700 text-white text-center">
                            {round.day}
                          </h1>
                          <div className="bg-white text-center w-100">
                            <span
                              className="fw-600 fs-4"
                              style={{ color: "#81D77A" }}
                            >
                              {round.time}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <section>
                {/* desktop display 7 limit */}
                <div className="d-none d-sm-flex align-items-center justify-content-between p-3 pointer">
                  {/* Left side: User profile images */}
                  {round.teeTime === "now" &&
                  round.userData.some(
                    (user) => user.uid === userData.uid && user.isJoin === true
                  ) ? (
                    <Link to={`/leader-board?round=${round.roundId}`}>
                      <div className="d-flex align-items-center">
                        {round.userData
                          .slice(0, maxUserList.desktop)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline me-4"
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline me-4"
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.desktop ? (
                          <div
                            className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                            style={{
                              width: 50,
                              height: 50,
                              outline: "1px dashed grey",
                              opacity: 0.6,
                            }}
                          >
                            <h6 className="text-center">
                              +{round.userData.length - maxUserList.desktop}{" "}
                              more
                            </h6>
                          </div>
                        ) : null}
                      </div>
                    </Link>
                  ) : (
                    <div className="d-flex align-items-center">
                      {round.userData
                        .slice(0, maxUserList.desktop)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={50}
                                  height={50}
                                  className="rounded-circle player-color-outline me-4"
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={50}
                                  height={50}
                                  className="rounded-circle caddie-color-outline me-4"
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={50}
                                  height={50}
                                  className="rounded-circle player-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.desktop - idx,
                                  }}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={50}
                                  height={50}
                                  className="rounded-circle caddie-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.desktop - idx,
                                  }}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.desktop ? (
                        <div
                          className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                          style={{
                            width: 50,
                            height: 50,
                            outline: "1px dashed grey",
                            opacity: 0.6,
                          }}
                        >
                          <h6 className="text-center">
                            +{round.userData.length - maxUserList.desktop} more
                          </h6>
                        </div>
                      ) : null}
                    </div>
                  )}

                  {/* Right side: Button */}
                  <div>
                    {round.sortType === "create" &&
                      (round.teeTime === "schedule" ? (
                        isSameDay(round.scheduleTime) && (
                          <button
                            className={clsx(
                              "p-2 lh-20 w100 me-2 text-center font-xssss fw-600 ls-1 rounded-xl border border-0",
                              {
                                "bg-current text-white":
                                  round.creator === userData.uid, // Active button style
                                "bg-light text-muted":
                                  round.creator !== userData.uid, // Disabled button style
                              }
                            )}
                            onClick={() =>
                              handleUpdateStartRound(round.roundId)
                            }
                            disabled={round.creator !== userData.uid} // Disable button if user is not the creator
                          >
                            Start
                          </button>
                        )
                      ) : (
                        <Link to={`/score?round=${round.roundId}`}>
                          <div className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                            Resume
                          </div>
                        </Link>
                      ))}

                    <div className="d-flex justify-content-end">
                      {/* Conditionally render Resume, Join, and Deny buttons */}
                      {round.sortType === "request" &&
                        (round.userData.some(
                          (user) => user.uid === userData.uid && user.isJoin
                        ) ? (
                          <Link to={`/score?round=${round.roundId}`}>
                            <div className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                              Resume
                            </div>
                          </Link>
                        ) : (
                          <>
                            <button
                              className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                              onClick={() => handleJoinClick(round.roundId)}
                            >
                              Join
                            </button>
                            <button
                              className="p-2 lh-20 w100 text-center text-black font-xssss fw-600 ls-1 rounded-xl border border-0"
                              style={{ background: "#f5f5f5" }}
                              onClick={() =>
                                handleDeny(round.roundId, userData.uid)
                              }
                            >
                              Deny
                            </button>
                          </>
                        ))}
                    </div>
                  </div>
                </div>

                {/* mobile display 5 limit */}
                <div className="d-sm-none d-flex align-items-center justify-content-between p-3 pointer">
                  {/* Left side: User profile images */}
                  {round.teeTime === "now" &&
                  round.userData.some(
                    (user) => user.uid === userData.uid && user.isJoin === true
                  ) ? (
                    <Link to={`/leader-board?round=${round.roundId}`}>
                      <div className="d-flex align-items-center">
                        {round.userData
                          .slice(0, maxUserList.mobile)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline me-4"
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline me-4"
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.mobile ? (
                          <div
                            className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                            style={{
                              width: 40,
                              height: 40,
                              outline: "1px dashed grey",
                            }}
                          >
                            <h6 className="text-center">
                              +{round.userData.length - maxUserList.mobile} more
                            </h6>
                          </div>
                        ) : null}
                      </div>
                    </Link>
                  ) : (
                    <div className="d-flex align-items-center">
                      {round.userData
                        .slice(0, maxUserList.mobile)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline me-4"
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline me-4"
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.mobile - idx,
                                  }}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.mobile - idx,
                                  }}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.mobile ? (
                        <div
                          className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                          style={{
                            width: 40,
                            height: 40,
                            outline: "1px dashed grey",
                          }}
                        >
                          <h6 className="text-center">
                            +{round.userData.length - maxUserList.mobile} more
                          </h6>
                        </div>
                      ) : null}
                    </div>
                  )}

                  {/* Right side: Button */}
                  <div>
                    {round.sortType === "create" &&
                      (round.teeTime === "schedule" ? (
                        isSameDay(round.scheduleTime) && (
                          <button
                            className={clsx(
                              "p-2 lh-20 w100 me-2 text-center font-xssss fw-600 ls-1 rounded-xl border border-0",
                              {
                                "bg-current text-white":
                                  round.creator === userData.uid, // Active button style
                                "bg-light text-muted":
                                  round.creator !== userData.uid, // Disabled button style
                              }
                            )}
                            onClick={() =>
                              handleUpdateStartRound(round.roundId)
                            }
                            disabled={round.creator !== userData.uid} // Disable button if user is not the creator
                          >
                            Start
                          </button>
                        )
                      ) : (
                        <Link to={`/score?round=${round.roundId}`}>
                          <div className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                            Resume
                          </div>
                        </Link>
                      ))}

                    <div className="d-flex justify-content-end">
                      {/* Conditionally render Resume, Join, and Deny buttons */}
                      {round.sortType === "request" &&
                        (round.userData.some(
                          (user) => user.uid === userData.uid && user.isJoin
                        ) ? (
                          <Link to={`/score?round=${round.roundId}`}>
                            <div className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                              Resume
                            </div>
                          </Link>
                        ) : (
                          <>
                            <button
                              className="p-2 lh-20 w75 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                              onClick={() => handleJoinClick(round.roundId)}
                            >
                              Join
                            </button>
                            <button
                              className="p-2 lh-20 w75 text-center text-black font-xssss fw-600 ls-1 rounded-xl border border-0"
                              style={{ background: "#f5f5f5" }}
                              onClick={() =>
                                handleDeny(round.roundId, userData.uid)
                              }
                            >
                              Deny
                            </button>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ))
      )}
    </>
  );
}
export default AllRound;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
