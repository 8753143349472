import { collection, doc, getDoc, getDocs, query, updateDoc, where, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";

export async function updateStartRound(roundId) {

    if (!roundId) {
        console.error('Round ID', roundId);
        return
    }

    const roundCollection = collection(firestore, 'round');

    try {
        const roundDoc = doc(roundCollection, roundId);
        const roundSnapshot = await getDoc(roundDoc);

        if (roundSnapshot.exists()) {
            // update status in round
            const roundRef = roundSnapshot.ref;

            await updateDoc(roundRef, {
                teeTime: 'now'
            })
            
        } else {
            console.warn('round not existed');
        }

    } catch (error) {
        console.error('Failed to delete', error);
    }
}