import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  limit,
  orderBy,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { denyRound } from "../module/denyRound";

function RoundRequest() {
  const userData = useSelector((state) => state.user.data);
  const [listRoundRequest, setListRoundRequest] = useState([]);
  const [listRoundStart, setListRoundStart] = useState([]);

  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();

  // useEffect(() => {
  //   async function fetchData() {
  //     const roundQuery = query(collection(firestore, "round"));
  //     const roundSnapshot = await getDocs(roundQuery);

  //     let roundDataArray = [];
  //     roundSnapshot.forEach((doc) => {
  //       const roundData = doc.data();

  //       const isCreator = roundData.creator === userData.uid;
  //       const isUserInList = roundData.userList.some(
  //         (user) =>
  //           (user.player?.uid === userData.uid &&
  //             user.player?.isJoin === true) ||
  //           (user.caddie?.uid === userData.uid && user.caddie?.isJoin === true)
  //       );

  //       if (isCreator || isUserInList) {
  //         if (roundData.teeTime === "now") {
  //           roundDataArray.push({ ...roundData, roundId: doc.id });
  //         }
  //       }
  //     });
  //     setListRoundStart(roundDataArray);
  //   }
  //   if (userData) {
  //     fetchData();
  //   }
  // }, [userData]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(firestore, "round")),
      async (userSnapshot) => {
        try {
          let roundDataArray = [];
          const fetchScoreDetails = async (uid, roundId) => {
            const q = query(
              collection(firestore, "scoreCards"),
              where("userId", "==", uid),
              where("roundId", "==", roundId)
            );
            const userSnapshot = await getDocs(q); // Still using getDocs here for nested async data fetching
            let scoreDetails = {};
            userSnapshot.forEach((scoreDoc) => {
              scoreDetails = scoreDoc.data();
            });
            return scoreDetails;
          };

          for (const roundDoc of userSnapshot.docs) {
            const roundData = roundDoc.data();
            const isCreator = roundData.creator === userData?.uid;
            const isUserInList = roundData.userList.some(
              (user) =>
                (user.player?.uid === userData?.uid &&
                  user.player?.isJoin === true) ||
                (user.caddie?.uid === userData?.uid &&
                  user.caddie?.isJoin === true)
            );

            if (isCreator || isUserInList) {
              const playerUids = roundData.userList
                .map((user) => user.player?.uid)
                .filter((uid) => uid !== undefined);

              if (playerUids.length > 0) {
                const scoreDetailsArray = await Promise.all(
                  playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
                );

                roundData.userList = roundData.userList.map((user) => {
                  if (playerUids.includes(user.player?.uid)) {
                    const playerScore = scoreDetailsArray.find(
                      (details) => details.userId === user.player?.uid
                    );
                    const status = playerScore ? playerScore.status : undefined;
                    return {
                      ...user,
                      player: { ...user.player, status },
                      caddie: { ...user.caddie, status },
                    };
                  }
                  return user;
                });

                const isRoundStatus = roundData.userList.some(
                  (user) =>
                    (user.player?.uid === userData.uid &&
                      user.player?.status !== "finished" &&
                      user.player?.status !== "unfinished" &&
                      user.player?.status == undefined) ||
                    (user.caddie?.uid === userData.uid &&
                      user.caddie?.status !== "finished" &&
                      user.caddie?.status !== "unfinished" &&
                      user.caddie?.status == undefined)
                );

                if (isRoundStatus && roundData.teeTime === "now") {
                  roundDataArray.push({
                    ...roundData,
                    roundId: roundDoc.id,
                  });
                }
              }
            }
          }

          setListRoundStart(roundDataArray);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    );

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [userData]);

  // console.log("listRoundStart",listRoundStart)

  useEffect(() => {
    if (!userData?.uid) return;

    const fetchUserDetails = async (uid, userCache) => {
      if (userCache[uid]) return userCache[uid]; // Return from cache if exists

      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", uid)
      );
      const userSnapshot = await getDocs(userQuery);
      let userDetails = {};
      userSnapshot.forEach((userDoc) => {
        userDetails = userDoc.data();
      });
      userCache[uid] = userDetails; // Cache the result
      return userDetails;
    };

    const fetchScoreDetails = async (uid, roundId) => {
      const q = query(
        collection(firestore, "scoreCards"),
        where("userId", "==", uid),
        where("roundId", "==", roundId)
      );
      const userSnapshot = await getDocs(q); // Still using getDocs here for nested async data fetching
      let scoreDetails = {};
      userSnapshot.forEach((scoreDoc) => {
        scoreDetails = scoreDoc.data();
      });
      return scoreDetails;
    };

    const roundActivityQuery = query(
      collection(firestore, "roundActivity"),
      where("userList", "array-contains", userData.uid),
      where("creator", "!=", userData.uid),
      orderBy("created", "desc")
    );

    const unsubscribe = onSnapshot(
      roundActivityQuery,
      async (roundActivitySnapshot) => {
        if (roundActivitySnapshot.empty) return setListRoundRequest([]);

        const rounds = roundActivitySnapshot.docs.map((doc) => doc.data());
        const dataArray = [];
        const userCache = {}; // Cache for user data

        for (const roundActivity of rounds) {
          const { roundId } = roundActivity;

          const roundQuery = query(
            collection(firestore, "round"),
            where("roundId", "==", roundId),
            where("teeTime", "!=", "finished"),
            orderBy("created", "desc"),
            limit(1)
          );

          const roundSnapshot = await getDocs(roundQuery);
          if (roundSnapshot.empty) continue;

          const roundDoc = roundSnapshot.docs[0];
          const round = roundDoc.data();

          let isJoinTrue = false;
          const userChecks = round.userList.map(({ player, caddie }) => {
            if (player?.uid === userData.uid && player.isJoin)
              isJoinTrue = true;
            if (caddie?.uid === userData.uid && caddie.isJoin)
              isJoinTrue = true;
          });

          await Promise.all(userChecks);

          if (!isJoinTrue && round.scheduleTime) {
            const courseHolesRef = doc(
              firestore,
              "courseHoles",
              round.holesCourse
            );
            const [courseHolesSnapshot, courseSnapshot] = await Promise.all([
              getDoc(courseHolesRef),
              getDocs(
                query(
                  collection(firestore, "courses"),
                  where("id", "==", round.course)
                )
              ),
            ]);

            const holesCourse = courseHolesSnapshot.exists()
              ? courseHolesSnapshot.data().courseHoleName
              : "";

            const { time, day, month } = epochToDateTime(round.scheduleTime);

            const userFetches = round.userList.map(
              async ({ player, caddie }) => {
                const userDataArray = [];
                if (player?.uid) {
                  const playerData = await fetchUserDetails(
                    player.uid,
                    userCache
                  );
                  userDataArray.push({
                    ...playerData,
                    isCreator: player.isCreator,
                    isJoin: player.isJoin,
                  });
                }
                if (caddie?.uid) {
                  const caddieData = await fetchUserDetails(
                    caddie.uid,
                    userCache
                  );
                  userDataArray.push({
                    ...caddieData,
                    isCreator: caddie.isCreator,
                    isJoin: caddie.isJoin,
                    playerConfirm: caddie.playerConfirm,
                  });
                }
                return userDataArray;
              }
            );

            const userDataArray = await Promise.all(userFetches);

            const playerUids = round.userList
              .map((user) => user.player?.uid)
              .filter((uid) => uid !== undefined);

            if (playerUids.length > 0) {
              const scoreDetailsArray = await Promise.all(
                playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
              );

              round.userList = round.userList.map((user) => {
                if (playerUids.includes(user.player?.uid)) {
                  const playerScore = scoreDetailsArray.find(
                    (details) =>
                      details.userId === user.player?.uid ||
                      details.userId === user.caddie?.uid
                  );
                  const status = playerScore ? playerScore.status : undefined;
                  return {
                    ...user,
                    player: { ...user.player, status },
                    caddie: { ...user.caddie, status },
                  };
                }
                return user;
              });

              const isRoundStatus = round.userList.some(
                (user) =>
                  (user.player?.uid === userData.uid &&
                    (user.player?.status === "playing" ||
                      user.player?.status === undefined)) ||
                  (user.caddie?.uid === userData.uid &&
                    user.caddie?.status === "playing") ||
                  user.caddie?.status === undefined
              );
              courseSnapshot.forEach((courseDoc) => {
                const courseData = courseDoc.data();
                if (isRoundStatus) {
                  dataArray.push({
                    ...round,
                    courseInfo: courseData,
                    time,
                    day,
                    month,
                    userData: userDataArray.flat(),
                    holesCourse,
                  });
                }
              });
            }

            // courseSnapshot.forEach((courseDoc) => {
            //   const courseData = courseDoc.data();
            //   dataArray.push({
            //     ...round,
            //     courseInfo: courseData,
            //     time,
            //     day,
            //     month,
            //     userData: userDataArray.flat(), // Flatten array
            //     holesCourse,
            //   });
            // });
          }
        }

        setListRoundRequest(dataArray);
      }
    );

    return () => unsubscribe();
  }, [userData]);

  const handleJoinClick = (roundId, teeTime) => {
    if (listRoundStart.length > 0 && teeTime == "now") {
      Swal.fire({
        title: "Round In Progress",
        text: "You still have a round in progress. Please finish that round first.",
        icon: "warning",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    } else {
      navigate(`/join-round?id=${roundId}`);
    }
  };

  const handleDeny = async (roundId, userId) => {
    Swal.fire({
      title: "Deny this round?",
      html: `
        <h5 class="fw-600">Are you sure you want to deny this round?</h5>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await denyRound(roundId, userId);
          console.log("Round denied successfully");
          Swal.fire("Denied!", "The round has been denied.", "success");
        } catch (error) {
          console.error("Error denying round:", error);
          Swal.fire("Error!", "There was an issue denying the round.", "error");
        }
      } else {
        console.log("Round denial cancelled");
      }
    });
  };
  // console.log(listRoundRequest);

  const filteredRoundRequest = listRoundRequest
    .filter((round) =>
      round.userData.some((user) => {
        if (user.uid === userData.uid) {
          if (user.role === "player") {
            return user.isJoin === false;
          } else if (user.role === "caddie") {
            return user.playerConfirm === true;
          }
        }
        return false;
      })
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 1); // เลือกแค่รอบที่สร้างล่าสุด

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl w-100 mb-2">
        <Card.Body className="main-player-stat p-1">
          <div className="card-body d-flex align-items-center p-2">
            <h4 className="fw-700 mb-0 font-xssss text-grey-900">
              Round Request
            </h4>
            <Link
              to="/round-activity?selectedOption=friendRequest"
              className="fw-600 ms-auto font-xssss text-primary"
            >
              See all
            </Link>
          </div>

          {filteredRoundRequest.length > 0 ? (
            filteredRoundRequest.map((round, index) => (
              <div key={index} className="create-round-req-main mb-3">
                <section className="create-round-req-head">
                  <div className="pointer">
                    <h5 className="fw-600 d-sm-block d-none">
                      {round.roundName}
                    </h5>
                    <h4 className="fw-600 d-sm-none d-block">
                      {round.roundName}
                    </h4>
                  </div>
                </section>

                <section
                  className="create-round-req-body p-2 mb-2 pointer"
                  style={{
                    backgroundImage: round.coverImage
                      ? typeof round.coverImage === "string"
                        ? `url(${round.coverImage})`
                        : `url(${URL.createObjectURL(round.coverImage)})`
                      : "none",
                    height: round.coverImage ? "250px" : "inherit",
                  }}
                >
                  <div
                    className="req-course-data d-flex align-items-center w-100"
                    style={{
                      backgroundColor: round.coverImage ? "" : "#fff",
                    }}
                  >
                    <div className="col-sm-3 col-3 pointer p-0 d-flex justify-content-center align-items-center">
                      <img
                        src={round.courseInfo.profileImage}
                        alt="course-profile"
                        width={50}
                        height={50}
                        className="rounded-circle my-2"
                      />
                    </div>
                    <div className="col-sm-6 col-xs-6 d-flex align-items-center justify-content-start pointer">
                      <div>
                        <span
                          className="fw-600 d-sm-block d-none"
                          style={{ fontSize: "11px" }}
                        >
                          {round.courseInfo.courseName}
                        </span>
                        <span className="fw-600 fs-5 d-sm-none d-block">
                          {round.courseInfo.courseName}
                        </span>
                        <span className="fw-600 fs-5 d-sm-none d-block">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                        <span
                          className="fw-600 d-sm-block d-none"
                          style={{ fontSize: "10px" }}
                        >
                          {`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 col-4 px-0 px-sm-20">
                      <div
                        className="h-100 w-75 d-flex flex-column justify-content-evenly rounded-3 py-2 pointer"
                        style={{
                          backgroundColor:
                            round.teeTime === "schedule"
                              ? "#E59E49"
                              : "#81D77A",
                        }}
                      >
                        {round.teeTime === "schedule" ? (
                          <>
                            <h6 className="fw-600 text-white text-center">
                              {round.month}
                            </h6>
                            <h4 className="fw-700 text-white text-center">
                              {round.day}
                            </h4>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-6"
                                style={{ color: "#E59E49" }}
                              >
                                {round.time}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <h6 className="fw-600 text-white text-center">
                              {round.month}
                            </h6>
                            <h4 className="fw-700 text-white text-center">
                              {round.day}
                            </h4>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-6"
                                style={{ color: "#81D77A" }}
                              >
                                {round.time}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div className="d-none d-sm-flex flex-column justify-content-between p-3 pointer">
                    <div className="d-flex align-items-center mb-3 ms-2">
                      {round.userData
                        .slice(0, maxUserList.desktop)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              <img
                                src={user.profileImage}
                                alt={user.role}
                                width={40}
                                height={40}
                                className={`rounded-circle ${user.role}-color-outline me-4`}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              <img
                                src={user.profileImage}
                                alt={user.role}
                                width={40}
                                height={40}
                                className={`rounded-circle ${user.role}-color-outline`}
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.desktop - idx,
                                }}
                              />
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.desktop && (
                        <div
                          className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                          style={{
                            width: 40,
                            height: 40,
                            outline: "1px dashed grey",
                            opacity: 0.6,
                          }}
                        >
                          <h6 className="text-center">
                            +{round.userData.length - maxUserList.desktop} more
                          </h6>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center mb-3">
                      {round.userData.map(
                        (user, idx) =>
                          user.isCreator && (
                            <div key={idx}>
                              <h6
                                className="fw-600"
                                style={{ fontSize: "11px" }}
                              >
                                Request By {user.firstName} {user.lastName}
                              </h6>
                            </div>
                          )
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        onClick={() =>
                          handleJoinClick(round.roundId, round.teeTime)
                        }
                      >
                        Join
                      </button>
                      <button
                        className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        style={{ background: "#f5f5f5" }}
                        onClick={() => handleDeny(round.roundId, userData.uid)}
                      >
                        Deny
                      </button>
                    </div>
                  </div>

                  <div className="d-sm-none d-flex flex-column justify-content-between p-3 pointer">
                    <div className="d-flex align-items-center mb-3 ms-2">
                      {round.userData
                        .slice(0, maxUserList.mobile)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              <img
                                src={user.profileImage}
                                alt={user.role}
                                width={40}
                                height={40}
                                className={`rounded-circle ${user.role}-color-outline me-4`}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              <img
                                src={user.profileImage}
                                alt={user.role}
                                width={40}
                                height={40}
                                className={`rounded-circle ${user.role}-color-outline`}
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.mobile - idx,
                                }}
                              />
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.mobile && (
                        <div
                          className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                          style={{
                            width: 40,
                            height: 40,
                            outline: "1px dashed grey",
                          }}
                        >
                          <h6 className="text-center">
                            +{round.userData.length - maxUserList.mobile} more
                          </h6>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center mb-3">
                      {round.userData.map(
                        (user, idx) =>
                          user.isCreator && (
                            <div key={idx}>
                              <h6 className="fw-600 fs-7">
                                Request By {user.firstName} {user.lastName}
                              </h6>
                            </div>
                          )
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        onClick={() =>
                          handleJoinClick(round.roundId, round.teeTime)
                        }
                      >
                        Join
                      </button>
                      <button
                        className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        style={{ background: "#f5f5f5" }}
                        onClick={() => handleDeny(round.roundId, userData.uid)}
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            ))
          ) : (
            <h5 className="text-center p-2">No rounds request available</h5>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default RoundRequest;
