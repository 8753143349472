import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { firestore } from "../../components/firebase/FirebaseConfig";
import {
  collection,
  deleteDoc,
  doc,
  where,
  query,
  getDocs,
  getDoc,
  updateDoc,
  addDoc,
  arrayRemove,
} from "firebase/firestore";

import { Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2";

function Label({
  showLabelModal,
  setShowLabelModal,
  setSelectedUserLabel,
  selectedUserLabel,
  triggerFriendList,
  setTriggerFriendList,
  userLabels,
  triggerGetLabel,
  setTriggerGetLabel,
}) {
  const userData = useSelector((state) => state.user.data);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setOptions([]);
      let fetchedOptions = [];
      for (let index = 0; index < userLabels.length; index++) {
        const element = userLabels[index];
        const formattedOptions = {
          value: element.value,
          label: element.label,
          totalCount: element.totalCount,
          docId: element.docId,
        };
        fetchedOptions.push(formattedOptions);
      }
      setOptions(fetchedOptions);
    }
    if (userLabels) {
      fetchData();
    }
  }, [userLabels]);

  useEffect(() => {
    async function fetchData() {
      const q = query(
        collection(firestore, "labeledUsers"),
        where("friendId", "==", selectedUserLabel?.uid),
        where("userId", "==", userData.uid)
      );

      const querySnapshot = await getDocs(q);
      let userLabelsDocIds = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data && data.labels) {
          userLabelsDocIds = data.labels;
        }
      });

      const selected = userLabels.filter((label) =>
        userLabelsDocIds.includes(label.docId)
      );
      setSelectedOptions(selected);
    }
    if (selectedUserLabel?.uid) {
      fetchData();
    }
  }, [selectedUserLabel]);

  const handleCreateOption = (inputValue) => {
    setIsCreating(true);
    setTimeout(async () => {
      const docRef = await addDoc(collection(firestore, "labels"), {
        label: inputValue,
        totalCount: 0,
        userId: userData.uid,
        value: inputValue.toLowerCase().replace(/\s+/g, "_"),
      });
      setTriggerGetLabel(!triggerGetLabel);
      setIsCreating(false);
    }, 1000);
  };

  const handleChange = async (selected) => {

    if (selected && selected.length > 5) {
      return;
    }

    const selectedDocId = (selected || []).map((option) => option.docId);
    setSelectedOptions(selected || []);

    try {
      const labelsCollectionRef = collection(firestore, "labeledUsers");
      const q = query(
        labelsCollectionRef,
        where("userId", "==", userData.uid),
        where("friendId", "==", selectedUserLabel.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Document exists, update labels and totalCount
        const docRef = querySnapshot.docs[0].ref;

        // Fetch existing labels
        const docData = querySnapshot.docs[0].data();
        const existingLabels = docData.labels || [];

        // Get labels that were previously selected but are now deselected
        const removedLabels = existingLabels.filter(
          (labelId) => !selectedDocId.includes(labelId)
        );

        // Get labels that are newly selected
        const newlySelectedLabels = selectedDocId.filter(
          (labelId) => !existingLabels.includes(labelId)
        );

        // Update totalCount for newly selected labels
        const updatePromises = newlySelectedLabels.map(async (labelId) => {
          const labelDocRef = doc(firestore, "labels", labelId);
          const labelDoc = await getDoc(labelDocRef);
          if (labelDoc.exists()) {
            const labelData = labelDoc.data();
            await updateDoc(labelDocRef, {
              totalCount: (labelData.totalCount || 0) + 1,
            });
          }
        });

        // Decrease totalCount for removed labels
        removedLabels.forEach(async (labelId) => {
          const labelDocRef = doc(firestore, "labels", labelId);
          const labelDoc = await getDoc(labelDocRef);
          if (labelDoc.exists()) {
            const labelData = labelDoc.data();
            await updateDoc(labelDocRef, {
              totalCount: (labelData.totalCount || 1) - 1,
            });
          }
        });

        await Promise.all(updatePromises);

        // Update labeledUsers document
        await updateDoc(docRef, {
          labels: selectedDocId,
        });
      } else {
        // Document does not exist, create new labeledUsers document
        await addDoc(labelsCollectionRef, {
          labels: selectedDocId,
          userId: userData.uid,
          friendId: selectedUserLabel.uid,
        });

        // Update totalCount for newly selected labels
        const updatePromises = selectedDocId.map(async (labelId) => {
          const labelDocRef = doc(firestore, "labels", labelId);
          const labelDoc = await getDoc(labelDocRef);
          if (labelDoc.exists()) {
            const labelData = labelDoc.data();
            await updateDoc(labelDocRef, {
              totalCount: (labelData.totalCount || 0) + 1,
            });
          }
        });

        await Promise.all(updatePromises);
      }
    } catch (error) {
      console.error("Error updating labels:", error);
    }
    setTriggerGetLabel(!triggerGetLabel);
  };

  const handleDeleteOption = async (valueToDelete) => {
    const dataObj = userLabels.find((item) => item.value === valueToDelete);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const labelsCollectionRef = collection(firestore, "labeledUsers");
          const q = query(
            labelsCollectionRef,
            where("labels", "array-contains", dataObj.docId)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (docSnapshot) => {
            const userRef = doc(firestore, "labeledUsers", docSnapshot.id);
            await updateDoc(userRef, {
              labels: arrayRemove(dataObj.docId),
            });
          });

          await deleteDoc(doc(firestore, "labels", dataObj.docId));
          setTriggerGetLabel(!triggerGetLabel);
        } catch (error) {}
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "20px", // Rounded borders
      display: "flex",
      alignItems: "center",
      padding: "6px 6px", // Increase padding for larger size
      fontSize: "16px", // Increase font size
      height: "30px", // Increase the height to make the separator taller
      background: "#F5F5F5",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "0 16px", // Adjust padding for more space between text and icon
      fontSize: "16px", // Match font size
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: "0 20px 20px 0",
      borderLeft: "1px solid #ccc", // Separator between text and close icon
      height: "100%", // Ensure the separator spans the full height of the container
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontSize: "18px", // Increase icon size
      "&:hover": {
        backgroundColor: "#FF6B6B", // Change background color on hover
        color: "white",
      },
    }),
  };
  return (
    <>
      <Modal
        show={showLabelModal}
        onHide={() => setShowLabelModal(false)}
        centered
      >
        <Modal.Body style={{ height: "50vh" }}>
          <div className="card-body d-flex p-4">
            <figure className="avatar mb-0 position-relative w60 z-index-1 me-3">
              <img
                src={selectedUserLabel?.imageUrl}
                alt="avatar"
                className="bg-image-cover float-left p-1 rounded-circle w-100"
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedUserLabel?.role === "caddie"
                      ? "#EE3D7F"
                      : "#4A74E5",
                }}
              />
            </figure>
            <div className="flex-grow-1">
              <h4
                className="fw-700 font-xsss mt-3 mb-1"
                //   onClick={() => handleProfileClick(value?.uid)}
                style={{ cursor: "pointer" }}
              >
                {selectedUserLabel?.name}
              </h4>
            </div>

            <ReactSVG
              src="assets/images/dooNineIconV2/others/post-close.svg"
              onClick={() => setShowLabelModal(false)}
              className="dooNine-close-button"
            />
          </div>

          <div>
            <label>Label ( {selectedOptions.length} / 5 )</label>
            <CreatableSelect
              isMulti
              options={options}
              value={selectedOptions}
              onChange={handleChange}
              onCreateOption={handleCreateOption}
              isDisabled={isCreating}
              styles={customStyles}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Select or create an option"
              components={{
                Option: (props) => (
                  <CustomOption
                    {...props}
                    onDelete={handleDeleteOption}
                    isCreating={isCreating}
                  />
                ),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn bg-greylight "
            onClick={() => setShowLabelModal(false)}
          >
            <h4 className="fw-600 m-0 text-grey-700 p-1">Close</h4>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Label;

const CustomOption = (props) => {
  const { data, innerRef, innerProps, selectProps } = props;
  const [hover, setHover] = useState(false); // State to manage hover
//   console.log(props);
  const handleDelete = (e) => {
    e.stopPropagation();
    props.onDelete(data.value);
  };

  const isNewOption = props.isCreating; // Check if it's a newly created option
  const isSelectedOption = selectProps.value.some(
    (option) => option.value === data.value
  );

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="custom-option"
      style={{
        display: "flex", // Use flexbox for layout
        alignItems: "center", // Center vertically
        justifyContent: "space-between", // Space between label and button
        padding: "4px 8px", // Add padding for better spacing
        backgroundColor: hover ? "#FCD8E5" : "", // Change color on hover
        transition: "color 0.3s",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span>{data.label}</span> {/* Display the label */}
      <div style={{ display: "flex", alignItems: "center" }}>
        {data.totalCount > 0 && (
          <span style={{ marginRight: "8px", fontSize: "16px" }}>
            {data.totalCount}
          </span>
        )}
        {!isNewOption && data.totalCount === 0 && (
          <button
            onClick={handleDelete}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <i className="bi bi-trash" style={{ fontSize: "18px" }}></i>
          </button>
        )}
      </div>
    </div>
  );
};
