import React, { useState, useEffect } from 'react';
import moment from 'moment';

const TimeAgo = ({ timestamp }) => {

  return (
    <span className="d-flex font-xssss fw-500 lh-3 text-grey-500">
      {moment(timestamp * 1000).calendar()}
    </span>
  );
};

export default TimeAgo;
