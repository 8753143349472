import React from 'react'

function Error404() {
    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}>
                <div style={{ textAlign: 'center' }}>
                    <div style={{ color: 'red', fontSize: '4rem', opacity: '0.8', fontFamily: 'cursive' }}>
                        Error 404
                    </div>
                    <div style={{ color: 'grey', fontSize: '4rem', opacity: '1', fontFamily: 'cursive' }}>
                        Page not found
                    </div>
                    <button
                        style={{
                            backgroundColor: '#FF5A99',
                            border: '0',
                            padding: '1rem',
                            borderRadius: '2rem',
                            margin: '2rem',
                            cursor: 'pointer',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                        onClick={() => window.location.href = '/home'}
                    >Return Home</button>
                </div>
            </div>

        </>
    )
}

export default Error404