import { firestore } from "../firebase/FirebaseConfig"
import { collection, addDoc, where, query, getDocs, serverTimestamp, updateDoc, deleteDoc } from "firebase/firestore"

export const favFriend = async (userId, favId) => {
    try {
        const timestamp = Math.floor(Date.now() / 1000);
        const q1 = query(
            collection(firestore, "favoritePlayers"),
            where("playerId", "==", favId),
            where("userId", "==", userId));

        const querySnapshot1 = await getDocs(q1);

        if (querySnapshot1.empty) {
            await addDoc(collection(firestore, "favoritePlayers"), {
                playerId: favId,
                userId: userId,
                timestamp: timestamp,
                status: 'active'
            });
            return true;
        } else {
            const docRef = querySnapshot1.docs[0];
            if (docRef.data().status === 'inactive') {
                await updateDoc(docRef.ref, {
                    status: 'active',
                    timestamp: timestamp
                });
            } else {
                await updateDoc(docRef.ref, {
                    status: 'inactive',
                    timestamp: timestamp
                });
            }
            return true;
        }
    } catch (error) {
        console.error('Error fav user', error);
        return false;
    }
}

export const unfavFriend = async (userId, favId) => {
    try {
        const q1 = query(
            collection(firestore, "favoritePlayers"),
            where("playerId", "==", favId),
            where("userId", "==", userId),
            where("status", "==", 'active'));

        const querySnapshot1 = await getDocs(q1);

        if (!querySnapshot1.empty) {
            for (const doc of querySnapshot1.docs) {
                const docRef = doc.ref;
                await updateDoc(docRef, {
                    status: 'inactive'
                });
            }
            return true;
        } else {
            console.warn('This user is not in your favorites');
            return false;
        }
    } catch (error) {
        console.error('Error unfavoriting the user', error);
        return false;
    }
};


export const favFriendCaddie = async (userId, favId) => {
    try {
        const timestamp = Math.floor(Date.now() / 1000);

        const q2 = query(
            collection(firestore, "favoriteFriends"),
            where("caddieId", "==", favId),
            where("userId", "==", userId));

        const querySnapshot2 = await getDocs(q2);

        if (querySnapshot2.empty) {
            await addDoc(collection(firestore, "favoriteFriends"), {
                caddieId: favId,
                userId: userId,
                timestamp: timestamp,
                status: 'active'
            });
            return true;
        } else {
            const docRef = querySnapshot2.docs[0]
            if (docRef.data().status === 'inactive') {
                await updateDoc(docRef.ref, {
                    status: 'active',
                    timestamp: timestamp
                });
            } else {
                await updateDoc(docRef.ref, {
                    status: 'inactive',
                    timestamp: timestamp
                });
            }
            return true;
        }
    } catch (error) {
        console.error('Error fav user', error);
        return false;
    }
}

export const unfavFriendCaddie = async (userId, favId) => {
    try {

        const q2 = query(
            collection(firestore, "favoriteFriends"),
            where("caddieId", "==", favId),
            where("userId", "==", userId),
            where("status", "==", 'active'));

        const querySnapshot2 = await getDocs(q2);

        if (!querySnapshot2.empty) {
            for (const doc of querySnapshot2.docs) {
                const docRef = doc.ref;
                await updateDoc(docRef, {
                    status: 'inactive'
                });
            }
            return true;
        } else {
            console.warn('This user is not in your favorites');
            return false;
        }
    } catch (error) {
        console.error('Error unfavoriting the user', error);
        return false;
    }
};

const removeFavoriteFriendDoc = async (userId, caddieId) => {
    try {
        // Query the collection for documents where userId and caddieId match
        const q = query(
            collection(firestore, "favoriteFriends"),
            where("userId", "==", userId),
            where("caddieId", "==", caddieId)
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.warn('No matching document found to delete.');
            return false;
        }

        // Loop through the query results and delete each document
        for (const doc of querySnapshot.docs) {
            await deleteDoc(doc.ref);
            console.log(`Document with id ${doc.id} deleted.`);
        }

        return true;
    } catch (error) {
        console.error('Error removing document', error);
        return false;
    }
};