import React, { useState, useEffect, useRef } from 'react'

import { Card } from 'react-bootstrap'
import Placeholder from 'react-bootstrap/Placeholder';
import Slider from "react-slick";

import './caddieStyle.css';

import { collection, getDocs, where, query } from "firebase/firestore";
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';


function CaddieStar() {
    const [caddieList, setCaddieList] = useState([]);
    const isMobile = useRef(window.matchMedia("(max-width: 768px)").matches);
    const userData = useSelector((state) => state.user.data);
    const [loading, setLoading] = useState(true);
    const caddiePlaceholder = [0, 1, 2, 3]

    const sliderStyle = {
        swipeToSlide: true,
        arrows: isMobile.current ? false : true,
        dots: false,
        infinite: true,
        centerMode: false,
        variableWidth: true,
        autoplay: true,
    }

    useEffect(() => {
        async function getCaddie() {
            try {
                setLoading(true);
                const q = query(
                    collection(firestore, "users"),
                    where('role', '==', 'caddie'),
                    where('isStar', '==', true)
                );
                const querySnapshot = await getDocs(q);
                let newCaddieList = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (!data.firstName.includes('[ทดสอบระบบ]')) {
                        newCaddieList.push(data);
                    }
                });
                setCaddieList(newCaddieList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching caddies: ", error);
            }
        }

        if (caddieList.length < 1) {
            getCaddie();
        }
    }, [caddieList.length]);

    if (!caddieList.length && !loading) {
        return null;
    } else {
        return (
            <>
                <div className='caddie-main-container overflow-visible'>
                    <div className='caddie-main-body'>
                        {!loading ?
                            <Slider {...sliderStyle}
                                infinite={caddieList.length < 3 ? false : true} // disable loop for less than 3
                                autoplay={caddieList.length < 3 ? false : true} // disable autoplay less than 3
                                arrows={caddieList.length < 3 || isMobile.current ? false : true} // disable arrows less than 3
                                className='list-container'>
                                {caddieList.map((caddie) => (
                                    <Link key={caddie?.id} to={caddie?.uid !== userData?.uid ? `/user?uid=${caddie?.uid}` : `/userPage`}>
                                        <div className="card caddie-card-container w175 h-100 d-block rounded-xxl overflow-hidden me-2 pointer border-0">
                                            <div className="card-body position-relative h125 bg-image-cover bg-image-center bg-light theme-light-bg" style={{ backgroundImage: `url(${caddie?.coverImage})` }}></div>
                                            <div className="card-body d-block w-100 h-100 ps-4 pe-4 pb-1 text-center bg-white theme-light-bg">
                                                <figure className="avatar overflow-hidden ms-auto me-auto mb-0 mt--2 position-relative w75 z-index-1">
                                                    <img src={`${caddie?.profileImage || defaultProfile}`} alt="avater" className="float-right p-1 bg-current rounded-circle w-100"
                                                        style={{ width: 75, height: 75, objectFit: 'cover' }}
                                                    />
                                                </figure>
                                                <div className="clearfix"></div>
                                                <h4 className="fw-700 font-xsss mt-2 mb-3 text-truncate">{(caddie?.firstName || '') + ' ' + (caddie?.lastName || '')} </h4>
                                                <div className="clearfix mb-2"></div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </Slider>
                            :
                            <div className='h-100'>
                                <Slider {...sliderStyle} className='list-container'>
                                    {caddiePlaceholder.map((index) => (
                                        <div key={index} className="card caddie-card-container w175 h-100 d-block rounded-xxl overflow-hidden me-2 pointer border-0">
                                            <div className="card-body position-relative h125 bg-image-cover bg-image-center bg-light theme-light-bg" style={{ backgroundImage: `` }}></div>
                                            <div className="card-body d-block w-100 h-100 ps-4 pe-4 pb-1 text-center bg-white theme-light-bg">
                                                <figure className="avatar overflow-hidden ms-auto me-auto mb-0 mt--2 position-relative w75 z-index-1">
                                                    <img src={`${defaultProfile}`} alt="avater" className="float-right p-1 rounded-circle w-100"
                                                        style={{ width: 75, height: 75, objectFit: 'cover' }}
                                                    />
                                                </figure>
                                                <div className="clearfix"></div>
                                                <div className="fw-700 font-xsss mt-2 mb-3 bg-light w-100" style={{ height: 18 }}></div>
                                                <div className="clearfix mb-2"></div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        }
                    </div>
                </div >
            </>
        )
    }
}

export default CaddieStar
