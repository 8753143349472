import React from 'react'
import { useScore } from '../ScoreContext/ScoreContext'
import { useSelector } from 'react-redux';
import SelectCourseModal from '../../scoreV2/component/SelectHoleCourseModal';
import useModalWithHistory from '../../../components/ModalPopState';

const SelectHoleCourse = () => {
    const userData = useSelector((state) => state.user.data);
    const { roundData } = useScore();
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();

    if (!roundData) return;

    return (
        <>
            {roundData.holesCourse ?
                null
                :
                <>
                    {roundData.creator === userData.uid ?
                        <div className='theme-light-bg d-flex justify-content-center align-items-center flex-column p-4 rounded-3' style={{ backgroundColor: '#F5F5F5' }}>
                            <h5 className='fw-600'>Please select your desired course before starting to play.</h5>
                            <button className='btn bg-current mt-2' onClick={() => openModal()}>
                                <h4 className='text-white fw-600'>Select Course</h4>
                            </button>
                        </div>
                        :
                        <div className='theme-light-bg d-flex justify-content-center align-items-center flex-column p-4 rounded-3' style={{ backgroundColor: '#F5F5F5' }}>
                            <h5 className='fw-600'>Please wait for the creator to select the course.</h5>
                        </div>
                    }
                </>
            }
            <SelectCourseModal
                isOpen={isModalOpen}
                closeModal={closeModal}
            />

        </>
    )
}

export default SelectHoleCourse