import React, { useState, useEffect, useRef } from 'react'
import styles from '../../scorestyle.module.scss'
import { ReactSVG } from 'react-svg'
import { useScore } from '../../ScoreContext/ScoreContext'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import LoadCircle from '../../../../components/LoadCircle'
import { checkScore, sumPar, sumPenalties, sumPutts, sumScoreStableford } from '../../scoring/module/scoreCalculation'
import { finishRound } from '../../module/finishRound'
import { Link, useNavigate } from 'react-router-dom'
import PlayerBag from '../PlayerBag'
import useModalWithHistory from '../../../../components/ModalPopState'

const ScoreHeaderStableford = () => {
    const userData = useSelector((state) => state.user.data);
    const [scoreHeaderWidth, setScoreHeaderWidth] = useState(null);
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();
    const { userList, score, courseHoles, roundData } = useScore();
    const navigate = useNavigate();
    const playerDataRef = useRef(null);

    const handlePlayerModal = (player) => {
        playerDataRef.current = player;
        openModal();
    }

    const handleCaddieClick = (user, score) => {
        const foundCaddie = score.find(item => item.caddieId === user.caddie.uid);
        if (foundCaddie) {
            const status = foundCaddie.status;
            if (status === 'finished' && foundCaddie.userId === userData.uid) {
                navigate(`/review-round?round=${roundData.roundId}`);
            } else {
                if (foundCaddie.caddieId === userData.uid) {
                    navigate(`/userPage`);
                } else {
                    navigate(`/user?uid=${foundCaddie.caddieId}`);
                }
            }
        } else {
            navigate(`/user?uid=${user.caddie.uid}`);
        }
    }

    const navFinishRound = () => {
        navigate(`/finish-round?round=${roundData.roundId}`);
    }

    useEffect(() => {
        if (scoreHeaderWidth) return;
        const scoreHeaderelem = document.getElementById('scoreheader');
        if (scoreHeaderelem) {
            setScoreHeaderWidth(scoreHeaderelem.clientWidth);
        }
    }, [scoreHeaderWidth, userList]);



    if (!userList.length)
        return (
            <div className='p-5 bg-light theme-light-bg text-center'>
                <LoadCircle size='lg' variant='dark' />
            </div>
        );

    // sort user
    userList.sort((a, b) => {
        const aIsMatched = (a.player?.uid === userData.uid || a.caddie?.uid === userData.uid);
        const bIsMatched = (b.player?.uid === userData.uid || b.caddie?.uid === userData.uid);

        // If both a and b are matched or both are not matched, sort by row
        if (aIsMatched === bIsMatched) {
            return a.row - b.row;
        }

        // If a is matched and b is not, a comes first
        return aIsMatched ? -1 : 1;
    });

    return (
        <>
            <style>
                {`
                    .score-body-wrapper > * {
                        width: ${scoreHeaderWidth ? `${scoreHeaderWidth}px` : 'auto'};
                    }
                `}
            </style>

            <div className={`${styles.scoreheader}`} id='scoreheader'
                style={{ width: `calc(${userList.length + 1} * 200px - 70px)` }}>
                <Link to={`/leader-board?round=${roundData.roundId}`} className={`${styles.headleaderboard} pointer theme-dark-bg`}>
                    <ReactSVG src='assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_leader_board.svg' wrapper='svg' width={40} height={50} />
                    <h6 className='fw-600'>Leader Board</h6>
                </Link>
                <div className={`${styles.headtotal} theme-dark-bg`}>
                    <h5 className='fw-600'>Total</h5>
                    <h6 className='fw-600'>Par {sumPar(courseHoles)}</h6>
                </div>
                {userList.map((user, idx) =>
                    <div key={idx} className={clsx(`${(user.player && user.player.uid === userData.uid) || (user.caddie && user.caddie.uid === userData.uid) ? styles.headplayer : styles.headotherplayer} d-flex flex-column justify-content-center align-items-center`, {
                        'theme-light-bg': user.player && user.player.uid === userData.uid,
                        'theme-dark-bg': (user.player && user.player.uid !== userData.uid) || !user.player
                    })}>
                        <div className='py-1 position-relative w-100 h-100'>
                            {user.player ?
                                <div
                                    className='position-absolute pointer'
                                    style={{ top: '50%', left: `${user.caddie && user.caddie.isJoin ? '40%' : '50%'}`, transform: 'translate(-50%,-50%)', zIndex: '1' }}
                                    onClick={() => handlePlayerModal(user)}
                                >
                                    <img src={user.player.profileImage} width={50} height={50} alt="player" className='rounded-circle player-color-outline' />
                                </div>
                                :
                                null
                            }
                            {user.caddie && user.caddie.isJoin ?
                                <div
                                    className='position-absolute pointer'
                                    style={{ top: '50%', left: `${user.player ? '60%' : '50%'}`, transform: 'translate(-50%,-50%)' }}
                                    onClick={() => handleCaddieClick(user, score)}
                                >
                                    <img src={user.caddie.profileImage} width={50} height={50} alt="player" className='rounded-circle caddie-color-outline' />
                                </div>
                                :
                                null
                            }
                        </div>
                        {(user.player || user.caddie) && score ? (
                            <>

                                {(() => {
                                    const foundPlayer = score.find((item) => (item && item.userId === user?.player?.uid) || (item && item.caddieId === user?.caddie?.uid));
                                    return foundPlayer ?
                                        <>
                                            {checkScore(foundPlayer) && foundPlayer.status === 'playing' && (foundPlayer.userId === userData.uid || foundPlayer.caddieId === userData.uid) ?
                                                <div className="mt-2 px-3 py-1 bg-current rounded-3 border border-white pointer" onClick={() => finishRound(foundPlayer, courseHoles, navFinishRound)}>
                                                    <h5 className="fw-600 text-white">Finish Round</h5>
                                                </div>
                                                :
                                                <div className="mt-2 px-3 py-1 bg-white theme-light-bg rounded-3 border border-white">
                                                    <h5 className="fw-600 text-capitalize">{foundPlayer.status}</h5>
                                                </div>
                                            }
                                            <div className="d-flex justify-content-around align-items-center w-100 py-1 px-3 mt-2">
                                                <span className="fw-600 fs-4 text-danger">{sumPenalties(foundPlayer)}</span>
                                                <h2 className="fw-600">{sumScoreStableford(foundPlayer, courseHoles)}</h2>
                                                <div className='d-flex justify-content-between align-items-center flex-column'>

                                                    {/* HCP for net */}
                                                    {/* <h4 className="fw-600"></h4> */}

                                                    <h4 className="fw-600">{sumPutts(foundPlayer)}</h4>
                                                </div>
                                            </div>
                                        </> : null;
                                })()}
                            </>
                        ) : null}
                    </div>
                )}
            </div >
            <PlayerBag
                user={playerDataRef.current}
                isOpen={isModalOpen}
                closeModal={closeModal}
            />
        </>
    )
}

export default ScoreHeaderStableford