import React, { useState, useEffect, useRef, useMemo } from 'react'
import Header from '../../../components/Header';
import Leftnav from '../../../components/Leftnav';
import Rightchat from '../../../components/Rightchat';
import Popupchat from '../../../components/Popupchat';
import Appfooter from '../../../components/Appfooter';

import { useLocation, useNavigate, Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Modal, Carousel, Spinner } from 'react-bootstrap';
import ImageCropper from '../../../components/ImageCropper';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { query, collection, where, getDocs, updateDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { storage, firestore } from '../../../components/firebase/FirebaseConfig';

import imageCompression from 'browser-image-compression';

const EditCaddieAbout = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const userData = location.state.userData;
  const descData = location.state.descriptionData;

  const [secImg, setSecImg] = useState(null);
  const [thirdImg, setThirdImg] = useState(null);
  const [desctext, setDescText] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [croppedModal, setCroppedModal] = useState(false);

  const [imageChanged, setImageChanged] = useState(null);
  const imageIndexRef = useRef(null)

  const [croppedImageSecFile, setCroppedImageSecFile] = useState(null);
  const [croppedImageThirdFile, setCroppedImageThirdFile] = useState(null);

  const [loading, setLoading] = useState(false);

  // console.log(userData);
  // console.log(descData);

  const showCoppedModal = () => setCroppedModal(true);
  const hideCroppedModal = () => setCroppedModal(false);

  const handleCroppedImage = (file, url) => {

    if (imageIndexRef.current === 1) {
      setSecImg({
        imageUrl: url,
        order: 1,
        isPrimary: true
      })
      setCroppedImageSecFile(file);
    } else {
      setThirdImg({
        imageUrl: url,
        order: 2,
        isPrimary: false
      })
      setCroppedImageThirdFile(file);
    }
  }

  useEffect(() => {
    if (descData && descData.image) {
      descData.image.forEach(image => {
        if (image.order === 1) {
          setSecImg(image ? image : null);
        } else {
          setThirdImg(image ? image : null);
        }
      });
    }
    setDescText(descData.description || "");
  }, [descData])

  const handleImageChange = (e, imgIndex) => {
    handleImage(e, imgIndex);
    e.target.value = null;
  };

  const handleImage = (e, imgIndex) => {
    const file = e.target.files[0];
    if (imgIndex === 'sec') {
      imageIndexRef.current = 1
      setImageChanged(URL.createObjectURL(file));
      showCoppedModal()
    } else {
      imageIndexRef.current = 2
      setImageChanged(URL.createObjectURL(file));
      showCoppedModal()
    }
  }

  const handleRemoveImage = (imgIndex) => {
    if (imgIndex === 'sec') {
      setSecImg(null);
      setCroppedImageSecFile(null);
    } else {
      setThirdImg(null);
      setCroppedImageThirdFile(null)
    }
  }

  const handleSave = async () => {
    try {
      setLoading(true);

      // Query to get the document reference for the user's profile
      const q = query(collection(firestore, 'caddieProfiles'), where("caddieId", "==", userData.uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        console.log(docRef);

        // Update the 'description' field
        await updateDoc(docRef, {
          description: desctext
        });

        // Reference to the 'image' subcollection
        const imagesCollectionRef = collection(docRef, 'image');

        // Helper function to upload or update an image document
        const handleImageUpdate = async (file, order) => {
          const uploadedImage = await uploadImage(file, order.toString());

          if (uploadedImage) {
            const imageQuery = query(imagesCollectionRef, where("order", "==", order));
            const imageQuerySnapshot = await getDocs(imageQuery);

            if (!imageQuerySnapshot.empty) {
              // Update existing image document
              const imageDocRef = imageQuerySnapshot.docs[0].ref;
              await updateDoc(imageDocRef, {
                imageUrl: uploadedImage
              });
            } else {
              // Create a new image document
              await addDoc(imagesCollectionRef, {
                order: order,
                imageUrl: uploadedImage
              });
            }
          }
        };

        // Helper function to delete an image document
        const handleImageDelete = async (order) => {
          const deletedImage = await deleteImage(order.toString());

          if (deletedImage) {
            const imageQuery = query(imagesCollectionRef, where("order", "==", order));
            const imageQuerySnapshot = await getDocs(imageQuery);

            if (!imageQuerySnapshot.empty) {
              // Delete the image document
              const imageDocRef = imageQuerySnapshot.docs[0].ref;
              await deleteDoc(imageDocRef);
            }
          }
        };

        // Update, add or delete images based on available files and conditions
        if (croppedImageSecFile) {
          await handleImageUpdate(croppedImageSecFile, 1);
        } else if (!secImg) {
          await handleImageDelete(1);
        }

        if (croppedImageThirdFile) {
          await handleImageUpdate(croppedImageThirdFile, 2);
        } else if (!thirdImg) {
          await handleImageDelete(2);
        }
      } else {
        console.log('No caddie profile found for this user.');
      }

      setLoading(false);
      navigate('/userPage');
    } catch (error) {
      setLoading(false);
      console.error('Cannot save', error);
    }
  };


  async function uploadImage(file, name) {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      });

      const storageRef = ref(storage, `caddie_about_image/${userData.uid}/${name}.jpg`);
      await uploadBytes(storageRef, compressedFile);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  }

  async function deleteImage(name) {
    try {
      const storageRef = ref(storage, `caddie_about_image/${userData.uid}/${name}.jpg`);
      await deleteObject(storageRef);
      return true;
    } catch (error) {
      console.error("Error deleting file:", error);
      return false;
    }
  }


  const handleView = useMemo(() => {

    const imageList = [{
      imageUrl: userData.profileImage,
      order: 0,
      isPrimary: false
    }];

    if (secImg) {
      imageList.push(secImg);
    }
    if (thirdImg) {
      imageList.push(thirdImg);
    }

    return (
      <Modal show={viewModal} onHide={() => setViewModal(false)} animation={false} centered>
        <Modal.Body className='d-flex justify-content-center px-0'>
          <div style={{ width: 400, height: 300 }}>
            <Carousel className='w-100 h-100'>
              {imageList.map((image, index) => {
                if (!image.imageUrl) return null;

                return (
                  <Carousel.Item key={index} interval={3000}>
                    <img src={image.imageUrl} alt={image.order}
                      style={{ objectFit: "cover", borderRadius: "15px", height: 300 }}
                      className='w-100'
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </Modal.Body>
        <Modal.Body>
          <h4 className='fw-600'>Intro</h4>
          <span>{desctext}</span>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn bg-current text-white fw-600 p-2 w150' onClick={() => handleSave()}>
            {!loading ? ('Save and Exit') : (
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}

          </button>
          <button className='btn bg-light theme-dark-bg p-2' onClick={() => setViewModal(false)}>Cancel</button>
        </Modal.Footer>
      </Modal>
    )
  }, [userData.profileImage, viewModal, loading]);


  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h1 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">About</h1>

                      <h4 className='fw-600'>Images</h4>

                      <div className='row'>
                        {/* main pic */}
                        <div className='main-pic col-lg-4' style={{ height: 190 }}>
                          <img src={userData?.profileImage} alt="main" className='w-100 h-100 p-2' style={{ objectFit: 'cover' }} />
                        </div>
                        {/* second pic */}
                        <div className='second-pic col-lg-4' style={{ height: 190 }}>
                          {secImg && secImg?.imageUrl !== "" ? (
                            <>
                              <img src={secImg?.imageUrl} alt="sec" className='w-100 h-100 p-2' style={{ objectFit: 'cover' }} />
                              <i className='bi bi-trash delete-pic-icon' onClick={() => handleRemoveImage('sec')}></i>
                            </>
                          ) : (
                            <>
                              <div className='w-100 h-100 p-2'>
                                <div className='w-100 h-100 theme-dark-bg border rounded-3'></div>
                              </div>
                              <ReactSVG src='assets/images/dooNineIconV2/others/profile-edit-image.svg' className='select-pic-icon' onClick={() => document.getElementById('sec-image-input').click()} />
                            </>
                          )}
                          <input type="file" accept='image/*' className='d-none' id='sec-image-input' onChange={(e) => handleImageChange(e, 'sec')} />
                        </div>
                        {/* third pic */}
                        <div className='third-pic col-lg-4' style={{ height: 190 }}>
                          {thirdImg && thirdImg?.imageUrl !== "" ? (
                            <>
                              <img src={thirdImg?.imageUrl} alt="third" className='w-100 h-100 p-2' style={{ objectFit: 'cover' }} />
                              <i className='bi bi-trash delete-pic-icon' onClick={() => handleRemoveImage('third')}></i>
                            </>
                          ) : (
                            <>
                              <div className='w-100 h-100 p-2'>
                                <div className='w-100 h-100 theme-dark-bg border rounded-3'></div>
                              </div>
                              <ReactSVG src='assets/images/dooNineIconV2/others/profile-edit-image.svg' className='select-pic-icon' onClick={() => document.getElementById('third-image-input').click()} />
                            </>
                          )}
                          <input type="file" accept='image/*' className='d-none' id='third-image-input' onChange={(e) => handleImageChange(e, 'third')} />
                        </div>
                      </div>

                      <hr />

                      <h4 className='fw-600'>Intro</h4>
                      <div>
                        <textarea name="desc-text" value={desctext} onChange={(e) => setDescText(e.target.value)} className='w-100 form-control' style={{ minHeight: 70, maxHeight: 150, lineHeight: 1.2 }}></textarea>
                      </div>
                      <hr />
                      <div className='d-flex justify-content-end gap-2'>
                        <button className='btn bg-current text-white fw-600 p-3' onClick={() => setViewModal(true)}>View and Save</button>
                        <Link to={'/userPage'}><button className='btn bg-light theme-dark-bg p-3'><h6 className='m-0 fw-600'>Cancel</h6></button></Link>
                        {handleView}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageCropper
        show={croppedModal}
        hide={hideCroppedModal}
        profileImage={imageChanged}
        croppedImage={handleCroppedImage}
        isCover={true}
      />
      <Popupchat />
      <Appfooter />
    </>
  )
}

export default EditCaddieAbout