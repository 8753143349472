import React from 'react'
import { useScore } from './ScoreContext/ScoreContext'
import StrokeGross from './scoring/stroke/StrokeGross';
import StrokeTopar from './scoring/stroke/StrokeTopar';

const ScoreStroke = () => {

  const { roundData, playerGameMode } = useScore();

  if (playerGameMode) {
    switch (playerGameMode.scoring) {
      case "Gross":
        return <StrokeGross />

      case "To Par":
        return <StrokeTopar />

      default:
        console.warn('Incorrect scoring', playerGameMode.scoring);
        break;
    }
  } else {
    switch (roundData.scoring) {
      case "Gross":
        return <StrokeGross />

      case "To Par":
        return <StrokeTopar />

      default:
        console.warn('Incorrect scoring', roundData.scoring);
        break;
    }
  }
}

export default ScoreStroke