import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

function NumberBox({ index }) {
  const cellStyle = {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
    borderBottom: "2px solid #ddd",
    borderTop: "2px solid #ddd",
    borderRight: "2px solid #ddd",
    borderLeft: "2px solid #ddd",
  };
  const colorStyles = {
    grey: { backgroundColor: "#A6A6A6", borderRadius: "6px" },
    white: { backgroundColor: "#F5F5F5", borderRadius: "6px" },
  };

  return (
    <>
      <div
        style={{
          ...cellStyle,
          ...colorStyles.white,
          position: "relative",
          fontSize: "2rem",
          lineHeight: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#f5427d",
            position: "relative",
            zIndex: 1,
          }}
        >
          <h1
            className="fw-700"
            style={{
              color: "#f5427d",
            }}
          >
            {index + 1}
          </h1>
        </span>
      </div>
    </>
  );
}

export default NumberBox;
