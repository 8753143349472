import React, { useRef } from "react";
import { Dropdown } from "react-bootstrap";

function CaddieSearch({ search, setSearch, openSearch, setOpenSearch }) {
  const inputRef = useRef(null);

  const handleOnTypeSearch = (e) => {
    const value = e.target.value;

    if (value.trim() === "") {
      setSearch({
        ...search,
        text: {
          state: false,
          value: "",
        },
      });
    } else {
      setSearch({
        ...search,
        text: {
          state: true,
          value: value,
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    const value = e.target.value;
    if (e.key === "Enter") {
      setOpenSearch(false);
      triggerSearch(value);
    }
  };

  const triggerSearch = (value) => {
    console.log(value);
    setSearch({
      ...search,
      text: {
        state: true,
        value: value,
      },
    });

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <>
      <Dropdown show={openSearch} onToggle={() => setOpenSearch(!openSearch)}>
        <Dropdown.Toggle as="div">
          <div className="search-form-2 flex-grow-1">
            <i className="ti-search font-xss"></i>
            <input
              type="text"
              className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
              placeholder="Search here."
              onChange={handleOnTypeSearch} // ค้นหาทันทีที่พิมพ์
              onKeyDown={handleKeyDown} // ค้นหาเมื่อกด Enter
              ref={inputRef}
              value={search.text.value}
            />
          </div>
        </Dropdown.Toggle>
      </Dropdown>
    </>
  );
}

export default CaddieSearch;
