import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

function RoundsCount({ activeSectionRound, setActiveSectionRound }) {
  const [listRoundUpcoming, setListRoundUpcoming] = useState([]);
  const [listRoundFinished, setListRoundFinsihed] = useState([]);

  const userData = useSelector((state) => state.user.data);

  // Handlers for clicking each section
  const handleUpcomingClick = () => {
    setActiveSectionRound("upcoming");
  };

  const handleFinishedClick = () => {
    setActiveSectionRound("finished");
  };

  useEffect(() => {
    const fetchData = () => {
      const roundQuery = query(collection(firestore, "round"));
  
      const unsubscribe = onSnapshot(roundQuery, async (roundSnapshot) => {
        let dataRondUpcoming = [];
        let dataRondFinished = [];
  
        // Collect all asynchronous fetch operations
        const roundPromises = roundSnapshot.docs.map(async (doc) => {
          const roundData = doc.data();
          const roundId = doc.id;
  
          const fetchScoreDetails = async (uid, roundId) => {
            if (!uid || !roundId) return {}; // Guard against undefined values
  
            const q = query(
              collection(firestore, "scoreCards"),
              where("userId", "==", uid),
              where("roundId", "==", roundId)
            );
            const userSnapshot = await getDocs(q);
            let scoreDetails = {};
            userSnapshot.forEach((scoreDoc) => {
              scoreDetails = scoreDoc.data();
            });
            return scoreDetails;
          };
  
          const isCreator = roundData.creator === userData.uid;
          const isUserInList = roundData.userList.some(
            (user) =>
              (user.player?.uid === userData.uid && user.player?.isJoin === true) ||
              (user.caddie?.uid === userData.uid && user.caddie?.isJoin === true)
          );
  
          if (isCreator || isUserInList) {
            if (roundData.teeTime === "schedule" && (roundData.status === undefined || roundData.status !== "deleted")) {
              dataRondUpcoming.push({ ...roundData });
            }
  
            if ((roundData.teeTime === "now" || roundData.teeTime === "finished") && (roundData.status === undefined || roundData.status !== "deleted")) {
              const playerUids = roundData.userList
                .filter((user) => user.player?.uid === userData.uid || user.caddie?.uid === userData.uid)
                .map((user) => user.player?.uid);
  
              if (playerUids.length > 0) {
                const scoreDetailsArray = await Promise.all(
                  playerUids.map((uid) => fetchScoreDetails(uid, roundId))
                );
  
                roundData.userList = roundData.userList.map((user) => {
                  if (playerUids.includes(user.player?.uid)) {
                    const playerScore = scoreDetailsArray.find((details) => details.userId === user.player?.uid);
                    const status = playerScore ? playerScore.status : undefined;
                    return {
                      ...user,
                      player: { ...user.player, status },
                      caddie: { ...user.caddie, status },
                    };
                  }
                  return user;
                });
  
                const isRoundStatus = roundData.userList.some(
                  (user) =>
                    (user.player?.uid === userData.uid && user.player?.status === "finished" || user.player?.status === "unfinished") ||
                    (user.caddie?.uid === userData.uid && user.caddie?.status === "finished" || user.caddie?.status === "unfinished")
                );
  
                if (isRoundStatus && (roundData.teeTime === "now" || roundData.teeTime === "finished")) {
                  dataRondFinished.push({
                    ...roundData,
                    roundId: roundId,
                  });
                }
              }
            }
          }
        });
  
        // Wait for all rounds to be processed before updating the state
        await Promise.all(roundPromises);
  
        setListRoundUpcoming(dataRondUpcoming);
        setListRoundFinsihed(dataRondFinished);
      });
  
      return () => unsubscribe(); // Clean up the subscription on unmount
    };
  
    if (userData) {
      fetchData();
    }
  }, [userData]);
  

  return (
    <>
      {/* Card for selecting sections */}
      <Card className="border-0 shadow-xss rounded-xxl mb-1 mt-2">
        <Card.Body className="main-player-stat">
          <section className="gap-2 d-flex flex-md-nowrap flex-wrap mb-2">
            {/* First Section: Upcoming Round */}
            <div
              className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3"
              style={{
                backgroundColor: "#F2CFA4",
                border:
                  activeSectionRound === "upcoming"
                    ? "3px solid #E59E49"
                    : "none",
                cursor: "pointer",
              }}
              onClick={handleUpcomingClick}
            >
              <strong
                className="px-3 rounded-3"
                style={{ backgroundColor: "#E59E49" }}
              >
                {listRoundUpcoming.length}
              </strong>
              <h4 className="mb-3 ms-3 fw-600" style={{ color: "#E59E49" }}>
                {`Upcoming Round`}
              </h4>
            </div>

            {/* Second Section: Finished Round */}
            <div
              className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3"
              style={{
                backgroundColor: "#909294",
                border:
                  activeSectionRound === "finished"
                    ? "3px solid #212529"
                    : "none",
                cursor: "pointer",
              }}
              onClick={handleFinishedClick}
            >
              <strong
                className="px-3 rounded-3"
                style={{ backgroundColor: "#212529" }}
              >
                {listRoundFinished.length}
              </strong>
              <h4 className="mb-3 ms-3 fw-600" style={{ color: "#212529" }}>
                {`Finished Round`}
              </h4>
            </div>
          </section>
        </Card.Body>
      </Card>
    </>
  );
}

export default RoundsCount;
