import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useScore } from '../ScoreContext/ScoreContext';

const HoleDetail = ({ hole, isOpen, closeModal }) => {
    const { courseHoles } = useScore();
    const [currentHole, setCurrentHole] = useState(hole.holeNumber);
    const [foundHole, setFoundHole] = useState(hole);
    const [noNext, setNoNext] = useState(false);
    const [noPrev, setNoPrev] = useState(false);




    useEffect(() => {
        function findHoleByNumber(holeNumber) {
            return courseHoles.holeDetails.find(item => item.holeNumber === holeNumber);
        }
        // check next and prev holes
        setNoNext(!findHoleByNumber(currentHole + 1));
        setNoPrev(!findHoleByNumber(currentHole - 1));
    }, [courseHoles.holeDetails, currentHole, isOpen]);

    function handleClose() {
        closeModal();
        setFoundHole(hole);
        setCurrentHole(hole.holeNumber);
    }


    function nextHole() {
        const nextHole = courseHoles.holeDetails.find(item => item.holeNumber === currentHole + 1);
        if (nextHole) {
            setCurrentHole(currentHole + 1);
            setFoundHole(nextHole);
        } else {
            console.log("No more holes ahead");
        }
    }

    function prevHole() {
        const prevHole = courseHoles.holeDetails.find(item => item.holeNumber === currentHole - 1);
        if (prevHole) {
            setCurrentHole(currentHole - 1);
            setFoundHole(prevHole);
        } else {
            console.log("No more holes behind");
        }
    }



    return (
        <Modal show={isOpen} onHide={handleClose} centered animation={false}>
            <Modal.Body>
                <div className='d-flex justify-content-between bg-dark p-3 rounded-3'>
                    <h4 className='fw-600 text-white'>{courseHoles.courseHoleName}</h4>
                    <div className=' d-flex align-items-baseline text-white'>
                        <sup className='fs-5 fw-600 me-2'>No.</sup>
                        <h3 className='fw-600 text-white'>{foundHole.holeNumber}</h3>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Body>
                <div className='row'>
                    <div className='col-sm-10 col-9' style={{ maxHeight: 600 }}>
                        <img src={foundHole.holeImage} alt="hole-img" className='w-100 h-100' style={{ objectFit: 'contain' }} />
                    </div>
                    <div className='col-sm-2 col-3 ps-0'>
                        <div className='bg-dark p-1 text-center rounded-3 mb-2'>
                            <div className='mb-1 rounded-3 fw-600' style={{ backgroundColor: '#E9F6E9' }}>Par<br />
                                <span className='fs-4 fw-600'>{foundHole.par}</span>
                            </div>
                            <div className='rounded-3 fw-600' style={{ backgroundColor: '#E9F6E9' }}>Index<br />
                                <span className='fs-4 fw-600'>{foundHole.index}</span>
                            </div>
                        </div>

                        <div className='bg-dark p-1 text-center rounded-3'>
                            <div className='mb-1 rounded-3 fw-600' style={{ backgroundColor: '#FFFED5' }}>Y<br />
                                <span className='fs-4 fw-600'>{foundHole.yellowTee}</span>
                            </div>
                            <div className='mb-1 rounded-3 fw-600' style={{ backgroundColor: '#FFD5D5' }}>R<br />
                                <span className='fs-4 fw-600'>{foundHole.redTee}</span>
                            </div>
                            <div className='mb-1 rounded-3 fw-600' style={{ backgroundColor: '#CAD0FE' }}>BL<br />
                                <span className='fs-4 fw-600'>{foundHole.blueTee}</span>
                            </div>
                            <div className='mb-1 rounded-3 fw-600' style={{ backgroundColor: '#FFFFFF' }}>W<br />
                                <span className='fs-4 fw-600'>{foundHole.whiteTee}</span>
                            </div>
                            <div className='rounded-3 fw-600 text-white' style={{ backgroundColor: '#000000' }}>B<br />
                                <span className='fs-4 fw-600'>{foundHole.blackTee}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className=' d-flex justify-content-end align-items-center gap-2'>
                    {noPrev ?
                        <button className='btn bg-light' disabled>Back</button>
                        :
                        <button className='btn bg-current text-white' onClick={() => prevHole()}>Back</button>
                    }
                    {noNext ?
                        <button className='btn bg-light' disabled>Next</button>
                        :
                        <button className='btn bg-current text-white' onClick={() => nextHole()}>Next</button>
                    }
                    <button className='btn bg-light' onClick={() => handleClose()}>OK</button>

                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default HoleDetail