import React, { createContext, useContext, useState, useEffect } from 'react';

const ScoreContext = createContext();

export const useScore = () => {
    return useContext(ScoreContext);
};


export const ScoreProvider = ({ children }) => {

    const [roundData, setRoundData] = useState(null);
    const [userList, setUserList] = useState([]);
    const [courseHoles, setCourseHoles] = useState(null);
    const [score, setScore] = useState(null);
    const [playerGameMode, setPlayerGameMode] = useState(null);

    // use for next hole button in scorebox
    const [currentHole, setCurrentHole] = useState(null);
    const [nextHoleNum, setNextHoleNum] = useState(null)

    useEffect(() => {
        if (!roundData) return
        setUserList(roundData.userList);
    }, [roundData])

    const nextHole = () => {
        const holeNum = Number(currentHole.holeNumber);
        setNextHoleNum(holeNum + 1)
    }


    return (
        <ScoreContext.Provider value={{
            roundData,
            setRoundData,
            userList,
            setCourseHoles,
            courseHoles,
            score,
            setScore,
            setPlayerGameMode,
            playerGameMode,
            nextHole,
            currentHole,
            setCurrentHole,
            nextHoleNum
        }}>
            {children}
        </ScoreContext.Provider>
    );
};