import { firestore } from "../../../../components/firebase/FirebaseConfig";
import { collection, where, getDocs, updateDoc, query, addDoc } from "firebase/firestore";


export async function updateClub(myClub, userData) {

    const clubsCollection = collection(firestore, 'clubs');
    const q = query(clubsCollection, where("userId", "==", userData.uid));

    try {
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            querySnapshot.forEach(async (doc) => {
                const docRef = doc.ref;
                await updateDoc(docRef, { clubs: myClub });
            });
        } else {
            await addDoc(clubsCollection, { userId: userData.uid, clubs: myClub });
        }
    } catch (e) {
        console.error("Error adding or updating document: ", e);
    }
}