import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './UserContext';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/login" />;
    }

    return Component ? <Component {...rest} /> : <Outlet />;
};

export default ProtectedRoute;
