import { useEffect, useRef, useState } from 'react';

const useModalWithHistory = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalClose, setIsModalClose] = useState(true);
    const [openCount, setOpenCount] = useState(0);
    const [wasModalOpen, setWasModalOpen] = useState(false);
    const modalRef = useRef(false);

    const openModal = () => {
        setIsModalOpen(true);
        setIsModalClose(false);
        setOpenCount(prevCount => prevCount + 1);
        modalRef.current = true;
        setWasModalOpen(true);
        window.history.pushState({ modal: true }, '');
    };

    const closeModal = () => {
        setIsModalOpen(false);
        if (window.history.state?.modal) {
            window.history.back();
        }
    };

    const toggleModal = () => {
        if (isModalOpen) {
            closeModal();
        } else {
            openModal();
        }
    };

    const resetModal = () => {
        setIsModalOpen(false);
        setOpenCount(0);
        setWasModalOpen(false);
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (event.state?.modal || modalRef.current) {
                setIsModalOpen(false);
                setWasModalOpen(false);
                setIsModalClose(true);
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    return {
        isModalOpen,
        openCount,
        wasModalOpen,
        isModalClose,
        openModal,
        closeModal,
        toggleModal,
        resetModal,
    };
};

export default useModalWithHistory;
