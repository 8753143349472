import React, { useState, useEffect, useRef, useMemo } from 'react'

import { Modal } from 'react-bootstrap';
import useModalWithHistory from '../../../components/ModalPopState';
import Picker from 'react-mobile-picker';
import ReactSwitch from 'react-switch';
import { defDefaultClubData, clubTypeDef } from './module/clubDef';
import clsx from 'clsx';

const switchStyle = {
    width: 44,
    height: 20,
    onColor: '#EE3D7F',
    boxShadow: '0 0 2px 1px #fff',
    handleDiameter: 16
}

const RenderClub = ({ myClub, defaultClubs, submitedClub, index, removeClub }) => {

    const isMobile = useRef(window.matchMedia("(max-width: 768px)").matches);
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();
    const [editDistanceModal, setEditDistanceModal] = useState(false);
    const [selectedValueType, setSelectedValueType] = useState(null);
    const clubTypeRef = useRef(myClub.type);
    const clubNumberChangeRef = useRef(myClub.number);
    const isIronNumChange = useRef(false);
    const isClubChanged = useRef(false);

    const [editClubName, setEditClubName] = useState(false);
    const [clubName, setClubName] = useState(myClub.name);
    const [inBag, setInBag] = useState(myClub.inBag);

    const noteRef = useRef();

    const [selectedClub, setSelectedClub] = useState({
        type: myClub.type,
    })

    const [selectedClubNumber, setSelectedClubNumber] = useState({
        number: myClub.number,
    })

    const [selectedClubLoft, setSelectedClubLoft] = useState({
        loftFull: Math.floor(myClub.loft),
        loftDec: Math.round((myClub.loft - Math.floor(myClub.loft)) * 10)
    })

    const [selectedClubDistance, setSelectedClubDistance] = useState({
        distance: myClub.distance,
    })

    const [selectedClubData, setSelectedClubData] = useState(null);


    const clubSelections = {
        type: [...new Set(defaultClubs.map(club => club.type))],
    };
    clubSelections.type.push("Chipper");

    const clubDistSelection = {
        distance: Array.from({ length: 547 }, (_, i) => i)
    }

    useEffect(() => {

        if (selectedClub.type === "Iron") {
            if (clubNumberChangeRef.current !== selectedClubNumber.number) {
                clubNumberChangeRef.current = selectedClubNumber.number;
                isIronNumChange.current = true;
            } else {
                isIronNumChange.current = false;
            }
        } else {
            isIronNumChange.current = false;
        }

        if (clubTypeRef.current !== selectedClub.type) {
            isIronNumChange.current = false;
            switch (selectedClub.type) {
                case 'Driver':
                    setSelectedClubLoft({
                        loftFull: 10,
                        loftDec: 0
                    })
                    setSelectedClubDistance({ distance: 230 })
                    break;

                case 'Wood':
                    setSelectedClubNumber({ number: 3 })
                    setSelectedClubDistance({ distance: 230 })
                    break;

                case 'Iron':
                    setSelectedClubNumber({ number: 2 })
                    setSelectedClubDistance({ distance: 202 })
                    break;

                case 'Hybrid':
                    setSelectedClubNumber({ number: 7 })
                    setSelectedClubDistance({ distance: 197 })
                    break;

                case 'Pitching Wedge':
                    setSelectedClubLoft({
                        loftFull: 46,
                        loftDec: 0
                    })
                    setSelectedClubDistance({ distance: 98 })
                    break;

                case 'Gap Wedge':
                    setSelectedClubLoft({
                        loftFull: 52,
                        loftDec: 0
                    })
                    setSelectedClubDistance({ distance: 87 })
                    break;

                case 'Sand Wedge':
                    setSelectedClubLoft({
                        loftFull: 56,
                        loftDec: 0
                    })
                    setSelectedClubDistance({ distance: 71 })
                    break;

                case 'Lob Wedge':
                    setSelectedClubLoft({
                        loftFull: 60,
                        loftDec: 0
                    })
                    setSelectedClubDistance({ distance: 55 })
                    break;

                case 'Chipper':
                    setSelectedClubLoft({
                        loftFull: 34,
                        loftDec: 0
                    })
                    setSelectedClubDistance({ distance: 55 })
                    break;

                default:
                    break;
            }
            clubTypeRef.current = selectedClub.type
        }

        const defaultClubData = defDefaultClubData(selectedClub.type,
            selectedClubNumber.number,
            selectedClubLoft,
            selectedClubDistance.distance,
            isIronNumChange.current
        )


        if (myClub.type !== defaultClubData.type ||
            isClubChanged.current ||
            myClub.loft !== defaultClubData.loft ||
            myClub.number !== defaultClubData.number
        ) {
            isClubChanged.current = true
            const isWedgeOrDriver = ['Pitching Wedge', 'Gap Wedge', 'Sand Wedge', 'Lob Wedge', 'Driver'].includes(defaultClubData?.type);
            const defaultValue = `${defaultClubData?.number || ""} ${defaultClubData?.name || ""} ${isWedgeOrDriver ? `${defaultClubData?.loft || ""}°` : ""
                }`.trim();

            setClubName(defaultValue)
        }


        if (selectedClub.type === "Iron") {
            setSelectedClubDistance({
                distance: defaultClubData.distance
            })
        }

        setSelectedClubData(defaultClubData);

    }, [selectedClub.type, selectedClubNumber.number, selectedClubLoft, selectedClubDistance.distance, selectedClubData?.type, selectedClubData?.name, selectedClubData?.loft, selectedClubData?.number],)

    const renderPicker = useMemo(() => {
        switch (selectedValueType) {
            case 'clubType':
                return (
                    <div>
                        <h4 className='m-0 fw-bold'>Select Club type</h4>
                        <Picker value={selectedClub} onChange={setSelectedClub} wheelMode="normal" height={160}>
                            <Picker.Column name="type">
                                {clubSelections.type.map((type, index) => (
                                    <Picker.Item key={index} value={type}>
                                        {type}
                                    </Picker.Item>
                                ))}
                            </Picker.Column>
                        </Picker>
                    </div>
                )

            case 'clubNum':
                let clubNumberSelection = {}
                if (selectedClub.type === 'Wood') {
                    clubNumberSelection = {
                        number: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
                    }
                } else {
                    clubNumberSelection = {
                        number: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
                    }
                }
                return (
                    <div>
                        <h4 className='m-0 fw-bold'>Select Club Number</h4>
                        <Picker value={selectedClubNumber} onChange={setSelectedClubNumber} wheelMode="normal" height={160}>
                            <Picker.Column name="number">
                                {clubNumberSelection.number.map((number, index) => (
                                    <Picker.Item key={index} value={number}>
                                        {number}
                                    </Picker.Item>
                                ))}
                            </Picker.Column>
                        </Picker>
                    </div>
                )

            case 'clubLoft':
                let clubLoftSelection = {}

                const loftDec = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                switch (selectedClub.type) {
                    case 'Driver':
                        clubLoftSelection = {
                            loftFull: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            loftDec: loftDec
                        }
                        break;
                    case 'Pitching Wedge':
                        clubLoftSelection = {
                            loftFull: [44, 45, 46, 47, 48],
                            loftDec: loftDec
                        }
                        break;

                    case 'Gap Wedge':
                        clubLoftSelection = {
                            loftFull: [50, 51, 52, 53, 54, 55],
                            loftDec: loftDec
                        }
                        break;

                    case 'Sand Wedge':
                        clubLoftSelection = {
                            loftFull: [54, 55, 56, 57],
                            loftDec: loftDec
                        }
                        break;

                    case 'Lob Wedge':
                        clubLoftSelection = {
                            loftFull: [58, 59, 60, 61, 62, 63, 64],
                            loftDec: loftDec
                        }
                        break;

                    case 'Chipper':
                        clubLoftSelection = {
                            loftFull: [30, 31, 32, 33, 34, 35, 36, 37],
                            loftDec: loftDec
                        }
                        break;

                    default:
                        break;
                }
                const columns = Object.keys(clubLoftSelection);

                return (
                    <div>
                        <h4 className='m-0 fw-bold'>Select Club Loft</h4>
                        <Picker value={selectedClubLoft} onChange={setSelectedClubLoft} wheelMode="normal" height={160}>
                            {Object.keys(clubLoftSelection).map((name, index) => (
                                <React.Fragment key={name}>
                                    <Picker.Column name={name}>
                                        {clubLoftSelection[name].map(option => (
                                            <Picker.Item key={option} value={option}>
                                                {option}
                                            </Picker.Item>
                                        ))}
                                    </Picker.Column>
                                    {index < columns.length - 1 && (
                                        <div style={{ width: 10, fontSize: 30 }} className='d-flex align-items-center mb-2'>.</div>
                                    )}
                                </React.Fragment>
                            ))}
                        </Picker>
                    </div>
                )

            case 'clubDist':
                return (
                    <div>
                        <h4 className='m-0 fw-bold'>Select Club Distance</h4>
                        <Picker value={selectedClubDistance} onChange={setSelectedClubDistance} wheelMode="normal" height={160}>
                            <Picker.Column name="distance">
                                {clubDistSelection.distance.map((distance, index) => (
                                    <Picker.Item key={index} value={distance}>
                                        {distance} yd
                                    </Picker.Item>
                                ))}
                            </Picker.Column>
                        </Picker>
                    </div>
                )
            default:
                return null;
        }


    }, [selectedValueType, selectedClub, clubSelections.type, selectedClubNumber]);

    const handleSubmit = () => {
        const name = clubName;
        const type = selectedClub.type;
        const number = selectedClubNumber.number;
        const { loftFull, loftDec } = selectedClubLoft;
        const distance = selectedClubDistance.distance;
        const note = noteRef?.current?.value || "";
        const isInBag = inBag;

        const data = {
            name: name,
            type: type,
            number: number,
            loft: {
                loftFull: loftFull,
                loftDec: loftDec
            },
            distance: distance,
            note: note,
            inBag: isInBag
        }

        const clubData = clubTypeDef(data);
        submitedClub(clubData, index)
        closeModal()
    }

    const handleEditDistance = () => {
        setEditDistanceModal(true);

    }

    return (
        <>
            <li className="list-inline-item d-block border-bottom me-0 my-3 pointer" onClick={openModal}>
                <div className={clsx("pt-2 pb-2 d-flex justify-content-between align-items-center", { "text-muted": !myClub.inBag })}>
                    <div className='text-truncate me-4'>
                        <span className="fw-600 font-xsss mb-0 mt-0">{myClub.name}</span>
                        <small className='ms-2'>{myClub.note}</small>
                    </div>
                    <div className='d-flex gap-4 align-items-center'>
                        <span className='fw-600 pointer text-nowrap' onClick={(event) => {
                            event.stopPropagation();
                            handleEditDistance()
                        }}>{myClub.distance} {myClub.type !== 'Putter' ? 'yd' : null}</span>
                        <i className="ti-angle-right font-xsss text-grey-500 ms-auto"></i>
                    </div>
                </div>
            </li>

            <Modal show={isModalOpen} onHide={closeModal} centered animation={false} fullscreen={isMobile.current ? true : false}>
                <Modal.Header>
                    <Modal.Title><h2 className='fw-bold'>Edit Club</h2></Modal.Title>

                </Modal.Header>
                <Modal.Body className='add-club-main-body'>

                    <div className='row mb-2' >
                        <label htmlFor='club-name' className="col-5 col-form-label"><strong>Club Name</strong></label>
                        <div className="col-7">
                            <div className='input-group'>
                                <input
                                    type="text"
                                    name="club-name"
                                    id="club-name"
                                    className='form-control'
                                    disabled={editClubName ? false : true}
                                    value={clubName}
                                    onChange={(e) => setClubName(e.target.value)}
                                />
                                <button className='btn btn-light' onClick={() => setEditClubName(!editClubName)}><i className='bi bi-pencil-fill'></i></button>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2' >
                        <strong className='col-5'>Club Type</strong>
                        <div className='col-7 add-club-container'>
                            <div className='add-club-type rounded-3 pointer' onClick={() => setSelectedValueType('clubType')}>{selectedClubData?.type}</div>
                        </div>
                    </div>

                    {selectedClubData?.number ?
                        <div className='row mb-2' >
                            <strong className='col-5'>Club Number</strong>
                            <div className='col-7 add-club-container'>
                                <div className='add-club-num rounded-3 pointer' onClick={() => setSelectedValueType('clubNum')}>{selectedClubData?.number}</div>
                            </div>
                        </div>
                        : null}

                    {selectedClubData?.loft ?
                        <div className='row mb-2' >
                            <strong className='col-5'>Loft</strong>
                            <div className='col-7 add-club-container'>
                                <div className='add-club-loft rounded-3 pointer' onClick={() => setSelectedValueType('clubLoft')}>{selectedClubData?.loft + "°"}</div>
                            </div>
                        </div>
                        : null}

                    {selectedClubData?.type !== "Putter" ? <div className='row mb-2' >
                        <strong className='col-5'>Distance</strong>
                        <div className='col-7 add-club-container'>
                            <div className='add-club-dist rounded-3 pointer' onClick={() => setSelectedValueType('clubDist')}>{selectedClubData?.distance} yd</div>
                        </div>
                    </div>
                        : null}

                    <div className='row mb-2' >
                        <label htmlFor='note' className="col-5 col-form-label"><strong>Note</strong></label>
                        <div className="col-7">
                            <input
                                ref={noteRef}
                                type="text"
                                name="note"
                                id="note"
                                className='form-control'
                                defaultValue={myClub?.note}
                            />
                        </div>
                    </div>

                    <div className='row mb-2' >
                        <strong className='col-5'>In Bag</strong>
                        <div className="col-auto">
                            <ReactSwitch
                                onChange={() => setInBag(!inBag)}
                                checked={inBag ? true : false}
                                onColor={switchStyle.onColor}
                                width={switchStyle.width}
                                height={switchStyle.height}
                                handleDiameter={switchStyle.handleDiameter}
                                activeBoxShadow={switchStyle.boxShadow} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Body>
                    {renderPicker}
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <button className='btn btn-danger' onClick={() => { removeClub(index); closeModal() }}>Remove</button>
                    <section>
                        <button className='btn bg-current text-white' onClick={handleSubmit}>OK</button>
                        <button className='btn btn-light' onClick={closeModal}>Cancel</button>
                    </section>
                </Modal.Footer>
            </Modal>

            <Modal show={editDistanceModal} onHide={() => setEditDistanceModal(false)} centered animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h3><strong>{myClub.name}</strong></h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='m-0 fw-bold'>Select Club Distance</h4>
                    <Picker value={selectedClubDistance} onChange={setSelectedClubDistance} wheelMode="normal" height={160}>
                        <Picker.Column name="distance">
                            {clubDistSelection.distance.map((distance, index) => (
                                <Picker.Item key={index} value={distance}>
                                    {distance} yd
                                </Picker.Item>
                            ))}
                        </Picker.Column>
                    </Picker>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn bg-current text-white' onClick={handleSubmit}>OK</button>
                    <button className='btn btn-light' onClick={() => setEditDistanceModal(false)}>Cancel</button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default RenderClub

