import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import {
  getDoc,
  query,
  where,
  getDocs,
  collection,
  doc,
  addDoc,
  onSnapshot,
  updateDoc,
  or,
  and,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import NumberBox from "./component/NumberBox";
import ImageProfileBox from "./component/ImageProfileBox";
import ScoreBox from "./component/ScoreBox";
import LoadCircle from "../../components/LoadCircle";
import Stableford from "./mode/Stableford";

function LeaderBoard() {
  const navigate = useNavigate();

  const [roundId, setRoundId] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const [holeData, setHoleData] = useState([]);
  const [scoreData, setScoreData] = useState(null);
  const [holeScoreData, setHoleScoreData] = useState([]);
  const [totalScoreData, setTotalScoreData] = useState([]);
  const [courseHoleData, setCourseHoleData] = useState(null);
  const [load, setLoad] = useState(true)

  const [trigger, setTrigger] = useState(false);

  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams(window.location.search);

      const roundId = params.get("round");
      setRoundId(roundId);

      if (roundId == null) {
        Swal.fire({
          title: "Invalid Round",
          html: `
            <h5 class="fw-600">The round you selected is invalid.</h5>
            <h5 class="fw-600">Please check and try again.</h5>
          `,
          icon: "error",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        }).then(async (result) => {
          navigate("/round-activity");
        });
      } else {
        const docRef = doc(firestore, "round", roundId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          //Check is scorecard exist
          // Check is user can view Leaderboard
          if (docSnap.data().isLeaderBoardPublic === true) {
            console.log("Leaderboard is public");
          } else {
            const isInRound = docSnap
              .data()
              .userList.some(
                (user) =>
                  (user.caddie && user.caddie.uid === userData.uid) ||
                  (user.player && user.player.uid === userData.uid)
              );

            if (isInRound === false) {
              Swal.fire({
                title: "Round is Private",
                html: `
                  <h5 class="fw-600">The round you selected is private.</h5>
                  <h5 class="fw-600">Please check and try again.</h5>
                `,
                icon: "error",
                confirmButtonColor: "#ee3d7f",
                confirmButtonText: "OK",
              }).then(async (result) => {
                navigate("/round-activity");
              });
            }
          }

          let userList = docSnap.data().userList;
          // console.log(userList);


          // if (docSnap.data().holesCourse) {
          //   for (let index = 0; index < userList.length; index++) {
          //     console.log(index);

          //     const element = userList[index];
          //     let q = null;
          //     if (!element.player && !element.caddie) {
          //       // Case 1: Neither player nor caddie exists, skip this element
          //       console.log('no both');

          //       continue;
          //     } else if (element.player && !element.caddie) {
          //       // Case 2: Only player exists, query by player uid
          //       console.log('player');

          //       q = query(
          //         collection(firestore, "scoreCards"),
          //         and(
          //           where("roundId", "==", roundId),
          //           where("userId", "==", element.player.uid)
          //         )
          //       );
          //     } else if (!element.player && element.caddie) {
          //       // Case 3: Only caddie exists, query by caddie uid
          //       console.log('caddie');

          //       q = query(
          //         collection(firestore, "scoreCards"),
          //         and(
          //           where("roundId", "==", roundId),
          //           where("userId", "==", element.caddie.uid)
          //         )
          //       );
          //     } else if (element.player && element.caddie) {
          //       // Case 4: Both player and caddie exist, query for either player or caddie uid
          //       console.log('both');

          //       q = query(
          //         collection(firestore, "scoreCards"),
          //         where("roundId", "==", roundId),
          //         where("userId", "==", element.player.uid),
          //         where("userId", "==", element.caddie.uid)
          //       );
          //     }

          //     const querySnapshot = await getDocs(q);


          //     // if (querySnapshot.empty) {
          //     //   const querySnapshot = await getDocs(q);
          //     //   if (querySnapshot.size === 0) {
          //     //     console.log("No such document!");

          //     //     if (docSnap.data().holes === "9 holes") {
          //     //       const holeObj = create9Holes();
          //     //       const docRef = await addDoc(
          //     //         collection(firestore, "scoreCards"), {
          //     //         ...holeObj,
          //     //         userId: userData.uid,
          //     //         roundId: roundId,
          //     //         caddieId: element?.caddie?.uid || null,
          //     //         status: 'playing'
          //     //       }
          //     //       );
          //     //     } else {
          //     //       const holeObj = create18Holes();
          //     //       const docRef = await addDoc(
          //     //         collection(firestore, "scoreCards"), {
          //     //         ...holeObj,
          //     //         userId: userData.uid,
          //     //         roundId: roundId,
          //     //         caddieId: element?.caddie?.uid || null,
          //     //         status: 'playing'
          //     //       }
          //     //       );
          //     //     }
          //     //   }
          //     // }
          //   }
          // } else {
          //   console.log("ยังไม่เลือก course hole");
          // }

          //   setUserList(docSnap.data().userList);

          for (let index = 0; index < userList.length; index++) {
            const element = userList[index];
            // console.log(element);

            if (element.caddie) {
              const q = query(
                collection(firestore, "users"),
                where("uid", "==", element.caddie.uid)
              );
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                userList[index].caddie.data = doc.data();
              });
            }

            if (element.player) {
              const q = query(
                collection(firestore, "users"),
                where("uid", "==", element.player.uid)
              );
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                userList[index].player.data = doc.data();
              });
            }
          }
          setRoundData({ ...docSnap.data(), userList: userList });

          if (docSnap.data().holesCourse) {
            // setIsSelectedCourse(true);

            const docRef2 = doc(
              firestore,
              "courseHoles",
              docSnap.data().holesCourse
            );
            const docSnap2 = await getDoc(docRef2);

            if (docSnap2.exists()) {
              setCourseHoleData(docSnap2.data());
              const holeDetails = docSnap2.data().holeDetails.map((hole) => ({
                holeNumber: hole.holeNumber,
                par: hole.par,
                handicapIndex: hole.index,
              }));

              setHoleData(holeDetails);
            }
          } else {
            // setIsSelectedCourse(false);
            setHoleData([
              { holeNumber: 1, par: 4, handicapIndex: 1 },
              { holeNumber: 2, par: 4, handicapIndex: 2 },
              { holeNumber: 3, par: 4, handicapIndex: 3 },
              { holeNumber: 4, par: 4, handicapIndex: 4 },
              { holeNumber: 5, par: 4, handicapIndex: 5 },
              { holeNumber: 6, par: 4, handicapIndex: 6 },
              { holeNumber: 7, par: 4, handicapIndex: 7 },
              { holeNumber: 8, par: 4, handicapIndex: 8 },
              { holeNumber: 9, par: 4, handicapIndex: 9 },
              { holeNumber: 10, par: 4, handicapIndex: 10 },
              { holeNumber: 11, par: 4, handicapIndex: 11 },
              { holeNumber: 12, par: 4, handicapIndex: 12 },
              { holeNumber: 13, par: 4, handicapIndex: 13 },
              { holeNumber: 14, par: 4, handicapIndex: 14 },
              { holeNumber: 15, par: 4, handicapIndex: 15 },
              { holeNumber: 16, par: 4, handicapIndex: 16 },
              { holeNumber: 17, par: 4, handicapIndex: 17 },
              { holeNumber: 18, par: 4, handicapIndex: 18 },
            ]);
          }
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
          Swal.fire({
            title: "Invalid Round",
            html: `
              <h5 class="fw-600">The round you selected is invalid.</h5>
              <h5 class="fw-600">Please check and try again.</h5>
            `,
            icon: "error",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/round-activity");
          });
        }
      }
    }
    if (userData) {
      fetchData();
    }
  }, [userData, trigger]);

  useEffect(() => {
    async function fetchData() {
      const userList = roundData.userList;
      try {
        // "NqcyjewRyJfdfzqHxmlMRln3iB23"
        let scoreData = [];

        for (let index = 0; index < userList.length; index++) {
          const element = userList[index];

          // Initialize an empty array to store the 'where' conditions
          let conditions = [];

          // Add conditions only if the respective values are not undefined
          if (element.player?.uid !== undefined) {
            conditions.push(where("userId", "==", element.player.uid));
          }

          if (element.caddie?.uid !== undefined) {
            conditions.push(where("caddieId", "==", element.caddie.uid));
          }

          // Add the roundId condition
          conditions.push(where("roundId", "==", roundId));

          // Create the query with the dynamically built conditions
          const q = query(collection(firestore, "scoreCards"), ...conditions);

          // Fetch and process the query results
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            scoreData.push({ docId: doc.id, ...doc.data() });
          });
        }
        setScoreData(scoreData);
      } catch (error) {
        console.error('Fail to get player score', error);
        setLoad(false);
      } finally {
        setLoad(false);
      }

    }
    if (roundData && roundId) {
      fetchData();
    }
  }, [roundData, roundId]);

  useEffect(() => {
    const unsubscribers = [];

    async function fetchData() {
      const userList = roundData.userList;

      for (let index = 0; index < userList.length; index++) {
        const element = userList[index];

        const q = query(
          collection(firestore, "scoreCards"),
          where(
            "userId",
            "==",
            element.player?.uid === undefined ? "" : element.player.uid
          ),
          where(
            "caddieId",
            "==",
            element.caddie?.uid === undefined ? "" : element.caddie.uid
          ),
          where("roundId", "==", roundId)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setScoreData((prevScoreData) => {
              return prevScoreData?.map((doc1) => {
                if (doc1.docId === doc.id) {
                  return { docId: doc.id, ...doc.data() }; // แทนที่ข้อมูลเก่าด้วยข้อมูลใหม่
                }
                return doc1; // ถ้าไม่ตรง docId ก็คืนค่าเดิม
              });
            });
          });
        });

        unsubscribers.push(unsubscribe);
      }
    }

    if (roundData) {
      if (roundData.gameMode !== "stableford") {
        fetchData();
      }
    }

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [roundData]);

  // Calculate Score of game
  useEffect(() => {
    if (!roundData) return;

    if (scoreData || holeData) {
      let array = [];
      holeData.map((hole) => {
        const holeScore = scoreData?.map((userScore) => {
          const holeNumberKey = `hole${hole.holeNumber}`;
          const scoreInfo = userScore[holeNumberKey] || {};
          return {
            userId: userScore.userId,
            caddieId: userScore.caddieId,
            roundId: userScore.roundId,
            docId: userScore.docId,
            ...scoreInfo,
          };
        });

        array.push({
          holeNumber: hole.holeNumber,
          par: hole.par,
          handicapIndex: hole.handicapIndex,
          holeScore: holeScore,
        });
      });

      const scoreSummary = [];

      if (
        (roundData?.gameMode === "Stroke Play" &&
          roundData?.scoring === "Gross" &&
          roundData?.handicap === "scratch") ||
        (roundData?.gameMode === "Match Play" &&
          roundData?.scoring === "Gross" &&
          roundData?.handicap === "scratch")
      ) {
        array.forEach((hole) => {
          hole?.holeScore?.forEach((score) => {
            const userId = score.userId;
            const caddieId = score.caddieId;
            let existingEntry = scoreSummary.find(
              (entry) =>
                entry.playerId === userId && entry.caddieId === caddieId
            );

            const user = scoreData.find(
              (item) => item.userId === "userId" || item.caddieId === caddieId
            );

            const currentScore =
              score.score === "-" ? 0 : parseInt(score.score, 10);
            const currentPutts =
              score.putts === "-" ? 0 : parseInt(score.putts);
            const currentPenalties =
              score.penalties === "-" ? 0 : parseInt(score.penalties);

            if (existingEntry) {
              existingEntry.totalScore += currentScore;
              existingEntry.totalPutts += currentPutts;
              existingEntry.totalPenalties += currentPenalties;
            } else {
              // ถ้ายังไม่มีข้อมูลของคู่นี้ ให้เพิ่มข้อมูลใหม่
              scoreSummary.push({
                playerId: userId,
                caddieId: caddieId,
                totalScore: currentScore,
                totalPutts: currentPutts,
                totalPenalties: currentPenalties,
                status: user?.status,
              });
            }
          });
        });
      } else if (
        (roundData?.gameMode === "Stroke Play" &&
          roundData?.scoring === "To Par" &&
          roundData?.handicap === "scratch") ||
        (roundData?.gameMode === "Match Play" &&
          roundData?.scoring === "To Par" &&
          roundData?.handicap === "scratch")
      ) {
        array.forEach((hole) => {
          hole?.holeScore?.forEach((score) => {
            const userId = score.userId;
            const caddieId = score.caddieId;

            const user = scoreData.find(
              (item) => item.userId === "userId" || item.caddieId === caddieId
            );

            let existingEntry = scoreSummary.find(
              (entry) =>
                entry.playerId === userId && entry.caddieId === caddieId
            );

            const hole = holeScoreData.find(
              (hole) =>
                hole.holeNumber === score?.holeNumber ||
                hole.holeNumber === parseInt(score?.holeNumber)
            );

            const currentScore =
              score.score === "-" ? 0 : parseInt(score.score, 10) - hole.par;
            const currentPutts =
              score.putts === "-" ? 0 : parseInt(score.putts);
            const currentPenalties =
              score.penalties === "-" ? 0 : parseInt(score.penalties);

            if (existingEntry) {
              existingEntry.totalScore += currentScore;
              existingEntry.totalPutts += currentPutts;
              existingEntry.totalPenalties += currentPenalties;
            } else {
              // ถ้ายังไม่มีข้อมูลของคู่นี้ ให้เพิ่มข้อมูลใหม่
              scoreSummary.push({
                playerId: userId,
                caddieId: caddieId,
                totalScore: currentScore,
                totalPutts: currentPutts,
                totalPenalties: currentPenalties,
                status: user?.status,
              });
            }
          });
        });
      } else {
        if (roundData.gameMode !== "Stableford" && roundData.gameMode !== "Match Play") {
          console.warn('Not found gamemode', roundData?.gameMode);
        }
      }
      const sortedData = scoreSummary.sort(
        (a, b) => a.totalScore - b.totalScore
      );
      setTotalScoreData(sortedData);
      setHoleScoreData(array);
    }

  }, [scoreData, holeData, roundData]);

  // console.log(userData);
  // console.log("roundData : ", roundData);
  // console.log("scoreData : ", scoreData);
  // console.log("holeData : ", holeData);
  // console.log("holeScoreData : ", holeScoreData);
  // console.log("totalScoreData : ", totalScoreData);
  // console.log("courseHoleData : ", courseHoleData);

  const handleCreateLink = async () => {
    Swal.fire({
      title: "Leaderboard Not Public",
      html: `
        <h5 class="fw-600">You need to set the leaderboard to public.</h5>
        <h5 class="fw-600">This will allow anyone with the link to view it.</h5>
      `,
      icon: "warning",
      confirmButtonColor: "#ee3d7f",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const ref = doc(firestore, "round", roundId);
        await updateDoc(ref, {
          isLeaderBoardPublic: true,
        });
        setTrigger(!trigger);
        Swal.fire({
          title: "Leaderboard Public",
          html: `
            <h5 class="fw-600">The leaderboard is now public.</h5>
            <h5 class="fw-600">You can share the link with anyone.</h5>
          `,
          icon: "success",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      }
    });
  };

  const tableContainerStyle = {
    maxWidth: "100%",
    // margin: "20px auto",
    borderRadius: "5px",
    overflow: "hidden",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "grid",
    gridTemplateColumns: `repeat(3, 1fr)`,
    gap: "6px",
  };

  // console.log("isLeaderBoardPublic : ", roundData?.isLeaderBoardPublic);

  const handleCopyToClipboard = () => {
    const url = `https://www.doonine.com/leader-board?round=${roundId}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        Swal.fire({
          title: "Copied to clipboard!",
          icon: "success",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Change to Private",
      html: `
        <h5 class="fw-600">You need to set the leaderboard to private.</h5>
        <h5 class="fw-600">Only player & caddies in round will be able to view it.</h5>
      `,
      icon: "warning",
      confirmButtonColor: "#ee3d7f",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const ref = doc(firestore, "round", roundId);
        await updateDoc(ref, {
          isLeaderBoardPublic: false,
        });
        setTrigger(!trigger);
        Swal.fire({
          title: "Leaderboard Set to Private",
          html: `
            <h5 class="fw-600">The leaderboard is now private.</h5>
            <h5 class="fw-600">Only player & caddies in round will be able to view it.</h5>
          `,
          icon: "success",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      }
    });
  };

  const cellStyle = {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
  };

  const colorStyles = {
    grey: { backgroundColor: "#F5F5F5", borderRadius: "6px" },
    white: { backgroundColor: "#F5F5F5", borderRadius: "6px" },
  };

  const isCreator = roundData?.userList?.some((user) => {
    if (user?.player?.uid === userData?.uid && user?.player?.isCreator) {
      return true;
    }

    if (
      user?.caddie &&
      user?.caddie?.uid === userData?.uid &&
      user?.caddie?.isCreator
    ) {
      return true;
    }

    return false;
  });

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3">
                  <div className="d-flex align-items-center">
                    <Link to={-1} className="d-inline-block mt-2">
                      <i className="ti-arrow-left font-sm text-white"></i>
                    </Link>
                    <div>
                      <h2 className="text-white fw-600 ms-4 mb-0 mt-0">
                        {roundData?.roundName}
                      </h2>
                      <h6 className="text-white fw-600 ms-4 mb-0 mt-0">
                        {roundData?.holes === "9 holes" && "9 Holes "}
                        {roundData?.holes === "18 holes" && "18 Holes "}(
                        {courseHoleData?.courseHoleName}), {roundData?.gameMode}{" "}
                        ({roundData?.scoring})
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="card-body p-lg-3 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-xl-12">
                      <h2 className="fw-600 mb-3">Leader Board</h2>
                    </div>
                    {!load ?
                      <div className="col-xl-12 mb-3">
                        {roundData?.gameMode !== "Stableford" ?
                          <>
                            <div
                              style={{
                                ...tableContainerStyle,
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {totalScoreData.map((item, index) => (
                                <React.Fragment key={index}>
                                  <NumberBox index={index} />
                                  <ImageProfileBox data={item} />
                                  <ScoreBox data={item} />
                                </React.Fragment>
                              ))}
                            </div>
                          </>
                          :
                          <>
                            <Stableford roundData={roundData} courseHoleData={courseHoleData} />
                          </>
                        }
                      </div>
                      :
                      <div className="p-5 text-center">
                        <LoadCircle size="md" variant="dark" />
                      </div>
                    }
                    {isCreator && (
                      <div className="col-xl-12">
                        <h3 className="fw-600 mb-3">Public Link</h3>

                        {roundData?.isLeaderBoardPublic ? (
                          <>
                            <div
                              className="mb-3"
                              style={{
                                ...cellStyle,
                                ...colorStyles.grey,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  margin: "10px 0",
                                  marginLeft: "10px",
                                }}
                              >
                                https://www.doonine.com/leader-board?round=
                                {roundId}
                              </div>
                            </div>
                            <button
                              className="btn bg-greylight"
                              onClick={() => handleDelete()}
                            >
                              <h4 className="fw-600 m-0 text-grey-700">
                                Delete
                              </h4>
                            </button>
                            <button
                              className="btn bg-greylight ms-3"
                              onClick={() => handleCopyToClipboard()}
                            >
                              <h4 className="fw-600 m-0 text-grey-700">Copy</h4>
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn bg-greylight"
                            onClick={() => handleCreateLink()}
                          >
                            <h4 className="fw-600 m-0 text-grey-700">Create</h4>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default LeaderBoard;
