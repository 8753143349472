import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";
import Form from "react-bootstrap/Form";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import ReactSwitch from "react-switch";

const switchStyle = {
  width: 44,
  height: 20,
  onColor: "#EE3D7F",
  boxShadow: "0 0 2px 1px #fff",
  handleDiameter: 16,
};

function FilterCourse({
  showSortAndFilter,
  setShowSortAndFilter,
  filter,
  setFilter,
}) {
  const [tempFilter, setTempFilter] = useState({
    officialCourse: {
      state: false,
    },
    startingPriceRange: {
      state: false,
      min: 500,
      max: 4500,
    },
    nightGolf: {
      state: false,
    },
    drivingRange: {
      state: false,
    },
    restaurant: {
      state: false,
    },
    banquetRooms: {
      state: false,
    },
    hotel: {
      state: false,
    },
  });

  const handleCloseSortAndFilter = () => {
    setShowSortAndFilter(false);
  };

  useEffect(() => {
    if (showSortAndFilter) {
      setTempFilter(filter);
    }
  }, [filter, showSortAndFilter]);

  const handleSwitchChange = (checked, switchId) => {
    setTempFilter((prevFilter) => ({
      ...prevFilter,
      [switchId]: {
        ...prevFilter[switchId],
        state: checked,
      },
    }));
  };

  const handleSubmit = () => {
    setFilter(tempFilter);
    handleCloseSortAndFilter();
  };

  const handleRangeChange = (values) => {
    setTempFilter((prevFilter) => ({
      ...prevFilter,
      startingPriceRange: {
        ...prevFilter.startingPriceRange,
        min: values[0],
        max: values[1],
      },
    }));
  };
  return (
    <>
      <Modal
        show={showSortAndFilter}
        onHide={handleCloseSortAndFilter}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="feather-filter font-md text-grey-500"></i>

            <h2
              style={{ display: "inline-block", marginLeft: "15px" }}
              className="fw-600 mb-0 mt-0"
            >
              Filter
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* Label : Caddie Star */}
            <label
              htmlFor="offcial-course-switch"
              className="mb-3 switch-label"
            >
              <span>
                <h5 className="fw-600 mb-0 mt-0 switch-text">
                  Offcial Course
                </h5>
                <h6 className="fw-600 mb-0 mt-0 text-muted">
                  Only officially registered golf courses with doonine.
                </h6>
              </span>

              {/* <Switch
                checked={tempFilter.officialCourse.state}
                onChange={(checked) =>
                  handleSwitchChange(checked, "officialCourse")
                }
                onColor="#EE3D7F"
                onHandleColor="#ffffff"
                offColor="#D3D3D3"
                offHandleColor="#888888"
                uncheckedIcon={false}
                checkedIcon={false}
                height={25}
                width={60}
                className="react-switch"
                id="offcial-course-switch"
              /> */}

              <ReactSwitch
                checked={tempFilter.officialCourse.state}
                onChange={(checked) =>
                  handleSwitchChange(checked, "officialCourse")
                }
                onColor={switchStyle.onColor}
                width={switchStyle.width}
                height={switchStyle.height}
                handleDiameter={switchStyle.handleDiameter}
                activeBoxShadow={switchStyle.boxShadow}
              />
            </label>

            {/* Label : Starting Price Range */}
            <label
              htmlFor="starting-price-range-switch"
              className="mb-3 switch-label"
            >
              <span>
                <h5 className="fw-600 mb-0 mt-0 switch-text">
                  Starting Price Range
                </h5>
              </span>

              {/* <ReactSwitch
                checked={tempFilter.startingPriceRange.state}
                onChange={(checked) =>
                  handleSwitchChange(checked, "startingPriceRange")
                }
                onColor="#EE3D7F"
                onHandleColor="#ffffff"
                offColor="#D3D3D3"
                offHandleColor="#888888"
                uncheckedIcon={false}
                checkedIcon={false}
                height={25}
                width={60}
                className="react-switch"
                id="starting-price-range-switch"
              /> */}
              <ReactSwitch
                checked={tempFilter.startingPriceRange.state}
                onChange={(checked) =>
                  handleSwitchChange(checked, "startingPriceRange")
                }
                onColor={switchStyle.onColor}
                width={switchStyle.width}
                height={switchStyle.height}
                handleDiameter={switchStyle.handleDiameter}
                activeBoxShadow={switchStyle.boxShadow}
              />
            </label>

            {/* Starting Price Range input */}
            {tempFilter.startingPriceRange.state && (
              <div className="slider-container d-flex align-items-center">
                <span>{`฿ ${tempFilter.startingPriceRange.min}`}</span>
                <Slider
                  range
                  min={0}
                  max={5000}
                  step={100}
                  value={[
                    tempFilter.startingPriceRange.min,
                    tempFilter.startingPriceRange.max,
                  ]}
                  onChange={handleRangeChange}
                  trackStyle={[{ backgroundColor: "#EE3D7F" }]}
                  handleStyle={[
                    { borderColor: "#EE3D7F" },
                    { borderColor: "#EE3D7F" },
                  ]}
                />
                <span>{`฿ ${tempFilter.startingPriceRange.max}`}</span>
              </div>
            )}

            {/* Label : Night Golf */}
            <label htmlFor="night-golf-checkbox" className="mb-3 switch-label">
              <span>
                <h5 className="fw-600 mb-0 mt-0 switch-text">
                  Night Golf
                </h5>
              </span>

              <input
                type="checkbox"
                checked={tempFilter.nightGolf.state}
                onChange={(e) =>
                  handleSwitchChange(e.target.checked, "nightGolf")
                }
                className="react-checkbox"
                id="night-golf-checkbox"
                style={{
                  width: "20px",
                  height: "20px",
                  accentColor: "#EE3D7F",
                }}
              />
            </label>

            {/* Label : Driving Range */}
            <label
              htmlFor="driving-range-checkbox"
              className="mb-3 switch-label"
            >
              <span>
                <h5 className="fw-600 mb-0 mt-0 switch-text">
                  Driving Range
                </h5>
              </span>

              <input
                type="checkbox"
                checked={tempFilter.drivingRange.state}
                onChange={(e) =>
                  handleSwitchChange(e.target.checked, "drivingRange")
                }
                className="react-checkbox"
                id="driving-range-checkbox"
                style={{
                  width: "20px",
                  height: "20px",
                  accentColor: "#EE3D7F",
                }}
              />
            </label>

            {/* Label : Restaurant */}
            <label htmlFor="restaurant-checkbox" className="mb-3 switch-label">
              <span>
                <h5 className="fw-600 mb-0 mt-0 switch-text">
                  Restaurant
                </h5>
              </span>

              <input
                type="checkbox"
                checked={tempFilter.restaurant.state}
                onChange={(e) =>
                  handleSwitchChange(e.target.checked, "restaurant")
                }
                className="react-checkbox"
                id="restaurant-checkbox"
                style={{
                  width: "20px",
                  height: "20px",
                  accentColor: "#EE3D7F",
                }}
              />
            </label>

            {/* Label : Banquet Rooms */}
            <label
              htmlFor="banquetRooms-checkbox"
              className="mb-3 switch-label"
            >
              <span>
                <h5 className="fw-600 mb-0 mt-0 switch-text">
                  Banquet Rooms
                </h5>
              </span>

              <input
                type="checkbox"
                checked={tempFilter.banquetRooms.state}
                onChange={(e) =>
                  handleSwitchChange(e.target.checked, "banquetRooms")
                }
                className="react-checkbox"
                id="banquetRooms-checkbox"
                style={{
                  width: "20px",
                  height: "20px",
                  accentColor: "#EE3D7F",
                }}
              />
            </label>

            {/* Label : hotel */}
            <label htmlFor="hotel-checkbox" className="mb-3 switch-label">
              <span>
                <h5 className="fw-600 mb-0 mt-0 switch-text">
                  Hotel
                </h5>
              </span>

              <input
                type="checkbox"
                checked={tempFilter.hotel.state}
                onChange={(e) => handleSwitchChange(e.target.checked, "hotel")}
                className="react-checkbox"
                id="hotel-checkbox"
                style={{
                  width: "20px",
                  height: "20px",
                  accentColor: "#EE3D7F",
                }}
              />
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <div
            className="btn lh-24 font-xsssss fw-700"
            onClick={handleCloseSortAndFilter}
            style={{ backgroundColor: "#F5F5F5", color: "#212529" }}
          >
            Close
          </div> */}
          
          <button
            className="btn bg-greylight "
            onClick={handleCloseSortAndFilter}

          >
            <h4 className="fw-600 m-0 text-grey-700 p-1">Close</h4>
          </button>

          {/* <div
            className="btn lh-24 font-xsssss fw-700 text-white"
            onClick={handleSubmit}
            style={{ backgroundColor: "#EE3D7F" }}
          >
            Apply
          </div> */}
          <button
            className="btn bg-current post-btn"
            style={{width: "80px"}}
            onClick={handleSubmit}
          >
            <h4 className="fw-600 m-0 text-white p-1">Apply</h4>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FilterCourse;
