export function convertHexToRGBA(hex, opacity) {
    // Remove hash symbol if present
    if (!hex) return
    hex = hex.replace('#', '');

    // Convert shorthand hex (e.g., #03F) to full hex (e.g., #0033FF)
    if (hex.length === 3) {
        hex = hex.split('').map((char) => char + char).join('');
    }

    // Convert hex to RGB values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Return RGBA string with opacity
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}