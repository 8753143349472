import { where, collection, getDocs, query, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";

export async function updateScoreCard(userList, roundId) {
    // work only scorecard have "row" field

    const scoreCollection = collection(firestore, 'scoreCards')

    const q = query(scoreCollection, where('roundId', '==', roundId));
    const scoreSnapshot = await getDocs(q);
    if (!scoreSnapshot.empty) {
        const scoreList = scoreSnapshot.docs.map(doc => ({ ...doc.data() }));

        for (const user of userList) {
            const foundScore = scoreList.find(item => item.row === user.row);
            if (foundScore) {
                foundScore.userId = user?.player?.uid || null;
                foundScore.caddieId = user?.caddie?.uid || null;

                const docRef = doc(scoreCollection, foundScore.docId);

                await updateDoc(docRef, {
                    userId: foundScore.userId,
                    caddieId: foundScore.caddieId
                })
            }
        }
    }
}