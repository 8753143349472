export function stepOneReducer(state, action) {
    switch (action.type) {
        case 'RENAME':
            action.updateStepData('stepOne', { roundName: action.payload });
            return {
                ...state,
                roundName: action.payload
            };
        case 'COURSE':
            action.updateStepData('stepOne', { course: action.payload });
            return {
                ...state,
                course: action.payload
            };
        case 'HOLES':
            action.updateStepData('stepOne', { holes: action.payload });
            return {
                ...state,
                holes: action.payload
            };
        case 'HOLESCOURSE':
            action.updateStepData('stepOne', { holesCourse: action.payload });
            return {
                ...state,
                holesCourse: action.payload
            };
        case 'MODE':
            action.updateStepData('stepOne', { gameMode: action.payload });
            return {
                ...state,
                gameMode: action.payload
            };
        case 'HANDICAP':
            action.updateStepData('stepOne', {
                handicap: action.payload.handicap,
                scoringType: action.payload.scoringType,
            });
            return {
                ...state,
                handicap: action.payload.handicap,
                scoringType: action.payload.scoringType,
            };
        case 'SCORRING':
            action.updateStepData('stepOne', { scoring: action.payload });
            return {
                ...state,
                scoring: action.payload
            };
        default:
            throw new Error('Unknown action type');
    }
}
