import React, { useRef, useState } from "react";
import { ReactSVG } from "react-svg";

function CourseDetailAboutBody({ courseData }) {
  return (
    <>
      <div className="card d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss">
        <h2 className="fw-600 mb-3 mt-1 ps-1 mb-3">
          {courseData?.courseName}
        </h2>
        <h5
          className="lh-28 mb-0 ps-2 mb-4"
          style={{
            lineHeight: "1.75",
            textAlign: "justify", // Ensures the text aligns nicely on both sides
            wordWrap: "break-word", // Ensures long words break to fit the container
            wordBreak: "break-word", // Break long words if necessary
            letterSpacing: "0.5px",
          }}
        >
          {courseData?.descriptionCourse.longDescriptionCourse}
        </h5>

        <div className="clearfix"></div>
        <h5 className="mt-2 mb-3 fw-600 text-grey-500 me-2">
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${courseData?.location?.latLong?._lat},${courseData?.location?.latLong?._long}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-grey-500 d-flex align-items-center"
          >
            <ReactSVG
              src="assets/icon/golf-course/Course-Location.svg"
              style={{
                width: "30px",
                marginRight: "10px",
                minWidth: "30px",
                flexShrink: 0,
              }}
            />
            {`${courseData?.location?.districtEng}, ${courseData?.location?.provinceEng}`}
          </a>
        </h5>
        <h5 className="mb-3 fw-600 text-grey-500 me-2">
          <a
            href={`tel:${courseData?.contact?.phone}`}
            className="text-grey-500 d-flex align-items-center"
          >
            <ReactSVG
              src="assets/icon/golf-course/Course-Tel.svg"
              style={{
                width: "30px",
                marginRight: "10px",
                minWidth: "30px",
                flexShrink: 0,
              }}
            />
            {formatPhoneNumber(courseData?.contact?.phone)}
          </a>
        </h5>
        <h5 className="mb-3 fw-600 text-grey-500 me-2">
          <a
            href={courseData?.contact?.website}
            target="_blank"
            rel="noopener noreferrer"
            className="text-grey-500 d-flex align-items-center"
            style={{
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}
          >
            <ReactSVG
              src="assets/icon/golf-course/Course-Website.svg"
              style={{
                width: "30px",
                marginRight: "10px",
                minWidth: "30px",
                flexShrink: 0,
              }}
            />
            {courseData?.contact?.website}
          </a>
        </h5>
        <h5 className="mb-3 fw-600 text-grey-500 me-2 d-flex align-items-center mb-4">
          <ReactSVG
            src="assets/icon/golf-course/Course-Email.svg"
            style={{
              width: "30px",
              marginRight: "10px",
              minWidth: "30px",
              flexShrink: 0,
            }}
          />
          {courseData?.contact?.email}
        </h5>

        <div className="clearfix"></div>

        <h2 className="fw-600 mb-3 mt-1 ps-1">Starting Price</h2>
        <span className="font-lg fw-700 pe-3 ls-2 lh-32 d-inline-block text-success float-left">
          <span className="font-xs">฿</span>{" "}
          {courseData?.features?.startingPriceRange?.minPrice}
          <span className="font-xsss text-grey-500">/ Rounds</span>
        </span>
      </div>
    </>
  );
}

export default CourseDetailAboutBody;

function formatPhoneNumber(number) {
  if (!number) return "";

  const cleaned = number.replace(/\D/g, "");

  if (number.length > 9) {
    const match = cleaned.match(/(\d{1,3})(\d{1,3})?(\d{1,4})?/);

    if (match) {
      return `${match[1]}-${match[2] || ""}-${match[3] || ""}`.trim();
    }
  } else {
    const match = cleaned.match(/(\d{1,2})(\d{1,3})?(\d{1,4})?/);

    if (match) {
      return `${match[1]}-${match[2] || ""}-${match[3] || ""}`.trim();
    }
  }

  return number;
}
