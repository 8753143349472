import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useState, useEffect } from 'react'
import { Toast } from 'react-bootstrap'
import { firestore } from './firebase/FirebaseConfig';
import { useSelector } from 'react-redux';

const NoCourseToast = () => {
    const userData = useSelector((state) => state.user.data);
    const [show, setShow] = useState(false);
    const currentPath = window.location.pathname;

    const showToast = () => setShow(true);
    const closeToast = () => setShow(false);

    useEffect(() => {
        async function getCaddieReq() {
            const caddieReqCollection = collection(firestore, 'caddieRequests');
            const q = query(caddieReqCollection, where('caddieId', '==', userData.uid), where('status', '!=', 'removed'));
            const reqSnapshot = await getDocs(q);
            if (reqSnapshot.empty) {
                if (currentPath !== '/caddie-req') {
                    showToast();
                }
            } else {
                closeToast();
            }
        }
        if (userData && userData.role === 'caddie') {
            getCaddieReq();
        }
    }, [currentPath, userData])


    return (
        <>
            {/* desktop */}
            <div className='d-none d-sm-block'>
                <Toast show={show} onClose={closeToast} className='position-fixed' style={{ bottom: 40, right: 0, zIndex: 3 }}>
                    <Toast.Header>
                        <strong className="me-auto">Warning</strong>
                    </Toast.Header>
                    <Toast.Body>You need to choose a course to access full feature.
                        <a href="/caddie-req"> click here</a>
                    </Toast.Body>
                </Toast>
            </div>
            {/* mobile */}
            <div className='d-sm-none d-block'>
                <Toast show={show} onClose={closeToast} className='position-fixed' style={{ top: 80, right: 0, zIndex: 3 }}>
                    <Toast.Header>
                        <strong className="me-auto">Warning</strong>
                    </Toast.Header>
                    <Toast.Body>You need to choose a course to access full feature.
                        <a href="/caddie-req"> click here</a>
                    </Toast.Body>
                </Toast>
            </div>
        </>
    )
}

export default NoCourseToast