import React, { useState, useEffect, useRef, useMemo } from 'react'
import { firestore } from '../../../../components/firebase/FirebaseConfig';
import { collection, query, where, getDocs, or, and } from 'firebase/firestore';
import Load from '../../../../components/Load';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import { ListGroup, Modal } from 'react-bootstrap';

const PlayerModal = ({ isOpen, handleClose, selectedPlayer, inplayPlayer }) => {

    const userData = useSelector((state) => state.user.data);

    const [playerList, setPlayerList] = useState([]);
    const [loadingUser, setLoadingUser] = useState(true);

    const playerIdRef = useRef([]);
    const favPlayerRef = useRef([]);
    const friendPlayerRef = useRef([]);
    const PlayerDataRef = useRef([]);

    useEffect(() => {

        async function getYourfriend() {
            friendPlayerRef.current = []
            try {
                const friendCollection = collection(firestore, 'friendRequests');
                const q = query(friendCollection,
                    or(
                        and(
                            where('fromUserId', '==', userData.uid),
                            where('status', '==', 'accept')
                        ),
                        and(
                            where('toUserId', '==', userData.uid),
                            where('status', '==', 'accept')
                        )
                    )
                )
                const friendSnapshot = await getDocs(q);
                if (!friendSnapshot.empty) {
                    friendSnapshot.forEach(doc => {
                        const data = doc.data();
                        if (data.toUserId === userData.uid) {
                            friendPlayerRef.current.push(data.fromUserId);
                        } else {
                            friendPlayerRef.current.push(data.toUserId);
                        }
                    });
                }

                await getYourFav()
                await getPlayerData()
                sortPlayer()
                // console.log('friend ', friendPlayerRef.current);
                setLoadingUser(false);
            } catch (error) {
                setLoadingUser(false);
                console.error('Fail to get friend caddie', error);
            }
        }

        const getYourFav = async () => {

            favPlayerRef.current = []
            try {
                const favCollection = collection(firestore, 'favoritePlayers');
                const q = query(favCollection,
                    where('userId', '==', userData.uid),
                    where('status', '==', 'active'));
                const favSnapshot = await getDocs(q);
                if (!favSnapshot.empty) {
                    favSnapshot.forEach(doc => {
                        const data = doc.data();
                        if (!favPlayerRef.current.includes(data.playerId)) {
                            favPlayerRef.current.push(data.playerId)
                        }
                    });
                }
                // console.log('fav ', favPlayerRef.current);

            } catch (error) {
                console.error('Fail to get fav caddie', error);
            }
        }

        const getPlayerData = async (playerList = [...friendPlayerRef.current]) => {
            if (!playerList.length) return;

            playerIdRef.current = playerList;
            const playerDataListArray = []
            try {
                const userCollection = collection(firestore, 'users');
                const q = query(userCollection, where('uid', 'in', playerList), where('role', '==', 'player'));
                const userSnapshot = await getDocs(q);
                if (!userSnapshot.empty) {
                    userSnapshot.forEach(doc => {
                        playerDataListArray.push(doc.data())
                    });
                    PlayerDataRef.current = playerDataListArray
                    // sortCaddie()
                }
            } catch (error) {
                console.error('Fail to get caddie data', error);
            }
        }

        function sortPlayer() {
            const updatedPlayerDataList = PlayerDataRef.current.map(player => {
                return {
                    ...player,
                    isFav: favPlayerRef.current.includes(player.uid),
                    isFriend: friendPlayerRef.current.includes(player.uid)
                };
            });
            // console.log(updatedPlayerDataList);

            setPlayerList(() => {
                return updatedPlayerDataList.sort((a, b) => {

                    if (a.isFav !== b.isFav) {
                        return b.isFav - a.isFav;
                    }
                    if (a.isFriend !== b.isFriend) {
                        return b.isFriend - a.isFriend;
                    }
                    return 0;
                });
            });
        }

        getYourfriend()

    }, [userData])


    const renderPlayerList = useMemo(() => {
        const handleCaddieClick = (player) => {
            if (!inplayPlayer.includes(player.uid)) {
                selectedPlayer(player);
                handleClose();
            }
        }
        return (
            <>
                <style>
                    {`.sel-player-item:hover{
background-color: rgba(155, 155, 155, 0.350)
}`}
                </style>
                <ListGroup>
                    {playerList.map((player, index) =>
                        <ListGroup.Item key={index} className='sel-player-item pointer' onClick={() => handleCaddieClick(player)}
                            style={{ backgroundColor: inplayPlayer.includes(player.uid) ? 'rgba(155, 155, 155, 0.350)' : '' }}
                        >
                            <div className='d-flex align-items-center'>
                                <div className='col-3'>
                                    <img src={player.profileImage} alt={`user${index}`} width={50} height={50} className='rounded-circle player-color-outline' style={{ objectFit: 'cover' }} />
                                </div>
                                <div className='col-6'>
                                    <h4 className='fw-600'>{`${player.firstName} ${player.lastName}`}</h4>
                                </div>
                                <div className='col-3 text-end'>
                                    {player.isFav ?
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-favCaddie.svg' wrapper='svg' width={30} height={30} />
                                        : null
                                    }
                                    {/* {player.isFriend ?
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-friendCaddie.svg' wrapper='svg' width={30} height={30} />
                                        : null
                                    } */}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </>
        )
    }, [playerList, handleClose, inplayPlayer, selectedPlayer])

    return (
        <>
            <style>
                {`.sel-player-card-list:hover {
background-color: #f5f5f5a1;
}
`}
            </style>
            <Modal show={isOpen} onHide={handleClose} centered animation={false}>
                <Modal.Header>
                    <Modal.Title className='d-flex justify-content-between align-items-center gap-4 w-100'>
                        <h2 className='fw-600 text-nowrap'>Select Player</h2>
                        <div className="search-form-2 flex-grow-1">
                            <i className="ti-search font-xss"></i>
                            <input
                                type="text"
                                className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                                placeholder="Search here."
                            />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='right-scroll-bar' style={{ maxHeight: '60vh' }}>
                        {!loadingUser ?
                            renderPlayerList
                            :
                            <Load />
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PlayerModal