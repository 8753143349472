export const en = {
    translation: {
        Login: 'Login',
        language: 'Language',
        Thai: 'Thai',
        English: 'English',
        Phone: 'Phone',
        Forgot_your_Password: 'Forgot your Password ?',
        Remember_me: 'Remember me',
        email: 'Email',
        password: 'Password',
        do_not_have_account: 'Do not have account ?',
        register:'Register'
    }
}
