import clsx from 'clsx';
import React, { useEffect, useState, useRef } from 'react'
import { useWizard } from '../wizardContext';
import { ReactSVG } from 'react-svg';
import { timeConverter, epochToDateTime } from '../module/covertTime';
import _ from 'lodash';

const EditStepThreeByPlayer = ({ isPast }) => {
  const { stepData, updateStepData } = useWizard();
  const data1 = stepData.stepOne;
  const data3 = stepData.stepThree;

  const [teeTimeBtn, setTeeTimeBtn] = useState(data3.teeTimeBtn);
  const { date, time } = epochToDateTime(data3.scheduleTime);
  const dateRef = useRef(null);
  const timeRef = useRef(null);

  const [groupChatBtn, setGroupChatBtn] = useState(data3.groupChatBtn);
  const groupChatNameRef = useRef(null);

  const [caddieReqBtn, setCaddieReqBtn] = useState(data3.caddieReqBtn);

  const [imageUrl, setImageUrl] = useState(data3.imageFile ? URL.createObjectURL(data3.imageFile) : data3.imageUrl);

  const handleTeeTimeChange = (teeTime) => {
    setTeeTimeBtn(teeTime);
    updateStepData('stepThree', { teeTimeBtn: teeTime })
  }

  const handleGroupChatChange = (groupChat) => {
    setGroupChatBtn(groupChat);
    if (groupChat === 'create') {
      updateStepData('stepThree', {
        groupChatBtn: groupChat,
        groupChatName: data3.groupChatName === '' ? `[Round Chat]${data1.roundName}` : data3.groupChatName
      })
      groupChatNameRef.current.value = data3.groupChatName === '' ? `[Round Chat]${data1.roundName}` : data3.groupChatName
    } else {
      updateStepData('stepThree', {
        groupChatBtn: groupChat,
        groupChatName: ''
      })
    }
  }

  useEffect(() => {
    updateStepData('stepThree', {
      groupChatName: data3.groupChatName === '' ? `[Round Chat]${data1.roundName}` : data3.groupChatName
    })
  }, [data1.roundName])


  const handleDateTimeChange = () => {
    const sec = timeConverter(dateRef.current.value, timeRef.current.value);
    updateStepData('stepThree', { scheduleTime: sec })
  }

  const handleCaddieReqChange = (caddieReq) => {
    setCaddieReqBtn(caddieReq);
    updateStepData('stepThree', { caddieReqBtn: caddieReq });
  }

  const handleGroupChatNameChange = _.debounce(() => {
    updateStepData('stepThree', { groupChatName: groupChatNameRef.current.value });
  }, 100)

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setImageUrl(URL.createObjectURL(file));
    updateStepData('stepThree', { imageFile: file });
  }

  const handleRemoveImage = () => {
    setImageUrl(null);
    updateStepData('stepThree', { imageFile: null, imageUrl: null });
  }

  return (
    <>
      <section className='tee-time-sect mb-4'>
        <h4 className='fw-600 mb-1'>Tee Time</h4>
        <div className='d-flex align-items-center gap-2 mb-3' style={{ pointerEvents: isPast ? 'none' : 'auto' }}>
          <div className={clsx('col-sm-3 step-three-radio-card pointer p-3 p-sm-4', {
            'active': teeTimeBtn === 'now',
            'disable': isPast
          })}
            onClick={() => handleTeeTimeChange('now')}
          >
            <h6 className='fw-600'>Start Now</h6>
            <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
          </div>
          <div className={clsx('col-sm-3 step-three-radio-card pointer p-3 p-sm-4', {
            'active': teeTimeBtn === 'schedule',
            'disable': isPast
          })}
            onClick={() => handleTeeTimeChange('schedule')}
          >
            <h6 className='fw-600'>Create Schedule</h6>
            <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
          </div>
        </div>

        <div className={clsx({ 'd-none': teeTimeBtn !== 'schedule' })}>
          <div className='d-sm-flex align-items-center mb-1'>
            <label className='form-label col-sm-2 col-3' htmlFor="date-input"><h4 className='fw-600'>Date</h4></label>
            <input type="date" name="date-input" id="date-input" className='form-control' style={{ lineHeight: "normal" }} disabled={isPast} defaultValue={date} ref={dateRef} onChange={handleDateTimeChange} />
          </div>

          <div className='d-sm-flex align-items-center'>
            <label className='form-label col-sm-2 col-3' htmlFor="time-input"><h4 className='fw-600'>Time</h4></label>
            <input type='time' name="time-input" id="time-input" className='form-control' style={{ lineHeight: "normal" }} disabled={isPast} defaultValue={time} ref={timeRef} onChange={handleDateTimeChange} />
          </div>
        </div>

      </section>

      <section className='group-chat-sect mb-4'>
        <h4 className='fw-600 mb-1'>Group Chat</h4>
        <div className='d-flex align-items-center gap-2 mb-3'>
          <div className={clsx('col-sm-3 step-three-radio-card pointer p-3 p-sm-4', { 'active': groupChatBtn === 'create' })}
            onClick={() => handleGroupChatChange('create')}
          >
            <h6 className='fw-600'>Create</h6>
            <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
          </div>
          <div className={clsx('col-sm-3 step-three-radio-card pointer p-3 p-sm-4', { 'active': groupChatBtn === 'disable' })}
            onClick={() => handleGroupChatChange('disable')}
          >
            <h6 className='fw-600'>Disable</h6>
            <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
          </div>
        </div>

        <div className={clsx({ 'd-none': groupChatBtn !== 'create' })}>
          <div className='d-sm-flex align-items-center'>
            <label className='form-label col-sm-2 col-3' htmlFor="chat-name-input"><h4 className='fw-600'>Name</h4></label>
            <input type="text" name="chat-name-input" id="chat-name-input" onChange={handleGroupChatNameChange} defaultValue={data3.groupChatName} className='form-control' ref={groupChatNameRef} />
          </div>
        </div>
      </section>

      <section className='caddie-req-sect mb-4'>
        <h4 className='fw-600'>Caddie Request</h4>
        <h6 className='fw-600 text-muted mb-1'>Would you like the caddies at the course to see and ask to join the round?</h6>
        <div className='d-flex align-items-center flex-wrap flex-sm-nowrap gap-2 mb-3'>
          <div className={clsx('col-sm-3 step-three-radio-card pointer p-3 p-sm-4', { 'active': caddieReqBtn === 'all' })}
            onClick={() => handleCaddieReqChange('all')}
          >
            <h6 className='fw-600'>All Caddie</h6>
            <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
          </div>
          <div className={clsx('col-sm-3 step-three-radio-card pointer p-3 p-sm-4', { 'active': caddieReqBtn === 'favorite' })}
            onClick={() => handleCaddieReqChange('favorite')}
          >
            <h6 className='fw-600'>Favorite</h6>
            <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
          </div>
          <div className={clsx('col-sm-3 step-three-radio-card pointer p-3 p-sm-4', { 'active': caddieReqBtn === 'disable' })}
            onClick={() => handleCaddieReqChange('disable')}
          >
            <h6 className='fw-600'>Disable</h6>
            <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
          </div>
        </div>
      </section>

      <section className="round-cover-sect mb-4">
        <h4 className='fw-600 mb-1'>Round Cover</h4>

        <input type="file" name="round-cover-input" id="round-cover-input" accept='image/*' className='d-none' onChange={(e) => handleImageChange(e)} />
        {imageUrl ? (
          <div className='position-relative p-1 border' style={{ width: 180 }}>
            <img src={imageUrl} alt="cover-img" className='w-100' style={{ objectFit: 'contain' }} />
            <ReactSVG src='assets/images/dooNineIconV2/others/post-close.svg' width={25} height={25} wrapper='svg' className='position-absolute pointer' style={{ top: 2, right: 2 }}
              onClick={handleRemoveImage}
            />
          </div>
        ) :
          (
            <ReactSVG src='assets/images/dooNineIconV2/others/create_Post_Add_Photo.svg' wrapper='svg' width={80} height={80}
              className='add-round-cover-btn'
              onClick={() => document.getElementById('round-cover-input').click()}
            />
          )}
      </section>
    </>
  )
}

export default EditStepThreeByPlayer