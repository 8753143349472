import React from 'react'
import { ReactSVG } from 'react-svg';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const StepTwoCard = ({ rowData, isJustShow, handleSwap }) => {

    if (!isJustShow) {
        return (
            <Card className='mb-3 border-0 main-step-two-card'>
                {rowData.map((row, index) =>
                    <Card.Body key={index} className='main-step-two-card-body bg-white d-block d-sm-grid theme-dark-bg my-1'>
                        {row.row !== 0 ?
                            <>
                                {/* player */}
                                {row.player ?
                                    <div className='player-container theme-light-bg d-flex align-items-center flex-wrap p-1'>
                                        <figure className='d-flex align-items-center mb-0'>
                                            <img src={row.player.profileImage} alt="user" className='user-img player-color-outline' />
                                            <h5 className='fw-600 ms-1'>{`${row.player.firstName} ${row.player.lastName}`}</h5>
                                        </figure>
                                    </div>
                                    :
                                    <div className='player-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addPlayer_w.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                }
                            </>
                            :
                            <div className='player-container d-flex align-items-center flex-wrap p-1'>
                                <figure className='d-flex align-items-center mb-sm-0'>
                                    <img src={row.player.profileImage} alt="user" className='user-img player-color-outline' />
                                    <h5 className='fw-600 ms-1'>{`${row.player.firstName} ${row.player.lastName}`}
                                        <span><h6 className='fw-600 text-muted'>Round Creator</h6></span>
                                    </h5>
                                </figure>
                            </div>
                        }

                        {/* caddie */}
                        {row.caddie ?
                            <div className='caddie-container theme-light-bg d-flex align-items-center flex-wrap p-1'>
                                <figure className='d-flex align-items-center mb-0 pointer' onClick={() => handleSwap(row.row)}>
                                    <img src={row.caddie.profileImage} alt="user" className='user-img caddie-color-outline' />
                                    <h5 className='fw-600 ms-1'>{`${row.caddie.firstName} ${row.caddie.lastName}`}</h5>
                                </figure>
                            </div>
                            :
                            <>
                                {row.row !== 0 ?
                                    <div className='caddie-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_w.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                            onClick={() => handleSwap(row.row)}
                                        />
                                    </div>
                                    :
                                    <div className='caddie-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_p.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                            onClick={() => handleSwap(row.row)}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </Card.Body>
                )}
            </Card>
        )
    } else {
        return (
            <Card className='mb-3 border-0 main-step-two-card'>
                {rowData.map((row, index) =>
                    <Card.Body key={index} className='main-step-two-card-body bg-white d-block d-sm-grid theme-dark-bg my-1'>
                        {row.row !== 0 ?
                            <>
                                {/* player */}
                                {row.player ?
                                    <div className='player-container theme-light-bg d-flex align-items-center flex-wrap p-1'>
                                        <figure className='d-flex align-items-center mb-0'>
                                            <img src={row.player.profileImage} alt="user" className='user-img player-color-outline' />
                                            <h5 className='fw-600 ms-1'>{`${row.player.firstName} ${row.player.lastName}`}</h5>
                                        </figure>
                                    </div>
                                    :
                                    <div className='player-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addPlayer_w.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                }
                            </>
                            :
                            <div className='player-container d-flex align-items-center flex-wrap p-1'>
                                <figure className='d-flex align-items-center mb-sm-0'>
                                    <img src={row.player.profileImage} alt="user" className='user-img player-color-outline' />
                                    <h5 className='fw-600 ms-1'>{`${row.player.firstName} ${row.player.lastName}`}
                                        <span><h6 className='fw-600 text-muted'>Round Creator</h6></span>
                                    </h5>
                                </figure>
                            </div>
                        }

                        {/* caddie */}
                        {row.caddie ?
                            <div className='caddie-container theme-light-bg d-flex align-items-center flex-wrap p-1'>
                                <figure className='d-flex align-items-center mb-0 pointer'>
                                    <img src={row.caddie.profileImage} alt="user" className='user-img caddie-color-outline' />
                                    <h5 className='fw-600 ms-1'>{`${row.caddie.firstName} ${row.caddie.lastName}`}</h5>
                                </figure>
                            </div>
                            :
                            <>
                                {row.row !== 0 ?
                                    <div className='caddie-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_w.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                    :
                                    <div className='caddie-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_p.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                }
                            </>
                        }
                    </Card.Body>
                )}
            </Card>
        )
    }
}

export const StepTwoCardCaddie = ({ rowData, isJustShow, handleSwap }) => {
    const userData = useSelector((state) => state.user.data);
    const filteredCaddie = rowData.filter((items) => items?.caddie?.uid !== userData.uid);

    if (!isJustShow) {
        return (
            <Card className='mb-3 border-0 main-step-two-card'>
                {filteredCaddie.map((row, index) =>
                    <Card.Body key={index} className='main-step-two-card-body bg-white d-block d-sm-grid theme-dark-bg my-1'>

                        {/* player */}
                        {row.player ?
                            <div className='player-container theme-light-bg d-flex align-items-center flex-wrap p-1'>
                                <figure className='d-flex align-items-center mb-0'>
                                    <img src={row?.player?.profileImage} alt="user" className='user-img player-color-outline' />
                                    <h5 className='fw-600 ms-1'>{`${row?.player?.firstName} ${row?.player?.lastName}`}</h5>
                                </figure>
                            </div>
                            :
                            <>
                                {row.row !== 0 ?
                                    <div className='player-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addPlayer_w.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                    :
                                    <div className='player-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addPlayer_p.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                }
                            </>
                        }

                        {/* caddie */}
                        {row.caddie ?
                            <>
                                {row.row !== 0 ?
                                    <>
                                        <div className='caddie-container theme-light-bg d-flex align-items-center flex-wrap p-1'>
                                            <figure className='d-flex align-items-center mb-0' onClick={() => handleSwap(row.row)}>
                                                <img src={row.caddie.profileImage} alt="user" className='user-img caddie-color-outline' />
                                                <h5 className='fw-600 ms-1'>{`${row.caddie.firstName} ${row.caddie.lastName}`}</h5>
                                            </figure>
                                        </div>
                                    </>
                                    :
                                    <div className='caddie-container d-flex align-items-center flex-wrap p-1'>
                                        <figure className='d-flex align-items-center mb-sm-0'>
                                            <img src={row.caddie.profileImage} alt="user" className='user-img caddie-color-outline' />
                                            <h5 className='fw-600 ms-1'>{`${row.caddie.firstName} ${row.caddie.lastName}`}
                                                <span><h6 className='fw-600 text-muted'>Round Creator</h6></span>
                                            </h5>
                                        </figure>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {row.row !== 0 ?
                                    <div className='caddie-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_w.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                            onClick={() => handleSwap(row.row)}
                                        />
                                    </div>
                                    :
                                    <div className='caddie-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_p.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                            onClick={() => handleSwap(row.row)}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </Card.Body>
                )}
            </Card>
        )
    } else {
        return (
            <Card className='mb-3 border-0 main-step-two-card'>
                {filteredCaddie.map((row, index) =>
                    <Card.Body key={index} className='main-step-two-card-body bg-white d-block d-sm-grid theme-dark-bg my-1'>

                        {/* player */}
                        {row.player ?
                            <div className='player-container theme-light-bg d-flex align-items-center flex-wrap p-1'>
                                <figure className='d-flex align-items-center mb-0'>
                                    <img src={row?.player?.profileImage} alt="user" className='user-img player-color-outline' />
                                    <h5 className='fw-600 ms-1'>{`${row?.player?.firstName} ${row?.player?.lastName}`}</h5>
                                </figure>
                            </div>
                            :
                            <>
                                {index > 0 ?
                                    <div className='player-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addPlayer_w.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                    :
                                    <div className='player-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addPlayer_p.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                }
                            </>
                        }

                        {/* caddie */}
                        {row.caddie ?
                            <>
                                {row.row !== 0 ?
                                    <>
                                        <div className='caddie-container theme-light-bg d-flex align-items-center flex-wrap p-1 position-relative'>
                                            <figure className='d-flex align-items-center mb-0'>
                                                <img src={row.caddie.profileImage} alt="user" className='user-img caddie-color-outline' />
                                                <h5 className='fw-600 ms-1'>{`${row.caddie.firstName} ${row.caddie.lastName}`}</h5>
                                            </figure>
                                        </div>
                                    </>
                                    :
                                    <div className='caddie-container d-flex align-items-center flex-wrap p-1'>
                                        <figure className='d-flex align-items-center mb-sm-0'>
                                            <img src={row.caddie.profileImage} alt="user" className='user-img caddie-color-outline' />
                                            <h5 className='fw-600 ms-1'>{`${row.caddie.firstName} ${row.caddie.lastName}`}
                                                <span><h6 className='fw-600 text-muted'>Round Creator</h6></span>
                                            </h5>
                                        </figure>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {index > 0 ?
                                    <div className='caddie-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_w.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                    :
                                    <div className='caddie-container-none theme-light-bg'>
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_p.svg'
                                            wrapper='svg'
                                            width={50}
                                            className='pointer'
                                        />
                                    </div>
                                }
                            </>
                        }
                    </Card.Body>
                )}
            </Card>
        )
    }
}
