import React, { useEffect, useState } from 'react'

import MatchPlayGross from './scoring/matchplay/MatchPlayGross';
import MatchPlayTopar from './scoring/matchplay/MatchPlayTopar';

import { useScore } from './ScoreContext/ScoreContext';
// import 'react-sticky-table/dist/react-sticky-table.css';

const ScoreMatchPlay = () => {

    const {roundData, playerGameMode } = useScore();

    if (playerGameMode) {
        switch (playerGameMode.scoring) {
            case "Gross":
                return <MatchPlayGross />
            case "To Par":
                return <MatchPlayTopar />

            default:
                console.warn('Incorrect scoring', playerGameMode.scoring);
                break;
        }
    } else {
        switch (roundData.scoring) {
            case "Gross":
                return <MatchPlayGross />
            case "To Par":
                return <MatchPlayTopar />

            default:
                console.warn('Incorrect scoring', roundData.scoring);
                break;
        }
    }
}

export default ScoreMatchPlay