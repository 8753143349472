import { scoringDef } from "./holeDef";

export const handleModeChange = (mode, updateStepData, dispatch) => {
    let gameMode = "";
    if (!mode.value) {
        gameMode = mode;
    } else {
        gameMode = mode.value
    }

    dispatch({ type: 'MODE', payload: gameMode, updateStepData: updateStepData, });

    // reset scorring list
    const scoringList = scoringDef(gameMode, 'scratch');

    // reset handicap
    dispatch({
        type: 'HANDICAP',
        payload: {
            handicap: 'scratch',
            scoringType: scoringList,
        },
        updateStepData: updateStepData,
    });
    //reset to default scorring
    dispatch({ type: 'SCORRING', payload: scoringList[0], updateStepData: updateStepData, });

}

export const handleHandicapChange = (e, updateStepData, state, dispatch) => {
    const handicapVal = e.target.value;
    const scoringList = scoringDef(state.gameMode, handicapVal);

    dispatch({
        type: 'HANDICAP',
        payload: {
            handicap: handicapVal,
            scoringType: scoringList
        },
        updateStepData: updateStepData,
    });
    dispatch({ type: 'SCORRING', payload: scoringList[0], updateStepData: updateStepData, });

}

export const handleHoleChange = (hole, updateStepData, dispatch) => {
    let numberOfHole = ""
    if (!hole.numberOfHole) {
        numberOfHole = hole;
    } else {
        numberOfHole = hole.numberOfHole;
    }

    dispatch({ type: 'HOLES', payload: numberOfHole, updateStepData: updateStepData, });
    dispatch({ type: 'HOLESCOURSE', payload: "", updateStepData: updateStepData, }); //reset holes course
}

export const handleScoringChange = (e, updateStepData, dispatch) => {
    const scoringVal = e.target.value;
    dispatch({ type: 'SCORRING', payload: scoringVal, updateStepData: updateStepData, });
}

export const handleRoundNameChange = (e, updateStepData, dispatch) => {
    dispatch({ type: 'RENAME', payload: e.target.value, updateStepData: updateStepData, });
}