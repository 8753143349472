import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { firestore } from '../../../../../components/firebase/FirebaseConfig'
import LoadCircle from '../../../../../components/LoadCircle'
import { epochToDateTime } from '../../wizard/module/covertTime'
import { ReactSVG } from 'react-svg'

const CourseRender = ({ roundData }) => {

    const [courseData, setCourseData] = useState(null);
    const { time, day, month } = epochToDateTime(roundData.scheduleTime);
    const [courseHolesName, setCourseHolesName] = useState("");

    useEffect(() => {
        async function getCourseData() {
            try {
                const courseCollection = collection(firestore, 'courses');
                const q = query(courseCollection, where('id', '==', roundData.course));
                const courseSnapshot = await getDocs(q);
                if (!courseSnapshot.empty) {
                    setCourseData(courseSnapshot.docs[0].data());
                }
            } catch (error) {
                console.error('Fail to get course data', error);
            }
        }
        async function getCourseHolesData() {
            try {
                const courseHolesCollection = collection(firestore, 'courseHoles');
                const q = doc(courseHolesCollection, roundData.holesCourse);
                const courseHolesSnapshot = await getDoc(q);
                if (courseHolesSnapshot.exists) {
                    setCourseHolesName(courseHolesSnapshot.data().courseHoleName);
                }
            } catch (error) {
                console.error('Fail to get Course Holes data', error);

            }
        }
        if (roundData) {
            getCourseData();
            getCourseHolesData();
        }
    }, [roundData])

    // console.log('Round data', roundData);
    // console.log('Course data', courseData);


    return (
        <>
            {courseData ?
                <section className='bg-light theme-dark-bg p-3 position-relative'>
                    <div className='mb-2 me-2'>
                        <h3 className='fw-600'>{roundData.roundName}</h3>
                        <h6 className='fw-600 text-muted'>{`${roundData.holes} (${courseHolesName}), ${roundData.gameMode}(${roundData.scoring})`}</h6>
                    </div>
                    <div className='create-round-req-body px-3 mb-2 bg-white theme-light-bg'>
                        <div className='req-course-data px-0 row' >
                            <div className='col-sm-2 col-3 p-0 d-flex justify-content-center align-items-center'>
                                {/* desktop */}
                                <img src={courseData.profileImage} alt="course-profile" width={70} height={70} className='rounded-circle my-2 d-sm-block d-none' />
                                {/* mobile */}
                                <img src={courseData.profileImage} alt="course-profile" width={50} height={50} className='rounded-circle my-2 d-sm-none d-block' />
                            </div>
                            <div className='col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center'>
                                <div>
                                    {/* desktop */}
                                    <span className='fw-600 fs-4 d-sm-block d-none'>{courseData.courseName}</span>
                                    {/* mobile */}
                                    <span className='fw-600 fs-5 d-sm-none d-block'>{courseData.courseName}</span>

                                    <span className='fw-600 fs-6 d-sm-block d-none'>{`${courseData.location?.districtEng}, ${courseData.location.provinceEng}`}</span>
                                </div>
                            </div>
                            <div className='col-sm-2 col-3 px-0 px-sm-2'>
                                <div className='pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column' style={{ backgroundColor: '#E59E49' }}>
                                    {roundData.teeTime === 'schedule' ?
                                        <>
                                            {/* desktop */}
                                            <div className='d-sm-block d-none w-100 py-1'>
                                                <h4 className='fw-600 text-white text-center'>{month}</h4>
                                                <h1 className='fw-700 text-white text-center'>{day}</h1>
                                                <div className='bg-white text-center w-100'>
                                                    <span className='fw-600 fs-4' style={{ color: '#E59E49' }}>{time}</span>
                                                </div>
                                            </div>
                                            {/* mobile */}
                                            <div className='d-sm-none d-block w-100 py-1'>
                                                <h5 className='fw-600 text-white text-center'>{month}</h5>
                                                <h2 className='fw-700 text-white text-center'>{day}</h2>
                                                <div className='bg-white text-center w-100'>
                                                    <span className='fw-600 fs-5' style={{ color: '#E59E49' }}>{time}</span>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className='bg-white text-center px-2'>
                                            <span className='fw-600 fs-4' style={{ color: '#E59E49' }}>Start Now</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-menu.svg'
                        wrapper='svg'
                        width={40}
                        height={40}
                        className='position-absolute pointer d-none'
                        style={{ top: 0, right: 0 }}
                    />
                </section>
                :
                <div className='text-center'>
                    <LoadCircle size='md' variant='dark' />
                </div>
            }
        </>
    )
}

export default CourseRender